<template id="view_giocatore">
	<section class="giocatore">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card" v-if="rosa">
				<div class="card-body">

					<div class="row">
						<div class="col">
							<div class="media">
								<img v-bind:src="rosa.avatar_url" class="" height="100">
								<div class="media-body align-self-center ml-3">
									<h3 class="text-primary mb-0"><strong><a :href="'#/medical/player/' + $route.params.id">{{ rosa.cognome }} {{ rosa.nome }}</a></strong></h3>
									<small class="text-muted">{{ rosa.last_carriera_sportiva.ruolo_ideale && rosa.last_carriera_sportiva.ruolo_ideale.length > 0 ? rosa.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</small>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="card-body">

					<div class="row" v-if="showTable">
						<div class="col-10">	
							<b-table striped show-empty
							ref="injuriesTable"
							:items="injuries"
							:fields="fields"
							>
							<template #cell(data)="row"><a href="#" @click="selectInjury(row.item.id)">{{ row.value | formatDate }}</a></template>
							<template #cell(return)="row">{{ row.value | formatDate }}</template>
							<template #cell(reinjury)="row">{{ row.value ? reinjury_label[row.value] : 'No'}}</template>
							<template #cell(pathology)="row">{{ row.value ? row.value.name : 'N/D' }}</template>
						</b-table>
					</div>
					<div class="col-2">
						<b-button variant="link" class="w-100 d-flex align-items-center justify-content-center" style="border: 5px dashed #e5e5e5;height: 200px;" @click="newInjury">
							<div class="text-center">
								<p class="mb-0"><i class="fa fa-plus-circle text-muted fa-5x"></i></p>
								<strong class="text-uppercase text-muted">NEW INJURY</strong>
							</div>
						</b-button>
					</div>
				</div>

				<div class="row" v-if="!showTable">
					<div class="col">
						<b-form @submit="onSubmit">
							<b-card :title="injury.id ? 'Edit Injury' : 'New Injury'">
								<b-row>
									<b-col>
										<b-form-group label="Date of Injury *">
											<b-form-input type="date" v-model="injury.data"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group label="Date of return of full participation">
											<b-form-input type="date" v-model="injury.return"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="2">
										<b-form-group description="Is this a second injury?">
											<label>Second Injury</label> <span class="color-rosso" style="cursor:pointer;"><i class="fa fa-info-circle" aria-hidden="true" @click="$refs.infoModal.show()"></i></span>
											<b-form-select v-model="injury.reinjury">
												<option :value="0">No</option>
												<option :value="2">Subsequent new injury</option>
												<option :value="3">Subsequent local injury</option>
												<option :value="4">Exacerbation</option>
												<option :value="1">Reinjury</option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group v-if="injury.reinjury" label="Previous injuries">
											<b-form-select v-model="injury.id_injury">
												<option :value="null">Select</option>
												<option v-if="!injury.id || (injury.id && injury.id != item.id)" v-for="item in injuries" :value="item.id">Injury of {{item.data | formatDate }} - {{ item.pathology ? item.pathology.name : 'N/D' }} - Data of return {{ item.return | formatDate }}</option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group v-if="injury.reinjury" label="Date of return from previous injury">
											<b-form-input type="date" v-model="injury.return_reinjury"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="2">
										<b-form-group label="Body Area">
											<b-form-select v-model="region_id">
												<option :value="null">Select</option>
												<option v-for="item in regions" :value="item.id"> {{ item.name }} </option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col cols="1">
										<b-form-group label="Side">
											<b-form-select v-model="injury.side">
												<option :value="null">Select</option>
												<option value="left">Left</option>
												<option value="center">Center</option>
												<option value="right">Right</option>
												<option value="N/A">N/A</option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group label="Classification">
											<b-form-select v-model="region_class_id">
												<option :value="null">Select</option>
												<option v-for="item in classifications" :value="item.pivot.id"> {{ item.name }} </option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group label="Pathologies">
											<b-form-select v-model="injury.id_pathology">
												<option :value="null">Select</option>
												<option v-for="item in pathologies" :value="item.id"> {{ item.name }} </option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col cols="1">
										<b-form-group label="OSICS code">
											<b-form-input v-model="osics_code" readonly></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card>
							<b-card title="Muscle injury details" v-if="classification_id == 11" class="mt-1">
								<b-row>
									<b-col cols="2">
										<b-form-group label="Functional/Structural">
											<b-form-select v-model="injury.muscle">
												<option :value="null">Select</option>
												<option value="functional">Functional</option>
												<option value="structural">Structural</option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col cols="1">
									</b-col>
									<b-col>
										<b-card v-if="injury.muscle == 'functional'">
											<b-form-group>
												<template slot="label">&nbsp;</template>
												<b-form-checkbox value="doms" v-model="injury.functional" :unchecked-value="null">DOMS/overuse</b-form-checkbox>
												<b-form-checkbox value="neuromuscular" v-model="injury.functional" :unchecked-value="null">Neuromuscular</b-form-checkbox>
											</b-form-group>
										</b-card>

										<b-card v-if="injury.muscle == 'structural'">
											<b-row>
												<b-col cols="4">
													<b-form-group label="Location *">
														<b-form-checkbox value="proximal" v-model="injury.location" :unchecked-value="null">Proximal</b-form-checkbox>
														<b-form-checkbox value="medial" v-model="injury.location" :unchecked-value="null">Medial</b-form-checkbox>
														<b-form-checkbox value="distal" v-model="injury.location" :unchecked-value="null">Distal</b-form-checkbox>
													</b-form-group>
												</b-col>
												<b-col cols="1">
												</b-col>
												<b-col>
													<b-form-group label="Tendon or muscle junction interested">
														<b-form-checkbox value="proximal" v-model="injury.mtj" :unchecked-value="null">Proximal MTJ</b-form-checkbox>
														<b-form-checkbox value="distal" v-model="injury.mtj" :unchecked-value="null">Distal MTJ</b-form-checkbox>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="4">
													<b-form-group label="Grade">
														<b-form-select v-model="injury.grade">
															<option :value="null">Select</option>
															<option value="0">0 (MRI negative)</option>
															<option value="1">1 (only edema)</option>
															<option value="2">2 (minimal GAP/edema interfascia)</option>
															<option value="3">3 (misurable GAP/edema, tendon detension)</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col cols="1">
												</b-col>
												<b-col>
													<b-form-group>
														<template slot="label">&nbsp;</template>
														<b-form-checkbox :value="1" v-model="injury.r" :unchecked-value="0">r (tendon disruption/retraction)</b-form-checkbox>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="2">
													<b-form-group label="Mechanism">
														<b-form-checkbox value="direct" v-model="injury.mechanism" :unchecked-value="null">Direct</b-form-checkbox>
													</b-form-group>
												</b-col>
												<b-col cols="3" v-if="injury.mechanism == 'direct'" class="mr-5">
													<b-form-group>
														<template slot="label">&nbsp;</template>
														<b-form-select v-model="injury.direct">
															<option :value="null">Select *</option>
															<option value="player">YES with other player</option>
															<option value="object">YES with object</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col cols="2">
													<b-form-group>
														<template slot="label">&nbsp;</template>
														<b-form-checkbox value="indirect" v-model="injury.mechanism" :unchecked-value="null">Indirect</b-form-checkbox>
													</b-form-group>
												</b-col>
												<b-col cols="3" v-if="injury.mechanism == 'indirect'">
													<b-form-group>
														<template slot="label">&nbsp;</template>
														<b-form-select v-model="injury.indirect">
															<option :value="null">Select</option>
															<option value="sprinting">Sprinting</option>
															<option value="stretching">Stretching</option>
														</b-form-select>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="3">
													<b-form-group label="Muscle injury code (MLG-R)">
														<b-form-input readonly :value="mlgr"></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
										</b-card>
									</b-col>
								</b-row>
							</b-card>
							<b-card title="Injury Mechanism" class="mt-1">
								<b-row>
									<b-col cols="5">
										<b-form-group label="Was the injury caused by overuse (gradual onset) or trauma (acute onset)">
											<b-form-select v-model="injury.cause">
												<option :value="null">Select</option>
												<option value="overuse">Overuse</option>
												<option value="trauma">Trauma</option>
												<option value="N/A">N/A</option>
											</b-form-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Injury mode *">
											<b-form-radio-group v-model="injury.contact">
												<b-form-radio :value="1">Contact</b-form-radio>
												<b-form-radio :value="0">Not Contact</b-form-radio>
											</b-form-radio-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group>
											<b-form-checkbox-group
											v-model="injury.mechanisms"
											:options="mechanisms"
											value-field="id"
											text-field="name"
											></b-form-checkbox-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Injury mechanism">
											<b-form-textarea
											v-model="injury.note"
											placeholder="Describe in words..."
											rows="3"
											max-rows="6"
											></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="5">
										<b-form-group label="Did the injury occur outside the pitch perimeter ?">
											<b-form-select v-model="injury.outside">
												<option :value="null">Select</option>
												<option value="yes">Yes</option>
												<option value="no">No</option>
												<option value="N/A">N/A</option>
											</b-form-select>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card>
							<b-card class="my-1">
								<b-row>
									<b-col cols="2">
										<b-form-group label="Event">
											<b-form-select v-model="injury.event">
												<option :value="null">Select</option>
												<option value="training">Training</option>
												<option value="match">Match</option>
												<option value="N/A">N/A</option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col cols="4">
										<b-form-group v-if="injury.event == 'training'" label="Training">
											<b-form-select v-model="injury.id_training_event">
												<option :value="null">Select</option>
												<option :value="item.id" v-for="item in events" v-if="item.type == 'training'">{{ item.name }}</option>
											</b-form-select>
										</b-form-group>
										<b-form-group v-if="injury.event == 'match'" label="Match">
											<b-form-select v-model="injury.id_match_event">
												<option :value="null">Select</option>
												<option :value="item.id" v-for="item in events" v-if="item.type == 'match'">{{ item.name }}</option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group v-if="injury.event == 'match'" label="Minute of injury">
											<b-form-input type="number" v-model="injury.minute" min="0"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Examination">
											<b-form-checkbox-group
											v-model="injury.examinations"
											:options="examinations"
											value-field="id"
											text-field="name"
											></b-form-checkbox-group>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group label="Other" v-if="injury.examinations.indexOf(6) > - 1">
											<b-form-input v-model="injury.other"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Diagnosis">
											<b-form-textarea
											v-model="injury.diagnosis"
											placeholder="Specify results of examination..."
											rows="3"
											max-rows="6"
											></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card>
							<b-card class="my-1">
								<div v-if="injury.id">
									<b-row>
										<b-col>
											<h6><a href="" @click.prevent="ux.allegati = !ux.allegati">Allegati ({{ countDoc }})</a></h6>
											<DocumentiManager :collapsed="ux.allegati" entity="MedicalInjury" :entityId="injury.id" :tipoId="1" path="" @countDoc="updateCountDoc"></DocumentiManager>
										</b-col>
									</b-row>
									<hr />
									<b-row>
										<b-col>
											<h6><a href="" @click.prevent="ux.video = !ux.video">Video ({{ countVideo }})</a></h6>
											<VideoManager :collapsed="ux.video" entity="MedicalInjury" :entityId="injury.id" @countVideo="updateCountVideo"></VideoManager>
										</b-col>
									</b-row>
								</div>
								<div v-else class="text-muted">
									<em>Per poter inserire Allegati e Video, devi prima salvare l'infortunio</em>
								</div>
							</b-card>
							<b-row>
								<b-col>
									<b-button type="submit" variant="success">Save</b-button>
									<b-button type="button" variant="primary" v-if="injury.id" @click="deleteInjury">Delete</b-button>
									<b-button @click="backToInjuries">Back</b-button>
								</b-col>
								<b-col>
									<div style="text-align:right;"><small> * Campi obbligatori </small></div>
								</b-col>
							</b-row>
						</b-form>
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="infoModal" title="Second Injury/Illness Work Flow" size="lg" ok-only ok-title="Chiudi">
			<div class="text-center">
				<b-img src="/assets/varie/injury_flow.jpeg" fluid/>
			</div>
		</b-modal>
	</div>
	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import moment from 'moment';

export default {
	data: function() {
		return {
			showTable: true,
			rosa: null,
			injuries: [],
			regions: [],
			classifications: [],
			pathologies: [],
			mechanisms: [],
			events: [],
			examinations: [],
			region_id: null,
			classification_id: null,
			region_class_id: null,
			set_region_class_id: null,
			set_patology_id: null,
			osics_code: null,
			fields: [
				{ key: 'data', label: 'Date of Injury' },
				{ key: 'return', label: 'Date of return' },
				{ key: 'reinjury', label: 'Second Injury' },
				{ key: 'pathology', label: 'Type of Injury' },
				],
			injury: {
				id: null,
				persona_id: this.$route.params.id,
				data: null,
				return: null,
				reinjury: 0,
				id_injury: null,
				return_reinjury: null,
				side: null,
				id_pathology: null,
				muscle: null,
				functional: null,
				location: null,
				mtj: null,
				grade: null,
				r: 0,
				mechanism: null,
				direct: null,
				indirect: null,
				code: null,
				cause: null,
				mechanisms: [],
				note: null,
				outside: null,
				event: null,
				id_training_event: null,
				id_match_event: null,
				minute: null,
				examinations: [],
				other: null,
				diagnosis: null,
			},
			reinjury_label: [ 'No', 'Reinjury', 'Subsequent new injury', 'Subsequent local injury', 'Exacerbation'],
			ux: {
				allegati: true,
				video: true,
			},
			countVideo: 0,
			countDoc: 0,	
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
		DocumentiManager: require('@/components/layout/documenti/manager.vue').default,
		VideoManager: require('@/components/layout/video/manager.vue').default,
	},

	created: function () {
		this.getInjuries();
		this.getRegions();
		this.getMechanisms();
		this.getEvents();
		this.getExaminations();
	},
	watch: {
		region_id: function () {
			this.region_class_id = null;
			if(this.region_id) {
				for(var i in this.regions) {
					if(this.regions[i].id == this.region_id) {
						this.classifications = this.regions[i].classifications;
						if(this.set_region_class_id) {
							this.region_class_id = this.set_region_class_id;
							this.set_region_class_id = null;
						}
						break;
					} 
				}
			} else {
				this.classifications = [];
			}
		},
		region_class_id: function () {
			this.injury.id_pathology = null;
			this.pathologies = [];
			if(this.region_class_id) {
				this.getPathologies();
				for(var i in this.classifications) {
					if(this.classifications[i].pivot.id == this.region_class_id) {
						this.classification_id = this.classifications[i].id;
						break;
					}
				}
			} else {
				this.classification_id = null;
			}
		},
		'injury.id_pathology': function () {
			this.osics_code = null;
			if(this.injury.id_pathology) {
				for(var i in this.pathologies) {
					if(this.pathologies[i].id == this.injury.id_pathology) {
						this.osics_code = this.pathologies[i].code;
					} 
				}		
			}
		}
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 
	methods: { 
		getInjuries() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/injuries/player/' + this.$route.params.id).then((response) => {
				if (response.ok) {
					this.rosa = response.data.rosa;
					this.injuries = response.data.injuries;
					this.reset(); 
					this.showTable = true; 
				}
			}, (response) => {
			});
		},

		getRegions() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/regions').then((response) => {
				if (response.ok) {
					this.regions = response.data; 
				}
			}, (response) => {
			});
		},

		getPathologies() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/phatologies/' + this.region_class_id).then((response) => {
				if (response.ok) {
					this.pathologies = response.data; 
					if(this.set_pathology_id) {
						this.injury.id_pathology = this.set_pathology_id;
						this.set_pathology_id = null;
					}
				}
			}, (response) => {
			});
		},

		getMechanisms() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/mechanisms').then((response) => {
				if (response.ok) {
					this.mechanisms = response.data; 
				}
			}, (response) => {
			});
		},

		getEvents() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/events').then((response) => {
				if (response.ok) {
					this.events = response.data; 
				}
			}, (response) => {
			});
		},

		getExaminations() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/examinations').then((response) => {
				if (response.ok) {
					this.examinations = response.data; 
				}
			}, (response) => {
			});
		},

		onSubmit(evt) {
			evt.preventDefault()
			if(!this.injury.data) {
				alert("Campo 'Date o Injury' obbligatorio!");
				return;
			} else {
				var m = moment(this.injury.data);
				if(!m.isValid()) {
					alert("Campo 'Date o Injury' invalido!");
					return;
				}
			}
			if(this.injury.return) {
				var m = moment(this.injury.return);
				if(!m.isValid()) {
					alert("Campo 'Date of Return' invalido!");
					return;
				}
			}
			if(this.injury.muscle == 'structural' && !this.injury.location) {
				alert("Campo 'Location' obbligatorio!");
				return;
			}
			if(this.injury.muscle == 'structural' && !this.injury.mechanism) {
				alert("Campo 'Mechanism' obbligatorio!");
				return;
			}
			if(this.injury.muscle == 'structural' && this.injury.mechanism == 'direct' && !this.injury.direct) {
				alert("Devi selezionare un'opzione relativa a Direct Mechanism!");
				return;
			}
			if(this.injury.contact === null) {
				alert("Campo 'Injury mode' obbligatorio!");
				return;
			}
			var apiPath = '/medical/add/injury';
			if(this.injury.id) {
				apiPath = '/medical/edit/injury/' + this.injury.id;
			}
			this.$http.post(this.$store.state.apiEndPoint + apiPath, this.injury).then((response) => {
				if (response.ok) {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getInjuries();
				}
			}, (response) => {  
				if (response.status == 422) {
				}
			});
		},

		deleteInjury() {
			if(confirm('Confermi la cancellazione di questo infortunio?')) {
				this.$http.get(this.$store.state.apiEndPoint + '/medical/delete/injury/' + this.injury.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getInjuries();
					}
				}, (response) => {
				});
			}
		},

		getIndexReinjury(index, id_injury) {
			var item = this.injuries.find(item => item.id === id_injury);
			if(!item.reinjury) {
				return index;
			} else {
				index++;
				if(item.id_injury) {
					return this.getIndexReinjury(index, item.id_injury);
				}
				return index;
			}
		},

		reset() {
			this.region_id = null;
			this.region_class_id = null;

			this.injury.id = null;
			this.injury.data = null;
			this.injury.return = null;
			this.injury.reinjury = 0;
			this.injury.id_injury = null;
			this.injury.return_reinjury = null;
			this.injury.side = null;
			this.injury.id_pathology = null;
			this.injury.muscle = null;
			this.injury.functional = null;
			this.injury.location = null;
			this.injury.mtj = null;
			this.injury.grade = null;
			this.injury.r = 0;
			this.injury.mechanism = null;
			this.injury.direct = null;
			this.injury.indirect = null;
			this.injury.code = null;
			this.injury.cause = null;
			this.injury.contact = null;
			this.injury.mechanisms = [];
			this.injury.note = null;
			this.injury.outside = null;
			this.injury.event = null;
			this.injury.id_training_event = null;
			this.injury.id_match_event = null;
			this.injury.minute = null;
			this.injury.examinations = [];
			this.injury.other = null;
			this.injury.diagnosis = null;
		},

		newInjury() {
			this.reset();
			this.showTable = false;
		},

		backToInjuries() {
			this.showTable = true;
			this.reset();
		},

		selectInjury(id) {
			var item = this.injuries.find(item => item.id === id);
			this.injury.id = item.id;
			this.injury.data = item.data;
			this.injury.return = item.return;
			this.injury.reinjury = item.reinjury;
			this.injury.id_injury = item.id_injury;
			this.injury.return_reinjury = item.return_reinjury;
			this.injury.side = item.side;

			if(item.pathology) {
				this.set_region_class_id = item.pathology.region_classification.id;
				this.set_pathology_id = item.id_pathology;
				this.region_id = item.pathology.region_classification.id_region;
			}
			
			this.injury.muscle = item.muscle;
			this.injury.functional = item.functional;
			this.injury.location = item.location;
			this.injury.mtj = item.mtj;
			this.injury.grade = item.grade;
			this.injury.r = item.r;
			this.injury.mechanism = item.mechanism;
			this.injury.direct = item.direct;
			this.injury.indirect = item.indirect;
			this.injury.code = item.code;
			this.injury.cause = item.cause;
			this.injury.contact = item.contact;
			this.injury.note = item.note;
			this.injury.outside = item.outside;
			this.injury.event = item.event;
			this.injury.id_training_event = item.id_training_event;
			this.injury.id_match_event = item.id_match_event;
			this.injury.minute = item.minute;
			this.injury.other = item.other;
			this.injury.diagnosis = item.diagnosis;

			for(var i in item.mechanism_injuries) {
				this.injury.mechanisms.push(item.mechanism_injuries[i].id_mechanism);
			}
			for(var i in item.examination_injuries) {
				this.injury.examinations.push(item.examination_injuries[i].id_examination);
			}
			this.showTable = false;	
		},
		updateCountVideo: function(count) {
			this.countVideo = count;
		},
		updateCountDoc: function(count) {
			this.countDoc = count;
		},
	},
	computed: {
		mlgr: function() {
			var code = '';
			if(this.injury.mechanism == 'direct') {
				code = code + 'T';
			} else if(this.injury.mechanism == 'indirect') {
				code = code + 'I';
				if(this.injury.indirect == 'sprinting') {
					code = code + 'p';
				} else if(this.injury.indirect == 'stretching') {
					code = code + 's';
				}
			}
			if(this.injury.location == 'proximal') {
				code = code + 'P';
			} else if(this.injury.location == 'medial') {
				code = code + 'M';
			} else if(this.injury.location == 'distal') {
				code = code + 'D';
			}
			if(this.injury.mtj == 'proximal') {
				code = code + 'p';
			} else if(this.injury.mtj == 'distal') {
				code = code + 'd';
			}
			if(this.injury.grade == '0') {
				code = code + 'G0';
			} else if(this.injury.grade == '1') {
				code = code + 'G1';
			} else if(this.injury.grade == '2') {
				code = code + 'G2';
			} else if(this.injury.grade == '3') {
				code = code + 'G3';
			}
			if(!this.injury.reinjury) {
				code = code + 'R0';
			} else {
				if(this.injury.id_injury) {
					var index = this.getIndexReinjury(1, this.injury.id_injury);
					code = code + 'R' + index;
				} else {
					code = code + 'R1';
				}
			}
			this.injury.code = code;
			return code;
		},
	}
}
</script>