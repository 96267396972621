<template>
	<div v-if="ux.show">

		<b-table-simple small striped>
			<b-thead>
				<b-tr>
					<b-th class="small">Nome</b-th>
					<b-th class="small"></b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="doc in documents">
					<b-td width="60%">{{ filename(doc) }}</b-td>
					<b-td width="40%" class="text-right">
						<b-button-group>
							<b-button :href="downloadUrl(doc.id)" target="_blank" class="mr-0" size="xs">
								<i class="fa fa-eye" aria-hidden="true"></i> Apri
							</b-button>
							<b-button size="xs" @click.prevent="deleteDoc(doc.id)" v-if="!readonly">
								<i class="fa fa-trash" aria-hidden="true"></i> Rimuovi
							</b-button>
						</b-button-group>
					</b-td>
				</b-tr>
				<b-tr v-if="documents.length == 0">
					<b-td colspan="2">
						Non ci sono allegati
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>

		<b-button variant="outline-secondary" size="sm" v-if="ux.upload == false && !readonly" @click.prevent="action_toggleUpload">Carica File</b-button>
		<FormUpload v-if="ux.upload == true && !readonly" :model="model" @upload="upload"></FormUpload>
		
	</div>
</template>

<script>

import _ from 'lodash'
var Auth = require('../../../auth.js');

export default {
	props: [ 'entity', 'collapsed', 'entityId', 'tipoId', 'path', 'readonly' ],
	computed: {
		user: function() {
			return Auth.user;
		}
	},
	components: {
		FormUpload: require('@/components/layout/documenti/form.vue').default,
	},
	watch: {
		collapsed: {
			immediate: true,
			handler: function() {
				if(this.collapsed == true)
					this.ux.show = false;
				else
					this.ux.show = true;
			}
		},
		documents: {
			deep: true,
			handler: function() {
				
			}
		},
		entity: {
			deep: true,
			handler: function() {
				this.fetch();
			},
			immediate: true
		}
	},
	methods: {
		action_toggleUpload: function() {
			this.ux.upload = !this.ux.upload;
		},
		downloadUrl: function(id) {
			var uid = Auth.user.id;
			var token = Auth.user.api_token;
			return this.$store.state.apiEndPoint + '/documenti/download/' + id + '/' + uid + '/' + token;
		},
		filename: function(doc) {

			if(doc.documento.nome && doc.documento.nome.length > 0) 
				return doc.documento.nome;

			var filename = _.last(doc.documento.path.split('/'));
			var filename_reale = filename.split('_');
			filename_reale.splice(0, 1);
			return filename_reale.join('_');

		},
		fetch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/documenti/of/' + this.entity + '/' + this.entityId).then(function(data) {
				this.documents = data.data;
				if(this.documents.length > 0) {
					this.$emit('countDoc', this.documents.length);
					this.$emit('getDocs', this.documents);
				}
				this.model = this.createModel();
			});
		},
		deleteDoc: function(entityId) {
			if(confirm("Confermi la rimozione dell'allegato?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/documenti/delete/' + entityId).then(function(data) {
					this.fetch();
				});	
			}
		},
		createModel: function() {
			return {
				nome: '',
				path: '',
				documento_tipo_glo_id: this.tipoId,
				emissione: null,
				scadenza: null,
				numero: null
			}
		},
		upload: function(target) {
			
			this.ux.wait = true;
			this.$emit('wait');

			var formData = new FormData();
			formData.append('documento_tipo_glo_id', this.model.documento_tipo_glo_id);
			formData.append('file', target);
			formData.append('nome', this.model.nome);

			this.$http.post(this.$store.state.apiEndPoint + '/documenti/of/' + this.entity + '/' + this.entityId + '/upload', formData).then(function(data) {
				this.ux.wait = false;
				this.ux.upload = false;
				this.$emit('upload', data.data);
				this.fetch();
			}, function(data) {
				this.ux.wait = false;
			});

		}
	},
	filters: {
		
	},
	data: function() {
		return {
			model: false,
			documents: false,
			ux: {
				upload: false,
				wait: false,
				show: false,
			}
		}
	}
};

</script>