<template id="archivio_giocatori">
	<section class="archivio_giocatori">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Giocatori: {{ total }} - Valutazioni: {{ count_val }}</span>
					</div>
					<div class="content">
						<div class="mb-1">
							<b-btn v-b-toggle.collapse variant="primary">Filtra <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
							<b-collapse id="collapse" class="mt-2">
								<b-row class="mb-1">
									<b-col sm="2">
										<b-form-select v-model="filters.ruolo">
											<option value="0">-- Ruolo --</option>
											<option v-for="item in ruoli" :value="item.id">{{item.nome}}</option>
										</b-form-select>
									</b-col>
									<b-col sm="2">
										<b-form-select v-model="filters.gradimento">
											<option value="0">-- Gradimento --</option>
											<option v-if="item.newscout" v-for="item in gradimenti" :value="item.id">{{item.new_name ? item.new_name : item.nome }}</option>
										</b-form-select>
									</b-col>
									<b-col sm="2" v-if="auth.isUserEnable('scout_utenti')">
										<b-form-select v-model="filters.osservatore">
											<option value="0">-- Osservatore --</option>
											<option v-for="item in preset_osservatori" :value="item.value">{{ item.name }}</option>
											<option v-for="item in osservatori" :value="item.id">{{item.nomeCompleto}}</option>
										</b-form-select>
									</b-col>
									<b-col sm="2" v-if="auth.isUserEnable('scout_utenti')">
										<b-form-select v-model="filters.val_view">
											<option value="0">-- Valutazioni osservatore --</option>
											<option value="1">Tutte le valutazioni</option>
										</b-form-select>
									</b-col>
									<b-col sm="2" v-if="auth.isUserEnable('scout_segnalatori')">
										<b-form-select v-model="filters.segnalatore">
											<option value="0">-- Segnalatore --</option>
											<option v-for="item in segnalatori" :value="item.id">{{item.nome}}</option>
										</b-form-select>
									</b-col>
									<b-col sm="2">
										<b-input-group>
											<b-form-input type="text" v-model="filters.search_name" placeholder="Cognome / Nome"></b-form-input>
											<b-input-group-append>
												<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>  	
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col sm="2">
										<b-form-select v-model="filters.anno" :options="anni">
										</b-form-select>
									</b-col>
									<b-col sm="2" v-if="auth.isUserEnable('scout_flag_player')">
										<b-form-select v-model="filters.stato">
											<option value="">-- Tutti --</option>
											<option value="prestito">In prestito</option>
											<option value="monitorare">Da monitorare</option>
											<option value="prospettiva">Di prospettiva</option>
											<!--option value="seguiti">Seguiti</option-->	
										</b-form-select>
									</b-col>
									<b-col sm="2">
										<b-form-select v-model="filters.non_valutati">
											<option value="0">-- Solo valutati --</option>
											<option value="1">Non valutati</option>
											<option value="2">Tutti</option>
											<!--option value="3">No skills</option-->
										</b-form-select>
									</b-col>
									<b-col sm="2">
										<b-form-select v-model="filters.lista">
											<option value="0">-- Liste --</option>
											<option v-for="item in liste" :value="item.id">{{item.nome}}</option>
										</b-form-select>
									</b-col>
									<b-col sm="2">
										<b-form-select v-model="filters.nazionale">
											<option value="0">-- Nazionale --</option>
											<option v-for="item in nazionali" :value="item.id">{{item.name}}</option>
										</b-form-select>
									</b-col>
									<b-col sm="2">
										<b-form-select v-model="filters.voto_opponent">
											<option :value="0">-- Valore avversario --</option>
											<option :value="1">+ forte</option>
											<option :value="2">equivalente</option>
											<option :value="3">+ bassa</option>
										</b-form-select>	
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col sm="2">
										<b-form-group label-cols="3" content-cols="9" label="Val. dal" class="mb-0">
											<b-form-input type="date" v-model="filters.valutati_dal"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col sm="2">
										<b-form-group label-cols="3" content-cols="9" label="Val. al" class="mb-0">
											<b-form-input type="date" v-model="filters.valutati_al"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col sm="2">
										<b-form-select v-model="filters.periodo">
											<option value="0">-- Ultime valutazioni --</option>
											<option value="1">7 giorni</option>
											<option value="2">30 giorni</option>
											<option value="3">6 mesi</option>
											<option value="4">1 anno</option>
											<option value="5">2 anni</option>
										</b-form-select>
									</b-col>
									<b-col sm="3">
										<b-form-group label-cols="3" content-cols="9" label="Current" class="mb-0">
											<b-input-group>
												<b-form-input type="number" min="0" max="10" step="0.01" v-model="filters.current_from" placeholder="From"></b-form-input>
												<b-form-input type="number" min="0" max="10" step="0.01" v-model="filters.current_to" placeholder="To"></b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col sm="3">
										<b-form-group label-cols="3" content-cols="9" label="Potential" class="mb-0">
											<b-input-group>
												<b-form-input type="number" min="0" max="10" step="0.01" v-model="filters.potential_from" placeholder="From"></b-form-input>
												<b-form-input type="number" min="0" max="10" step="0.01" v-model="filters.potential_to" placeholder="To"></b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col sm="2">
										<b-btn variant="primary" @click="searchTeam" title="Filtra per squadra">Squadra</b-btn>
										<span v-if="squadra"><img :src="squadra.logo_url" height="35" /><strong>{{ squadra.name }}</strong><b-btn variant="secondary" @click="rimuoviSquadra" class="ml-2" title="Rimuovi filtro squadra"><i class="fa fa-ban" aria-hidden="true"></i></b-btn></span>
									</b-col>
								</b-row>
							</b-collapse>
						</div>
						<b-row>
							<b-col cols="5">
								<b-row>
									<b-col cols="7">
										<b-form-group label="Lista" label-cols="2" content-cols="10">
											<b-form-select v-model="lista_selected">
												<option v-for="item in liste" :value="item.id">{{item.nome}} {{ auth.isUserEnable('shadow_team') && isCurrentShadowTeamList(item.id) ? ' (Current Shadow Team)' : ''}}</option>
											</b-form-select>
										</b-form-group>
										<b-collapse id="collapse2" ref="collapse2" class="mb-1">
											<b-form-input v-model="new_lista"
											type="text"
											placeholder="Inserisci nuova lista"></b-form-input>
											<b-btn variant="primary" size="sm" @click="aggiungiLista">Salva</b-btn>
										</b-collapse>
										<b-collapse id="collapse3" ref="collapse3" class="mb-1">
											<b-form-checkbox-group stacked v-model="lista_utenti">
												<b-form-checkbox v-for="item in osservatori_liste" :value="item.id" v-if="item.attivo && item.id != auth.user.id">{{ item.nomeCompleto }}</b-form-checkbox>
											</b-form-checkbox-group>
											<b-btn variant="primary" size="sm" @click="condividiLista">Salva</b-btn>
										</b-collapse>
									</b-col>
									<b-col>
										<b-button-group>
											<b-btn variant="primary" title="Elimina lista" @click="eliminaLista" v-if="!is_lista_master && !is_lista_condivisa"><i class="fa fa-minus" aria-hidden="true"></i></b-btn>
											<b-btn variant="primary" title="Aggiungi lista" v-b-toggle="'collapse2'"><i class="fa fa-plus" aria-hidden="true"></i></b-btn>
											<b-btn variant="success" title="Condividi lista" v-b-toggle="'collapse3'" v-if="!is_lista_condivisa"><i class="fa fa-share-alt" aria-hidden="true"></i></b-btn>
											<b-btn variant="secondary" title="Impostazioni Lista" v-if="lista_selected" @click.prevent="showConfigLista"><i class="fa fa-cog" aria-hidden="true"></i></b-btn>
										</b-button-group>
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="3">
								<div v-if="auth.isUserEnable('scout_compare_players')">
									<b-row align-v="center">
										<b-col cols="6">
											<b-form-checkbox id="checkConfronto" v-model="confronta">
												Confronta
											</b-form-checkbox>
										</b-col>
										<b-col cols="2">
											<b-btn variant="primary" title="Mostra confronto" @click="showConfronto"><i class="fa fa-search" aria-hidden="true"></i></b-btn>
										</b-col>
										<b-col cols="2">
											<b-btn variant="secondary" title="Reset confronto" @click="resetConfronto"><i class="fa fa-ban" aria-hidden="true"></i></b-btn>
										</b-col>
										<b-col cols="2">
											<b-btn variant="success" title="Liste confronto" @click="listeConfronto"><i class="fa fa-list" aria-hidden="true"></i></b-btn>
										</b-col>
									</b-row>
								</div>
							</b-col>
							<b-col cols="2">
								<b-form-select v-model="filters.order_by" v-if="auth.isUserEnable('current_potential_avg')">
									<option value="0">-- Order by --</option>
									<option value="1">Current</option>
									<option value="2">Potential</option>
								</b-form-select>
							</b-col>
							<b-col cols="1">
							</b-col>
							<b-col cols="1">
								<b-img @click="listaInCampo" src="/assets/varie/campo_red.png" width="40" height="40" style="cursor:pointer;" title="Lista in campo"/>
								<b-img v-if="auth.isAdmin()" @click="listaInCampoNew" src="/assets/varie/campo_red_new.png" width="40" height="40" style="cursor:pointer;" title="Lista in campo"/>
							</b-col>
						</b-row>
						<div v-if="vista == 'griglia'">	
							<v-infinite-scroll :loading="loading" @bottom="nextPage" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
								<div v-for="osservato in osservati" class="col-2">
									<cardosservato :osservato="osservato" :in_lista="lista_giocatori.indexOf(osservato.id) > -1" @addGiocatoreLista="addGiocatoreLista" @delGiocatoreLista="delGiocatoreLista" :editable="!is_lista_condivisa"
										:opta_range="opta_range" :confronta="confronta" @editConfronto="editConfronto" :da_confrontare="inListaConfronto(osservato) > -1" :newscout="true" :gradimenti="gradimenti" @getPercentualeValutazioni="getPercentualeValutazioni"></cardosservato>
									</div>
									<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
								</v-infinite-scroll>

							</div>
							<div v-if="vista == 'lista'">
								<tabellaosservati :osservati="osservati"></tabellaosservati>	
							</div>
						</div>
					</div>
				</div>
				<b-modal id="searchTeamModal" ref="searchTeamModal" title="Filtro squadra" ok-only ok-title="Chiudi">
					<div v-if="show_aree">
						<span>Seleziona un'area: </span>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<div v-if="aree.length == 0"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
							<b-row>
								<b-col cols="3" v-for="area in aree" style="padding: 0.2rem">
									<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.id)">
										<flag :country="area.sigla" :size="24"></flag><br/>
										{{area.paese}}
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>
					<div v-if="show_squadre">
						<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
						<div v-if="!is_search && squadre.length == 0"><h5>Nessuna squadra trovata!</h5></div>
						<div v-if="squadre.length > 0">
							<span>Seleziona una squadra: </span>
							<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
								<b-row>
									<b-col cols="4" v-for="team in squadre" style="padding: 0.2rem">
										<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="selezionaSquadra(team)">
											<img :src="team.logo_url" width="50" /><br/>
											<strong>{{team.name}}</strong><br/>
										</b-card>
									</b-col>
								</b-row>
							</div>
						</div>
					</div>
				</b-modal>
				<b-modal id="confrontoModal" size="xl" ref="confrontoModal" title="Confronta giocatori" class="fs-modal" ok-only ok-title="Chiudi">
					<tabellaconfronto :opta_fields="opta_fields" :lista_confronto="lista_confronto"></tabellaconfronto>
				</b-modal>
				<b-modal ref="listeConfrontoModal" title="Liste confronto" ok-only ok-title="Chiudi">	
					<b-row class="m-2">
						<b-col cols="9">
							<b-form-group label="Nuova lista" label-cols="3" content-cols="9">
								<b-form-input v-model="nuova_lista_confronto"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-button variant="primary" title="Salva" @click="addListaConfronto">
								Aggiungi
							</b-button>
						</b-col>
					</b-row>
					<b-table :items="liste_confronto" :fields="['nome', {key: 'created_at', label: 'Data', formatter: this.formatDate}, 'azioni']">
						<template #cell(azioni)="row">
							<b-button size="sm" variant="success" title="carica lista" @click="loadListaConfronto(row.item.id)">
								<i class="fa fa-list" aria-hidden="true"></i>
							</b-button>
							<b-button size="sm" variant="primary" title="aggiorna lista" @click="updateListaConfronto(row.item.id)">
								<i class="fa fa-pencil" aria-hidden="true"></i>
							</b-button>
							<b-button size="sm" title="elimina lista" @click="deleteListaConfronto(row.item.id)">
								<i class="fa fa-trash" aria-hidden="true"></i>
							</b-button>
						</template>
					</b-table>
				</b-modal>
				<b-modal ref="percentValModal" :title="'Percentuale valutata: ' + (percent_val ? percent_val.percent : '0') + '%'" ok-only ok-title="Chiudi">	
					<b-row align-v="center" v-if="osservato_sel" class="mb-3"><b-col class="text-center"><b-img width="80px" :src="osservato_sel.giocatore.avatar_url"/><br/>
						{{ osservato_sel.giocatore.cognome }} {{ osservato_sel.giocatore.nome }}
					</b-col>
				</b-row>
				<div v-if="percent_val">
					<b-row align-v="center" class="text-center">
						<b-col class="p-2 m-1 border bg-dark color-white">+ Forte</b-col>
						<b-col class="p-2 m-1 border bg-dark color-white">Equivalente</b-col>
						<b-col class="p-2 m-1 border bg-dark color-white">+ Bassa</b-col></b-row>
						<b-row align-v="center" class="text-center">
							<b-col :class="'p-2 m-1 border ' + (percent_val.home1 ? percent_val.class_home1 : '')">Casa</b-col>
							<b-col :class="'p-2 m-1 border ' + (percent_val.home2 ? percent_val.class_home2 : '')">Casa</b-col>
							<b-col :class="'p-2 m-1 border ' + (percent_val.home3 ? percent_val.class_home3 : '')">Casa</b-col>
						</b-row>
						<b-row align-v="center" class="text-center">
							<b-col :class="'p-2 m-1 border ' + (percent_val.away1 ? percent_val.class_away1 : '')">Trasferta</b-col>
							<b-col :class="'p-2 m-1 border ' + (percent_val.away2 ? percent_val.class_away2 : '')">Trasferta</b-col>
							<b-col :class="'p-2 m-1 border ' + (percent_val.away3 ? percent_val.class_away3 : '')">Trasferta</b-col>
						</b-row>
					</div>
				</b-modal>
			</div>

			<b-modal ref="modalConfigLista" size="lg">
				
				<config-lista @refresh="fetchListe" v-if="lista_selected" :listId="lista_selected"></config-lista>

			</b-modal>

			<footerapp></footerapp>

		</section>
	</template>

	<script>
	import moment from 'moment'
	import * as types from '../../../store/types'
	import InfiniteScroll from 'v-infinite-scroll'
	import { mapState } from 'vuex';
	import { jsPDF } from 'jspdf';

	var Auth = require('../../../auth.js');

	export default {

		components: {
			headapp: 	require('../../layout/headapp.vue').default,
			menusub:    require('../../layout/menusub.vue').default,
			footerapp:  require('../../layout/footerapp.vue').default,
			cardosservato: 	require('../../layout/cardosservato.vue').default,
			tabellaosservati: 	require('../../layout/tabellaosservati.vue').default,
			tabellaconfronto: 	require('../../layout/scouting/tabella_confronto.vue').default,
			flag:               require('../../layout/flag.vue').default,
			configLista:               require('@/components/layout/nextscouting/config-lista.vue').default,
			'v-infinite-scroll': InfiniteScroll,
		},

		data: function(){
			return {
				ux: {
					showConfigLista: false
				},
				vista: 'griglia',
				osservati: [],
				ruoli: [],
				gradimenti: [],
				osservatori: [],
				osservatori_liste: [],
				segnalatori: [],
				nazionali: [],
				filters: {
					ruolo: 0,
					gradimento: 0,
					osservatore: 0,
					search_name: '',
					anno: '',
					voto_medio: '',
					voto_medio_ruolo: '',
					segnalatore: 0,
					stato: '',
					non_valutati: 0,
					note: 0,
					valutati_dal: '',
					valutati_al: '',
					lista: 0,
					live: 0,
					squadra: 0,
					periodo: 0,
					appunti: 0,
					nazionale: 0,
					current_from: 0,
					current_to: 0,
					potential_from: 0,
					potential_to: 0,
					order_by: 0,
					val_view: 0,
					voto_opponent: 0,
				},
				liste: [],
				lista_selected: 0,
				lista_giocatori: [],
				lista_utenti: [],
				new_lista: '',
				ruoli: [],
				elenco_pdf: [],
				total: 0,
				loading: false,
				page: 1,
				is_search: false,
				aree: [],
				squadre: [],
				show_aree: false,
				show_squadre: false,
				squadra: null,	
				opta_range: {},
				opta_fields: [],
				confronta: false,
				lista_confronto: [],
				liste_confronto: [],
				nuova_lista_confronto: '',	
				percent_val: null,
				osservato_sel: null,
				count_val: 0,
				loading_val: false,
			}
		},
		created: function () {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/giocatori/' + this.scoutingCategory + '/new').then((response) => {
				if (response.ok)
				{
					this.osservati = response.data.data; 
					this.total = response.data.total;
					this.loading = false;
				}
			}, (response) => {
			}); 

			this.$http.get(this.$store.state.apiEndPoint + '/scouting/count/valutazioni/archivio/giocatori/' + this.scoutingCategory + '/new').then((response) => {
				if (response.ok)
				{
					this.count_val = response.data;
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
				if (response.ok)
				{
					this.ruoli = response.data;    
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
				if (response.ok)
				{
					this.gradimenti = response.data;    
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/opta/range/stats').then((response) => {
				if (response.ok)
				{
					this.opta_range = response.data.opta_range;  
					this.opta_fields = response.data.opta_fields;  
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/osservatori/newscout').then((response) => {
				if (response.ok)
				{
					this.osservatori = response.data; 
				}
			}, (response) => {
			}); 

			this.$http.get(this.$store.state.apiEndPoint + '/osservatori/liste/newscout').then((response) => {
				if (response.ok)
				{
					this.osservatori_liste = response.data; 
				}
			}, (response) => {
			}); 

			this.$http.get(this.$store.state.apiEndPoint + '/segnalatori').then((response) => {
				if (response.ok)
				{
					this.segnalatori = response.data; 
				}
			}, (response) => {
			});

			this.fetchListe();

			this.$http.get(this.$store.state.apiEndPoint + '/liste/confronto').then((response) => {
				if (response.ok)
				{
					this.liste_confronto = response.data; 
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/paesi/squadre/giocatori').then((response) => {
				if (response.ok)
				{
					this.aree = response.data;    
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/nazionali').then((response) => {
				if (response.ok)
				{
					this.nazionali = response.data;    
				}
			}, (response) => {
			});
		},

		methods: {
			fetchListe: function() {
				this.$http.get(this.$store.state.apiEndPoint + '/liste', {params: {no_players: true }}).then((response) => {
					if (response.ok)
					{
						this.liste = response.data; 
						this.lista_selected = this.liste[0].id;
					}
				}, (response) => {
				});
			},
			showConfigLista: function() {
				this.$refs.modalConfigLista.show();
			},
			nextPage() {
				if(this.osservati.length < this.total) {
					this.page++;
					this.loading = true;
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/giocatori/' + this.scoutingCategory + '/new?page=' + this.page, {params: this.filters}).then((response) => {
						if (response.ok)
						{
							this.osservati = this.osservati.concat(response.data.data);
							this.loading = false;
						}
					}, (response) => {
					});
				}
			},
			filtraArchivio() {
				this.loading = true;
				this.osservati = [];
				this.page = 1;
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/giocatori/' + this.scoutingCategory + '/new', {params: this.filters}).then((response) => {
					if (response.ok)
					{
						this.osservati = response.data.data;
						this.total = response.data.total;
						this.loading = false; 
					}
				}, (response) => {
				});

				this.$http.get(this.$store.state.apiEndPoint + '/scouting/count/valutazioni/archivio/giocatori/' + this.scoutingCategory + '/new', {params: this.filters}).then((response) => {
					if (response.ok)
					{
						this.count_val = response.data;
					}
				}, (response) => {
				});
			},
			getGiocatoriLista(id) {
				this.lista_giocatori = [];
				if(this.lista_selected) {
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/get/giocatori/lista/' + this.lista_selected).then((response) => {
						if (response.ok)
						{
							var giocatori = response.data;
							for(var i in giocatori) {
								var item = giocatori[i]; 
								this.lista_giocatori.push(item.persona_id);
							}
						}
					}, (response) => {
					}); 
				}
			},
			addGiocatoreLista(id) {
				if(!this.is_lista_condivisa) {
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/add/giocatore/lista/' + this.lista_selected + '/' + id, {params: {no_liste: true }}).then((response) => {
						if (response.ok)
						{
							this.$store.commit(types.ALERTS_PUSH, { msg: 'Giocatore aggiunto alla lista!', status: 1 });
							this.lista_giocatori.push(id);  
						}
					}, (response) => {
					}); 
				}
			},
			delGiocatoreLista(id) {
				if(!this.is_lista_condivisa) {
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/delete/giocatore/lista/' + this.lista_selected + '/' + id, {params: {no_liste: true }}).then((response) => {
						if (response.ok)
						{
							this.$store.commit(types.ALERTS_PUSH, { msg: 'Giocatore eliminato dalla lista!', status: 1 });
							var index = this.lista_giocatori.indexOf(id);	
							this.lista_giocatori.splice(index, 1);
							if(this.filters.lista > 0) {
								this.filtraArchivio();
							}  
						}
					}, (response) => {
					}); 
				}  
			},
			aggiungiLista() {
				if(this.new_lista == '') {
					alert("Inserisci un nome per la lista!");
					return;
				}
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/add/lista/' + this.new_lista, {params: {no_players: true }}).then((response) => {
					if (response.ok)
					{
						this.$refs.collapse2.toggle();
						this.new_lista = '';
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista aggiunta ok', status: 1 });
						this.liste = response.data; 
					}
				}, (response) => {
				}); 
			},
			eliminaLista() {
				if(confirm("Confermi l'eliminazione della lista selezionata?")) {

					this.$http.get(this.$store.state.apiEndPoint + '/scouting/delete/lista/' + this.lista_selected, {params: {no_players: true }}).then((response) => {
						if (response.ok)
						{
							this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista eliminata ok', status: 1 });
							this.liste = response.data; 
							this.lista_selected = this.liste[0].id;
						}
					}, (response) => {
					}); 
				}
			},
			condividiLista() {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/condividi/lista/' + this.lista_selected + '/' + this.lista_utenti, {params: {no_players: true }}).then((response) => {
					if (response.ok)
					{
						this.$refs.collapse3.toggle();
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista condivisa ok', status: 1 });
						this.liste = response.data; 
						this.lista_selected = this.liste[0].id;
					}
				}, (response) => {

				}); 
			},
			listaInCampo() {
				this.$router.push({ name: "newscout_lista_in_campo", params: { id: this.lista_selected } });
			},
			listaInCampoNew() {
				this.$router.push({ name: "newscout_lista_in_campo_new", params: { id: this.lista_selected } });
			},
			formatDate (data) {
				var m = moment(data);
				var format = m.format('DD/MM/YYYY'); 
				return format;
			},
			searchTeam: function() {
				this.show_aree = true;
				this.show_squadre = false;
				this.$refs.searchTeamModal.show();	
			},
			selezionaArea: function(id) {
				this.squadre = [];
				this.show_aree = false;
				this.is_search = true;
				this.show_squadre = true;
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/' + id).then((response) => {

					if (response.ok)
					{
						this.squadre = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			},
			selezionaSquadra: function(squadra) {
				this.squadra = squadra;
				this.filters.squadra = squadra.id;
				this.$refs.searchTeamModal.hide();	
			},
			rimuoviSquadra: function() {
				this.squadra = null;
				this.filters.squadra = 0;	
			},
			inListaConfronto(osservato) {
				for(var index in this.lista_confronto) {
					var item = this.lista_confronto[index];
					if(item.id == osservato.id) {
						return index;
					}
				}
				return -1;
			},
			editConfronto(osservato) {
				var index = this.inListaConfronto(osservato);
				if(index > -1) {
					this.lista_confronto.splice(index, 1);
				} else {
					this.lista_confronto.push(osservato); 
				}
			},
			showConfronto() {
				this.$refs.confrontoModal.show();
			},
			resetConfronto() {
				this.lista_confronto = [];
			},
			listeConfronto() {
				this.$refs.listeConfrontoModal.show();
			},
			addListaConfronto: function() {
				if(this.nuova_lista_confronto != "") {
					this.$http.get(this.$store.state.apiEndPoint + '/add/lista/confronto/' + this.nuova_lista_confronto).then((response) => {
						if (response.ok)
						{
							this.liste_confronto = response.data;
							this.nuova_lista_confronto = "";
						}
					}, (response) => {

					});
				}
			},
			loadListaConfronto: function(id) {
				this.$http.get(this.$store.state.apiEndPoint + '/load/lista/confronto/giocatori/' + id).then((response) => {
					if (response.ok)
					{
						this.lista_confronto = response.data;
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista di confronto caricata con successo!', status: 1 });
						this.$refs.listeConfrontoModal.hide();
					}
				}, (response) => {

				});
			},
			updateListaConfronto: function(id) {
				var ids_gio = [];
				for(var i in this.lista_confronto) {
					var id_gio = this.lista_confronto[i].persona_id;
					ids_gio.push(id_gio);
				}
				this.$http.get(this.$store.state.apiEndPoint + '/edit/lista/confronto/giocatori/' + id + "/" + ids_gio).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					}
				}, (response) => {

				});
			},
			deleteListaConfronto: function(id) {
				if(confirm("Confermi l'eliminazione della lista confronto selezionata?")) {
					this.$http.get(this.$store.state.apiEndPoint + '/delete/lista/confronto/' + id).then((response) => {
						if (response.ok)
						{
							this.liste_confronto = response.data;
						}
					}, (response) => {

					});
				}
			},
			getPercentualeValutazioni(id) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/percentuale/valutazioni/' + id).then((response) => {
					if (response.ok)
					{
						this.osservato_sel = this.osservati.find((item) => item.id == id);
						this.percent_val = response.data;
						this.$refs.percentValModal.show();
					}
				}, (response) => {
				}); 

			},
			isCurrentShadowTeamList(id) {	
				var lista = this.liste.find((item) => item.id == id);
				for(var i in lista.formazioni) {
					var form = lista.formazioni[i];
					if(form.shadow_team) {
						return true;
					} 
				}
				return false;
			},
		},

		watch: {
			'filters.ruolo': function () {
				this.filtraArchivio();
			},
			'filters.gradimento': function () {
				this.filtraArchivio();
			},
			'filters.osservatore': function () {
				this.filtraArchivio();
			},
			'filters.anno': function () {
				this.filtraArchivio();
			},
			'filters.segnalatore': function () {
				this.filtraArchivio();
			},
			'filters.stato': function () {
				this.filtraArchivio();
			},
			'filters.non_valutati': function () {
				this.filtraArchivio();
			},
			'filters.note': function () {
				this.filtraArchivio();
			},
			'filters.valutati_dal': function () {
				this.filtraArchivio();
			},
			'filters.valutati_al': function () {
				this.filtraArchivio();
			},
			'filters.lista': function () {
				this.filtraArchivio();
			},
			'filters.live': function () {
				this.filtraArchivio();
			},
			'filters.squadra': function () {
				this.filtraArchivio();
			},
			'filters.periodo': function () {
				this.filtraArchivio();
			},
			'filters.appunti': function () {
				this.filtraArchivio();
			},
			'filters.nazionale': function () {
				this.filtraArchivio();
			},
			'filters.order_by': function () {
				this.filtraArchivio();
			},
			'filters.val_view': function () {
				this.filtraArchivio();
			},
			'filters.voto_opponent': function () {
				this.filtraArchivio();
			},
			lista_selected: function () {
				this.getGiocatoriLista();
				this.lista_utenti = [];
				for(var index in this.liste) {
					var lista = this.liste[index];
					if(lista.id == this.lista_selected) {
						for(var i in lista.lista_utenti) {
							var item = lista.lista_utenti[i]; 
							this.lista_utenti.push(item.utente_id);
						}
					}
				}	
			},
			scoutingCategory() {
				this.filtraArchivio();
			}, 
		},

		computed: {
			auth: function() {
				return Auth;
			},
			anni: function() {
				var options = [];
				options.push({ value: '', text: '-- Anno --' });
				var anno = moment().subtract(45, 'years').format('YYYY'); 
				for(var i = 0; i <= 40; i++) {
					options.push({ value: anno, text: anno });
					anno++;
				}
				return options;
			},
			is_lista_master: function() {
				for(var index in this.liste) {
					var lista = this.liste[index];
					if(lista.id == this.lista_selected) {
						if(lista.master) {
							return true;

						}
					}
				}
				return false;
			},
			is_lista_condivisa: function() {
				for(var index in this.liste) {
					var lista = this.liste[index];
					if(lista.id == this.lista_selected) {
						if(lista.condivisa) {
							return true;
						}
					}
				}
				return false;
			},
			preset_osservatori: function() {
				var preset_osservatori = [];
				if(this.auth.user.preset) {
					var preset = JSON.parse(this.auth.user.preset);
					if(preset.osservatori) {
						for(var i in preset.osservatori) {
							var item = preset.osservatori[i];
							if(item.value.length > 0) {
								preset_osservatori.push(item);

							}

						}
						if(preset_osservatori.length > 0) {
							this.filters.osservatore = preset_osservatori[0].value;
						}		
					}
				}
				return preset_osservatori;
			},
			...mapState(['scoutingCategory']),
		},
		filters: {
			formatYear: function (date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('YY'); 
				}
				return "";
			}
		},

	}
	</script>
