<template>
	<div>
		<!--<b-button @click.prevent="getDashboard">refresh</b-button>
		<b-button @click.prevent="genGraph1">gen</b-button>-->
		<div v-if="dati && lplayers">
		
			<div v-if="scatter && dati">
				<b-datepicker v-model="ux.scatterDate" :min="ux.from" :max="ux.to" size="sm"></b-datepicker>
				<E-Chart style="width: 100%; height: 450px" :option="scatter"></E-Chart>
			</div>

			<b-row class="align-items-center">
				<b-col cols="5" v-if="ux.mode == 'detail'">
					<b-datepicker v-model="ux.from"></b-datepicker>
				</b-col>
				<b-col cols="5" v-if="ux.mode == 'detail'">
					<b-datepicker v-model="ux.to"></b-datepicker>
				</b-col>
				<b-col>
					<div class="text-right">
						<b-button v-if="ux.mode == 'card'" @click.prevent="ux.mode = 'detail'" size="sm" variant="outline-success">Espandi Vista</b-button>
						<b-button-group v-if="ux.mode == 'detail'">
							<b-button @click.prevent="getDashboard" size="sm" variant="secondary">Cerca</b-button>
							<b-button @click.prevent="ux.mode = 'card'" size="sm" variant="secondary">Contrai Vista</b-button>
						</b-button-group>
					</div>
				</b-col>
			</b-row>

			<div v-if="ux.mode == 'card'">
				<b-row>
					<b-col cols="2" v-for="p in graph" class="my-1" v-if="lplayers.indexOf(p.player.person.id) !== -1">
						<div :id="'pp' + p.player.person.id" class="p-1 py-2" style="border: 1px solid rgba(0,0,0,.05); border-radius: 3px">
							<div class="text-center d-flex">
								<div class="flex-column justify-content-center" style="z-index: 1">
									<img :src="p.player.person.board_avatar_url" class="img-fluid" style="object-fit: contain; width: 100px; height: 100px; border: 1px solid rgba(0,0,0,.2); background-color: white; border-radius: 100%; box-shadow: 0px 0px 10px rgba(0,0,0,.1)" />
								</div>
								<div style="height: 100px; z-index: 0" class="d-flex align-items-center">
									<div class="text-right" style="background-color: white; margin-left: -10px; padding-left: 20px; padding-right: 5px; padding-bottom: 6px; padding-top: 4px; border-radius: 3px">
										<span v-if="p.player.person.info_fisica"> {{ p.player.person.info_fisica.altezza }} <span class="text-muted">cm</span></span>
										<div style="line-height: 15px">
											<span style="font-size: 160%" class="font-weight-bold">{{ rangeW(p).latest }}</span>
											<span class="text-muted small"> kg</span>
										</div>
									</div>
								</div>
							</div>
							<div class="my-1 text-center">
								<div><span class="font-weight-bold">{{ p.player.person.cognome }}</span></div>
								<div class="small" style="line-height: 0">{{ p.player.person.nome }}</div>
							</div>
						</div>

						<b-popover :target="'pp' + p.player.person.id" placement="top" :title="p.player.person.cognome + ' ' + p.player.person.nome" custom-class="wof" triggers="hover">
							<E-Chart style="width: 100%; height: 300px" :option="p.g"></E-Chart>
						</b-popover>
					</b-col>
				</b-row>
			</div>
			<div v-else>

				<b-row v-for="p in graph">
					<b-col class="text-center align-items-center d-flex flex-column justify-content-center">
						<img :src="p.player.person.board_avatar_url" class="img-fluid" style="object-fit: contain; width: 100px; height: 100px; border: 1px solid rgba(0,0,0,.2); border-radius: 100%; box-shadow: 0px 0px 10px rgba(0,0,0,.1)" />
						<div class="my-1">
							<span class="font-weight-bold">{{ p.player.person.cognome }}</span>
							{{ p.player.person.nome }}
						</div>
						<div>
							<!--{{ p.player.peso }} <span class="text-muted">kg</span>-->
							<span v-if="p.player.person.info_fisica"> {{ p.player.person.info_fisica.altezza }} <span class="text-muted">cm</span></span>
							<div>
								<span class="text-muted small">Min:</span> {{ rangeW(p).min }} - <span class="text-muted small">Max:</span> {{ rangeW(p).max }}
							</div>
							<div>
								<span class="small text-muted">(± {{ rangeW(p).range }} kg)</span>
							</div>
						</div>
					</b-col>
					<b-col cols="10">
						<E-Chart style="width: 100%; height: 350px" :option="p.g"></E-Chart>
					</b-col>
					<b-col cols="12">
						<hr />
					</b-col>
				</b-row>

			</div>
		</div>

		<!--
		<pre>{{ graph }}</pre>
		<hr />
		<pre>{{ grafico_1 }}</pre>
		-->

	</div>
</template>

<style type="text/css">

.wof {
	max-width: 550px !important;
	width: 550px !important;
}

.wof .popover-body {
	width: 550px !important;
}

</style>

<script>

import csvDownload from 'json-to-csv-export'
import eChart from '@/components/layout/E-Chart/Base.vue'
import PlayerCard from '@/components/layout/giocatore/PlayerCard.vue'

var Auth = require('../../../auth.js');
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';

export default {
	props: [ 'config' ],
	components: {
		'E-Chart': eChart,
		'Player-Card': PlayerCard,
	},
	data: function(){
		return {
			graphs: {
				g1: null
			},
			lplayers: null,
			dati: [],
			dexa: [],
			ux: {
				mode: 'card', //'detail',
				scatterDate: moment().format('YYYY-MM-DD'),
				from: moment().add(-1, 'year').format('YYYY-MM-DD'),
				to: moment().add(1, 'day').format('YYYY-MM-DD')
			}
		}
	},
	created: function() {
		//console.log('0');
		//this.ux.from = moment().add(-100, 'days').format('YYYY-MM-DD');
		//this.ux.to = moment().format('YYYY-MM-DD');
	},
	computed: {
		dates: function() {

			if(!this.dati) return false;
			//console.log(this.ux.scatterDate);
			var k = this.graph[0].g.xAxis.data.indexOf(moment(this.ux.scatterDate).format('DD/MM/YYYY'));
			if(k === -1) {
				k = this.graph[0].g.xAxis.data.length - 1;
			}

			return k;
			
		},
		scatter: function() {

			if(this.lplayers && this.lplayers.length == 0) return false;
			if(!this.dates) return false;
			var g = _.groupBy(this.dati, 'persona_id');

			// semmai ordinare una volta per tutti i dati.
			var min = _.minBy(this.dati, function(a) {
				if(a.data)
					return moment(a.data).format('X');
				return false
			}).data;

			var max = _.maxBy(this.dati, function(a) {
				if(a.data)
					return moment(a.data).format('X');
				return false
			}).data;

			var start = min;
			var labels = [];
			var labels_ = [];
			while( moment(start).format('X') < moment(max).format('X') ) {
				labels.push( moment(start).format('YYYY-MM-DD') );
				labels_.push( moment(start).format('DD/MM/YYYY') );
				start = moment(start).add(1, 'days').format('YYYY-MM-DD');
			}

			var out = [];
			_.each(g, function(b, k) {

				if(this.lplayers.indexOf(parseInt(k)) == -1) return true;

				var player_graph = _.find(this.graph, function(a) {
					return a.player.persona_id === parseInt(k);
				});

				var latest = _.last(_.orderBy(b, function(e) { return moment(e.data).format('X') }));
				if(latest.person.info_fisica && latest.person.info_fisica.altezza == 0) return false;

				var findingDate = this.dates;
				while(player_graph.g.series[0].data[findingDate] == null && findingDate > 0) {
					findingDate--;
				}

				//console.log('Series', player_graph.g.series[0].data[findingDate], labels_[findingDate], labels_[this.dates]);
				//console.log(this.dates);
				var peso = player_graph.g.series[1].data[this.dates];

				var t = { dt: labels_[findingDate], player: latest.persona, peso: peso, altezza: latest.person.info_fisica ? latest.person.info_fisica.altezza : 0 };
				if(t.peso != 0 && t.altezza != 0 && t.altezza !== null)
					out.push(t);

			}.bind(this));

			if(out.length == 0) return false;

			return {
				grid: [
					 { left: '4%', top: '10%', width: '88%', height: '84%' }
				],
				xAxis: {
					name: 'Peso KG',
					min: _.minBy(out, 'peso').peso - 5,
					max: _.maxBy(out, 'peso').peso + 5,
				},
				yAxis: {
					name: 'Altezza CM',
					min: _.minBy(out, 'altezza').altezza - 5,
					max: _.maxBy(out, 'altezza').altezza + 5
				},
				tooltip: {
					axisPointer: {
						type: 'cross'
					},
					formatter: function(b) {
						return '<strong>' + b.data[2] + '</strong><br />' + b.data[1] + ' cm / ' + b.data[0] + ' kg<br />Misurazione del: <strong>' + b.data[3] + '</strong>';
					}
				},
				series: [
					{
						data: _.map(out, function(b) { return [ b.peso, b.altezza, b.player, b.dt ] }),
						symbolSize: 20,
						type: 'scatter'
					}
				],
			}

			return out;

		},
		graph: function() {

			if(!this.dati) return [];

			// semmai ordinare una volta per tutti i dati.
			var min = _.minBy(this.dati, function(a) {
				if(a.data)
					return moment(a.data).format('X');
				return false
			}).data;

			var max = _.maxBy(this.dati, function(a) {
				if(a.data)
					return moment(a.data).format('X');
				return false
			}).data;

			var start = min;
			var labels = [];
			var labels_ = [];
			while( moment(start).format('X') < moment(max).format('X') ) {
				labels.push( moment(start).format('YYYY-MM-DD') );
				labels_.push( moment(start).format('DD/MM/YYYY') );
				start = moment(start).add(1, 'days').format('YYYY-MM-DD');
			}

			var ind = labels.indexOf(this.ux.scatterDate);
			if(ind == -1)
				ind = labels.length-1;
			else
				ind = ind;
			//console.log('A', this.ux.scatterDate, ind, labels.length);

			var datiPersona = _.groupBy(this.dati, 'persona_id');
			var graphs = [];
			_.each(datiPersona, function(dati, persona) {

				var track = [];
				var untrack = [];
				var p = null;

				_.each(labels, function(i) {
					var has = _.find(dati, { data: i });
					if(has) {
						untrack.push(has.peso);
						track.push(has.peso);
						p = has.peso;
					} else {
						track.push(null);
						if(p)
							untrack.push(p);
						else {
							p = _.first(_.sortBy(dati, function(b) { return moment(b.data).format('X') })).peso
							untrack.push(p);
						}
					}
				});

				// Massa di tessuto adiposo…
				var dexaOfMe = _.filter(this.dexa, { persona_id: parseInt(persona) });
				if(dexaOfMe.length > 0) {

					var dexaMagra = [];
					var dexaAdiposo = [];

					_.each(labels, function(i) {

						var exists = _.find(dexaOfMe, { data: i });
						if(!exists) {
							dexaMagra.push(null);
							dexaAdiposo.push(null);
						}
						else {
							dexaMagra.push(exists.Intero_Massa_di_tessuto_magro/1000);
							dexaAdiposo.push(exists.Intero_Massa_di_tessuto_adiposo/1000);
						}

					});

				}
				//console.log(persona, dexaOfMe);

				var series = [
				];

				series.push({
					data: track,
					name: 'Peso Rilevato',
					type: 'bar',
					symbol: 'circle',
					showSymbol: true,
					showAllSymbol: true,
					itemStyle: {
						opacity: 0.2
					},
					markLine: {
						label: {
							formatter: 'Media',
							position: 'end'
						},
						lineStyle: {
							type: 'dashed'
						},
						data: [
							[ { coord: [_.first(labels_), _.sum(untrack)/labels.length ], symbol: 'none' },
							{ coord: [_.last(labels_), _.sum(untrack)/labels.length ], symbol: 'none' } ]
						]
					}
				});
				
				series.push({
					data: untrack,
					showSymbol: false,
					name: 'Peso Calcolato',
					type: 'line',
					markArea: {
						itemStyle: {
							color: 'rgba(255, 173, 177, 0.4)',
						},
						data: [
							[
								{ xAxis: labels_[ind] },
								{ xAxis: labels_[ind+1] }
							]
						]
					}
				});


				if(dexaAdiposo && dexaMagra) {

					var dAdp = [];
					var dMag = [];
					var pd = [];
					_.each(dexaAdiposo, function(b, k) {
						if(b) {

							var t = dexaAdiposo[k] + dexaMagra[k];
							var range = ( untrack[k] - (_.min(untrack) - 1.5) ) * 0.9;

							var ratio = dexaMagra[k]/t;
							var val = range * ratio + (_.min(untrack) - 1.5);
							var valNeg = range * (ratio-1) + (_.min(untrack) - 1.5);

							dMag.push(dexaMagra[k]);//((dexaMagra[k]/t) * range) + (_.min(untrack) - 1.5));

							dAdp.push(dexaAdiposo[k]);//((dexaAdiposo[k]/t) * range));
							
							// dMag.push(((dexaMagra[k]/t) * range) + (_.min(untrack) - 1.5));

							pd.push({ xAxis: k, name: 'Dexa', label: { normal: { formatter: '' } } });
						} else {
							dAdp.push(null);
							dMag.push(null);
						}
					});

					var ii = null;
					var ahrr = [];
					var ahrrFilled = [];

					for(var a = 0; a < dMag.length; a++) {
						var l = dMag[a];
						if(ii != null && l == null) {
							ahrrFilled.push(ii);
							ahrr.push(null);
						} else if(l != null) {
							ahrr.push(l);
							ahrrFilled.push(l);
							ii = l;
						} else {
							ahrr.push(null);
							ahrrFilled.push(null);
						}
					}
					//console.log(dMag, ahrr);

					series.push({
						markLine: {
							symbol: 'none',
							data: pd
						},
						data: ahrr,
						name: 'Tessuto Magro',
						type: 'line',
						connectNulls: true,
						//stack: 'Total',
						yAxisIndex: 1,
						showSymbol: false,
						// areaStyle: {
						// 	opacity: 0.2
						// },
						lineStyle: {
							type: 'solid'
						},
						emphasis: {
							focus: 'series'
						}
					});

					var jj = null;
					var ahrr2 = [];
					var ahrr2Filled = [];

					for(var a = 0; a < dAdp.length; a++) {
						var l = dAdp[a];
						if(jj != null && l == null) {
							ahrr2.push(null);
							ahrr2Filled.push(jj);
						} else if(l != null) {
							ahrr2.push(l);
							ahrr2Filled.push(l);
							jj = l;
						} else {
							ahrr2.push(null);
							ahrr2Filled.push(null);
						}
					}

					var ii = _.min(ahrr) - _.min(ahrr2);

					ahrr2 = _.map(ahrr2, function(b) {
						if(b == null) return null;
						return ii + b;
					});

					//console.log(ahrr2, ahrr)
					series.push({
						data: ahrr2,
						name: 'Tessuto Adiposo',
						type: 'line',
						showSymbol: false,
						showAllSymbol: true,
						//stack: 'Total',
						yAxisIndex: 1,
						lineStyle: {
							type: 'solid'
						},
						connectNulls: true,
						// areaStyle: {
						// 	opacity: 0.2
						// },
						emphasis: {
							focus: 'series'
						},
					});

				}
		

				graphs.push({
					player: _.last(_.orderBy(dati, function(e) { return moment(e.data).format('X') })),
					g: {
						grid: {
							containLabel: true
						},
						toolbox: {
							show: true,
							feature: {
								saveAsImage: {}
							}
						},
						legend: {
							orient: 'horizontal',
							selectedMode: false,
							data: [ 'Peso Rilevato', 'Peso Calcolato', 'Tessuto Magro', 'Tessuto Adiposo' ],
							bottom: 30
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'cross'
							},
							formatter: function(a,b) {

								if(ahrr2Filled[a[0].dataIndex-1]) {

									var a2 = ahrr2Filled[a[0].dataIndex]-ahrr2Filled[a[0].dataIndex-1]; // Adiposo
									var a1 = ahrrFilled[a[0].dataIndex]-ahrrFilled[a[0].dataIndex-1]; // Magro
									
									var varianceA = 0;
									var varianceB = 0;
									if(Math.abs(a1) >= 0.5) {
										varianceA = a1 > 0 ? 1 : -1;
									}

									if(Math.abs(a2) >= 0.139) {
										varianceB = a2 > 0 ? 1 : -1;
									}

								}

								var out = [];
								_.each(a, function(c) {

									if(c.seriesName != 'HIDDEN')
										var v = c.value;
									else return true;

									out.push({
										marker: c.marker,
										name: c.seriesName,
										value: v
									})

								});

								//console.log('BBBB', a);

								var template = '<div>';
								template += '<div>Giorno</div>';
								template += '<table>';

								_.each(out, function(bb) {

									template += '<tr>';
										template += '<td>';
											template += bb.marker;
										template += '</td>';
										template += '<td>';
											template += bb.name;
										template += '</td>';
										template += '<td class="text-right pl-3">';

											template += (bb.value != undefined && bb.value != null ? '<strong>' + (bb.value).toFixed(3) + '</strong>' : '-');
											
											if(bb.name == 'Tessuto Magro' && a1)
												template+= ' <small class="'+ {'-1': 'text-danger', '0': 'text-muted', '1': 'text-success'}[varianceA.toString()] +'">' + (a1 > 0 ? '+' : '') + a1.toFixed(2) + 'kg</small> ';

											if(bb.name == 'Tessuto Adiposo' && a2)
												template+= ' <small class="'+ {'1': 'text-danger', '0': 'text-muted', '-1': 'text-success'}[varianceB.toString()] +'">' + (a2 > 0 ? '+' : '') + a2.toFixed(2) + 'kg</small> ';

										template += '</td>';
									template += '</tr>';

								});

								template += '</table>';
								template += '</div>';

								return template;

							}

						},
						xAxis: {
							type: 'category',
							boundaryGap: false,
							data: labels_
						},
						yAxis: [{
							name: 'Peso',
							type: 'value',
							axisLabel: {
								formatter: '{value} kg',
								align: 'right'
							},
							min: _.round((_.minBy(dati, 'peso').peso - 1.5), 2),
							max: _.round((_.maxBy(dati, 'peso').peso + 1.5), 2)
						},{
							name: 'in k',
							// axisLabel: {
							// 	textStyle: {
							// 		color: 'orange'
							// 	}
							// },
							show: true,
							type: 'value',
							showGrid: true,
							splitLine: {
								show: false
							},
							axisLabel: {
								formatter: function(b) {
									return _.round(b - _.min(ahrr), 3).toFixed(3);
								},
								align: 'left'
							},
							min: _.min(ahrr),
							max: 0.1 + (_.max(ahrr) > _.max(ahrr2) ? _.max(ahrr) : _.max(ahrr2))
						}],
						series: series
					}
				});

			}.bind(this));

			return graphs;

		},
		grafico_1: function() {

			if(!this.dati) return [];

			// semmai ordinare una volta per tutti i dati.
			var min = _.minBy(this.dati, function(a) {
				return moment(a.data).format('X');
			}).data;

			var max = _.maxBy(this.dati, function(a) {
				return moment(a.data).format('X');
			}).data;

			var start = min;
			var labels = [];
			while( moment(start).format('X') < moment(max).format('X') ) {

				labels.push( moment(start).format('YYYY-MM-DD') );

				start = moment(start).add(1, 'days').format('YYYY-MM-DD');

			}

			var datiPersona = _.groupBy(this.dati, 'persona_id');
			var datasets = [];
			_.each(datiPersona, function(dati, persona) {

				var track = [];

				_.each(labels, function(i) {
					var has = _.find(dati, { data: i });
					if(has)
						track.push(has.peso);
					else
						track.push(null);
				});

				datasets.push({
					data: track,
					label: _.first(dati).persona,
					tension: 0.4,
					spanGaps: true
				});

			});

			var datiG = _.filter(this.dati, { persona_id: 239 });

			//var labels = _.map(datiG, 'data');
			var values = _.map(datiG, 'peso');

			return { labels, datasets };

		},
		_: function() { return _; },
		players: function() {
			return _.keys(_.groupBy(this.dati, 'persona')).sort();
		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		'ux.scatterDate': {
			immediate: true,
			handler: function() {
				//console.log('Hello World');
				this.lplayers = null;
				this.$http.get(this.$store.state.apiEndPoint + '/atlas/squadra/' + this.rosaTeamId + '/at/' + this.ux.scatterDate).then(function(data) {
					this.lplayers = data.data;
				});
				// atlas/squadra/13/at/sq
			},
		},
		'ux.from': function(a,b) {
			if(b != null)
				this.canFilter = true;
		},
		'ux.to': function(a,b) {
			if(b != null)
				this.canFilter = true;
		},
		rosaTeamId: {
			immediate: true,
			handler: function() {
				this.getDashboard();
			}
		}
	},
	filters: {
		data: function(i, format) {
			return moment(i).format(format || 'D/M/YY HH:mm')
		}
	},
	methods: {
		rangeW: function(k) {
			var a = {
				min: 0,
				max: 0,
				range: 0,
				latest: 0
			};

			a.min = _.min(_.filter(k.g.series[0].data));
			a.max = _.max(_.filter(k.g.series[0].data));
			a.range = _.round(a.max - a.min, 2);
			a.latest = k.g.series[1].data[this.dates]

			return a;
		},
		applyPreset: function(days) {

			this.ux.from = moment().add(days, 'days').format('YYYY-MM-DD');
			this.ux.to = moment().format('YYYY-MM-DD');

			this.getDashboard();

		},
		getDashboard: function() {

			this.dati = null;
			this.canFilter = null;

			this.$http.post(this.$store.state.apiEndPoint + this.config.endpoint, {
				team_id: this.rosaTeamId,
				from: this.ux.from,
				to: this.ux.to
			}).then(function(data) {
				this.dati = data.data.dati;
				this.dexa = data.data.dexa;
			});

		},
	}
}

</script>