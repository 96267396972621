<template id="agenda">
	<section class="agenda">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Agenda</span>
					</div>

					<div class="content">
						<full-calendar ref="calendar" :events="events" @event-selected="eventSelected" @day-click="dayClick" :config="config" @event-drop="eventDrop" @event-resize="eventResize"></full-calendar>
						<p>Filtra per:</p>
						<b-row>
							<b-col>
								<b-form-checkbox-group v-model="filter_events">
									<b-form-checkbox value="partite-aperte"><b-badge variant="partite-aperte">Missioni partita in corso</b-badge></b-form-checkbox>
									<b-form-checkbox value="partite-chiuse"><b-badge variant="partite-chiuse">Missioni partita completate</b-badge></b-form-checkbox>
									<b-form-checkbox value="giocatore-aperte"><b-badge variant="giocatore-aperte">Missioni giocatore in corso</b-badge></b-form-checkbox>
									<b-form-checkbox value="giocatore-chiuse"><b-badge variant="giocatore-chiuse">Missioni giocatore completate</b-badge></b-form-checkbox>
									<b-form-checkbox value="promemoria"><b-badge variant="promemoria">Promemoria</b-badge> </b-form-checkbox>
									<b-form-checkbox value="ferie"><b-badge variant="ferie">Ferie</b-badge> </b-form-checkbox>
								</b-form-checkbox-group>
							</b-col>				
						</b-row>
						<b-row class="mt-3" v-if="auth.isUserEnable('scout_utenti')">
							<b-col cols="2">
								<b-form-group label="Missioni assegnate da">
									<b-form-select v-model="filter_utente">
										<option :value="null">Tutti</option>
										<option v-for="(item, index) in filter_utenti_id" :value="item">{{filter_utenti_nome[index]}}</option>	
									</b-form-select></b-form-group>
								</b-col>
								<b-col cols="2"><b-form-group label="Missioni assegnate a">
									<b-form-select v-model="filter_osservatore">
										<option :value="null">Tutti</option>
										<option v-for="(item, index) in filter_osservatori_id" :value="item">{{filter_osservatori_nome[index]}}</option>	
									</b-form-select></b-form-group></b-col>
									<b-col cols="2"><b-form-group label="Promemoria di" v-if="auth.isAdmin()">
										<b-form-select v-model="filter_promemoria">
											<option :value="null">Tutti</option>
											<option v-for="(item, index) in filter_promemoria_id" :value="item">{{filter_promemoria_nome[index]}}</option>	
										</b-form-select></b-form-group></b-col>
										<b-col cols="2"><b-form-group label="Ferie di" v-if="auth.isAdmin()">
											<b-form-select v-model="filter_ferie">
												<option :value="null">Tutti</option>
												<option v-for="(item, index) in filter_ferie_id" :value="item">{{filter_ferie_nome[index]}}</option>	
											</b-form-select></b-form-group></b-col>	
										</b-row> 
									</div>
								</div>
							</div>
						</div>
						<footerapp></footerapp>
						<b-modal id="eventModal" ref="eventModal" :title="titleModal" ok-only ok-title="Chiudi">
							<b-form-radio-group v-model="tipo_evento"
							:options="tipi_evento" v-if="!selected && tipo_evento == ''">
						</b-form-radio-group>
						<b-form @submit="aggiungiMissione" v-if="!selected && tipo_evento == 'missione'">
							<b-form-group label="Tipo di missione:">
								<b-form-radio-group id="tipo_missione" v-model="missione.tipo" required>
									<b-form-radio value="giocatore">Giocatore</b-form-radio>
									<b-form-radio value="partita">Partita</b-form-radio>	      
								</b-form-radio-group>
							</b-form-group>
							<div v-if="missione.tipo == 'giocatore'">
								<b-form-group label="Cerca giocatore in archivio:">
									<b-input-group>
										<b-form-input id="cognome" type="text" v-model="query" placeholder="Inserisci cognome giocatore"></b-form-input>
										<b-input-group-append>
											<b-button variant="primary" @click="cercaGiocatoreByCognome"><i class="fa fa-search" aria-hidden="true"></i></b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
								<div v-if="show_osservati">
									<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
									<div v-if="!is_search && osservati.length == 0"><h5>Nessun giocatore trovato in archivio!</h5></div>
									<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;" v-if="osservati.length > 0">
										<div class="row">
											<div class="col-md-6" v-for="osservato in osservati" style="border: 1px solid #E5E5E5; cursor: pointer;font-size:0.85rem;" v-on:click="selezionaGiocatore(osservato, 'missione')">
												<div class="row">	
													<div class="col-md-4 my-3" style="text-align: center;">
														<b-img :src="osservato.avatar_url" fluid :alt="osservato.cognome + ' ' + osservato.nome" :width="130"></b-img>
													</div>
													<div class="col-md-8 my-3">
														<p style="display: inline;"><strong>{{ osservato.nome }} {{ osservato.cognome }}</strong></p><br />
														<span class="text-muted"><flag :country="osservato.nascita_paese ? osservato.nascita_paese.sigla : ''" :size="24"></flag> {{osservato.nascita_paese ? osservato.nascita_paese.paese : ''}} </span><br />
														<span class="text-muted">{{osservato.nascita_data | formatDate}}</span><br />
														<span class="text-muted">{{osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}</span><br />
														<span class="text-muted">{{ osservato.last_carriera_sportiva.squadra ? osservato.last_carriera_sportiva.squadra.name : '' }}</span><br /></div>
													</div>
												</div>
											</div>

										</div>

									</div>
									<div class="card" v-if="missione.osservato">
										<div style="text-align:center;">
											<b-img :src="missione.osservato.avatar_url" :alt="missione.osservato.cognome + ' ' + missione.osservato.nome" fluid :width="130"></b-img>
										</div>
										<div class="m-1 font-bold text-center">{{ missione.osservato.nome }} {{ missione.osservato.cognome }}</div>
									</div>
								</div>
								<div v-if="missione.tipo == 'partita'">
									<b-form-group id="competizioneGroup" label="Competizione">
										<b-form-input id="competizione" type="text" v-model="missione.competizione"></b-form-input>
									</b-form-group>
									<b-form-group id="partitaGroup" label="Partita">
										<b-input-group>
											<b-form-input id="partita" type="text" v-model="missione.partita" required></b-form-input>
											<b-input-group-append>
												<b-button variant="primary" @click="cercaPartita"><i class="fa fa-search" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
									<div v-if="show_aree">
										<span>Seleziona un'area: </span>
										<b-form-input
										v-model="search_area"
										placeholder="Quick search"
										></b-form-input>
										<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
											<b-row>
												<b-col cols="3" v-for="area in filtered_aree" style="padding: 0.2rem">
													<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.id)">
														<flag :country="area.sigla" :size="24"></flag><br/>
														{{area.paese}}
													</b-card>
												</b-col>
											</b-row>
										</div>
									</div>
									<div v-if="show_competizioni">
										<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
										<div v-if="!is_search && competizioni.length == 0"><h5>Nessuna competizione trovata!</h5></div>
										<div v-if="competizioni.length > 0">
											<span>Seleziona una competizione: </span>
											<b-form-input
											v-model="search_competizione"
											placeholder="Quick search"
											></b-form-input>
											<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
												<b-row>
													<b-col cols="4" v-for="comp in filtered_competizioni" style="padding: 0.2rem">
														<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaCompetizione(comp, 'missione')">
															<strong>{{comp.nome}}</strong><br/>
															<span class="font-size-xs color-rosso">{{$i18n.t(comp.formato)}}</span><br/>
														</b-card>
													</b-col>
												</b-row>
											</div>
										</div>
									</div>
									<div v-if="show_partite">
										<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
										<div v-if="!is_search && partite.length == 0"><h5>Nessuna partita trovata per la data selezionata!</h5></div>
										<div v-if="partite.length > 0">
											<span>Seleziona una partita: </span>
											<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
												<b-row class="py-1" v-for="partita in partite" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" @click="selezionaPartita(partita, 'missione')">
													<b-col>
														<h5>{{ partita.nome_partita }}</h5>	
													</b-col>
													<b-col>
														{{ partita.data_partita + " " + partita.ora_partita  | formatDateHour }}
													</b-col>
												</b-row>
											</div>
										</div>
									</div>
									<b-form-group id="dataGroup" label="Data / Ora">
										<b-row>
											<b-col cols="8">
												<b-form-input id="data" type="date" v-model="missione.data_partita"></b-form-input>
											</b-col>
											<b-col>
												<b-form-input id="ora" type="time" v-model="missione.ora_partita"></b-form-input>
											</b-col>
										</b-row>
									</b-form-group>
								</div>
								<b-form-group label="Seleziona uno o più osservatori:">
									<p style="color:#800080; font-weight:bold;" v-if="in_ferie != ''">Sono in ferie: {{ in_ferie }}</p>
									<b-form-checkbox-group v-model="missione.osservatori">
										<b-form-checkbox
										v-if="item.attivo"
										v-for="item in osservatori"
										:key="item.id"
										:value="item.id"
										>{{ item.nomeCompleto }}</b-form-checkbox>
									</b-form-checkbox-group>
								</b-form-group>
								<b-form-group label="Tipo di evento:">
									<b-form-radio-group v-model="missione.evento">
										<b-form-radio value="partita">Partita</b-form-radio>
										<b-form-radio value="allenamento">Allenamento</b-form-radio>
									</b-form-radio-group>
								</b-form-group>
								<b-form-group label="Dove:" v-if="missione.evento == 'partita'">
									<b-form-radio-group v-model="missione.luogo">
										<b-form-radio value="video">Video</b-form-radio>
										<b-form-radio value="stadio">Stadio</b-form-radio>
									</b-form-radio-group>
								</b-form-group>
								<b-form-group label="Intervallo di viaggio:" v-if="missione.evento == 'partita' &&missione.luogo == 'stadio'">
									<b-row>
										<b-col cols="8">
											<b-form-group horizontal label="Dal:" :label-cols="3">
												<b-form-input type="date" v-model="missione.viaggio_dal_data"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="4">
											<b-form-group horizontal label="/" :label-cols="1">
												<b-form-input type="time" v-model="missione.viaggio_dal_ora"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="8">
											<b-form-group horizontal label="Al:" :label-cols="3">
												<b-form-input type="date" v-model="missione.viaggio_al_data"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="4">
											<b-form-group horizontal label="/" :label-cols="1">
												<b-form-input type="time" v-model="missione.viaggio_al_ora"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
								</b-form-group>
								<b-form-textarea
								v-model="missione.nota"
								placeholder="Inserisci note"
								:rows="2">
							</b-form-textarea>
							<b-button type="submit" variant="primary" class="mt-3">Salva</b-button>
						</b-form>
						<b-form @submit="aggiungiPromemoria" v-if="!selected && tipo_evento == 'promemoria'">
							<b-form-group label="Associa:">
								<b-form-radio-group id="tipo_memo" v-model="promemoria.tipo">
									<b-form-radio value="">Nessuno</b-form-radio>
									<b-form-radio value="giocatore">Giocatore</b-form-radio>
									<b-form-radio value="partita">Partita</b-form-radio>	      
								</b-form-radio-group>
							</b-form-group>
							<div v-if="promemoria.tipo == 'giocatore'">
								<b-form-group label="Cerca giocatore in archivio:">
									<b-input-group>
										<b-form-input id="cognome" type="text" v-model="query" placeholder="Inserisci cognome giocatore"></b-form-input>
										<b-input-group-append>
											<b-button variant="primary" @click="cercaGiocatoreByCognome"><i class="fa fa-search" aria-hidden="true"></i></b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
								<div v-if="show_osservati">
									<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
									<div v-if="!is_search && osservati.length == 0"><h5>Nessun giocatore trovato in archivio!</h5></div>
									<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;" v-if="osservati.length > 0">
										<div class="row">
											<div class="col-md-6" v-for="osservato in osservati" style="border: 1px solid #E5E5E5; cursor: pointer;font-size:0.85rem;" v-on:click="selezionaGiocatore(osservato, 'promemoria')">
												<div class="row">	
													<div class="col-md-4 my-3" style="text-align: center;">
														<b-img :src="osservato.avatar_url" fluid :alt="osservato.cognome + ' ' + osservato.nome" :width="130"></b-img>
													</div>
													<div class="col-md-8 my-3">
														<p style="display: inline;"><strong>{{ osservato.nome }} {{ osservato.cognome }}</strong></p><br />
														<span class="text-muted"><flag :country="osservato.nascita_paese ? osservato.nascita_paese.sigla : ''" :size="24"></flag> {{osservato.nascita_paese ? osservato.nascita_paese.paese : ''}} </span><br />
														<span class="text-muted">{{osservato.nascita_data | formatDate}}</span><br />
														<span class="text-muted">{{osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}</span><br />
														<span class="text-muted">{{ osservato.last_carriera_sportiva.squadra ? osservato.last_carriera_sportiva.squadra.name : '' }}</span><br /></div>
													</div>
												</div>
											</div>

										</div>

									</div>
									<div class="card" v-if="promemoria.osservato">
										<div style="text-align:center;">
											<b-img :src="promemoria.osservato.avatar_url" :alt="promemoria.osservato.cognome + ' ' + promemoria.osservato.nome" fluid :width="130"></b-img>
										</div>
										<div class="m-1 font-bold text-center">{{ promemoria.osservato.nome }} {{ promemoria.osservato.cognome }}</div>
									</div>
								</div>
								<div v-if="promemoria.tipo == 'partita'">
									<b-form-group id="competizioneGroup" label="Competizione">
										<b-form-input id="competizione" type="text" v-model="promemoria.competizione"></b-form-input>
									</b-form-group>
									<b-form-group id="partitaGroup" label="Partita">
										<b-input-group>
											<b-form-input id="partita" type="text" v-model="promemoria.partita" required></b-form-input>
											<b-input-group-append>
												<b-button variant="primary" @click="cercaPartita"><i class="fa fa-search" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
									<div v-if="show_aree">
										<span>Seleziona un'area: </span>
										<b-form-input
										v-model="search_area"
										placeholder="Quick search"
										></b-form-input>
										<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
											<b-row>
												<b-col cols="3" v-for="area in filtered_aree" style="padding: 0.2rem">
													<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.id)">
														<flag :country="area.sigla" :size="24"></flag><br/>
														{{area.paese}}
													</b-card>
												</b-col>
											</b-row>
										</div>
									</div>
									<div v-if="show_competizioni">
										<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
										<div v-if="!is_search && competizioni.length == 0"><h5>Nessuna competizione trovata!</h5></div>
										<div v-if="competizioni.length > 0">
											<span>Seleziona una competizione: </span>
											<b-form-input
											v-model="search_competizione"
											placeholder="Quick search"
											></b-form-input>
											<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
												<b-row>
													<b-col cols="4" v-for="comp in filtered_competizioni" style="padding: 0.2rem">
														<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaCompetizione(comp, 'promemoria')">
															<strong>{{comp.nome}}</strong><br/>
															<span class="font-size-xs color-rosso">{{$i18n.t(comp.formato)}}</span><br/>
														</b-card>
													</b-col>
												</b-row>
											</div>
										</div>
									</div>
									<div v-if="show_partite">
										<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
										<div v-if="!is_search && partite.length == 0"><h5>Nessuna partita trovata per la data selezionata!</h5></div>
										<div v-if="partite.length > 0">
											<span>Seleziona una partita: </span>
											<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
												<b-row class="py-1" v-for="partita in partite" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" @click="selezionaPartita(partita, 'promemoria')">
													<b-col>
														<h5>{{ partita.nome_partita }}</h5>	
													</b-col>
													<b-col>
														{{ partita.data_partita + " " + partita.ora_partita  | formatDateHour }}
													</b-col>
												</b-row>
											</div>
										</div>
									</div>
									<b-form-group id="dataGroup" label="Data / Ora">
										<b-row>
											<b-col cols="8">
												<b-form-input id="data" type="date" v-model="promemoria.data_partita"></b-form-input>
											</b-col>
											<b-col>
												<b-form-input id="ora" type="time" v-model="promemoria.ora_partita"></b-form-input>
											</b-col>
										</b-row>
									</b-form-group>
								</div>
								<b-form-textarea class="mt-3"
								v-model="promemoria.nota"
								placeholder="Inserisci promemoria"
								:rows="10">
							</b-form-textarea>
							<b-button type="submit" variant="primary" class="mt-3">Salva</b-button>
						</b-form>

						<b-form @submit="aggiungiFerie" v-if="!selected && tipo_evento == 'ferie'">
							<b-form-group horizontal label="Dal:" :label-cols="3">
								<b-form-input type="date" v-model="ferie.dal" required></b-form-input>
							</b-form-group>
							<b-form-group horizontal label="Al:" :label-cols="3">
								<b-form-input type="date" v-model="ferie.al" required></b-form-input>
							</b-form-group>
							<b-form-textarea class="mt-3"
							v-model="ferie.nota"
							placeholder="Inserisci note"
							:rows="5">
						</b-form-textarea>
						<b-button type="submit" variant="primary" class="mt-3">Salva</b-button>
					</b-form>

					<div v-if="selected && selected.tipo == 'missione'">
						<b-card v-if="selected.missione.tipo == 'giocatore'">
							<b-row>
								<b-col cols="4" class="md-3 my-1 text-center">
									<b-img :src="selected.missione.osservato.avatar_url" fluid :alt="selected.missione.osservato.cognome + ' ' + selected.missione.osservato.nome" :width="130"></b-img>
								</b-col>
								<b-col cols="8" class="my-1"><h5>{{ selected.missione.osservato.nome }} {{ selected.missione.osservato.cognome }}</h5>
									<span><flag :country="selected.missione.osservato.nascita_paese ? selected.missione.osservato.nascita_paese.sigla : ''" :size="24"></flag> {{selected.missione.osservato.nascita_paese ? selected.missione.osservato.nascita_paese.paese : ''}} </span><br/>
									<span>{{selected.missione.osservato.nascita_data | formatDate}}</span><br/>
									<span class="color-rosso">{{selected.missione.osservato.last_carriera_sportiva.ruolo_ideale && selected.missione.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? selected.missione.osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}</span><br/>
									<span>{{ selected.missione.osservato.last_carriera_sportiva.squadra ? selected.missione.osservato.last_carriera_sportiva.squadra.name : '' }}</span>
								</b-col>
							</b-row>
						</b-card>
						<b-card v-if="selected.missione.tipo == 'partita'">
							<b-row class="mx-1 my-1">
								<b-col cols="12">
									<h5>{{selected.missione.partita}}</h5>
								</b-col>
								<b-col cols="12">
									<span class="color-rosso">{{selected.missione.data_partita | formatDateHour}}</span>
								</b-col>
								<b-col cols="12">
									{{selected.missione.competizione}}
								</b-col>
							</b-row>
						</b-card>
						<b-row class="mt-1">
							<b-col cols="4">Assegnata da</b-col>
							<b-col><span class="color-rosso">{{selected.missione.utente.nomeCompleto}}</span></b-col>
						</b-row>
						<b-row class="mt-1">
							<b-col cols="4">Osservatore</b-col>
							<b-col><span class="color-rosso">{{selected.missione.osservatore.nomeCompleto}}</span></b-col>
						</b-row>
						<b-row class="mt-1">
							<b-col cols="4">Tipo di evento</b-col>
							<b-col><span class="color-rosso">{{selected.missione.evento}}</span></b-col>
						</b-row>
						<b-row class="mt-1" v-if="selected.missione.evento == 'partita'">
							<b-col cols="4">Dove</b-col>
							<b-col><span class="color-rosso">{{selected.missione.luogo}}</span></b-col>
						</b-row>
						<b-row class="mt-1" v-if="selected.missione.luogo == 'stadio'">
							<b-col cols="12">Intervallo di viaggio</b-col>
							<b-col cols="12">
								<b-row>
									<b-col cols="4">Dal</b-col>
									<b-col><span class="color-rosso">{{selected.missione.viaggio_dal | formatDateHour}}</span></b-col>
								</b-row>
								<b-row>
									<b-col cols="4">Al</b-col>
									<b-col><span class="color-rosso">{{selected.missione.viaggio_al | formatDateHour}}</span></b-col>
								</b-row>
							</b-col>
						</b-row>
						<b-row class="mt-1" v-if="selected.missione.nota">
							<b-col cols="12">Note</b-col>
							<b-col cols="12"><span class="color-rosso">{{selected.missione.nota}}</span></b-col>
						</b-row>
						<b-button type="button" variant="primary" class="mt-3" @click="eliminaMissione(selected.missione.id)" v-if="auth.isUserEnable('scout_del_mission')">Elimina</b-button>
						<b-button type="button" variant="success" class="mt-3 ml-2" @click="notificaMissione(selected.missione.id)" v-if="auth.isUserEnable('scout_add_mission')">Invia notifica email</b-button>
						<b-button type="button" variant="warning" class="mt-3 ml-2" @click="chiudiMissione(selected.missione.id)" v-if="auth.isUserEnable('scout_edit_mission') && selected.missione.stato == 0">Chiudi</b-button>
					</div>
					<div v-if="selected && selected.tipo == 'promemoria'">
						<b-row class="mt-1">
							<b-col><h5>{{selected.promemoria.utente.nomeCompleto}}</h5></b-col>
						</b-row>
						<b-card v-if="selected.promemoria.osservato">
							<b-row>
								<b-col cols="4" class="md-3 my-1 text-center">
									<b-img :src="selected.promemoria.osservato.avatar_url" fluid :alt="selected.promemoria.osservato.cognome + ' ' + selected.promemoria.osservato.nome" :width="130"></b-img>
								</b-col>
								<b-col cols="8" class="my-1"><h5>{{ selected.promemoria.osservato.nome }} {{ selected.promemoria.osservato.cognome }}</h5>
									<span><flag :country="selected.promemoria.osservato.nascita_paese ? selected.promemoria.osservato.nascita_paese.sigla : ''" :size="24"></flag> {{selected.promemoria.osservato.nascita_paese ? selected.promemoria.osservato.nascita_paese.paese : ''}} </span><br/>
									<span>{{selected.promemoria.osservato.nascita_data | formatDate}}</span><br/>
									<span class="color-rosso">{{selected.promemoria.osservato.last_carriera_sportiva.ruolo_ideale && selected.promemoria.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? selected.promemoria.osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}</span><br/>
									<span>{{ selected.promemoria.osservato.last_carriera_sportiva.squadra ? selected.promemoria.osservato.last_carriera_sportiva.squadra.name : '' }}</span>
								</b-col>
							</b-row>
						</b-card>
						<b-card v-if="selected.promemoria.partita">
							<b-row class="mx-1 my-1">
								<b-col cols="12">
									<h5>{{selected.promemoria.partita}}</h5>
								</b-col>
								<b-col cols="12">
									<span class="color-rosso">{{selected.promemoria.data_partita | formatDateHour}}</span>
								</b-col>
								<b-col cols="12">
									{{selected.promemoria.competizione}}
								</b-col>
							</b-row>
						</b-card>
						<b-row class="mt-1">
							<b-col>{{selected.promemoria.nota}}</b-col>
						</b-row>
						<b-button type="button" variant="primary" class="mt-3" @click="eliminaPromemoria(selected.promemoria.id)">Elimina</b-button>
					</div>

					<div v-if="selected && selected.tipo == 'ferie'">
						<b-row class="mt-1">
							<b-col><h5>{{selected.ferie.utente.nomeCompleto}}</h5></b-col>
						</b-row>
						<b-row>
							<b-col cols="4">Dal</b-col>
							<b-col><span class="color-rosso">{{selected.ferie.dal | formatDate}}</span></b-col>
						</b-row>
						<b-row>
							<b-col cols="4">Al</b-col>
							<b-col><span class="color-rosso">{{selected.ferie.al | formatDate}}</span></b-col>
						</b-row>
						<b-row class="mt-1">
							<b-col>{{selected.ferie.nota}}</b-col>
						</b-row>
						<b-button type="button" variant="primary" class="mt-3" @click="eliminaFerie(selected.ferie.id)">Elimina</b-button>
					</div>
				</b-modal>
			</section>
		</template>

		<script>

		import moment from 'moment'
		import * as types from '../../../store/types'
		import { FullCalendar } from 'vue-full-calendar'
//		import '../../../lang/agenda.js'

		var Auth = require('../../../auth.js');

		export default {

			data: function(){
				return {
					missioni: [],
					promemorias: [],
					feries: [],
					config: {
						locale: 'it',
						defaultView: 'month',
						eventLimit: 10,
						viewRender: (view) => {
							var reload = false;
							var start = moment(view.start).format("YYYY-MM-DD");
							var end = moment(view.end).format("YYYY-MM-DD");
							if(start >= this.start_view && end <= this.end_view) {
								reload = false;
							} else {
								this.start_view = start;
								this.end_view = end;
								reload = true;
							}
							if(reload) {
								this.resetDataAndFilters();
								this.getMissioni(start, end);
								this.getPromemoria(start, end);
								this.getFerie(start, end);
							}
						},
					},
					selected: null,
					tipo_evento: '',
					tipi_evento: [
					{ text: 'Missione', value: 'missione' },
					{ text: 'Promemoria', value: 'promemoria' },
					{ text: 'Ferie', value: 'ferie' }
					],
					missione: {
						tipo: '',
						scadenza: null,
						osservato: null,
						osservatori: [],
						wyscout_partita_id: null,
						partita_id: null,
						partita: null,
						competizione: null,
						data_partita: null,
						ora_partita: null,
						evento: null,
						luogo: null,
						viaggio_dal_data: null,
						viaggio_dal_ora: null,
						viaggio_al_data: null,
						viaggio_al_ora: null,
						nota: null,
						newscout: 2,
					},
					promemoria: {
						tipo: '',
						scadenza: null,
						nota: null,
						osservato: null,
						wyscout_partita_id: null,
						partita_id: null,
						partita: null,
						competizione: null,
						data_partita: null,
						ora_partita: null,
						newscout: 2,
					},
					ferie: {
						dal: null,
						al: null,
						nota: null,	
						newscout: 2,	
					},
					osservati: [],
					osservatori: [],
					query: '',
					is_search: false,
					show_osservati: false, 
					aree: [],
					competizioni: [],
					partite: [],
					show_aree: false,
					show_competizioni: false,
					show_partite: false,
					filter_events: [],
					filter_utenti_id: [],
					filter_osservatori_id: [],
					filter_promemoria_id: [],
					filter_ferie_id: [],
					filter_utenti_nome: [],
					filter_osservatori_nome: [],
					filter_promemoria_nome: [],
					filter_ferie_nome: [],
					filter_utente: null,
					filter_osservatore: null,
					filter_promemoria: null,
					filter_ferie: null,
					errors: [],
					start_view: null,
					end_view: null,
					search_area: "",
					search_competizione: "",
				}
			},

			created: function () {
				this.$http.get(this.$store.state.apiEndPoint + '/youthscout/osservatori/youth').then((response) => {
					if (response.ok)
					{
						this.osservatori = response.data; 
					}
				}, (response) => {
				});
				this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
					if (response.ok)
					{
						this.aree = response.data; 
					}
				}, (response) => {
				}); 

			//	this.getMissioni();
			//	this.getPromemoria();
			//	this.getFerie();

		},
		methods: {
			getMissioni(start, end) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/get/missioni', {params: { start_at: start, end_at: end, newscout: 2 }}).then((response) => {
					if (response.ok)
					{
						this.missioni = response.data;
						for(var index in this.missioni) {
							var missione = this.missioni[index];
							if(this.filter_utenti_id.indexOf(missione.utente_id) == -1) {
								this.filter_utenti_id.push(missione.utente_id);
								this.filter_utenti_nome.push(missione.utente.nomeCompleto);
							}
							if(this.filter_osservatori_id.indexOf(missione.osservatore_id) == -1) {
								this.filter_osservatori_id.push(missione.osservatore_id);
								this.filter_osservatori_nome.push(missione.osservatore.nomeCompleto);
							}
						} 
					}
				}, (response) => {
				});

			},
			getPromemoria(start, end) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/get/promemoria', {params: { start_at: start, end_at: end, newscout: 2 }}).then((response) => {
					if (response.ok)
					{
						this.promemorias = response.data; 
						for(var index in this.promemorias) {
							var memo = this.promemorias[index];
							if(this.filter_promemoria_id.indexOf(memo.utente_id) == -1) {
								this.filter_promemoria_id.push(memo.utente_id);
								this.filter_promemoria_nome.push(memo.utente.nomeCompleto);
							}
						}
					}
				}, (response) => {
				});
			},
			getFerie(start, end) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/get/ferie', {params: { start_at: start, end_at: end, newscout: 2 }}).then((response) => {
					if (response.ok)
					{
						this.feries = response.data; 
						for(var index in this.feries) {
							var ferie = this.feries[index];
							if(this.filter_ferie_id.indexOf(ferie.utente_id) == -1) {
								this.filter_ferie_id.push(ferie.utente_id);
								this.filter_ferie_nome.push(ferie.utente.nomeCompleto);
							}
						}
					}
				}, (response) => {
				});
			},
			resetDataAndFilters() {
				this.missioni = [];
				this.promemorias = [];
				this.ferie = [];
				this.filter_events = [];
				this.filter_utenti_id = [];
				this.filter_osservatori_id = [];
				this.filter_promemoria_id = [];
				this.filter_ferie_id = [];
				this.filter_utenti_nome = [];
				this.filter_osservatori_nome = [];
				this.filter_promemoria_nome = [];
				this.filter_ferie_nome = [];
				this.filter_utente = null;
				this.filter_osservatore = null;
				this.filter_promemoria = null;
				this.filter_ferie = null;
			},
			removeEvent() {
				this.$refs.calendar.$emit('remove-event', this.selected);
				this.selected = {};
			},
			eventSelected(event) {
				this.selected = event;
				this.$refs.eventModal.show();
			},
			dayClick(date, jsEvent, view) {
				this.selected = null;
				this.tipo_evento = '';
				this.is_search = false;
				this.query = '';
				this.osservati = [];
				this.competizioni = [];
				this.resetMissione();
				this.resetPromemoria();
				this.resetFerie();
				this.show_osservati = false;
				this.show_aree = false;
				this.show_competizioni = false;
				this.show_partite = false;
				this.missione.scadenza = date;
				this.promemoria.scadenza = date;
				this.ferie.dal = moment(date).format('YYYY-MM-DD');
				this.ferie.al = moment(date).format('YYYY-MM-DD');
				if(!this.auth.isUserEnable('scout_add_mission')) {
					this.tipo_evento = 'promemoria';
				}
				this.$refs.eventModal.show();
			},
			eventDrop(event, delta, revertFunc) {
				if(event.tipo == 'missione' && this.auth.isUserEnable('scout_edit_mission')) {
					event.missione.scadenza = moment(moment(event.missione.scadenza) + delta).format("YYYY-MM-DD HH:mm:ss");
					if(moment(event.missione.viaggio_dal).isValid()) {
						event.missione.viaggio_dal = moment(moment(event.missione.viaggio_dal) + delta).format("YYYY-MM-DD HH:mm:ss");
					}
					if(moment(event.missione.viaggio_al).isValid()) {
						event.missione.viaggio_al = moment(moment(event.missione.viaggio_al) + delta).format("YYYY-MM-DD HH:mm:ss");
					}
					this.$http.post(this.$store.state.apiEndPoint + '/scouting/aggiorna/missione', event.missione).then((response) => {
						if (response.ok) {
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });	
						}
					}, (response) => {
						revertFunc();
					});

				} else if(event.tipo == 'promemoria') {
					event.promemoria.scadenza = moment(moment(event.promemoria.scadenza) + delta).format("YYYY-MM-DD HH:mm:ss");
					this.$http.post(this.$store.state.apiEndPoint + '/scouting/aggiorna/promemoria', event.promemoria).then((response) => {
						if (response.ok) {
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });	
						}
					}, (response) => {
						revertFunc();
					});
				} else if(event.tipo == 'ferie') {
					event.ferie.dal = moment(moment(event.ferie.dal) + delta).format("YYYY-MM-DD HH:mm:ss");
					event.ferie.al = moment(moment(event.ferie.al) + delta).format("YYYY-MM-DD HH:mm:ss");
					this.$http.post(this.$store.state.apiEndPoint + '/scouting/aggiorna/ferie', event.ferie).then((response) => {
						if (response.ok) {
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });	
						}
					}, (response) => {
						revertFunc();
					});
				}
			},
			eventResize(event, delta, revertFunc) {
				if(event.tipo == 'missione' && this.auth.isUserEnable('scout_edit_mission')) {
					if(moment(event.missione.viaggio_al).isValid()) {
						event.missione.viaggio_al = moment(moment(event.missione.viaggio_al) + delta).format("YYYY-MM-DD HH:mm:ss");
					}
					this.$http.post(this.$store.state.apiEndPoint + '/scouting/aggiorna/missione', event.missione).then((response) => {
						if (response.ok) {
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });	
						}
					}, (response) => {
						revertFunc();
					});
				} else if(event.tipo == 'ferie') {
					event.ferie.al = moment(moment(event.ferie.al) + delta).format("YYYY-MM-DD HH:mm:ss");
					this.$http.post(this.$store.state.apiEndPoint + '/scouting/aggiorna/ferie', event.ferie).then((response) => {
						if (response.ok) {
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });	
						}
					}, (response) => {
						revertFunc();
					});
				}
			},
			resetMissione() {
				this.missione.tipo = '';
				this.missione.osservato = null;
				this.missione.osservatori = [];
				this.missione.wyscout_partita_id = null;
				this.missione.partita_id = null;
				this.missione.partita = null;
				this.missione.competizione = null;
				this.missione.data_partita = null;
				this.missione.ora_partita = null;
				this.missione.scadenza = null;
				this.missione.evento = null;
				this.missione.luogo = null;
				this.missione.viaggio_dal_data = null;
				this.missione.viaggio_dal_ora = null;
				this.missione.viaggio_al_data = null;
				this.missione.viaggio_al_ora = null;
				this.missione.nota = null;
			},
			resetPromemoria() {
				this.promemoria.tipo = '';
				this.promemoria.scadenza = null;
				this.promemoria.nota = null;
				this.promemoria.osservato = null;
				this.promemoria.wyscout_partita_id = null;
				this.promemoria.partita_id = null;
				this.promemoria.partita = null;
				this.promemoria.competizione = null;
				this.promemoria.data_partita = null;
				this.promemoria.ora_partita = null;
			},
			resetFerie() {
				this.ferie.dal = null;
				this.ferie.al = null;
				this.ferie.nota = null;
			},
			cercaGiocatoreByCognome: function() {
				if(this.query != "") {
					this.osservati = [];
					this.is_search = true;
					this.show_osservati = true;
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/cerca/giocatore/by/cognome/' + this.query).then((response) => {

						if (response.ok)
						{
							this.osservati = response.data;
							this.is_search = false;
						}
					}, (response) => {

					});

				}
			},
			selezionaGiocatore(osservato, evento) {
				if(evento == 'missione') {
					this.missione.osservato = osservato;
				} else if(evento == 'promemoria'){
					this.promemoria.osservato = osservato;
				}
				this.query = '';
				this.osservati = [];
				this.show_osservati = false;
			},
			cercaPartita() {
				this.show_aree = true;
				this.show_competizioni = false;
				this.show_partite = false;
				this.search_area = "";
				this.search_competizione = "";
			},
			selezionaArea: function(sigla) {
				this.show_aree = false;
				this.competizioni = [];
				this.is_search = true;
				this.show_competizioni = true;
				this.$http.get(this.$store.state.apiEndPoint + '/club/competizioni/' + sigla).then((response) => {

					if (response.ok)
					{
						this.competizioni = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			},

			selezionaCompetizione: function(competizione, evento) {

				this.show_competizioni = false;
				this.partite = [];
				this.is_search = true;
				this.show_partite = true;
				var scadenza = '';
				if(evento == 'missione') {
					this.missione.competizione = competizione.nome;
					scadenza = this.missione.scadenza/1000;
				} else if(evento == 'promemoria'){
					this.promemoria.competizione = competizione.nome;
					scadenza = this.promemoria.scadenza/1000;
				}
				if(competizione.wyscout_id) {
					this.$http.get(this.$store.state.apiEndPoint + '/ws/get/matches/by/competition/and/date/' + competizione.wyscout_id + '/' + scadenza).then((response) => {

						if (response.ok)
						{
							this.partite = response.data;
							this.is_search = false;
						}
					}, (response) => {

					});
				} else {
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/get/partite/inserite', 
						{ params: {competizione_id: competizione.id, data: scadenza}}).then((response) => {

							if (response.ok)
							{
								this.partite = response.data;
								this.is_search = false;
							}
						}, (response) => {

						});
					}
				},
				selezionaPartita: function(partita, evento) {
					this.show_partite = false;
					if(evento == 'missione') {
						this.missione.partita = partita.nome_partita;
						this.missione.data_partita = partita.data_partita;
						this.missione.ora_partita = partita.ora_partita;
						this.missione.wyscout_partita_id = partita.matchId ? partita.matchId : null;
						this.missione.partita_id = partita.id ? partita.id : null;
					} else if(evento == 'promemoria') {
						this.promemoria.partita = partita.nome_partita;
						this.promemoria.data_partita = partita.data_partita;
						this.promemoria.ora_partita = partita.ora_partita;
						this.promemoria.wyscout_partita_id = partita.matchId ? partita.matchId : null;
						this.promemoria.partita_id = partita.id ? partita.id : null;
					}
				},

				aggiungiMissione(evt) {
					evt.preventDefault();
					this.$http.post(this.$store.state.apiEndPoint + '/scouting/add/missione', this.missione).then((response) => {
						if (response.ok)
						{
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
							for (var index in response.data.missioni) {
								var evento = this.aggiungiMissioneAgenda(response.data.missioni[index]);
								this.$refs.calendar.$emit('render-event', evento);
							}
							this.$refs.eventModal.hide();
						}
					}, (response) => {  
						if (response.status == 422) {
							this.errors = response.data;
							for (var index in this.errors) {
								this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
							}
						}
					});
				},

				eliminaMissione(missione_id) {
					if(confirm("Confermi l'eliminazione della missione?")) {
						this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/missione/' + missione_id).then((response) => {

							if (response.ok)
							{
								this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
								this.removeEvent();
								this.$refs.eventModal.hide();		
							}
						}, (response) => {
							if (response.status == 422) {
								this.errors = response.data;
								for (var index in this.errors) {
									this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
								}
							}
						});
					}
				},

				notificaMissione(missione_id) {
					if(confirm("Confermi l'invio della notifica email all'osservatore ?")) {
						this.$http.get(this.$store.state.apiEndPoint + '/scouting/notifica/missione/' + missione_id).then((response) => {

							if (response.ok)
							{
								this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
								this.$refs.eventModal.hide();		
							}
						}, (response) => {
							if (response.status == 422) {
								this.errors = response.data;
								for (var index in this.errors) {
									this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
								}
							}
						});
					}
				},

				chiudiMissione(missione_id) {
					if(confirm("Confermi la chiusura della missione?")) {
						this.$http.get(this.$store.state.apiEndPoint + '/scouting/chiudi/missione/' + missione_id).then((response) => {

							if (response.ok)
							{
								this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
								this.missioni.find((item) => item.id == missione_id).stato = 1;
								this.$refs.eventModal.hide();		
							}
						}, (response) => {
							if (response.status == 422) {
								this.errors = response.data;
								for (var index in this.errors) {
									this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
								}
							}
						});
					}
				},

				aggiungiMissioneAgenda(missione) {
					var tipo = 'missione';
					var start = '';
					var end = '';
					var color = '';
					var textColor = '#000000';
					var allDay = true;
					var durationEditable = false;
					var title = missione.osservatore.nomeCompleto + ": "; 
					if (missione.tipo == "partita") {
						title = title + missione.partita;
						start = missione.scadenza;
						var m = moment(missione.data_partita);
						if(m.isValid()) {
							title = title + " del " + m.format('DD/MM/YYYY HH:mm'); 
						}
						if(missione.stato == '1') {
							color = '#00B386';
							textColor = '#FFF';
						} else {
							color = '#FFB800';
						}	
					} else if(missione.tipo == "giocatore") {
						title = title + missione.osservato.cognome + " " + missione.osservato.nome;
						start = missione.scadenza;
						if(missione.stato == '1') {
							color='#7BDE3C';
						} else {
							color = '#FF4E3B';
							textColor = '#FFF';
						}
					}
					if(missione.viaggio_dal && missione.viaggio_al) {
						var dal =  moment(missione.viaggio_dal);
						var al =  moment(missione.viaggio_al);
						if(dal.isValid() && al.isValid()) {
							start = missione.viaggio_dal;
							end = missione.viaggio_al;
							allDay = false;
							durationEditable = true;
						}

					} 
					var evento = {
						id: 'm' + missione.id,
						title: title,
						start: start,
						end: end,
						tipo: tipo,
						color: color,
						allDay: allDay,
						textColor: textColor,
						missione: missione,
						durationEditable: durationEditable,
					};
					return evento;
				},

				aggiungiPromemoria(evt) {
					evt.preventDefault();
					this.$http.post(this.$store.state.apiEndPoint + '/scouting/add/promemoria', this.promemoria).then((response) => {
						if (response.ok)
						{
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
							var evento = this.aggiungiPromemoriaAgenda(response.data.promemoria);
							this.$refs.calendar.$emit('render-event', evento);
							this.$refs.eventModal.hide();
						}
					}, (response) => {  
						if (response.status == 422) {
							this.errors = response.data;
							for (var index in this.errors) {
								this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
							}
						}
					});
				},
				eliminaPromemoria(promemoria_id) {
					if(confirm("Confermi l'eliminazione del promemoria?")) {
						this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/promemoria/' + promemoria_id).then((response) => {

							if (response.ok)
							{
								this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
								this.removeEvent();
								this.$refs.eventModal.hide();		
							}
						}, (response) => {

						});
					}
				},

				aggiungiPromemoriaAgenda(promemoria) {
					var tipo = 'promemoria';
					var start = promemoria.scadenza;
					var color = '#FFF300';
					var allDay = true; 
					var title = promemoria.nota; 
					if (promemoria.partita) {
						title = title + ": " + promemoria.partita;
						var m = moment(promemoria.data_partita);
						if(m.isValid()) {
							title = title + " del " + m.format('DD/MM/YYYY HH:mm'); 
						}	
					} else if(promemoria.osservato) {
						title = title + ": " + promemoria.osservato.cognome + " " + promemoria.osservato.nome;	
					} 
					var evento = {
						id: 'p' + promemoria.id,
						title: title,
						start: start,
						tipo: tipo,
						color: color,
						allDay: allDay,
						promemoria: promemoria,
						durationEditable: false,
					};
					return evento;			
				},
				aggiungiFerie(evt) {
					evt.preventDefault();
					this.$http.post(this.$store.state.apiEndPoint + '/scouting/add/ferie', this.ferie).then((response) => {
						if (response.ok)
						{
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
							var evento = this.aggiungiFerieAgenda(response.data.ferie);
							this.$refs.calendar.$emit('render-event', evento);
							this.$refs.eventModal.hide();
						}
					}, (response) => {  
						if (response.status == 422) {
							this.errors = response.data;
							for (var index in this.errors) {
								this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
							}
						}
					});
				},
				eliminaFerie(ferie_id) {
					if(confirm("Confermi l'eliminazione delle ferie?")) {
						this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/ferie/' + ferie_id).then((response) => {

							if (response.ok)
							{
								this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
								this.removeEvent();
								this.$refs.eventModal.hide();		
							}
						}, (response) => {

						});
					}
				},

				aggiungiFerieAgenda(ferie) {
					var tipo = 'ferie';
					var start = ferie.dal;
					var end = moment(ferie.al).add(1, 'days');
					var color = '#800080';
					var textColor = '#FFF';
					var allDay = true;
					var title = ferie.utente.nomeCompleto; 
					var evento = {
						id: 'f' + ferie.id,
						title: title,
						start: start,
						end: end,
						tipo: tipo,
						color: color,
						textColor: textColor,
						allDay: allDay,
						ferie: ferie,
						durationEditable: true,
					};
					return evento;			
				},
			},


			computed: {
				auth: function() {
					return Auth;
				},
				filtered_aree: function () {
					if (this.search_area != "") {
						return this.aree.filter((area) =>
							area.paese.toLowerCase().includes(this.search_area.toLowerCase())
							);
					}
					return this.aree;
				},
				filtered_competizioni: function () {
					if (this.search_competizione != "") {
						return this.competizioni.filter((competizione) =>
							competizione.name
							.toLowerCase()
							.includes(this.search_competizione.toLowerCase())
							);
					}
					return this.competizioni;
				},
				titleModal() {
					if(!this.selected && this.tipo_evento == '') {
						return "Scegli evento";
					} else if(!this.selected && this.tipo_evento != '') {
						if(this.tipo_evento == "missione") {
							return "Nuova missione del " + moment(this.missione.scadenza).format("DD/MM/YYYY");
						} else if(this.tipo_evento == "promemoria") {
							return "Nuovo promemoria del " + moment(this.promemoria.scadenza).format("DD/MM/YYYY");
						} else if(this.tipo_evento == "ferie") {
							return "Nuove ferie del " + moment(this.ferie.dal).format("DD/MM/YYYY");
						}
						return "Nuovo evento";
					} else if(this.selected) {
						var data = moment(this.selected.start).format("DD/MM/YYYY");
						if(this.selected.tipo == "missione") {
							return "Missione del " + data;
						} else if(this.selected.tipo == "promemoria") {
							return "Promemoria del " + data;
						} else if(this.selected.tipo == "ferie") {
							return "Ferie del " + data;
						}
						return "Evento";
					}	
				},


				events() {
					var events = [];
					for (var index in this.missioni) {
						var missione = this.missioni[index];
						if(this.filter_events.length > 0) {
							if(missione.tipo == "partita" && missione.stato == "0") {
								if(this.filter_events.indexOf("partite-aperte") == -1) {
									continue;
								}
							} else if(missione.tipo == "partita" && missione.stato == "1") {
								if(this.filter_events.indexOf("partite-chiuse") == -1) {
									continue;
								}
							} else if(missione.tipo == "giocatore" && missione.stato == "0") {
								if(this.filter_events.indexOf("giocatore-aperte") == -1) {
									continue;
								}
							} else if(missione.tipo == "giocatore" && missione.stato == "1") {
								if(this.filter_events.indexOf("giocatore-chiuse") == -1) {
									continue;
								}
							}
						}
						if(this.filter_utente && missione.utente_id != this.filter_utente) {
							continue;
						}
						if(this.filter_osservatore && missione.osservatore_id != this.filter_osservatore) {
							continue;
						}
						var evento = this.aggiungiMissioneAgenda(missione);
						events.push(evento); 		
					}
					if(this.filter_events.length == 0 || (this.filter_events.length > 0 && this.filter_events.indexOf("promemoria") > -1)) {
						for (var index in this.promemorias) {
							if(this.filter_promemoria && this.promemorias[index].utente_id != this.filter_promemoria) {
								continue;
							}
							var evento = this.aggiungiPromemoriaAgenda(this.promemorias[index]);
							events.push(evento); 		
						}
					}
					if(this.filter_events.length == 0 || (this.filter_events.length > 0 && this.filter_events.indexOf("ferie") > -1)) {
						for (var index in this.feries) {
							if(this.filter_ferie && this.feries[index].utente_id != this.filter_ferie) {
								continue;
							}
							var evento = this.aggiungiFerieAgenda(this.feries[index]);
							events.push(evento); 		
						}
					}
					return events;
				},  
				in_ferie: function() {
					var in_ferie = "";
					if(this.missione.scadenza != null) {
						var scadenza = moment(this.missione.scadenza).format("YYYY-MM-DD");
						for(var i in this.feries) {
							var item = this.feries[i];
							if(scadenza >= item.dal && scadenza <= item.al) {
								if(in_ferie != "") {
									in_ferie += ", ";
								}
								in_ferie += item.utente.nomeCompleto;
							}
						}
					}
					return in_ferie;
				},
			}, 

			components: {
				headapp:            require('../../layout/headapp.vue').default,
				menusub:            require('../../layout/menusub.vue').default,
				footerapp:          require('../../layout/footerapp.vue').default,
				card:               require('../../layout/card.vue').default,
				flag:               require('../../layout/flag.vue').default,
				FullCalendar,
			},
			filters: {
				formatDate: function (date) {
					var m = moment(date);
					if(m.isValid()) {
						return m.format('DD/MM/YYYY'); 
					}
					return "";
				},
				formatDateHour: function (date) {
					var m = moment(date);
					if(m.isValid()) {
						return m.format('DD/MM/YYYY HH:mm'); 
					}
					return "";
				},
			}, 

		}
		</script>
