<template>
	<div>
		<b-modal size="lg" v-model="ux.show">

			<template #modal-header>
				<span v-if="rule == false">
					<strong>1.</strong> Seleziona una <em>Regola di Partenza</em>
				</span>
				<span v-else>
					<strong>2.</strong> Configura <u>{{ ruleInfo.name }}</u>
					<p class="small m-0">{{ruleInfo.description}}</p>
				</span>
			</template>

			<template #modal-footer>

				<b-row style="width: 100%">
					<b-col>
						<b-button-group>
							<b-button class="float-left" size="sm" variant="outline-secondary" @click.prevent="ux.show = false">Cancella</b-button>
							<b-button @click.prevent="rule = false" size="sm" variant="warning">Ricomincia</b-button>
						</b-button-group>
					</b-col>
					<b-col class="text-right">
						<b-button v-if="rule" variant="success" @click.prevent="add">Inserisci nel contratto</b-button>
					</b-col>
				</b-row>

			</template>

			<div v-if="rule == false">
				<b-list-group>
					<b-list-group-item button @click.prevent="pick(r)" v-for="r in rules">
						<strong>{{ r.name }}</strong>
						<p class="small">{{ r.description }}</p>
					</b-list-group-item>
				</b-list-group>
			</div>

			<div v-else>

				
				<b-form-group label="Nome">
					<b-input v-model="rule.name"></b-input>
					<template #description>Il nome della regola ha il solo scopo di descrivere la configurazione.</template>
				</b-form-group>

				<Rule-Config :rule="ruleInfo.payload" :config="rule.input_payload" @update="rule.input_payload = $event"></Rule-Config>

			</div>

		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'

export default {
	components: {
		RuleConfig: require('./RegolaConfiguratore.vue').default
	},
	watch: {
		'ux.show': {
			deep: true,
			handler: function() {
				if(this.ux.show == false)
					this.rule = false;
			}
		}
	},
	props: [ 'position' ],
	data: function() {
		return {
			rule: false,
			ux: {
				show: false
			},
			ruleInfo: false,
			rules: []
		}
	},
	created: function() {
		this.$http.get(this.$store.state.apiEndPoint + '/contratti/rules').then(function(data) {
			this.rules = data.data;
		})
	},
	methods: {
		add: function() {
			this.$emit('add', this.rule);
			this.ux.show = false;
		},
		pick: function(rule) {

			this.ruleInfo = rule;

			var pl = {};
			_.each(rule.payload, function(val, a) {

				if(val.type == 'from' && val.from_params.multi == true)
					pl[a] = [];
				else if(val.type == 'int')
					pl[a] = 0;
				else 
					pl[a] = null

			});

			this.rule = {
				name: rule.name + ', Senza Titolo',
				posizione: this.position,
				input_payload: JSON.stringify(pl),
				regola: rule.file_id,
			};

		},
		show: function() {
			this.ux.show = true;
		}
	}
}

</script>