<template id="headapp">
	<header>

		<alerts></alerts>
		<div class="header">
			<div class="container-fluid">
				<div id="multiapp" class="row" style="display: none;">
					<ul style="list-style: none; padding-top: 20px; display: block; margin: 0 auto;">
						<li style="display: inline-block; width: 120px; height: 130px;" v-for="a in apps" :key="a.name">	
							<a style="display: block; float: left; width: 120px; padding-right: 10px; padding-left: 10px; height: 80px;" :href="a.default" @click.prevent="hideModalApps(a.name, a.default)">
								<span style="display: block; text-align: center; width: 100px; height: 80px;"><img :src="a.icon" width="80" class="rounded-circle img-fluid mx-auto" :alt="a.name" /></span>
								<span style="display: block; text-align: center; color: #FFFFFF; text-decoration: none;">{{ a.label }}</span>
							</a>
						</li>
					</ul>
				</div>

				<div class="row">
					<div class="col-5 px-3">

						<ul class="utilitymenu list-inline mb-0">
							<li class="list-inline-item"><a href="#/"><img src="/assets/images/logo.png" height="24" class="d-inline-block p-x-1"> A.S. Roma System</a></li>
							<li class="list-inline-item" v-if="route.name == 'scouting_dashboard' || route.name == 'newscout_dashboard'">

								<div class="category-select">
									<select name="category_scout" class="form-control" v-model="scoutingCategory">
										<option value="all">Tutti</option>
										<option value="over">Prima squadra</option>
										<option value="under19">Under 19</option>
										<option value="under17">Under 17</option>
										<option value="under16">Under 16</option>
										<option value="under15">Under 15</option>
										<option value="under14">Under 14</option>
										<option value="under13">Under 13</option>
										<option value="under12">Under 12</option>
										<option value="under11">Under 11</option>
										<option value="under10">Under 10</option>
									</select>
								</div>
							</li>
							<li class="list-inline-item" v-if="route.meta.module == 'football' || route.meta.module == 'performance' || route.meta.module == 'analysis' || route.meta.module == 'medical' || route.meta.module == 'reports' || route.meta.module == 'contratti'">
								<div class="category-select">
									<select id="team_football" name="team_football" class="form-control" :disabled="(!route.meta.enableRosaTeam) ? true : false" v-model="rosaTeam">
										<option v-for="team in user.rosaTeams" :value="team.id" :key="team.id" :disabled="!auth.isUserEnable(route.meta.permesso, team.id_categoria)"> 
											{{ team.categoria.name }}
										</option>
									</select>
								</div>
							</li>
							<li class="list-inline-item" v-if="route.meta.module == 'romalab' && route.meta.showCategory">
								<div class="category-select">
									<select id="category" name="category" class="form-control" v-model="romalabCategory">
										<option v-for="cat in user.romalabCats" :value="cat.id" :key="cat.id"> 
											{{ cat.name }} 
										</option>
									</select>
								</div>
							</li>
							<li class="list-inline-item" v-if="(route.meta.module == 'academy_ita' || route.meta.module == 'academy_int') && route.meta.showAcademyCategory">
								<div class="category-select">
									<select id="cat_academy" name="cat_academy" class="form-control" v-model="academyCategory">
										<option v-for="cat in user.academyCats" :value="cat.id" :key="cat.id">
											{{ cat.nome }} 
										</option>
									</select>
								</div>
							</li>
							<li class="list-inline-item" v-if="auth.isUserEnable('academy_societa') && route.meta.module == 'academy_ita' && route.meta.showAcademyList">
								<div class="category-select">
									<select id="academy" name="academy" class="form-control" v-model="academyId">
										<option v-for="acad in user.academyList" :value="acad.id" :key="acad.id">
											{{ acad.nome }} 
										</option>
									</select>
								</div>
							</li>
							<li class="list-inline-item" v-if="auth.isUserEnable('academy_societa') && route.meta.module == 'academy_int' && route.meta.showAcademyList">
								<div class="category-select">
									<select id="academyInt" name="academyInt" class="form-control" v-model="academyIntId">
										<option v-for="acad in user.academyIntList" :value="acad.id" :key="acad.id">
											{{ acad.nome }} 
										</option>
									</select>
								</div>
							</li>
							<li class="list-inline-item" v-if="(route.meta.module == 'football' && route.meta.showSeason) || route.meta.module == 'performance' || route.meta.module == 'analysis' || (route.meta.module == 'medical' && route.meta.showSeason) || (route.meta.module == 'contratti' && route.meta.showSeason) || (route.meta.module == 'reports' && route.meta.showSeason)">
								<div class="category-select">
									<select id="season" name="season" class="form-control" :disabled="(!route.meta.enableSeason) ? true : false" v-model="season">
										<option v-for="season in user.seasons" :value="season.id" :key="season.id"> 
											{{ season.stagione }} 
										</option>
									</select>
								</div>
							</li>
						</ul>
					</div>
					<div class="col-2 text-center ">

						<button class="btn btn-apps" style="border: none;" @click.prevent="showApps()">
							<span class="fa-stack fa-lg">
								<i class="fa fa-circle color-rosso fa-stack-2x"></i>
								<i class="fa fa-th fa-stack-1x color-white fa-inverse"></i>
							</span>
						</button>

					</div>
					<div class="col-5 px-3">

						<ul class="utilitymenu list-inline text-right mb-0">
							<li class="list-inline-item" v-if="news.length > 0">
								<b-button v-b-toggle.sidebar-news variant="link">
									<i class="fa fa-bell" style="color:#FFFFFF" aria-hidden="true"></i>
									<b-badge v-if="hasNewNews" variant="danger" style="font-size:0.6rem;" pill>&nbsp;</b-badge>
								</b-button>
								<b-sidebar id="sidebar-news" right shadow width="30em" backdrop backdrop-variant="transparent">
									<div class="px-3 py-2">
										<div v-for="item in news" class="border-bottom py-2 text-left" style="font-size:0.85rem;line-height:1.1rem;">
											<div :class="item.date == moment().format('YYYY-MM-DD') ? 'color-rosso' : ''"><strong><em>{{ item.date | formatDate }} - {{ item.title }}</em></strong></div>
											<div v-html="item.description"></div>
											<div class="text-right">
												<a href="#" v-if="item.body && item.body != ''" v-b-toggle="'collapse-news-' + item.id" style="color:#a80532;text-decoration:none;">
													<span class="when-closed">Leggi +</span>
													<span class="when-opened">Leggi -</span>
												</a>
											</div>
											<b-collapse :id="'collapse-news-' + item.id" class="mt-2">
												<div v-html="item.body"></div>
											</b-collapse>
										</div>		
									</div>
								</b-sidebar>
							</li>
							<li class="list-inline-item">
								<a href="#/profilo"><i class="fa fa-user"></i> {{ user.nome }} {{ user.cognome }}</a>
							</li>
							<li class="list-inline-item">
								<a href="#" @click="logout" title="Esci"><i class="fa fa-sign-out"></i></a>
							</li>
							<li class="list-inline-item" v-if="route.meta.module == 'academy_int'">
								<langswitch :size="16"></langswitch>
							</li>
						</ul>
					</div>
				</div>

			</div>
		</div>

		<menuapp></menuapp>

	</header>
</template>

<script>

var Auth = require('../../auth.js');
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';

export default {
	data: function() {
		return{
			apps: [],
			news: [],
			moduli_categoria: ['football', 'performance', 'analysis', 'medical', 'reports', 'contratti'],
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},

		user: function() {
			return Auth.user;
		},

		route: function() {
			return this.$route;
		},

		moment: function() {
			return moment;
		},

		hasNewNews() {
			if(this.news.findIndex((item) => item.date == moment().format('YYYY-MM-DD')) != -1) {
				return true;
			}
			return false;
		},

		scoutingCategory: {
			get () {
				return this.$store.state.scoutingCategory
			},
			set (value) {
				this.$store.commit('changeScoutingCategory', value)
			}
		},
		rosaTeam: {
			get () {
				if(this.$store.state.rosaTeamId == null) {
					if(this.user.rosaTeams.length > 0) {
						this.$store.commit('changeRosaTeamId', this.user.rosaTeams[0].id);
						this.$store.commit('changeCategoriaId', this.user.rosaTeams[0].categoria.id);
					}
				}
				return this.$store.state.rosaTeamId
			},
			set (value) {
				this.$store.commit('changeRosaTeamId', value);
				this.$store.commit('changeCategoriaId', _.find(this.user.rosaTeams, { id: value }).categoria.id);
			}
		},
		romalabCategory: {
			get () {
				if(this.$store.state.romalabCatId == null) {
					if(this.user.romalabCats.length > 0) {		
						this.$store.commit('changeRomalabCatId', this.user.romalabCats[0].id);     	
					}
				}
				return this.$store.state.romalabCatId;
			},
			set (value) {
				this.$store.commit('changeRomalabCatId', value)
			}
		},
		academyCategory: {
			get () {
				if(this.$store.state.academyCatId == null) {
					if(this.user.academyCats.length > 0) {
						this.$store.commit('changeAcademyCatId', this.user.academyCats[0].id);
					}
				}
				return this.$store.state.academyCatId
			},
			set (value) {
				this.$store.commit('changeAcademyCatId', value)
			}
		},
		academyId: {
			get () {
				if(this.$store.state.academyId == null) {
					if(this.user.academyList.length > 0) {
						this.$store.commit('changeAcademyId', this.user.academyList[0].id);
					}
				}
				return this.$store.state.academyId
			},
			set (value) {
				this.$store.commit('changeAcademyId', value)
			}
		},
		academyIntId: {
			get () {
				if(this.$store.state.academyIntId == null) {
					if(this.user.academyIntList.length > 0) {
						this.$store.commit('changeAcademyIntId', this.user.academyIntList[0].id);
					}
				}
				return this.$store.state.academyIntId
			},
			set (value) {
				this.$store.commit('changeAcademyIntId', value)
			}
		},
		season: {
			get () {
				if(this.$store.state.seasonId == null) {
					if(this.user.seasons.length > 0) {
						this.$store.commit('changeSeasonId', this.user.seasons[0].id);
					}
				}
				return this.$store.state.seasonId
			},
			set (value) {
				this.$store.commit('changeSeasonId', value)
			}
		},
	},

	components: {
		langswitch: require('./langswitch.vue').default,
		menuapp: require('./menuapp.vue').default,
		alerts: require('./alerts.vue').default,
	},

	methods:{
		hideModalApps: function (module, href) {
			//$('#modalApps').modal('hide');
			if(this.auth.isUserEnable(module)) {
				this.$router.push(href);
			}
		},
		showApps: function () {
			$('#multiapp').slideToggle('400');
		},
		logout: function() {
			Auth.logout(this);
		},
		getNewsUtente: function () {
			var url = this.$store.state.apiEndPoint + '/news/utente';
			this.$http.get(url).then((response) => {
				this.news = response.data;
			});		
		},
	},
	watch: {
		'$route': function () {
			$('.popover').remove();
		},
		rosaTeam: function () {
			for(var i in this.user.rosaTeams) {
				if(this.user.rosaTeams[i].id == this.rosaTeam) {
					if(this.user.rosaTeams[i].categoria.name == 'In prova') {
						this.$store.commit('changeInProva', true);
					} else {
						this.$store.commit('changeInProva', false);
					}
				}
			}
			if(this.$route.meta.permesso && !this.auth.isUserEnable(this.$route.meta.permesso, this.$store.state.categoriaId) && this.moduli_categoria.includes(this.$route.meta.module)) {
                this.$router.push('/');
            }
		},
	},
	created: function () {
		var url = this.$store.state.apiEndPoint + '/modules';
		this.$http.get(url).then((response) => {
			if (response.ok) {
				this.apps = response.body;
				this.apps = this.apps.filter((item) => this.auth.isUserEnable(item.name));
			}
		});
		this.getNewsUtente();
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},

	mounted: function () {},
}

</script>