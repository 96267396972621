<template>
	<div></div>
</template>

<script>

export default {
	props: [ 'option' ],
	data: function() {
		return {
			chart: null
		}
	},
	watch: {
		'option': {
			deep: true,
			handler: function() {
				this.generateGraph();
			}
		}
	},
	mounted: function() {
		this.generateGraph();
	},
	methods: {
		generateGraph: function() {

			if(this.chart == null) {
				var chartDom = this.$el;
				var eChart = echarts.init(chartDom);
				this.chart = eChart;
			} else {
				eChart = this.chart;
			}
			
			eChart.setOption(this.option);

		}
	}
}

</script>