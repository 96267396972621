<template>
	<div style="position: sticky; top: 0px; z-index: 2">
		<b-card no-body class="p-2 mb-1"> 
			<b-row align-v="center">	
				<b-col cols="2" class="text-left">
					<slot name="left"></slot>
					<b-button @click.prevent="$refs.video.show()" variant="outline-success"><em class="fa fa-video-camera"></em></b-button>
					<b-button v-if="match && match.allegati" @click.prevent="$refs.doc.show()" variant="outline-info"><em class="fa fa-file"></em></b-button>
				</b-col>
				<b-col cols="8">
					<b-row align-v="center">
						<!-- <b-col cols="1">

						</b-col> -->
						<b-col cols="2">
							<slot name="left2"></slot>
						</b-col>
						<b-col :cols="8" class="text-center">
							<h5 v-if="competizione" style="line-height: 1; font-size: 95%">{{ competizione }}</h5>
							<h4 class="color-rosso" style="display: inline;">{{ label.split(",")[0] }}</h4>
							<div>{{ label.split(",")[1] }}</div>
							<small class="text-muted">{{ moment(date).format('DD/MM/YYYY HH:mm') }}</small>
						</b-col>
						<b-col cols="2">
							<slot name="right2"></slot>
						</b-col>
						<!-- <b-col cols="1">
							<slot name="picker"></slot>
						</b-col> -->
					</b-row>
				</b-col>
				<b-col cols="2" class="text-right">
					<div style="position: absolute; right: 4%; top: -95%"><slot name="picker"></slot></div>
					<slot name="right"></slot>
				</b-col>
			</b-row>
			<slot name="bottom"></slot>
		</b-card>

		<b-modal v-if="match && match.match_football" ref="video" size="xl" :title="label">
			<video_partita_football :readonly="true" :partita="match.match_football.id"></video_partita_football>
		</b-modal>

		<b-modal v-else-if="match" ref="video" size="xl" :title="label">
			<video_partita_scouting :partita="{ entita: 'Partite', entita_id: match.video_scouting.entita_id }"></video_partita_scouting>
		</b-modal>
		<b-modal v-if="match && match.allegati" ref="doc" size="xl" :title="label">
			<DocumentiManager entity="Partite" :entityId="match.allegati.entita_id" :tipoId="1" path=""></DocumentiManager>
		</b-modal>

	</div>
</template>

<script>
	
import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {}
	},
	props: ['match', 'label', 'date', 'competizione'],
	computed: {
		hasVideo: function() {

			if(this.match && this.match.match_football) {
				if(this.match.match_football.video && this.match.match_football.video.length) return true;
			}

			if(this.match && this.match.video_scouting) {
				return true;
			}
			return false;
		}
	},
	components: {
		video_partita_football: require('@/components/layout/video/video_partita_football.vue').default,
		video_partita_scouting: require('@/components/layout/video/video_partita_scouting.vue').default,
		DocumentiManager: require('@/components/layout/documenti/manager.vue').default,
	}
}

</script>