export const ROUTES_USASCOUT = [
    {
        path: '/usascout/dashboard',
        name: 'usascout_dashboard',
        component: require('../components/pages/usascout/dashboard.vue').default,
        meta: {
            module: 'usascout',

            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'usascout',
        }
    },
    {
        path: '/usascout/nuovo/giocatore',
        name: 'usascout_nuovo_giocatore',
        component: require('../components/pages/usascout/nuovo_giocatore.vue').default,
        meta: {
            module: 'usascout',
            label: 'Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'usascout',
        }
    },
    {
        path: '/usascout/view/giocatore/:id',
        name: 'usascout_view_giocatore',
        component: require('../components/pages/usascout/view_giocatore.vue').default,
        meta: {
            module: 'usascout',
            label: 'Scheda Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'usascout_nuova_valutazione',
            permesso: 'usascout',
        }
    },
    {
        path: '/usascout/edit/giocatore/:id',
        name: 'usascout_edit_giocatore',
        component: require('../components/pages/usascout/edit_giocatore.vue').default,
        meta: {
            module: 'usascout',
            label: 'Modifica Anagrafica',
            requiresAuth: true,
            showInMenu: false,
            parent: 'usascout_view_giocatore',
            menusub: ['usascout_view_giocatore'],
            permesso: 'usascout',
        }
    },
    {
        path: '/usascout/edit/valutazione/:id/:val_id',
        name: 'usascout_edit_valutazione',
        component: require('../components/pages/usascout/edit_valutazione.vue').default,
        meta: {
            module: 'usascout',
            label: 'Modifica Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'usascout_view_giocatore',
            menusub: ['usascout_view_giocatore', 'usascout_nuova_valutazione'],
            permesso: 'usascout',
        }
    },
    {
        path: '/usascout/nuova/valutazione/:id',
        name: 'usascout_nuova_valutazione',
        component: require('../components/pages/usascout/nuova_valutazione.vue').default,
        meta: {
            module: 'usascout',
            label: 'Nuova Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'usascout_view_giocatore',
            menusub: ['usascout_view_giocatore'],
            permesso: 'usascout',
        }
    },
    {
        path: '/usascout/view/valutazione/:id/:val_id',
        name: 'usascout_view_valutazione',
        component: require('../components/pages/usascout/view_valutazione.vue').default,
        meta: {
            module: 'usascout',
            label: 'Valutazione Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'usascout_view_giocatore',
            menusub: ['usascout_view_giocatore'],
            permesso: 'usascout',
        }
    },
    {
        path: '/usascout/osservatori',
        name: 'usascout_osservatori',
        component: require('../components/pages/scouting/osservatori.vue').default,
        meta: {
            module: 'usascout',
            label: 'Osservatori',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_utenti',        
        }
    },
    {
        path: '/usascout/osservatore/paesi/:id',
        name: 'usascout_osservatore_paesi',
        component: require('../components/pages/scouting/osservatore_paesi.vue').default,
        meta: {
            module: 'usascout',
            label: 'Paesi',
            requiresAuth: true,
            showInMenu: false,
            parent: 'usascout_osservatori',
            permesso: 'scout_utenti',
            menusub: ['usascout_osservatore_paesi', 'usascout_osservatore_liste'],         
        }
    },
    {
        path: '/usascout/osservatore/liste/:id',
        name: 'usascout_osservatore_liste',
        component: require('../components/pages/scouting/osservatore_liste.vue').default,
        meta: {
            module: 'usascout',
            label: 'Liste',
            requiresAuth: true,
            showInMenu: false,
            parent: 'usascout_osservatori',
            permesso: 'scout_utenti',  
            menusub: ['usascout_osservatore_paesi', 'usascout_osservatore_liste'],   
        }
    },
    {
        path: '/usascout/squadre',
        name: 'usascout_squadre',
        component: require('../components/pages/usascout/squadre.vue').default,
        meta: {
            module: 'usascout',
            label: 'Squadre',
            requiresAuth: true,
            showInMenu: true,
            parent: '',  
            permesso: 'usascout',    
        }
    },
    {
        path: '/usascout/squadre/nuova',
        name: 'usascout_squadre_new',
        component: require('../components/pages/usascout/new_squadra.vue').default,
        meta: {
            module: 'usascout',

            label: 'Nuova Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'usascout_squadre',
            permesso: 'usascout',
        }
    },

    {
        path: '/usascout/squadre/edit/:id',
        name: 'usascout_squadre_edit',
        component: require('../components/pages/usascout/edit_squadra.vue').default,
        meta: {
            module: 'usascout',
            label: 'Modifica Squadra',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'usascout',
        }
    },
];
