<template id="edit_giocatore">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card title_page">
				<div class="card-body">
					<div class="card-title">
						<span>Modifica Giocatore</span>
					</div>	
				</div>
			</div>	
			<div class="content_page">	
				<b-row>
					<b-col cols="8">   	
						<b-form @submit="checkBeforeSubmit">
							<picture-input ref="picturePlayer" @change="onAvatarChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="avatar"></picture-input>						
							<b-form-group id="nomeGroup" horizontal label="Nome" :label-cols="3" label-for="nome" :feedback="errors.nome ? $t(errors.nome[0]) : ''" :state="errors.nome ? false : null">
								<b-form-input id="nome" type="text" v-model="form.nome" :state="errors.nome ? false : null"></b-form-input>
							</b-form-group>
							<b-form-group id="cognomeGroup" horizontal label="Cognome" :label-cols="3" label-for="cognome" :feedback="errors.cognome ? $t(errors.cognome[0]) : ''" :state="errors.cognome ? false : null">
								<b-input-group>
									<b-form-input id="cognome" type="text" v-model="form.cognome" :state="errors.cognome ? false : null"></b-form-input>
									<b-input-group-append>
										<b-button variant="secondary" @click="searchTeam" title="Cerca per squadra"><i class="fa fa-users" aria-hidden="true"></i></b-button>
										<b-button variant="primary" @click="searchPlayer" title="Cerca per nome"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
							<b-form-group id="dataDiNascitaGroup" horizontal label="Data di nascita" :label-cols="3" label-for="data_nascita">
								<b-form-input id="data_nascita" type="date" v-model="form.nascita_data"></b-form-input>
							</b-form-group>
							<b-form-group id="paeseNascitaGroup" horizontal label="Paese di nascita" :label-cols="3" label-for="paese_nascita">
								<autocomplete-paese :suggestions="paesi" id="paese_nascita" ref="paese_nascita" @select="onPaeseNascitaSelect" @change="onPaeseNascitaChange" v-model="form.nascita_paese_id"></autocomplete-paese>
							</b-form-group>					
							<b-form-group id="altezzaGroup" horizontal label="Altezza (cm)" :label-cols="3" label-for="altezza">
								<b-form-input id="altezza" type="text" v-model="form.info_fisica.altezza"></b-form-input>
							</b-form-group>
							<b-form-group id="pesoGroup" horizontal label="Peso (kg)" :label-cols="3" label-for="peso">
								<b-form-input id="peso" type="text" v-model="form.info_fisica.peso"></b-form-input>
							</b-form-group>
							<b-form-group id="piedeGroup" horizontal label="Piede" :label-cols="3" label-for="piede">
								<b-form-radio-group id="piede" v-model="form.giocatore.piede">
									<b-form-radio value="0">Destro</b-form-radio>
									<b-form-radio value="1">Sinistro</b-form-radio>
									<b-form-radio value="2">Ambidestro</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
							<b-form-group id="squadraGroup" horizontal label="Squadra" :label-cols="3" label-for="squadra">
								<b-input-group>
									<b-input-group-prepend is-text>
										<b-img :src="logo_squadra" width="24"/>
									</b-input-group-prepend>
									<b-form-input id="squadra" type="text" v-model="form.last_carriera_sportiva.squadra.nome" readonly></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="loadSquadra" title="Carica squadra da archivio"><i class="fa fa-folder-open" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
							<b-form-group id="agenteGroup" horizontal label="Agente" :label-cols="3" label-for="agente">
								<b-row>
									<b-col>
										<autocomplete-nome :suggestions="agenti" id="agente" @select="onAgenteSelect" @change="onAgenteChange" v-model="form.agente.id" cols="10" ref="agente" placeholder="Cognome" field="cognome"></autocomplete-nome>
									</b-col>
									<b-col>
										<b-form-input type="text" v-model="form.agente.nome" placeholder="Nome"></b-form-input>
									</b-col>
									<b-col cols="2">
										<b-button variant="primary" v-b-toggle.agente_pnl>+ info</b-button>
									</b-col>
								</b-row>
							</b-form-group> 
							<b-collapse id="agente_pnl" class="mt-2" @shown="updateImageAgente"> 
								<b-card class="p-1 mb-1">
									<picture-input ref="pictureAgente" @change="onAvatarAgenteChange" width="150" height="150" size="10" class="mb-1" :customStrings="customStrings" :prefill="avatar_agente"></picture-input>
									<b-form-group horizontal label="Nazionalità" :label-cols="3">
										<autocomplete-paese :suggestions="paesi" id="paese_agente" ref="paese_agente" @select="onPaeseAgenteSelect" @change="onPaeseAgenteChange" v-model="form.agente.id_paese"></autocomplete-paese>
									</b-form-group>
									<b-form-group horizontal label="Luogo di nascita" :label-cols="3">
										<b-form-input v-model="form.agente.luogo_nascita"></b-form-input>
									</b-form-group>
									<b-form-group horizontal label="Data di nascita" :label-cols="3">
										<b-form-input type="date" v-model="form.agente.data_nascita"></b-form-input>
									</b-form-group>
									<b-form-group horizontal label="Indirizzo" :label-cols="3">
										<b-form-input v-model="form.agente.indirizzo.indirizzo"></b-form-input>
									</b-form-group>
									<b-form-group id="emailAgenteGroup" horizontal label="Email" :label-cols="3" label-for="emailAgente">
										<b-form-input id="emailAgente" type="email" v-model="form.agente.email.valore"></b-form-input>
									</b-form-group>
									<b-form-group id="telefono1AgenteGroup" horizontal label="Telefono 1" :label-cols="3" label-for="telefono1Agente">
										<b-form-input id="telefono1Agente" type="tel" v-model="form.agente.telefono1.valore"></b-form-input>
									</b-form-group> 
									<b-form-group id="telefono2AgenteGroup" horizontal label="Telefono 2" :label-cols="3" label-for="telefono2Agente">
										<b-form-input id="telefono2Agente" type="tel" v-model="form.agente.telefono2.valore"></b-form-input>
									</b-form-group>
								</b-card>
							</b-collapse>
							<b-form-group id="scadenzaContrattoGroup" horizontal label="Scadenza contratto" :label-cols="3" label-for="scadenza_contratto">
								<b-form-input id="scadenza_contratto" type="date" v-model="form.osservato.scadenza_contratto"></b-form-input>
							</b-form-group>
							<b-form-group id="valoreGroup" horizontal label="Valore giocatore" :label-cols="3" label-for="valore">
								<b-form-input id="valore" type="text" v-model="form.info_economica.valore"></b-form-input>
							</b-form-group>
							<b-form-group id="stipendioGroup" horizontal label="Stipendio lordo" :label-cols="3" label-for="stipendio">
								<b-form-input id="stipendio" type="text" v-model="form.contratto.stipendio_lordo"></b-form-input>
							</b-form-group>
							<b-form-group id="telefono1Group" horizontal label="Telefono 1" :label-cols="3" label-for="telefono1">
								<b-form-input id="telefono1" type="tel" v-model="form.telefono.valore"></b-form-input>
							</b-form-group>
							<b-form-group id="telefono2Group" horizontal label="Telefono 2" :label-cols="3" label-for="telefono2">
								<b-form-input id="telefono2" type="tel" v-model="form.telefono2.valore"></b-form-input>
							</b-form-group>
							<b-form-group id="giovaniliVivaioGroup" horizontal :label-cols="3">
								<b-form-checkbox id="giovanili" v-model="form.giocatore.cresciuto_giovanili">
									Cresciuto nelle giovanili
								</b-form-checkbox>
								<b-form-checkbox id="vivaio italiano" v-model="form.giocatore.cresciuto_vivaio_italiano">
									Cresciuto in un vivaio italiano
								</b-form-checkbox>
							</b-form-group> 
							<b-form-group id="baricentroGroup" horizontal label="Baricentro" :label-cols="3">
								<b-form-select v-model="form.info_fisica.baricentro">
									<option value="0">Basso</option>
									<option value="1">Medio</option>
									<option value="2">Alto</option>
								</b-form-select>
							</b-form-group>
							<b-form-group id="somatotipoGroup" horizontal label="Somatotipo" :label-cols="3">
								<b-form-select v-model="form.info_fisica.somatotipo">
									<option value="0">Normolineo</option>
									<option value="1">Brevilineo</option>
									<option value="2">Longilineo</option>
								</b-form-select>
							</b-form-group>
							<b-form-group id="costituzioneGroup" horizontal label="Costituzione" :label-cols="3">
								<b-form-select v-model="form.info_fisica.costituzione">
									<option value="0">Esile</option>
									<option value="1">Media</option>
									<option value="2">Robusta</option>
								</b-form-select>
							</b-form-group>
							<b-form-group id="sistemaGiocoIdealeGroup" horizontal label="Sistema di gioco ideale" :label-cols="3">
								<b-form-select v-model="form.giocatore.sistema_gioco_id" :options="sistemi" value-field="id" text-field="sistema_gioco"></b-form-select>
							</b-form-group>
							<b-form-group id="flagVariGroup" horizontal :label-cols="3" v-if="auth.isUserEnable('scout_flag_player')">
								<b-form-checkbox id="monitorare" v-model="form.osservato.da_monitorare">
									Da monitorare
								</b-form-checkbox>
								<b-form-checkbox id="prospettiva" v-model="form.osservato.di_prospettiva">
									Di prospettiva
								</b-form-checkbox>
								<b-form-checkbox id="prestito" v-model="form.last_carriera_sportiva.prestito">
									In prestito
								</b-form-checkbox>
							</b-form-group>
							<b-form-group id="ruoloIdealeGroup" horizontal label="Ruolo ideale" :label-cols="3">
								<b-form-select v-model="form.last_carriera_sportiva.ruolo_ideale_id" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
							</b-form-group>
							<b-form-group id="altriRuoliGroup" horizontal label="Altri ruoli" :label-cols="3">
								<div id="altri_ruoli">{{altri_ruoli_lbl != "" ? altri_ruoli_lbl : "&nbsp"}}</div>
							</b-form-group> 
							<b-button type="submit" variant="primary">Salva</b-button>
							<b-button @click="eliminaGiocatore" variant="secondary" v-if="auth.isUserEnable('scout_del_player')">Elimina</b-button>
						</b-form>
					</b-col>
					<b-col class="text-center">
						<div class="outsideWrapper">
							<div class="insideWrapper">
								<div id="morf" style="margin-bottom: 10px;" class="coveredImage" v-if="form.info_fisica.baricentro && form.info_fisica.somatotipo && form.info_fisica.costituzione">
									<img :src="sagoma" :style="'height:' + altezza_sagoma + 'px!important;margin-top:' + (550 - altezza_sagoma) +'px'"/>
									<div id="h_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'" v-if="form.info_fisica.altezza > 0">H: {{form.info_fisica.altezza}} cm</div>
									<div id="b_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">B: {{etichette.baricentro[form.info_fisica.baricentro]}}</div>
									<div id="s_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">S: {{etichette.somatotipo[form.info_fisica.somatotipo]}}</div>
									<div id="c_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">C: {{etichette.costituzione[form.info_fisica.costituzione]}}</div> 
								</div>
								<canvas width="250px" height="550px" id="mycanvas" ref="mycanvas" class="coveringCanvas"></canvas>
							</div>
						</div>					
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col>
						<div class="modulo">
							<div class="campo">
								<img src="/assets/images/campo.svg">
							</div>
							<div v-for="r in ruoli" :class="'player ' + r.sigla.toLowerCase()" v-on:click="selezionaRuolo(r.id)" :title="r.ruolo">
								<span :class="'ruolo' + (form.last_carriera_sportiva.altri_ruoli_ids.indexOf(r.id) > -1 ? ' on' : (form.last_carriera_sportiva.ruolo_ideale_id == r.id ? ' ide' : ''))">{{ r.sigla }}<div :class="'ovale' + (form.last_carriera_sportiva.altri_ruoli_ids.indexOf(r.id) > -1 ? ' bg_on' : (form.last_carriera_sportiva.ruolo_ideale_id == r.id ? ' bg_ide' : ''))"></div></span>
							</div>
						</div>       
					</b-col>
				</b-row>
			</div>
			<b-modal id="searchPlayerModal" ref="searchPlayerModal" title="Ricerca giocatore">
				<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<p v-if="!is_search && !players.length">Nessun giocatore trovato!</p>
				<div v-if="!is_search && players.length">
					<p>Trovati {{players.length}} giocatori!</p>
					<hr/>
					<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="(player, index) in players" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaGiocatore(player)">
							<div class="col-md-3 my-1 mr-1" style="text-align: center;">
								<img v-if="player.imageDataURL" :src="player.imageDataURL"/>
							</div>
							<div class="col-md-6 my-1">
								<h5 style="display: inline;">{{ player.lastName }} {{ player.firstName }}</h5><br />
								<span class="text-muted" v-if="player.birthArea"><flag v-if="player.birthArea.alpha2code" :country="player.birthArea.alpha2code" :size="24"></flag> {{ player.birthArea.name }} </span><br />
								<span class="text-muted">{{ player.birthDate | formatDate }}</span><br />
								<span class="text-muted">{{ player.role.name }}</span><br />
								<span class="text-muted" v-if="player.currentTeam"><flag v-if="player.birthArea.alpha2code" :country="player.currentTeam.area.alpha2code" :size="24"></flag> {{ player.currentTeam.officialName }}</span><br />     
							</div>
							<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
								<img v-if="player.currentTeam" :src="player.currentTeam.imageDataURL" width="100" />
							</div>
						</div>
					</div>
				</div>
			</b-modal>
			<b-modal id="searchTeamModal" ref="searchTeamModal" title="Ricerca squadra">
				<div v-if="show_aree">
					<span>Seleziona un'area: </span>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<div v-if="aree.length == 0"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
						<b-row>
							<b-col cols="3" v-for="area in aree" style="padding: 0.2rem">
								<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.sigla3)">
									<flag :country="area.sigla" :size="24"></flag><br/>
									{{area.paese}}
								</b-card>
							</b-col>
						</b-row>
					</div>
				</div>
				<div v-if="show_competizioni">
					<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<div v-if="!is_search && competizioni.length == 0"><h5>Nessuna competizione trovata!</h5></div>
					<div v-if="competizioni.length > 0">
						<span>Seleziona una competizione: </span>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<b-row>
								<b-col cols="4" v-for="comp in competizioni" style="padding: 0.2rem">
									<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaCompetizione(comp)">
										<strong>{{comp.name}}</strong><br/>
										<span class="font-size-xs color-rosso">{{$i18n.t(comp.format)}}</span><br/>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>
				<div v-if="show_squadre">
					<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<div v-if="!is_search && squadre.length == 0"><h5>Nessuna squadra trovata!</h5></div>
					<div v-if="squadre.length > 0">
						<span>Seleziona una squadra: </span>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<b-row>
								<b-col cols="4" v-for="team in squadre" style="padding: 0.2rem">
									<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="selezionaSquadra(team)">
										<img :src="team.imageDataURL" width="50" /><br/>
										<strong>{{team.officialName}}</strong><br/>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>
			</b-modal>
			<b-modal id="loadTeamModal" ref="loadTeamModal" title="Seleziona squadra" ok-only ok-title="Chiudi" size="lg">
				<div>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
						<b-row class="mb-1">
							<b-col sm="6">
								<b-form-select v-model="filters.categoria">
									<option value="0">-- Categoria --</option>
									<option v-for="item in categories_opt" :value="item.id" :key="item.id">{{item.name}}</option>
								</b-form-select>
							</b-col>
							<b-col sm="6">
								<b-input-group>
									<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>  	
							</b-col>
						</b-row>
						<div v-if="teams.data && teams.data.length == 0" class="my-2">
							<h5>Nessuna squadra trovata!</h5>
							<b-button :to="{ name: 'youthscout_squadre_new'}" variant="outline-primary" target="_blank" class="mt-2">Aggiungi squadra</b-button>
						</div>	
						<b-row>
							<b-col cols="4" v-for="team in teams.data" v-bind:key="team.id" style="padding: 0.2rem">
								<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="scegliSquadra(team)">
									<img :src="team.logo_url" width="50" /><br/>
									<strong>{{team.officialName}}</strong><br/>
									<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
								</b-card>
							</b-col>
						</b-row>	
						<b-row>
							<b-col cols="6" style="padding: 0.2rem">
								<a v-if="teams.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(teams.current_page) - 1)" >Indietro</a>
							</b-col>	

							<b-col cols="6" style="padding: 0.2rem">
								<a v-if="teams.current_page < teams.last_page" class="page-link" @click="paginate(parseInt(teams.current_page) + 1)">Avanti</a>
							</b-col>	
						</b-row>				
					</div>
				</div>
			</b-modal>
			<b-modal id="checkPersonaModal" ref="checkPersonaModal" title="Attenzione!" ok-only ok-title="Chiudi">
				<b-row>
					<b-col class="border-right pr-2" v-if="$route.params.id || persona_id">
						<p>Sono già presenti su AS Roma System i seguenti individui con questo Wyscout ID ({{ form.wyscout.chiave }}):</p>
						<hr/>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<div v-for="(persona, index) in persone" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;">
								<div class="col-md-3 my-1 mr-3" style="text-align: center;">
									<img :src="persona.avatar_url" height="100"/>
								</div>
								<div class="col-md-6 my-1" style="font-size:0.85rem">
									<h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
									<span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
									<span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
									<span class="text-muted">{{ persona.last_carriera_sportiva && persona.last_carriera_sportiva.ruoli && persona.last_carriera_sportiva.ruoli.length > 0 ? persona.last_carriera_sportiva.ruoli[0].nome : '' }}</span><br />
									<span class="text-muted" v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra"><flag v-if="persona.last_carriera_sportiva.squadra.paese.sigla" :country="persona.last_carriera_sportiva.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera_sportiva.squadra.officialName }}</span><br />     
								</div>
								<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
									<img v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra" :src="persona.last_carriera_sportiva.squadra.logo_url" width="70" />
								</div>
							</div>
						</div>
						<div>Premi su <b-button variant="link" class="py-0"><strong>SALVA</strong></b-button> per forzare comunque l'aggiornamento del Wyscout ID sull'anagrafica del giocatore</div>
					</b-col>
					<b-col class="border-right pr-2" v-else>
						<p>Sono già presenti su AS Roma System i seguenti individui corrispondenti ai campi inseriti:</p>
						<hr/>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<div v-for="(persona, index) in persone" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPersona(persona.id)">
								<div class="col-md-3 my-1 mr-3" style="text-align: center;">
									<img :src="persona.avatar_url" height="100"/>
								</div>
								<div class="col-md-6 my-1" style="font-size:0.85rem">
									<h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
									<span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
									<span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
									<span class="text-muted">{{ persona.last_carriera_sportiva && persona.last_carriera_sportiva.ruoli && persona.last_carriera_sportiva.ruoli.length > 0 ? persona.last_carriera_sportiva.ruoli[0].nome : '' }}</span><br />
									<span class="text-muted" v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra"><flag v-if="persona.last_carriera_sportiva.squadra.paese.sigla" :country="persona.last_carriera_sportiva.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera_sportiva.squadra.officialName }}</span><br />     
								</div>
								<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
									<img v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra" :src="persona.last_carriera_sportiva.squadra.logo_url" width="70" />
								</div>
							</div>
						</div>
						<p>Come vuoi procedere?</p>
						<p>- Seleziona un'anagrafica tra quelle sopra disponibili se ritieni corrisponda al giocatore che vuoi inserire</p>
						<p><strong>oppure</strong></p>
						<div>- Premi su <b-button variant="link" class="py-0" @click.prevent="forceSubmit"><strong>SALVA</strong></b-button> per forzare l'inserimento di una nuova anagrafica</div>
					</b-col>
				</b-row>
			</b-modal>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

import moment from 'moment'
import axios from 'axios'
import * as types from '@/store/types'
var Auth = require('@/auth.js');

export default {

	data: function() {
		return {
			image_player: '',
			image_agente: '',
			players: [],
			is_search: false,
			file_avatar: '',
			file_avatar_agente: '',
			form: {
				nome: null,
				cognome: null,
				nascita_data: null,
				nascita_paese_id: 0,
				info_fisica: {
					id: 0,
					baricentro:	null,
					somatotipo: null,
					costituzione: null,
					altezza: null,
					peso: null,      	
				},
				giocatore: {
					id: 0,
					cresciuto_giovanili: false,
					cresciuto_vivaio_italiano: false,
					piede: null,
					sistema_gioco_id: null,
				},
				osservato: {
					id: 0,
					da_monitorare: false,
					di_prospettiva: false,
					scadenza_contratto: null,
				},
				last_carriera_sportiva: {
					id: 0,
					ruolo_ideale_id: null,
					altri_ruoli_ids: [],
					prestito: 0,
					squadra: {
						id: 0,
						wyscout_id: null,
						nome: null,
						logo: null,
						ws_team: null,
					},
				},
				info_economica: {
					id: 0,
					valore: null,
				},
				contratto: {
					id: 0,
					stipendio_lordo: null,
				},
				telefono: {
					id: 0,
					valore: null,
				},
				telefono2: {
					id: 0,
					valore: null,
				},
				wyscout: {
					id: 0,
					chiave: null,
				},
				agente: {
					id: 0,
					cognome: null,
					nome: null,
					id_paese: 0,
					luogo_nascita: null,
					data_nascita: null,
					email: {
						id: 0,
						valore: null,
					},
					telefono1: {
						id: 0,
						valore: null,
					},
					telefono2:{
						id: 0,
						valore: null,
					},
					indirizzo: {
						id: 0,
						indirizzo: null,
					}
				},
			},
			paesi: [],
			agenti: [],
			ruoli: [],
			sistemi: [],
			etichette: {
				baricentro: ['Basso', 'Medio', 'Alto'],
				somatotipo: ['Normolineo', 'Brevilineo', 'Longilineo'],
				costituzione: ['Esile', 'Media', 'Robusta'],
			},
			errors: [],
			customStrings: {
				drag: this.$i18n.t('drag'),
				fileSize: this.$i18n.t('fileSize'),
				fileType: this.$i18n.t('fileType'),
			},
			aree: [],
			competizioni: [],
			squadre: [],
			show_aree: false,
			show_competizioni: false,
			show_squadre: false,
			persone: [],
			persona_id: null,

			page: 1,
			categories_opt: [],
			teams: {
				data: [],
			},
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
		}
	},
	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		autocompletePaese: require('@/components/layout/autocomplete_paese.vue').default,
		autocompleteNome: require('@/components/layout/autocomplete_nome.vue').default,
		pictureInput: require('@/components/layout/picture_input.vue').default,
		flag: require('@/components/layout/flag.vue').default,
	},

	methods: {
		checkBeforeSubmit(evt) {
			evt.preventDefault();
			this.persone = [];
			var checkRequest = false;
			var formData = new FormData();
			if(this.$route.params.id) {
				formData.set('id', this.$route.params.id);  
				if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
					formData.set('wyscout_id', this.form.wyscout.chiave); 
					checkRequest = true;                      
				} 
			} else if(this.persona_id) {
				formData.set('id', this.persona_id); 
				if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
					formData.set('wyscout_id', this.form.wyscout.chiave); 
					checkRequest = true;                      
				}  
			} else {
				if(this.form.cognome && this.form.cognome.trim() != "") {
					formData.set('cognome', this.form.cognome.trim());  
					checkRequest = true;                     
				}
				if(this.form.nome && this.form.nome.trim() != "") {
					formData.set('nome', this.form.nome.trim()); 
					checkRequest = true;                      
				}
				if(this.form.nascita_data && this.form.nascita_data != '0000-00-00') {
					formData.set('nascita_data', this.form.nascita_data); 
					checkRequest = true;                      
				}
				if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
					formData.set('wyscout_id', this.form.wyscout.chiave); 
					checkRequest = true;                      
				}
			}
			if(checkRequest) {
				this.$http.post(this.$store.state.apiEndPoint + '/persona/search', formData).then((response) => {
					this.persone = response.data;
					if(this.persone.length > 0) {
						this.$refs.checkPersonaModal.show();	
					} else {
						this.onSubmit();
					}
				});
			} else {
				this.onSubmit();
			}
		},
		forceSubmit() {
			this.$refs.checkPersonaModal.hide();
			this.onSubmit();
		},
		selezionaPersona: function (persona_id) {
			this.persona_id = persona_id;
			this.getGiocatore(this.persona_id);
			this.$refs.searchPlayerModal.hide();
			this.$refs.checkPersonaModal.hide();
		},
		onSubmit() {
			var formData = new FormData();
			formData.append("form", JSON.stringify(this.form));
			formData.append("file_avatar", this.file_avatar);
			formData.append("file_avatar_agente", this.file_avatar_agente);
			var id = this.persona_id ? this.persona_id : this.$route.params.id;
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/edit/giocatore/' + id, formData).then((response) => {
				this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				if(response.data.id > 0) {	
					this.$router.push({ name: this.area + "_next_view_giocatore", params: { id: response.data.id } });
				} else {
					this.$router.push({ name: this.area + "scout_dashboard"});
				}

			}, (response) => {  
				this.errors = response.data;
				for (var index in this.errors) {
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
				}
			});
		},
		onAvatarChange(e) {
			if (this.$refs.picturePlayer.file) {
				this.file_avatar = this.$refs.picturePlayer.file;
			} else {
				console.log('FileReader Player API not supported: use the <form>')
			}
		},
		onAvatarAgenteChange(e) {
			if (this.$refs.pictureAgente.file) {
				this.file_avatar_agente = this.$refs.pictureAgente.file;
			}
		},
		onPaeseNascitaSelect(item) {
			this.form.nascita_paese_id = item;
		},
		onPaeseNascitaChange() {
			this.form.nascita_paese_id = 0;
		},
		onPaeseAgenteSelect(item) {
			this.form.agente.id_paese = item;
		},
		onPaeseAgenteChange() {
			this.form.agente.id_paese = 0;
		},
		onAgenteSelect(id) {
			this.form.agente.id = id;
			for(var index in this.agenti) {
				var agente = this.agenti[index];
				if(agente.id == id) {
					this.form.agente.cognome = agente.cognome;
					this.form.agente.nome = agente.nome;
					for(var i in agente.contatti) {
						var contatto = agente.contatti[i];
						if(contatto.contatto.tipo == 'email') {
							this.form.agente.email.id = contatto.contatto.id;
							this.form.agente.email.valore = contatto.contatto.valore;
						}
					}
					var telefoni = [];
					for(var i in agente.contatti) {
						var contatto = agente.contatti[i];
						if(contatto.contatto.tipo == 'telefono') {
							telefoni.push(contatto.contatto);
						}
					}
					if(telefoni.length > 0) {
						this.form.agente.telefono1.id = telefoni[0].id;
						this.form.agente.telefono1.valore = telefoni[0].valore;
					}
					if(telefoni.length > 1) {
						this.form.agente.telefono2.id = telefoni[1].id;
						this.form.agente.telefono2.valore = telefoni[1].valore;
					}
					this.form.agente.luogo_nascita = agente.nascita_comune;
					this.form.agente.data_nascita = agente.nascita_data;
					if(agente.indirizzi.length > 0 ) {
						this.form.agente.indirizzo.id = agente.indirizzi[0].indirizzo.id;
						this.form.agente.indirizzo.indirizzo = agente.indirizzi[0].indirizzo.indirizzo;
					}
					if(agente.avatar != null && agente.avatar != "") {
						this.image_agente = agente.avatar_url;
					}
					if(agente.nascita_paese) {
						this.$refs.paese_agente.setPaese(agente.nascita_paese.id, agente.nascita_paese.paese);	
					}
					break;
				}
			}
		},
		onAgenteChange(value) {	
			this.form.agente.id = 0;
			this.form.agente.cognome = value;
			this.form.agente.nome = '';
			this.form.agente.email.id = 0;
			this.form.agente.email.valore = null;
			this.form.agente.telefono1.id = 0;
			this.form.agente.telefono1.valore = null;
			this.form.agente.telefono2.id = 0;
			this.form.agente.telefono2.valore = null;
			this.image_agente = '';
			this.file_avatar_agente = '';
			this.form.agente.luogo_nascita = null;
			this.form.agente.data_nascita = null;
			this.form.agente.indirizzo.id = 0;
			this.form.agente.indirizzo.indirizzo = null;
			this.$refs.paese_agente.setPaese(0, '');	
		},
		updateImageAgente() {
			this.$refs.pictureAgente.onResize();
		},
		selezionaRuolo: function (ruolo) {
			if(this.form.last_carriera_sportiva.ruolo_ideale_id == 0) {
				this.form.last_carriera_sportiva.ruolo_ideale_id = ruolo;
			} else if(ruolo != this.form.last_carriera_sportiva.ruolo_ideale_id) {        
				if(this.form.last_carriera_sportiva.altri_ruoli_ids.indexOf(ruolo) == -1) {
					this.form.last_carriera_sportiva.altri_ruoli_ids.push(ruolo);
				} else {
					this.form.last_carriera_sportiva.altri_ruoli_ids.splice(this.form.last_carriera_sportiva.altri_ruoli_ids.indexOf(ruolo), 1);
				}
			}               
		},
		searchPlayer: function() {
			var query = this.form.cognome.trim().replace(/ /g, "_");
			if(query != "") {
				this.players = [];
				this.is_search = true;
				this.$refs.searchPlayerModal.show();
				if(this.form.nome && this.form.nome.trim() != "") {
					query = query + "_" + this.form.nome.trim().replace(/ /g, "_");                        
				}
				this.$http.get(this.$store.state.apiEndPoint + '/ws/search/player/' + query).then((response) => {

					if (response.ok)
					{
						this.players = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});

			}
		},
		searchTeam: function() {
			this.show_aree = true;
			this.show_competizioni = false;
			this.show_squadre = false;
			if(this.aree.length == 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {	
					this.aree = response.data; 
				});
			}
			this.$refs.searchTeamModal.show();	
		},
		selezionaArea: function(sigla) {
			this.show_aree = false;
			this.competizioni = [];
			this.is_search = true;
			this.show_competizioni = true;
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/competitions/' + sigla).then((response) => {
				this.competizioni = response.data;
				this.is_search = false;
			});
		},
		selezionaCompetizione: function(competizione) {
			this.show_competizioni = false;
			this.squadre = [];
			this.is_search = true;
			this.show_squadre = true;
			this.form.categoria = competizione.name;
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/teams/by/competition/' + competizione.wyId).then((response) => {
				this.squadre = response.data;
				this.is_search = false;
			});
		},
		selezionaSquadra: function(squadra) {
			this.$refs.searchTeamModal.hide();
			this.players = [];
			this.is_search = true;
			this.$refs.searchPlayerModal.show();
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/players/by/team/' + squadra.wyId).then((response) => {
				this.players = response.data;
				this.is_search = false;
			});	
		},
		selezionaGiocatore: function (giocatore) {
			if(this.form.wyscout.chiave && this.form.wyscout.chiave != giocatore.wyId) {
				if(!confirm("Vuoi sovrascrivere il giocatore attuale?")) {
					return;     
				}
			}
			this.form.wyscout.chiave = giocatore.wyId;
			this.form.cognome = giocatore.lastName;
			this.form.nome = giocatore.firstName;
			this.form.nascita_data = giocatore.birthDate;
			this.form.info_fisica.altezza = giocatore.height;
			this.form.info_fisica.peso = giocatore.weight;
			this.form.giocatore.piede = this.piede(giocatore.foot);

			if(giocatore.currentTeam) {
				this.form.last_carriera_sportiva.squadra.nome = giocatore.currentTeam.officialName;
				this.form.last_carriera_sportiva.squadra.wyscout_id = giocatore.currentTeam.wyId;
				this.form.last_carriera_sportiva.squadra.logo = giocatore.currentTeam.imageDataURL;
				this.form.last_carriera_sportiva.squadra.ws_team = giocatore.currentTeam;
			} else {
				this.form.last_carriera_sportiva.squadra.nome = null;
				this.form.last_carriera_sportiva.squadra.wyscout_id = null;
				this.form.last_carriera_sportiva.squadra.logo = null;
				this.form.last_carriera_sportiva.squadra.ws_team = null;
			}
			this.form.last_carriera_sportiva.squadra.id = 0;
			this.image_player = giocatore.imageDataURL;
			if(giocatore.birthArea.alpha2code) {
				for (var index in this.paesi) {
					var paese = this.paesi[index];
					if (giocatore.birthArea.alpha2code == paese.sigla) {
						this.$refs.paese_nascita.setPaese(paese.id, paese.paese)
						break;
					}
				}
			}
			this.$refs.searchPlayerModal.hide();          
		},

		piede: function (foot) {
			if(foot == "right") {        
				return "0";
			}  else if(foot == "left") {
				return "1";
			}  else if(foot == "both") {
				return "2";
			} 
			return 0;          
		},
		eliminaGiocatore: function() {
			if(confirm("Confermi l'eliminazione del giocatore?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/delete/giocatore/' + this.$route.params.id).then((response) => {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: this.area + "scout_dashboard"});
				}, (response) => {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				});
			}
		},
		loadSquadra: function() {
			this.$refs.loadTeamModal.show();	
		},
		getCategories() {
			axios.get(`/exercise/categories`)
			.then((res) => {
				this.categories_opt = res.data.map(item => ({
					name: item.name,
					id: item.id,
				}));
			});
		},
		getTeams: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then((response) => {
				this.teams = response.data;						
			});
		},
		filtraArchivio() {
			this.teams = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then((response) => {	
				this.teams = response.data;
			});
		},
		paginate(page) {
			this.page = page;
			this.getTeams();
		},
		scegliSquadra: function(squadra) {
			this.form.last_carriera_sportiva.squadra.id = squadra.id;
			this.form.last_carriera_sportiva.squadra.nome = squadra.officialName;
			this.form.last_carriera_sportiva.squadra.wyscout_id = squadra.wyscout ? squadra.wyscout.chiave : null;
			this.form.last_carriera_sportiva.squadra.logo = squadra.logo_url;
			this.$refs.loadTeamModal.hide();	
		},
		getGiocatore(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/anagrafica/giocatore/' + id).then((response) => {
				var osservato = response.data; 
				if(osservato.avatar != null && osservato.avatar != "") {
					this.image_player = osservato.avatar_url;
				}
				this.form.nome = osservato.nome;
				this.form.cognome = osservato.cognome;
				this.form.nascita_data = osservato.nascita_data;
				if(osservato.nascita_paese) {
					this.$refs.paese_nascita.setPaese(osservato.nascita_paese.id, osservato.nascita_paese.paese);	
				}
				if(osservato.info_fisica) {
					this.form.info_fisica.id = osservato.info_fisica.id;
					this.form.info_fisica.altezza = osservato.info_fisica.altezza;
					this.form.info_fisica.peso = osservato.info_fisica.peso;
					this.form.info_fisica.baricentro = osservato.info_fisica.baricentro;
					this.form.info_fisica.somatotipo = osservato.info_fisica.somatotipo;
					this.form.info_fisica.costituzione = osservato.info_fisica.costituzione;
				}
				if(osservato.giocatore) {
					this.form.giocatore.id = osservato.giocatore.id;
					this.form.giocatore.piede = osservato.giocatore.piede;
					this.form.giocatore.sistema_gioco_id = osservato.giocatore.sistema_gioco_id;
					this.form.giocatore.cresciuto_giovanili = osservato.giocatore.cresciuto_giovanili ? true : false;
					this.form.giocatore.cresciuto_vivaio_italiano = osservato.giocatore.cresciuto_vivaio_italiano ? true : false;
				}
				if(osservato.last_carriera_sportiva) {
					this.form.last_carriera_sportiva.id = osservato.last_carriera_sportiva.id;
					this.form.last_carriera_sportiva.prestito = osservato.last_carriera_sportiva.tipologia == 'prestito_out' ? true : false;  
					if(osservato.last_carriera_sportiva.squadra) {
						this.form.last_carriera_sportiva.squadra.id = osservato.last_carriera_sportiva.squadra.id;
						this.form.last_carriera_sportiva.squadra.nome = osservato.last_carriera_sportiva.squadra.officialName;
						this.form.last_carriera_sportiva.squadra.logo = osservato.last_carriera_sportiva.squadra.logo_url;
					}
					if(osservato.last_carriera_sportiva.ruolo_ideale.length > 0) {
						this.form.last_carriera_sportiva.ruolo_ideale_id = osservato.last_carriera_sportiva.ruolo_ideale[0].id;
					}
					if(osservato.last_carriera_sportiva.altri_ruoli) {
						for (var index in osservato.last_carriera_sportiva.altri_ruoli) {
							this.form.last_carriera_sportiva.altri_ruoli_ids.push(osservato.last_carriera_sportiva.altri_ruoli[index].id);
						}
					}
				}
				if(osservato.osservato) {
					this.form.osservato.id = osservato.osservato.id;
					this.form.osservato.da_monitorare = osservato.osservato.da_monitorare ? true : false;
					this.form.osservato.di_prospettiva = osservato.osservato.di_prospettiva  ? true : false;
					this.form.osservato.scadenza_contratto = osservato.osservato.scadenza_contratto;
				}

				if(osservato.info_economica) {
					this.form.info_economica.id = osservato.info_economica.id;
					this.form.info_economica.valore = osservato.info_economica.valore;
				}

				if(osservato.contratto) {
					this.form.contratto.id = osservato.contratto.id;
					this.form.contratto.stipendio_lordo = osservato.contratto.stipendio_lordo;
				}

				var telefoni = [];
				for(var i in osservato.contatti) {
					var item = osservato.contatti[i];
					if(item.contatto.tipo == 'telefono') {
						telefoni.push(item.contatto);
					}
				}
				if(telefoni.length > 0) {
					this.form.telefono.id = telefoni[0].id;
					this.form.telefono.valore = telefoni[0].valore;
				}
				if(telefoni.length > 1) {
					this.form.telefono2.id = telefoni[1].id;
					this.form.telefono2.valore = telefoni[1].valore;
				}
				var agenti = [];
				for(var i in osservato.relazioni) {
					var item = osservato.relazioni[i];
					if(item.persona_relazioni_glo_id == 4) {
						agenti.push(item.relazionante);
					}
				}
				if(agenti.length > 0) {
					this.$refs.agente.setNome(agenti[0].id, agenti[0].cognome);
				}
				if(osservato.wyscout) {
					this.form.wyscout.id = osservato.wyscout.id;
					this.form.wyscout.chiave = osservato.wyscout.chiave;
				}
			});
		},
	},

	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		auth: function() {
			return Auth;
		},
		avatar: function() {
			if(this.image_player != "") {
				return this.image_player;
			} 
			return '';
		},
		avatar_agente: function() {
			if(this.image_agente != "") {
				return this.image_agente;
			} 
			return '';
		},
		sagoma: function(){     
			return "/assets/morfologia/s" + this.form.info_fisica.somatotipo + "b" + this.form.info_fisica.baricentro + "c" + this.form.info_fisica.costituzione + ".png";
		},
		altezza_sagoma: function() {
			var height = 550;
			if(this.form.info_fisica.baricentro > -1 && this.form.info_fisica.somatotipo > -1 && this.form.info_fisica.costituzione > -1) {
				if(!isNaN(this.form.info_fisica.altezza)) {
					if(this.form.info_fisica.altezza < 190 && this.form.info_fisica.altezza >= 180) {
						height = 530;
					}else if(this.form.info_fisica.altezza < 180 && this.form.info_fisica.altezza >= 170) {
						height = 510;
					} else if(this.form.info_fisica.altezza < 170 && this.form.info_fisica.altezza >= 160) {
						height = 490;
					} else if(this.form.info_fisica.altezza < 160) {
						height = 470;
					}     
				}
				return height;
			}
		},
		altri_ruoli_lbl: function() {
			var label = "";
			for (var index in this.form.last_carriera_sportiva.altri_ruoli_ids) {
				var id_ruolo = this.form.last_carriera_sportiva.altri_ruoli_ids[index];
				if(id_ruolo > 0) {
					label = label + this.ruoli.find((item) => item.id == id_ruolo).nome + ", ";
				}
			}
			if(label.length > 0) {
				label = label.substr(0, label.length - 2); 
			}
			return label;
		},
		logo_squadra: function() {
			if(this.form.last_carriera_sportiva.squadra.logo) {
				return this.form.last_carriera_sportiva.squadra.logo;
			}  
			return '/assets/varie/team.png';
		},
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/paesi').then((response) => {	
			this.paesi = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
			this.ruoli = response.data;    	
		});

		this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
			this.sistemi = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/agenti').then((response) => {
			this.agenti = response.data;    
		});

		this.getCategories();
		this.getTeams();
		this.getGiocatore(this.$route.params.id);
	},
	mounted () {
		var context = this.$refs.mycanvas.getContext("2d");
		context.lineWidth = 1;
		context.strokeStyle = "#CCCCCC";
		context.lineCap = "square"; 
		context.moveTo(0, 0);
		context.lineTo(300, 0);
		context.moveTo(0, 80);
		context.lineTo(300, 80);
		context.moveTo(0, 280);
		context.lineTo(300, 280);
		context.moveTo(0, 550);
		context.lineTo(300, 550);
		context.moveTo(65, 0);
		context.lineTo(65, 550);
		context.moveTo(162, 0);
		context.lineTo(162, 550);
		context.stroke();
	},
	watch: {
		'form.last_carriera_sportiva.ruolo_ideale_id': function (ruolo) {
			if(this.form.last_carriera_sportiva.altri_ruoli_ids.indexOf(ruolo) > -1) {
				this.form.last_carriera_sportiva.altri_ruoli_ids.splice(this.form.last_carriera_sportiva.altri_ruoli_ids.indexOf(ruolo), 1);
			}
		},
		form: {
			handler: function () {this.errors = []; },
			deep: true
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	}
}
</script>
