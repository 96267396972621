import { render, staticRenderFns } from "./orizzontale.vue?vue&type=template&id=16750a20&"
import script from "./orizzontale.vue?vue&type=script&lang=js&"
export * from "./orizzontale.vue?vue&type=script&lang=js&"
import style0 from "./orizzontale.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports