<template>
	<b-card>

		<template #header>
			<b-row>
				<b-col>
					<span v-if="ux.show == false && qualityCheck.alert">⚠️</span>
					<small>Bonus</small> {{ bonus.name }}
				</b-col>
				<b-col class="text-right">
					<small class="mr-1"><a href="" @click.prevent="$emit('duplicate')">Duplica</a></small>
					<small v-if="ux.show == false"><a href="" @click.prevent="ux.show = true">Espandi</a></small>
					<small v-if="ux.show == true"><a href="" @click.prevent="ux.show = false">Collassa</a></small>
				</b-col>
			</b-row>
		</template>
		<template #footer v-if="ux.show">
			<small><a href="" @click.prevent="do_removeBonus">Elimina Bonus</a></small>
		</template>

		<div v-show="ux.show">

			<h5 class="font-bold">Descrittivi ed Area Economica</h5>

			<b-form-group label="Nome del Bonus">
				<b-input v-model="bonus.name"></b-input>
			</b-form-group>

			<b-form-group label="Descrizione del Bonus">
				<b-textarea v-model="bonus.description"></b-textarea>
			</b-form-group>

			<!-- {{ rulesArchive }} -->
			<Modal-New-Rule ref="modalNew" :position="maxPosition" @add="bonus.rules.push($event)"></Modal-New-Rule>
			
			<!-- <b-row>
				<b-col cols="10"></b-col>
				<b-col>
					<b-button block @click.prevent="$refs.modalNew.show()" variant="outline-success" size="sm">Aggiungi Regola</b-button>
				</b-col>
			</b-row> -->

			<b-row>
				<b-col>
					<b-form-group label="Importo Bonus Lordo">
						<b-input type="number" step="0.01" v-model.number="bonus.importo"></b-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Importo Bonus Netto">
						<b-input type="number" step="0.01" v-model.number="bonus.netto"></b-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Periodo di Riferimento">
				<b-select v-model="bonus.persona_contratto_periodo_id">
					<b-select-option :value="0" disabled>Seleziona il Periodo</b-select-option>
					<b-select-option v-for="periodo in periodi" :value="periodo.value">{{ periodo.label }}</b-select-option>
				</b-select>
			</b-form-group>

			<b-row>
				<b-col>
					<div>
						<label style="display: block"><input type="radio" v-model="bonus.con_ultima_mensilita" :value="1"> Pagamento con <strong>ultima mensilità</strong></label>
						<label style="display: block"><input type="radio" v-model="bonus.con_ultima_mensilita" :value="0"> Pagamento con <strong>primo pagamento utile</strong></label>
						<label style="display: block"><input type="radio" v-model="bonus.con_ultima_mensilita" :value="-1"> Pagamento <strong>in data</strong></label>
					</div>
					<b-form-group v-if="bonus.con_ultima_mensilita == -1" label="Data Pagamento">
						<b-input type="date"></b-input>
					</b-form-group>
				</b-col>
				<b-col>
					<div>
						<label><input type="checkbox" v-model="bonus.netto_garantito" :true-value="1" :false-value="0"> Importo Netto Garantito</label>
					</div>
				</b-col>
			</b-row>

			<hr />
			<h5 class="font-bold">Condizioni del Bonus</h5>

			<!--<b-nav tabs>
				<b-nav-item :active="ux.area == 'editor'" @click.prevent="ux.area = 'editor'">Editor della Condizione</b-nav-item>
				<b-nav-item :active="ux.area == 'rules'" @click.prevent="ux.area = 'rules'">Regole nel Bonus</b-nav-item>
			</b-nav>-->

			<div>
				
				<!--<div v-if="ux.area == 'editor'">

					<TestBlockly :workspace="bonus.workspace" @workspaceupdate="bonus.workspace = $event" ref="beditor" v-if="ux.show" :rules="bonus.rules"></TestBlockly>
					
				</div>
				<div v-else-if="ux.area == 'rules'">-->
					
					<div v-if="bonus.rules.length > 0" class="mt-2">

						<b-row>
							<b-col>
								<h6>Riepilogo Regole del Bonus</h6>
							</b-col>
							<b-col class="text-right small">
								<a href="" @click.prevent="expandAll">Espandi Tutto</a> / <a href="" @click.prevent="collapseAll">Collassa Tutto</a>
							</b-col>
						</b-row>
						<Regola :contratto="contratto" ref="rule" class="mb-1" :key="rule.posizione" v-for="rule, k in bonus.rules" :rule="rule" @removerule="do_removeRule(k)"></Regola>

					</div>

				<!--</div>-->
				
				<b-button @click.prevent="$refs.modalNew.show()" class="mt-2" block variant="outline-success" size="sm">+ Aggiungi Regola</b-button>


			</div>

			<!--

			<b-row>
				<b-col v-if="ux.editorValidazione == 'validation'">
					<Rule-Helper :fields="[ 'result', 'amount' ]" @addr="$refs.modalNew.show()" :bonus-rules="bonus.rules" :rules="rulesArchive" @add="bonus.validation += ' ' + $event"></Rule-Helper>
				</b-col>
				<b-col>
					<b-form-group label="Regola di Validazione">
						<b-textarea @focus="ux.editorValidazione = 'validation'" v-model="bonus.validation"></b-textarea>
						<template #description v-if="bonus.validation.length == 0">
							Se assente, la regola di validazione corrisponderà al verificarsi di tutte <strong>le regole del bonus ({{ bonus.rules.length }}).</strong>
						</template>
					</b-form-group>
				</b-col>
			</b-row>



			-->
			<!--<b-row>
				<!--<b-col>
					<Rule-Helper :fields="[ 'result', 'progress', 'amount' ]" @addr="$refs.modalNew.show()" :bonus-rules="bonus.rules" :rules="rulesArchive" @add="bonus.multiplier += ' ' + $event"></Rule-Helper>
				</b-col>
				<b-col>-->
					<hr />
					<h5 class="font-bold">Ripetibilità</h5>
					<b-form-group>

						<b-form-select v-model="bonus.multiplier_type">
							<b-form-select-option value="once">Una Tantum</b-form-select-option>
							<b-form-select-option value="repeat">Ripetibile</b-form-select-option>
							<b-form-select-option value="amount">Moltiplicatore</b-form-select-option>
							<b-form-select-option value="custom">Personalizzato</b-form-select-option>
						</b-form-select>

						<div class="my-1" v-if="bonus.multiplier_type == 'repeat'">

							<b-form-select v-model="bonus.multiplier">
								<b-form-select-option :value="''" disabled>Seleziona la regola per il calcolo del modulo.</b-form-select-option>
								<b-form-select-option v-for="opt in rulesWtarget" :value="'rule' + opt.posizione">{{ opt.name }}</b-form-select-option>
							</b-form-select>

						</div>

						<div class="my-1" v-if="bonus.multiplier_type == 'amount'">
							
							<b-form-select v-model="bonus.multiplier">
								<b-form-select-option :value="''" disabled>Seleziona la regola per il calcolo del moltiplicatore.</b-form-select-option>
								<b-form-select-option v-for="opt in bonus.rules" :value="'rule' + opt.posizione + '.amount'">{{ opt.name }}</b-form-select-option>
							</b-form-select>

						</div>

						<b-textarea v-if="bonus.multiplier_type == 'custom'" class="mt-1" @focus="ux.editorValidazione = 'multiplier'" v-model="bonus.multiplier"></b-textarea>

					</b-form-group>

				<!-- </b-col>
				
			</b-row> -->

			<b-alert variant="danger" v-if="qualityCheck.alert" show>

				<p class="lead">Attenzione, <strong>questo BONUS ha dei problemi.</strong></p>

				<div v-for="area in validationsArea">
					<div v-if="area.bucket && qualityCheck.missingRules[area.bucket].length > 0">
						<u>{{area.name}}</u> utilizza delle REGOLE inesistenti.
						<ul>
							<li v-for="single in qualityCheck.missingRules[area.bucket]">
								<code>{{single}}</code>
							</li>
						</ul>
					</div>
				</div>

				<div v-if="qualityCheck.noRule">
					<p>Questo BONUS <strong>non ha nessuna regola</strong>, e nessuna <strong>Regola di Validazione</strong></p>
				</div>
				

			</b-alert>

			<hr />
			<h5 class="font-bold">Alerts</h5>

			<b-card size="sm" v-for="watchdog, k in bonus.watchdogs" class="mb-2">
				<template #header>Alert {{ k+1 }}</template>
				<b-row>
					<b-col v-if="ux.editorValidazione == 'watchdog_' + k">
						<Rule-Helper :fields="[ 'result', 'progress', 'amount' ]" @addr="$refs.modalNew.show()" :bonus-rules="bonus.rules" :rules="rulesArchive" @add="watchdog.validation += ' ' + $event"></Rule-Helper>
					</b-col>
					<b-col>
						<b-form-group label="Nome dell'Alert">
							<b-input v-model="watchdog.name"></b-input>
						</b-form-group>
						<b-form-group label="Descrizione dell'Alert">
							<b-textarea v-model="watchdog.description"></b-textarea>
						</b-form-group>
						<b-form-group label="Regola">
							<b-textarea @focus="ux.editorValidazione = 'watchdog_' + k" v-model="watchdog.validation"></b-textarea>
						</b-form-group>
						<b-btn @click.prevent="removeAlert(k)">Rimuovi</b-btn>
					</b-col>
				</b-row>
									
			</b-card>

			<a href="" @click.prevent="addWatchdog">Aggiungi Alert</a>

			<!-- <b-row>
				<b-col v-if="ux.editorValidazione == 'watchdog'">
					<Rule-Helper :fields="[ 'result', 'progress', 'amount' ]" @addr="$refs.modalNew.show()" :bonus-rules="bonus.rules" :rules="rulesArchive" @add="bonus.watchdog += ' ' + $event"></Rule-Helper>
				</b-col>
				<b-col>

				</b-col>
			</b-row> -->

			<hr />			

			<div v-if="bonus_test" class="my-3">
				<b-row>
					<b-col cols="9">
						<table class="table small table-striped">
							<thead>
								<tr>
									<th>Regola</th>
									<th>Verificata</th>
									<th>Quantità</th>
									<th>Progresso</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="b in bonus_test.rules">
									<td>{{ b.entity.name }}</td>
									<td>{{ b.result ? '✅' : '✖️' }}</td>
									<td>{{ b.amount }}</td>
									<td>{{ b.progress }}</td>
								</tr>
							</tbody>
						</table>

						<div class="text-success" v-if="bonus_test.invalid_validation == false">
							La Regola di Validazione non ha errori.
						</div>
						<div class="text-danger" v-else>
							La Regola di Validazione ha degli errori.
							<pre>{{ bonus_test.invalid_validation }}</pre>
						</div>
						
						<div class="text-success" v-if="bonus_test.invalid_watchdog == false">
							La Regola del Watchdog non ha errori.
						</div>
						<div class="text-danger" v-else>
							La Regola del Watchdog ha degli errori.
							<pre>{{ bonus_test.invalid_watchdog }}</pre>
						</div>

					</b-col>
					<b-col cols="3">
						<b-alert show class="text-center" variant="success" v-if="bonus_test.result">
							Il Bonus <strong>è</strong> Maturato
						</b-alert>
						<b-alert show class="text-center" variant="secondary" v-else>
							Il Bonus <strong>non è</strong> Maturato
						</b-alert>
						<hr />
						Attuale Moltiplicatore: <code>{{ bonus_test.multiplier_value }}</code>
					</b-col>
				</b-row>
			</div>

			<b-button size="sm" class="mt-3" variant="outline-info" @click.prevent="do_test(bonus)">Prova il Bonus e le Regole</b-button>

			<hr />
			<h5 class="font-bold">Note</h5>
			
			<NoteManager entity="Persona_Contratto_Bonus" :entityId="bonus.id"></NoteManager>

		</div>

		<div v-show="!ux.show">
			<b-row align-v="center">
				<b-col :cols="5">
					<b-row align-v="center">
						<b-col cols="2" class="text-center" style="line-height: 1">
							<strong class="small">Num. Regol{{ bonus.rules.length == 1 ? 'a' : 'e' }}</strong>
							<div>{{ bonus.rules.length }}</div>
						</b-col>
						<b-col class="small">
							<div v-if="bonus.description.length">
								<div><strong>{{ bonus.name }}</strong></div>
								{{ bonus.description }}
							</div>
							<ol class="m-0 ml-0 list-unstyled" v-else>
								<li :style="a%2 == 0 ? 'background-color: rgba(0,0,0,.05)' : ''" class="p-1" v-for="r,a in bonus.rules">{{ r.name }}</li>
							</ol>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="5" class="text-center small" style="line-height: 1">
					
					<div class="esmall">
						Importo
						<strong class="text-muted">
							<span v-if="bonus.multiplier_type == 'once'">1 Tantum</span>
							<span v-if="bonus.multiplier_type == 'repeat'">Ripetibile</span>
							<span v-if="bonus.multiplier_type == 'amount'">Moltiplicata</span>
						</strong>
					</div>

					<p class="lead m-0">{{bonus.importo | money }}</p>

				</b-col>
				<b-col cols="2" class="small text-center">
					<div v-if="bonus.con_ultima_mensilita == 1">
						Pagamento con ultima mensilità
					</div>
					<div v-else-if="bonus.con_ultima_mensilita == 0">
						Al primo pagamento utile
					</div>
				</b-col>
			</b-row>
		</div>

	</b-card>
</template>


<script>

import _ from 'lodash'

export default {
	props: [ 'bonus', 'rulesArchive', 'contratto', 'periodi' ],
	components: {
		Regola: require('./Regola.vue').default,
		NoteManager: require('@/components/layout/notes/manager.vue').default,
		ModalNewRule: require('./ModalNewRule.vue').default,
		RuleHelper: require('./RuleHelper.vue').default,
		TestBlockly: require('@/components/layout/contratti/testBlockly.vue').default
	},
	created: function() {
		if(this.bonus.id == undefined)
			this.ux.show = true;
	},
	computed: {
		rulesWtarget: function() {
			return _.filter(this.bonus.rules, function(a) {
				return JSON.parse(a.input_payload).target != undefined && JSON.parse(a.input_payload).target > 0;
			});
		},
		maxPosition: function() {

			return (_.max(_.map(this.bonus.rules, 'posizione')) || 0) + 1;

		},
		validationsArea: function() {
			return [
				{ name: 'Regola di Validazione', bucket: 'validation' },
				{ name: 'Moltiplicatore', bucket: 'multiplier' },
				{ name: 'Watchdog', bucket: 'watchdog' },
			];			
		},
		qualityCheck: function() {

			var validations = this.validationsArea;

			var qualityMissingRules = { validation: [], multiplier: [], watchdog: [] };

			var regex = /rule(\d+)\.(amount|progress|result)/gm; // this is the rule seeker :D

			_.each(validations, function(a) {
				var m = false;
				var collectedRules = [];
				while ((m = regex.exec(this.bonus[a.bucket])) !== null)
					collectedRules.push(parseInt(m[1]));

				_.each(collectedRules, function(i) {

					var exists = _.find(this.bonus.rules, { posizione: i });
					if(!exists)
						qualityMissingRules[a.bucket].push('rule' + i);

				}.bind(this));

			}.bind(this));

			var noRule = this.bonus.rules.length == 0 && this.bonus.validation.length == 0

			return {
				alert: noRule || qualityMissingRules.validation.length > 0 || qualityMissingRules.multiplier.length > 0 || qualityMissingRules.watchdog.length > 0,
				missingRules: qualityMissingRules,
				noRule
			}

		}
	},
	watch: {
		'bonus.rules': {
			deep: true,
			handler: function() {
				this.$refs.beditor.reBlockly();
			}
		},
		'bonus.multiplier_type': {
			deep: true,
			handler: function() {
				if(this.bonus.multiplier_type == 'once')
					this.bonus.multiplier = 1;
				else
					this.bonus.multiplier = '';
			}
		},
		'bonus.validation': {
			deep: true,
			handler: _.debounce(function() {
				console.log('bonus.validation');
			}, 300)
		},
		'bonus.multiplier': {
			deep: true,
			handler: _.debounce(function() {
				console.log('bonus.multiplier');
			}, 300)
		},
		'bonus.watchdog': {
			deep: true,
			handler: _.debounce(function() {
				console.log('bonus.watchdog');
			}, 300)
		},
	},
	methods: {
		removeAlert: function(k) {

			if(this.bonus.watchdogs[k].validation.length == 0 || window.confirm('Confermi la rimozione dell\'Alert?'))
				this.bonus.watchdogs.splice(k, 1);

		},
		addWatchdog: function() {

			// has some bad validators?
			if(_.filter(this.bonus.watchdogs, function(single) {
				return single.validation.length == 0;
			}).length > 0) return false;

			this.bonus.watchdogs.push({
				name: '',
				description: '',
				validation: ''
			});

		},
		expandAll: function() {
			_.each(this.$refs.rule, function(a) {
				a.ux.show = true;
			});
		},
		collapseAll: function() {
			_.each(this.$refs.rule, function(a) {
				a.ux.show = false;
			});
		},
		do_test: function(bonus) {
			this.$http.post(
				this.$store.state.apiEndPoint + '/contratto/' + this.contratto.id + '/test-bonus', 
				{ bonus }
			).then(function(data) {
				this.bonus_test = data.data;
			})
		},
		mr: function(a,b) {
			return a[b];
		},
		do_removeRule: function(ruleIndex) {

			this.bonus.rules.splice(ruleIndex, 1);

		},
		do_removeBonus: function() {
			var confirm = window.confirm('Vuoi davvero procedere con la rimozione del bonus?');

			if(confirm)
				this.$emit('remove');

		}
	},
	filters: {
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return formatter.format(v).replace(' €', '');
		},
	},
	data: function() {
		return {
			bonus_test: false,
			ux: {
				area: 'editor',
				show: false,
				editorValidazione: false
			}
		}
	}
};

</script>