export const ROUTES_FOOTBALL = [
    {
        path: '/football/dashboard',
        name: 'football_dashboard',
        component: require('../components/pages/football/dashboard.vue').default,
        meta: {
            module: 'football',
            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
            permesso: 'football',
        }
    },

    {
        path: '/football/calendar',
        name: 'football_calendar',
        component: require('../components/pages/calendar.vue').default,
        meta: {
            module: 'football',

            label: 'Calendario',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            enableRosaTeam: true,
            permesso: 'football_allenamenti',
        }
    },

    {
        path: '/football/sessione/:id/:tipo',
        name: 'football_sessione',
        component: require('../components/pages/medical/sessione.vue').default,
        meta: {
            module: 'football',

            label: 'Sessione',
            requiresAuth: true,
            parent: '',
            menusub: [],
            permesso: 'football_allenamenti',
        }
    },

    {
        path: '/football/sessione/video/:id/:tipo',
        name: 'football_sessione_video',
        component: require('../components/pages/football/video/video_sessione.vue').default,
        meta: {
            module: 'football',

            label: 'Video',
            requiresAuth: true,
            parent: '',
            menusub: ['football_sessione'],
            permesso: 'video_sessioni',
        }
    },

    {
        path: '/football/sessione/videotag/:id/:tipo/:id_video',
        name: 'football_sessione_videotag',
        component: require('../components/pages/football/video/videotag_sessione.vue').default,
        meta: {
            module: 'football',

            label: 'Videotag',
            requiresAuth: true,
            parent: '',
            menusub: ['football_sessione', 'football_sessione_video'],
            permesso: 'video_sessioni',
        }
    },

    {
        path: '/football/sessione/presentazione/:id/:tipo',
        name: 'football_sessione_presentazione',
        component: require('../components/pages/football/sessione_presentazione.vue').default,
        meta: {
            module: 'football',

            label: 'Presentazione',
            requiresAuth: true,
            parent: '',
            permesso: 'video_sessioni',
        }
    },

    {
        path: '/football/rosa',
        name: 'football_rosa',
        component: require('../components/pages/football/rosa/rosa.vue').default,
        meta: {
            module: 'football',
            label: 'Rosa',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'football_rosa',
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
        }
    }, 

    {
        path: '/football/rosa/elenco',
        name: 'football_rosa_elenco',
        component: require('../components/pages/football/rosa/rosa_elenco.vue').default,
        meta: {
            module: 'football',
            label: 'Rosa',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'football_rosa',
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
        }
    },

    {
        path: '/football/rosa/edit/:id',
        name: 'football_rosa_edit',
        component: require('../components/pages/football/rosa/edit.vue').default,
        meta: {
            module: 'football',

            label: 'Rosa',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_rosa',
            menusub: ['football_giocatore_scheda'],
            permesso: 'football_rosa_edit',
            showSeason: true,
        }
    },

    {
        path: '/football/rosa/nuovo',
        name: 'football_giocatore',
        component: require('../components/pages/football/rosa/aggiungi.vue').default,
        meta: {
            module: 'football',

            label: 'Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_rosa',
            permesso: 'football_rosa_mng',
            showSeason: true,
        }
    },

    {
        path: '/football/rosa/giocatore/:id',
        name: 'football_giocatore_scheda',
        component: require('../components/pages/football/rosa/giocatore.vue').default,
        meta: {
            module: 'football',

            label: 'Scheda Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_rosa',
            permesso: 'football_rosa_view',
            showSeason: true,
        }
    },   

    {
        path: '/football/rosa/edit/valutazione/:id/:val_id',
        name: 'football_rosa_edit_valutazione',
        component: require('../components/pages/football/rosa/edit_valutazione.vue').default,
        meta: {
            module: 'football',
            label: 'Modifica Valutazione',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_giocatore_scheda',
            menusub: ['football_giocatore_scheda', 'football_rosa_nuova_valutazione'],
            permesso: 'football_rosa_val',
            showSeason: true,
        }
    },

    {
        path: '/football/rosa/nuova/valutazione/:id',
        name: 'football_rosa_nuova_valutazione',
        component: require('../components/pages/football/rosa/nuova_valutazione.vue').default,
        meta: {
            module: 'football',
            label: 'Nuova Valutazione',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_giocatore_scheda',
            menusub: ['football_giocatore_scheda'],
            permesso: 'football_rosa_val',
            showSeason: true,
        }
    },

    {
        path: '/football/rosa/view/valutazione/:id/:val_id',
        name: 'football_rosa_view_valutazione',
        component: require('../components/pages/football/rosa/view_valutazione.vue').default,
        meta: {
            module: 'football',
            label: 'Valutazione Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_giocatore_scheda',
            menusub: ['football_giocatore_scheda', 'football_rosa_edit_valutazione', 'football_rosa_nuova_valutazione'],
            permesso: 'football_rosa_val',
            showSeason: true,
        }
    },

    {
        path: '/football/staff',
        name: 'football_staff',
        component: require('../components/pages/football/staff/staff.vue').default,
        meta: {
            module: 'football',
            label: 'Staff',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'football_staff',
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
        }
    },

    {
        path: '/football/staff/nuovo',
        name: 'football_staff_add',
        component: require('../components/pages/football/staff/aggiungi.vue').default,
        meta: {
            module: 'football',

            label: 'Nuovo membro dello staff',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_staff',
            permesso: 'football_staff_mng',
            showSeason: true,
        }
    },

    {
        path: '/football/staff/edit/:id',
        name: 'football_staff_edit',
        component: require('../components/pages/football/staff/edit.vue').default,
        meta: {
            module: 'football',

            label: 'Staff',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_staff',
            menusub: ['football_staff_scheda'],
            permesso: 'football_staff_mng',
            showSeason: true,
        }
    },

    {
        path: '/football/staff/membro/:id',
        name: 'football_staff_scheda',
        component: require('../components/pages/football/staff/membro.vue').default,
        meta: {
            module: 'football',

            label: 'Scheda Membro Staff',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_staff',
            permesso: 'football_staff',
            showSeason: true,
        }
    },

    {
        path: '/esercizi',
        name: 'esercizi',
        component: require('../components/pages/football/esercizi.vue').default,
        meta: {
            module: 'football',
            label: 'Esercizi',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'esercizi',
            enableRosaTeam: true,
            menusub: ['football_esercizi', 'football_palestra_esercizi', 'football_piscina_esercizi'],
        }
    },

    {
        path: '/football/esercizi',
        name: 'football_esercizi',
        component: require('../components/pages/football/esercizi/esercizi.vue').default,
        meta: {
            module: 'football',

            label: 'Football',
            requiresAuth: true,
            showInMenu: true,
            parent: 'esercizi',
            permesso: 'football_esercizi',
            enableRosaTeam: true,
            menusub: ['football_esercizi', 'football_palestra_esercizi', 'football_piscina_esercizi'],
        }
    },

    {
        path: '/football/esercizi/edit/:id',
        name: 'football_esercizi_edit',
        component: require('../components/pages/football/esercizi/esercizio.vue').default,
        meta: {
            module: 'football',
            label: 'Esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            go_to: 'update',
            permesso: 'football_esercizi',
            menusub: ['football_esercizi', 'football_palestra_esercizi', 'football_piscina_esercizi'],
        }
    },

    {
        path: '/football/esercizi/new',
        name: 'football_esercizi_new',
        component: require('../components/pages/football/esercizi/esercizio.vue').default,
        meta: {
            module: 'football',
            label: 'Editor',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            go_to: 'new',
            permesso: 'football_esercizi',
            menusub: ['football_esercizi', 'football_palestra_esercizi', 'football_piscina_esercizi'],
        }
    },

    {
        path: '/football/video/esercizio/:id',
        name: 'football_video_esercizio',
        component: require('../components/pages/football/video/video_esercizio.vue').default,
        meta: {
            module: 'football',
            label: 'Video Esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_esercizi_edit',
            menusub: [ 'football_video_esercizio_add', 'football_esercizi_edit'],
            permesso: 'video_esercizi',
        }
    },

    {
        path: '/football/video/esercizio/add/:id',
        name: 'football_video_esercizio_add',
        component: require('../components/pages/football/video/video_esercizio_add.vue').default,
        meta: {
            module: 'football',
            label: 'Aggiungi Video',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_video_esercizio',
            menusub: [ 'football_video_esercizio', 'football_esercizi_edit'],
            permesso: 'video_esercizi',
        }
    },

    {
        path: '/football/video/esercizio/edit/:id/:id_video',
        name: 'football_video_esercizio_edit',
        component: require('../components/pages/football/video/video_esercizio_edit.vue').default,
        meta: {
            module: 'football',
            label: 'Modifica Video',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_video_esercizio',
            menusub: [ 'football_video_esercizio', 'football_esercizi_edit'],
            permesso: 'video_esercizi',
        }
    },

    {
        path: '/football/allenamenti',
        name: 'football_allenamenti',
        component: require('../components/pages/football/allenamenti/allenamenti.vue').default,
        meta: {
            module: 'football',

            label: 'Allenamenti',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'football_allenamenti',
            enableRosaTeam: true,
        }
    },

    {
        path: '/football/allenamento/:id',
        name: 'football_allenamento',
        component: require('../components/pages/football/allenamenti/allenamento.vue').default,
        meta: {
            module: 'football',

            label: 'Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_allenamenti', 
            permesso: 'football_allenamenti',           
        }
    },

    {
        path: '/football/allenamento/:id/presenze',
        name: 'football_presenze_allenamento',
        component: require('../components/pages/football/allenamenti/presenze_allenamento.vue').default,
        meta: {
            module: 'football',

            label: 'Presenze Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_allenamento',
            permesso: 'football_allenamenti',
        }
    },

    {
        path: '/football/gps/allenamento/:id',
        name: 'football_gps_allenamento',
        component: require('../components/pages/football/allenamenti/gps_allenamento.vue').default,
        meta: {
            module: 'football',
            label: 'Dati Gps Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_allenamento',
            menusub: ['football_allenamento'],
            permesso: 'gps_allenamenti',
        }
    },

    {
        path: '/football/video/allenamento/:id',
        name: 'football_video_allenamento',
        component: require('../components/pages/football/video/video_allenamento.vue').default,
        meta: {
            module: 'football',
            label: 'Video Allenamento',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_allenamento',
            menusub: [ 'football_video_allenamento_add', 'football_allenamento'],
            permesso: 'video_allenamenti',
        }
    },

    {
        path: '/football/video/allenamento/add/:id',
        name: 'football_video_allenamento_add',
        component: require('../components/pages/football/video/video_allenamento_add.vue').default,
        meta: {
            module: 'football',
            label: 'Aggiungi Video',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_video_allenamento',
            menusub: [ 'football_video_allenamento', 'football_allenamento'],
            permesso: 'video_allenamenti',
        }
    },

    {
        path: '/football/video/allenamento/edit/:id/:id_video',
        name: 'football_video_allenamento_edit',
        component: require('../components/pages/football/video/video_allenamento_edit.vue').default,
        meta: {
            module: 'football',
            label: 'Modifica Video',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_video_allenamento',
            menusub: [ 'football_video_allenamento', 'football_allenamento'],
            permesso: 'video_allenamenti',
        }
    },

    {
        path: '/football/videotag/allenamento/:id/:id_video',
        name: 'football_videotag_allenamento',
        component: require('../components/pages/football/video/videotag_allenamento.vue').default,
        meta: {
            module: 'football',
            label: 'Videotag Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_video_allenamento',
            menusub: [ 'football_video_allenamento', 'football_allenamento'],
            permesso: 'video_allenamenti',
        }
    },

    {
        path: '/football/partite',
        name: 'football_partite',
        component: require('../components/pages/football/partite/partite.vue').default,
        meta: {
            module: 'football',

            label: 'Partite',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'football_partite',
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
        }
    },

    {
        path: '/football/partite/aggiungi',
        name: 'football_partite_aggiungi',
        component: require('../components/pages/football/partite/aggiungi.vue').default,
        meta: {
            module: 'football',

            label: 'Nuova Partita',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_partite',
            permesso: 'football_partite',
        }
    },

    {
        path: '/football/partite/convocati/aggiungi',
        name: 'football_partite_aggiungi_convocati',
        component: require('../components/pages/football/partite/convocati.vue').default,
        meta: {
            module: 'football',

            label: 'Convocati',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_partite',
            permesso: 'football_partite',
        }
    },

    {
        path: '/football/partita/:id',
        name: 'football_partita',
        component: require('../components/pages/football/partite/partita.vue').default,
        meta: {
            module: 'football',

            label: 'Partita',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_partite',
            permesso: 'football_partite',
        }
    },

    {
        path: '/football/gps/partita/:id',
        name: 'football_gps_partita',
        component: require('../components/pages/football/partite/gps_partita.vue').default,
        meta: {
            module: 'football',
            label: 'Dati Gps Partita',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_partita',
            menusub: ['football_partita'],
            permesso: 'gps_partite',
        }
    },

    {
        path: '/football/video/partita/:id',
        name: 'football_video_partita',
        component: require('../components/pages/football/video/video_partita.vue').default,
        meta: {
            module: 'football',
            label: 'Video Partita',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_partita',
            menusub: [ 'football_video_partita_add', 'football_partita'],
            permesso: 'video_partite',
        }
    },

    {
        path: '/football/video/partita/add/:id',
        name: 'football_video_partita_add',
        component: require('../components/pages/football/video/video_partita_add.vue').default,
        meta: {
            module: 'football',
            label: 'Aggiungi Video',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_video_partita',
            menusub: [ 'football_video_partita', 'football_partita'],
            permesso: 'video_partite',
        }
    },

    {
        path: '/football/video/partita/edit/:id/:id_video',
        name: 'football_video_partita_edit',
        component: require('../components/pages/football/video/video_partita_edit.vue').default,
        meta: {
            module: 'football',
            label: 'Modifica Video',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_video_partita',
            menusub: [ 'football_video_partita', 'football_partita'],
            permesso: 'video_partite',
        }
    },

    {
        path: '/football/videotag/partita/:id/:id_video',
        name: 'football_videotag_partita',
        component: require('../components/pages/football/video/videotag_partita.vue').default,
        meta: {
            module: 'football',
            label: 'Videotag Partita',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_video_partita',
            menusub: [ 'football_video_partita', 'football_partita'],
            permesso: 'video_partite',
        }
    },

    {
        path: '/football/pre/partita/:id',
        name: 'football_pre_partita',
        component: require('../components/pages/football/partite/pre.vue').default,
        meta: {
            module: 'football',
            label: 'Pre Partita',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_partita',
            menusub: ['football_partita'],
            permesso: 'prepost_partita',
        }
    },

    {
        path: '/football/post/partita/:id',
        name: 'football_post_partita',
        component: require('../components/pages/football/partite/post.vue').default,
        meta: {
            module: 'football',
            label: 'Post Partita',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_partita',
            menusub: ['football_partita'],
            permesso: 'prepost_partita',
        }
    },  

    {
        path: '/football/squadre',
        name: 'football_squadre',
        component: require('../components/pages/football/squadre/squadre.vue').default,
        meta: {
            module: 'football',
            label: 'Squadre',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'football_squadre',
        }
    },

    {
        path: '/football/squadre/nuova',
        name: 'football_squadre_new',
        component: require('../components/pages/football/squadre/new.vue').default,
        meta: {
            module: 'football',

            label: 'Nuova Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_squadre',
            permesso: 'football',
        }
    },

    {
        path: '/football/squadre/view/:id',
        name: 'football_squadre_view',
        component: require('../components/pages/football/squadre/view.vue').default,
        meta: {
            module: 'football',
            label: 'Squadra',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_squadre',
            permesso: 'football',
        }
    },

    {
        path: '/football/squadre/edit/:id',
        name: 'football_squadre_edit',
        component: require('../components/pages/football/squadre/edit.vue').default,
        meta: {
            module: 'football',
            label: 'Modifica Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_squadre_view',
            menusub: ['football_squadre_view','football_squadre_videotag', 'football_squadre_partite'],
            permesso: 'football',
        }
    },

    {
        path: '/football/squadre/videotag/:id',
        name: 'football_squadre_videotag',
        component: require('../components/pages/football/squadre/videotag.vue').default,
        meta: {
            module: 'football',
            label: 'Videotag',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_squadre_view',
            menusub: ['football_squadre_view','football_squadre_videotag', 'football_squadre_partite'],
            permesso: 'football_squadre_videotag',
        }
    },

    {
        path: '/football/squadre/:id/partite',
        name: 'football_squadre_partite',
        component: require('../components/pages/football/squadre/partite.vue').default,
        meta: {
            module: 'football',
            label: 'Partite',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_squadre_view',
            menusub: ['football_squadre_view','football_squadre_videotag', 'football_squadre_partite'],
            permesso: 'football',
        }
    },

    {
        path: '/football/squadre/:id/partita/:partite_id/:wy_id',
        name: 'football_squadre_partita_wy',
        component: require('../components/pages/football/squadre/partita.vue').default,
        meta: {
            module: 'football',
            label: 'Partite',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_squadre_view',
            contesto: 'partita-partite-wyscout',
            menusub: ['football_squadre_view','football_squadre_videotag', 'football_squadre_partite'],
            permesso: 'football',
        }
    },
    {
        path: '/football/squadre/:id/partita/:partite_id',
        name: 'football_squadre_partita',
        component: require('../components/pages/football/squadre/partita.vue').default,
        meta: {
            module: 'football',
            label: 'Partite',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_squadre_view',
            contesto: 'partita-partite',
            menusub: ['football_squadre_view','football_squadre_videotag', 'football_squadre_partite'],
            permesso: 'football',
        }
    },

    {
        path: '/football/squadre/allenatore/:id/:team_id',
        name: 'football_squadre_allenatore',
        component: require('../components/pages/football/squadre/allenatore.vue').default,
        meta: {
            module: 'football',
            label: 'Allenatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_squadre_view',
            permesso: 'football',
        }
    },

    {
        path: '/football/opponents/:team_id/:category_id',
        name: 'football_opponents_team',
        component: require('../components/pages/football/opponent/opponents.vue').default,
        meta: {
            module: 'football',

            label: 'Rosa',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_squadre',
            permesso: 'football',
            showSeason: true,
            enableSeason: true,
        }
    },

    {
        path: '/football/opponents/edit/:id/:team_id/:category_id',
        name: 'football_opponent_edit',
        component: require('../components/pages/football/opponent/edit.vue').default,
        meta: {
            module: 'football',
            label: 'Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_opponents_team',
            menusub: ['football_opponents_team'],
            permesso: 'football',
        }
    },

    {
        path: '/football/opponents/view/:id/:team_id/:category_id',
        name: 'football_opponent_view',
        component: require('../components/pages/football/opponent/view.vue').default,
        meta: {
            module: 'football',
            label: 'Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_opponents_team',
            menusub: ['football_opponents_team'],
            permesso: 'football',
        }
    },

    {
        path: '/football/opponents/nuovo/:team_id/:category_id',
        name: 'football_opponents_new',
        component: require('../components/pages/football/opponent/new.vue').default,
        meta: {
            module: 'football',

            label: 'Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'football_opponents_team',
            menusub: ['football_opponents_team'],
            permesso: 'football',
        }
    },

    {
        path: '/football/visita/medica',
        name: 'football_visita_medica',
        component: require('../components/pages/football/visita_medica.vue').default,
        meta: {
            module: 'football',
            label: 'Visita Medica',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'football_visita_medica',
            enableRosaTeam: true,
        }
    },

    {
        path: '/football/documenti',
        name: 'football_docs',
        component: require('../components/pages/football/documenti.vue').default,
        meta: {
            module: 'football',
            label: 'Documenti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'football_docs',
            enableRosaTeam: true,
        }
    }, 

    {
        path: '/football/in/prova',
        name: 'football_in_prova',
        component: require('../components/pages/football/in_prova.vue').default,
        meta: {
            module: 'football',
            label: 'In prova',
            requiresAuth: true,
            showInMenu: true,
            onlyInProva: true,
            parent: '',
            permesso: 'football_rosa',
        }
    },

    {
        path: '/football/palestra/esercizi',
        name: 'football_palestra_esercizi',
        component: require('../components/pages/football/palestra/esercizi.vue').default,
        meta: {
            module: 'football',

            label: 'Palestra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'esercizi',
            permesso: 'palestra_esercizi',
            enableRosaTeam: true,
            menusub: ['football_esercizi', 'football_palestra_esercizi', 'football_piscina_esercizi'],
        }
    },

    {
        path: '/football/palestra/esercizi/new',
        name: 'football_palestra_esercizi_new',
        component: require('../components/pages/football/palestra/esercizi_new.vue').default,
        meta: {
            module: 'football',

            label: 'Nuovo esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'palestra_ese_mng',
        }
    }, 

    {
        path: '/football/palestra/esercizi/edit/:id',
        name: 'football_palestra_esercizi_edit',
        component: require('../components/pages/football/palestra/esercizi_edit.vue').default,
        meta: {
            module: 'football',

            label: 'Esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'palestra_esercizi',
        }
    },

    {
        path: '/football/palestra/allenamento/:id',
        name: 'football_palestra_allenamento',
        component: require('../components/pages/football/allenamenti/palestra_allenamento.vue').default,
        meta: {
            module: 'football',

            label: 'Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'football_allenamenti', 
            permesso: 'football_allenamenti',           
        }
    },

    {
        path: '/football/arbitri',
        name: 'football_arbitri',
        component: require('../components/pages/football/arbitri.vue').default,
        meta: {
            module: 'football',
            label: 'Arbitri',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'football_arbitri',
        }
    },  

    {
        path: '/football/piscina/esercizi',
        name: 'football_piscina_esercizi',
        component: require('../components/pages/football/piscina/esercizi.vue').default,
        meta: {
            module: 'football',

            label: 'Piscina',
            requiresAuth: true,
            showInMenu: true,
            parent: 'esercizi',
            permesso: 'piscina_esercizi',
            enableRosaTeam: true,
            menusub: ['football_esercizi', 'football_palestra_esercizi', 'football_piscina_esercizi'],
        }
    },

    {
        path: '/football/piscina/esercizi/new',
        name: 'football_piscina_esercizi_new',
        component: require('../components/pages/football/piscina/esercizi_new.vue').default,
        meta: {
            module: 'football',

            label: 'Nuovo esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'piscina_ese_mng',
        }
    }, 

    {
        path: '/football/piscina/esercizi/edit/:id',
        name: 'football_piscina_esercizi_edit',
        component: require('../components/pages/football/piscina/esercizi_edit.vue').default,
        meta: {
            module: 'football',

            label: 'Esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'piscina_esercizi',
        }
    },
];
