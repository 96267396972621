<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Prestiti ({{ osservati.length }})</span>
					</div>
					<div class="content">
						<b-form @submit="onSubmit">
							<b-row class="mb-1">
								<b-col>
									Carica dati partite
								</b-col>
								<b-col cols="7">
									Filtra prestiti
								</b-col>
							</b-row>

							<b-row>
								<b-col>
									<b-row>	
										<b-col cols="5">
											<b-form-group horizontal label="Dal" :label-cols="2" label-class="text-sm-right">
												<b-form-input type="date"  v-model="partite.dal"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="5">
											<b-form-group horizontal label="al" :label-cols="2" label-class="text-sm-right">
												<b-form-input type="date" v-model="partite.al"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col>	
											<b-btn title="Carica dati partite" @click="loadDatiPartite" v-if="!waiting">
												<i class="fa fa-futbol-o" aria-hidden="true"></i>
											</b-btn>
											<div v-if="waiting" style="width:100%;">
												<span class="font-bold">Loading...</span>
												<img src="/assets/varie/loading.gif" style="width: 30px;" />
											</div>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="7">
									<b-row>
										<b-col cols="3">
											<b-form-group horizontal label="Dal" :label-cols="2" label-class="text-sm-right">
												<b-form-input type="date"  v-model="filters.dal"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="3">
											<b-form-group horizontal label="al" :label-cols="2" label-class="text-sm-right">
												<b-form-input type="date" v-model="filters.al"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="2" v-if="auth.isUserEnable('prestiti_utenti')">
											<b-form-select :options="utenti" value-field="id" text-field="nomeCompleto" v-model="filters.utente">
												<template slot="first">
													<option :value="auth.user.id">I miei voti</option>
													<option :value="0">Tutti</option>
												</template>
											</b-form-select>
										</b-col>
										<b-col cols="3">
											<b-btn variant="primary" title="Cerca" @click="loadPrestiti">
												<i class="fa fa-search" aria-hidden="true"></i>
											</b-btn>
											<b-btn title="Reset" @click="inPrestito">
												<i class="fa fa-ban" aria-hidden="true"></i>
											</b-btn>
											<b-btn variant="success" title="Download" @click="downloadPrestiti">
												<i class="fa fa-download" aria-hidden="true"></i>		
											</b-btn>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<div v-if="loading" align="center" style="width:100%">
								<img src="/assets/varie/loading.gif" style="width: 150px;" />
							</div>
							<b-card v-for="osservato in osservati" class="mb-2">
								<b-row class="mb-1">
									<b-col cols="2" class="text-center">
										<a :href="'#/prestiti/giocatore/' + osservato.id" target="_blank" style="text-decoration:none;">
											<b-img :src="osservato.avatar_url" fluid :alt="osservato.cognome + ' ' + osservato.nome" :width="120"/>
											<div class="mt-2 font-bold">{{osservato.cognome + ' ' + osservato.nome}}</div>
										</a>
										<div><strong>{{ prestiti[osservato.id].at_away ?  prestiti[osservato.id].away : prestiti[osservato.id].home }}</strong>
										</div>
										<div><b-form-checkbox v-model="prestiti[osservato.id].nazionale" :value="1">Nazionale</b-form-checkbox></div>
									</b-col>
									<b-col>
										<b-row>
											<b-col>
												<b-row>
													<b-col>
														<b-form-group label="Partita">
															<b-form-input type="text" placeholder="Categoria" v-model="prestiti[osservato.id].categoria" class="mb-2"></b-form-input>
															<b-row>
																<b-col>
																	<b-form-input type="text" placeholder="Home" v-model="prestiti[osservato.id].home" class="mb-2"></b-form-input>
																</b-col>
																<b-col cols="3">
																	<b-form-input type="number" min="0" v-model="prestiti[osservato.id].home_score"></b-form-input>
																</b-col>
															</b-row>
															<b-row>
																<b-col>
																	<b-form-input type="text" placeholder="Away" v-model="prestiti[osservato.id].away"></b-form-input>
																</b-col>
																<b-col cols="3">
																	<b-form-input type="number" min="0" v-model="prestiti[osservato.id].away_score"></b-form-input>
																</b-col>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
											</b-col>
											<b-col cols="2">
												<b-form-group label="Data/Ora">
													<b-form-input v-model="prestiti[osservato.id].date" type="date"></b-form-input>
													
													<b-form-input v-model="prestiti[osservato.id].hour" type="time" class="mt-2"></b-form-input>
												</b-form-group>	
												<b-form-radio-group v-model="prestiti[osservato.id].at_away">
													<b-form-radio :value="0">Casa</b-form-radio>
													<b-form-radio :value="1">Trasferta</b-form-radio>
												</b-form-radio-group>
											</b-col>
											<b-col cols="3">
												<b-form-group label="Stato">
													<b-form-radio-group v-model="prestiti[osservato.id].stato">
														<b-form-radio value="titolare">Titolare</b-form-radio>
														<b-form-radio value="panchina">Panchina</b-form-radio>
														<b-form-radio value="tribuna">Tribuna</b-form-radio>
														<b-form-radio value="squalificato">Squalificato</b-form-radio>
													</b-form-radio-group>
													<b-form-select :options="infortuni" value-field="id" text-field="nome" v-model="prestiti[osservato.id].infortunio_id">
														<template slot="first">
															<option :value="null">-- Infortunio --</option>
														</template>
													</b-form-select>
													<b-form-input type="text" class="mt-2" v-model="prestiti[osservato.id].infortunio_note" placeholder="Note infortunio"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col cols="1">
												<b-form-group label="Minuti" class="mb-4">
													<b-form-input type="number" v-model="prestiti[osservato.id].minuti"></b-form-input>
												</b-form-group>
												<b-form-group label="Assist" class="mb-4">
													<b-form-input type="number" v-model="prestiti[osservato.id].assist"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col cols="1">
												<b-form-group label="Goal" class="mb-4">
													<b-form-input type="number" v-model="prestiti[osservato.id].goal"></b-form-input>
												</b-form-group>
												<b-form-group label="Yellow">
													<b-form-input type="number" v-model="prestiti[osservato.id].gialli"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col cols="1">
												<b-form-group label="Goal subiti" class="mb-4">
													<b-form-input type="number" v-model="prestiti[osservato.id].goal_subiti"></b-form-input>
												</b-form-group>
												<b-form-group label="Red">
													<b-form-input type="number" v-model="prestiti[osservato.id].rossi"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col cols="1">
												<b-form-group label="Autogoal" class="mb-4">
													<b-form-input type="number" v-model="prestiti[osservato.id].autogoal"></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								<b-row class="mb-2 border p-2" v-for="voto in prestiti[osservato.id].voti">
									<b-col cols="12" class="text-right" v-if="voto.utente">
										<strong>{{ voto.utente.nomeCompleto}}</strong>
									</b-col>
									<b-col cols="1">
										<b-form-group label="Voto" class="mb-1">
											<b-form-input type="number" min="0" max="10" step="0.1" v-model="voto.voto" :disabled="voto.utente && voto.utente.id != auth.user.id" :style="'background-color:' + voto_bg(voto.voto)"></b-form-input>
										</b-form-group>
										<b-form-radio-group v-model="voto.modalita" :disabled="voto.utente && voto.utente.id != auth.user.id">
											<b-form-radio value="stadio">Stadio</b-form-radio>
											<b-form-radio value="video">Video</b-form-radio>
										</b-form-radio-group>
									</b-col>
									<b-col>
										<b-form-group label="Note">
											<textarea class="form-control" :rows="3" v-model="voto.note" :disabled="voto.utente && voto.utente.id != auth.user.id"></textarea>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card>
							<b-button type="submit" variant="primary">Salva</b-button>
						</b-form>
					</div>
				</div>
			</div>	
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>

var Auth = require('../../../auth.js');
import moment from 'moment';
import * as types from '../../../store/types';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
	},

	data: function(){
		return {
			partite: {
				dal: null,
				al: null,
				utenti: Auth.isUserEnable('prestiti_utenti'),	
			},
			filters: {
				dal: null,
				al: null,
				utente: 0,	
				utenti: Auth.isUserEnable('prestiti_utenti'),
			},
			prestiti: {},
			osservati: [],
			utenti: [],
			infortuni: [],
			loading: false,
			waiting: false,	
			errors: [],
			gradimenti: [],
		}
	},

	created: function () {
		this.inPrestito();

		this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
			if (response.ok)
			{
				this.gradimenti = response.data;    
			}
		}, (response) => {
		});

		this.$http.get(this.$store.state.apiEndPoint + '/infortuni').then((response) => {
			if (response.ok)
			{
				this.infortuni = response.data;    
			}
		}, (response) => {
		});

		this.$http.get(this.$store.state.apiEndPoint + '/prestiti/utenti').then((response) => {
			if (response.ok)
			{
				this.utenti = response.data;  
				this.filters.utente = this.auth.user.id;  
			}
		}, (response) => {
		});
	},
	methods: {
		loadDatiPartite() {
			this.filters.dal = null;
			this.filters.al = null;
			this.filters.utente = 0;
			var oggi = moment();
			var date_dal = moment(this.partite.dal);
			var date_al = moment(this.partite.al);
			if(date_dal.isValid() && date_al.isValid() && date_dal <= date_al && date_al <= oggi) {
				this.waiting = true;
				this.osservati = [];
				this.prestiti = {};

				this.$http.get(this.$store.state.apiEndPoint + '/prestiti').then((response) => {
					if (response.ok)
					{
						this.osservati = response.data;
						this.loading = false;
						for(var i in this.osservati) {
							var prestito = {
								nazionale: 0,
								at_away: 0,
								categoria: null,
								home: null,
								away: null,
								home_id: null,
								away_id: null,
								home_score: 0,
								away_score: 0,
								date: null,
								hour: null,
								match_id: null,
								minuti: 0,
								stato: null,
								infortunio_id: null,
								infortunio_note: null,
								goal: 0,
								goal_subiti: 0,
								assist: 0,
								autogoal: 0,
								gialli: 0,
								rossi: 0,
								voti: [{
									voto: 0,
									note: null,
									modalita: null,
								}],
							};
							this.prestiti[this.osservati[i].id] = prestito;
						} 

						var array_osserv_ids = Object.keys(this.prestiti);

						this.$http.get(this.$store.state.apiEndPoint + '/prestiti/dati/partite', {params: {dal: this.partite.dal, al: this.partite.al, osserv_ids: array_osserv_ids}}).then((response) => {
							if (response.ok)
							{
								var dati_partite_obj = response.data;
								for(var key in dati_partite_obj) {
									var item = dati_partite_obj[key];
									this.prestiti[key].nazionale = item.nazionale;
									this.prestiti[key].at_away = item.at_away;
									this.prestiti[key].categoria = item.categoria;
									this.prestiti[key].home = item.home;
									this.prestiti[key].away = item.away;
									this.prestiti[key].home_id = item.home_id;
									this.prestiti[key].away_id = item.away_id;
									this.prestiti[key].home_score = item.home_score;
									this.prestiti[key].away_score = item.away_score;
									this.prestiti[key].date = item.date;
									this.prestiti[key].hour = item.hour;
									this.prestiti[key].match_id = item.match_id;
									this.prestiti[key].minuti = item.minuti;
									this.prestiti[key].stato = item.stato;
									this.prestiti[key].goal = item.goal;
									this.prestiti[key].goal_subiti = item.goal_subiti;
									this.prestiti[key].assist = item.assist;
									this.prestiti[key].autogoal = item.autogoal;
									this.prestiti[key].gialli = item.gialli;
									this.prestiti[key].rossi = item.rossi;
									if(item.id) {
										this.prestiti[key].id = item.id;
										this.prestiti[key].infortunio_id = item.infortunio_id;
										this.prestiti[key].infortunio_note = item.infortunio_note;
										if(item.voto) {
											this.prestiti[key].voti[0].id = item.voto.id;
											this.prestiti[key].voti[0].voto = item.voto.voto;
											this.prestiti[key].voti[0].note = item.voto.note;
											this.prestiti[key].voti[0].modalita = item.voto.modalita;
										}
									}
								} 
								this.waiting = false;
							}
						}, (response) => {
							this.waiting = false;
						});

					}
				}, (response) => {
				});
			} else {
				alert("Devi selezionare un intervallo valido!");
			}
		},
		onSubmit(evt) {
			evt.preventDefault();
			this.$http.post(this.$store.state.apiEndPoint + '/prestiti/salva', this.prestiti).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		inPrestito() {
			this.loading = true;
			this.osservati = [];
			this.partite.dal = null;
			this.partite.al = null;
			this.prestiti = {};
			this.filters.dal = null;
			this.filters.al = null;
			this.filters.utente = 0;
			this.$http.get(this.$store.state.apiEndPoint + '/prestiti').then((response) => {
				if (response.ok)
				{
					this.osservati = response.data;
					this.loading = false;
					for(var i in this.osservati) {
						var prestito = {
							nazionale: 0,
							at_away: 0,
							categoria: null,
							home: null,
							away: null,
							home_id: null,
							away_id: null,
							home_score: 0,
							away_score: 0,
							date: null,
							hour: null,
							match_id: null,
							minuti: 0,
							stato: null,
							infortunio_id: null,
							infortunio_note: null,
							goal: 0,
							goal_subiti: 0,
							assist: 0,
							autogoal: 0,
							gialli: 0,
							rossi: 0,
							voti: [{
								voto: 0,
								note: null,
								modalita: null,
							}],
						};
						this.prestiti[this.osservati[i].id] = prestito;
					}    
				}
			}, (response) => {
			});
		},
		loadPrestiti() {
			var oggi = moment();
			var date_dal = moment(this.filters.dal);
			var date_al = moment(this.filters.al);
			if(date_dal.isValid() && date_al.isValid() && date_dal <= date_al && date_al <= oggi) {
				this.loading = true;
				this.osservati = [];
				this.partite.dal = null;
				this.partite.al = null;
				this.prestiti = {};
				this.$http.post(this.$store.state.apiEndPoint + '/prestiti/partite', this.filters).then((response) => {
					if (response.ok)
					{
						var prestiti = response.data.prestiti;
						for(var i in prestiti) {
							var item = prestiti[i];
							this.osservati.push(item.osservato);
							var voti = [];
							var hasVoto = false;
							for(var j in item.voti) {
								var voto = {
									id: item.voti[j].id,
									voto: item.voti[j].voto,
									note: item.voti[j].note,
									modalita: item.voti[j].modalita,
									utente: item.voti[j].utente ? item.voti[j].utente : null, 
								}
								voti.push(voto);
								if(item.voti[j].utente_new_id == Auth.user.id) {
									hasVoto = true;
								}
							} 
							if(voti.length == 0 || !hasVoto) {
								voti.push({ voto: 0, note: null, modalita: null });
							}
							var prestito = {
								id: item.id,
								nazionale: item.nazionale,
								at_away: item.at_away,
								categoria: item.categoria,
								home: item.home,
								away: item.away,
								home_id: item.home_id,
								away_id: item.away_id,
								home_score: item.home_score,
								away_score: item.away_score,
								date: item.data.split(' ')[0],
								hour: item.data.split(' ')[1],
								match_id: item.match_id,
								minuti: item.minuti,
								stato: item.stato,
								infortunio_id: item.infortunio_id,
								infortunio_note: item.infortunio_note,
								goal: item.goal,
								goal_subiti: item.goal_subiti,
								assist: item.assist,
								autogoal: item.autogoal,
								gialli: item.gialli,
								rossi: item.rossi,
								voti: voti,

							};
							this.prestiti[item.osservato.id] = prestito;
						}
						this.loading = false;    
					}
				}, (response) => {
				});
			} else {
				alert("Devi selezionare un intervallo valido!");
			}
		},
		downloadPrestiti() {
			var oggi = moment();
			var date_dal = moment(this.filters.dal);
			var date_al = moment(this.filters.al);
			if(date_dal.isValid() && date_al.isValid() && date_dal <= date_al && date_al <= oggi) {
				window.open(this.$store.state.apiEndPoint + '/prestiti/download/' + this.filters.dal + "/" + this.filters.al + "/" + this.filters.utenti + "/" + this.filters.utente + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
			} else {
				alert("Devi selezionare un intervallo valido!");

			}
		},

		voto_bg: function(voto) {
			if(voto >= 6 && voto <= 10) {
				return this.gradimenti[0].colore + '7F';
			} else if(voto >= 5 && voto <= 5.9) {
				return this.gradimenti[3].colore + '7F';
			} else if(voto > 0 && voto  <= 4.9) {
				return this.gradimenti[4].colore + '7F';
			} 
			return '#FFFFFF';
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},
	watch: {
	},
	computed: {
		auth: function() {
			return Auth;
		},
	}
}
</script>
