<template>
	<div>

		<!--pre>{{playerFasiDato}}</pre-->
		<b-table-simple sticky-header="80vh" striped hover responsive small>
			<b-thead>
				<b-tr>
					<b-th style="min-width: 80px" sticky-column>Giocatore</b-th>
					<b-th class="text-center" v-for="h,k in playerFasiDato.persone" :colspan="h.length" :style="'background-color: ' + colorOf(k) + '; color: white'">
						{{k}}
					</b-th>
				</b-tr>
				<b-tr>
					<b-th style="min-width: 80px" sticky-column></b-th>
					<b-th class="text-center" v-for="colonna in playerFasiDato.persone_colonne">{{ createHeading(colonna) }}</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="p in playerFasiDato.persone_output">
					<b-td style="max-width: 150px; vertical-align: middle" class="text-truncate" sticky-column>
						<router-link :to="{ name: $route.meta.module + '_giocatore_scheda', params: { id: p.id }}">{{ p.name_table }}</router-link>
						<i class="fa fa-ambulance ml-2" v-if="p.injured" title="Infortunato"></i>
					</b-td>
					<b-td style="vertical-align: middle" class="text-center" v-for="colonna in playerFasiDato.persone_colonne">
						<div style="min-width: 80px;" v-if="stampaColonna(p, colonna)">
							<div full :readonly="true" :is="typeOfDato(stampaColonna(p, colonna).intestazione)" :test="stampaColonna(p, colonna)" field="valori" :dataLayer="dataLayer" :options="stampaColonna(p, colonna).intestazione"></div>
						</div>
						<div v-else class="text-muted">-</div>
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>

	</div>
</template>

<script>
var Auth = require('../../../auth.js');
import axios from 'axios';
import $ from 'jquery';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
export default {
	props: [ 'persone', 'config' ],
	data: function(){
		return {
			dataLayer: [],
			ready: false,
		}
	},
	components: {
		SessioneMatriceBoolean: require('@/components/layout/Sessioni/Matrix/Boolean.vue').default,
		SessioneMatriceActivitycheck: require('@/components/layout/Sessioni/Matrix/ActivityCheck.vue').default,
		SessioneMatriceNumber: require('@/components/layout/Sessioni/Matrix/Number.vue').default,
		SessioneMatriceSelector: require('@/components/layout/Sessioni/Matrix/Selector.vue').default,
		SessioneMatriceTextarea: require('@/components/layout/Sessioni/Matrix/Textarea.vue').default,
		SessioneMatriceText: require('@/components/layout/Sessioni/Matrix/Text.vue').default,
		SessioneMatriceEnum: require('@/components/layout/Sessioni/Matrix/Enum.vue').default,
	},
	watch: {
		persone: {
			deep: true,
			immediate: true,
			handler: function() {

				var b = {};
				_.each(_.map(this.heading, 'intestazione'), function(a,k) {
					_.each(a, function(c,kk) {
						b[kk] = c;
					})
				});

				var selectors = _.filter(b, { type: 'selector' });
				_.each(selectors, function(sel) {
					if(!_.find(this.dataLayer, { url: sel.url })) {
						this.$http.get(this.$store.state.apiEndPoint + sel.url).then(function(data) {
							this.dataLayer.push({
								url: sel.url,
								data: data.data
							});
						});
					}
				}.bind(this));

			}
		}
	},
	computed: {
		playerFasiDato: function() {

			var persone = {};
			var persone_output = [];
			var tipologieSessione = {};

			for(var a in this.persone) {

				var contenitorePersona = {};
				var contenitorePersonaDati = {};
				var persona = _.cloneDeep(this.persone[a]);

				for(var b in persona.stats.fase_form) {

					var formDato = persona.stats.fase_form[b];

					// b: c'è Fase_Dato_Presenza
					for(var c in formDato) {

						var datiDelForm = formDato[c];
						var tipoSessione = datiDelForm.fase.sessione.sessione_tipo.nome;

						tipologieSessione[tipoSessione] = datiDelForm.fase.sessione.sessione_tipo;
						
						if(!contenitorePersona[tipoSessione]) {
							contenitorePersona[tipoSessione] = [];
						}

						if(!contenitorePersonaDati[tipoSessione]) {
							contenitorePersonaDati[tipoSessione] = [];
						}

						if(!persone[tipoSessione]) {
							persone[tipoSessione] = [];
						}

						var key = b + '_' + (_.filter(contenitorePersona[tipoSessione], function(d) { return d == b }).length);
						//console.log();
						contenitorePersona[tipoSessione].push(b);

						_.each(datiDelForm.intestazione, function(f, e) {
							persone[tipoSessione].push(tipoSessione + '|' + key + '|' + e + '|' + f.label);
						})

						persone[tipoSessione] = _.uniq(persone[tipoSessione]);

						contenitorePersonaDati[tipoSessione].push({ tipologia: b, valori: datiDelForm, personaId: persona.id });

					}

				}

				persona.griglia = contenitorePersonaDati;
				persone_output.push(persona);

			}

			return { persone, persone_output, persone_colonne: _.flatten(_.values(persone)), tipologieSessione };

			return false;

			return _.map(this.persone, function(i) {

				var b = _.cloneDeep(i);

				//b.sessioni = 

				return b;
				//console.log(i.stats.fase_form);

				/*return _.groupBy(i.stats.fase_form, function(a) {
					return a[0].fase.sessione.sessione_tipo_glo_id;
				});*/

			});

		},
		colonne: function() {

			var cols = [];

			_.each(this.heading, function(colonne, k) {

				for(var b = 0; b < colonne.noResults; b++) {
					_.each(colonne.intestazione, function(colonna, kk) {
						cols.push({ type: colonna.type, key: kk, label: colonna.label, fase_form: k, index: b, original: colonna });
					})
				}

			});

			return cols;

		},
		heading: function() {

			var h = _.map(this.persone, function(a) {
				var tipoForm = _.keys(a.stats.fase_form);

				var amounts = {};
				_.each(tipoForm, function(k) {
					amounts[k] = a.stats.fase_form[k].length;
				});

				return amounts;
			});

			var intestazione = {};
			_.each(this.config.fase_form, function(tipologia) {

				var intestazione_size = _.first(_.filter(_.map(this.persone, function(i) { 
					if(!i.stats.fase_form[tipologia]) return false;
					return i.stats.fase_form[tipologia][0].intestazione
				})));

				if(!intestazione_size) return false;
				var maxTipologia = _.maxBy(h, tipologia)[tipologia];

				intestazione[tipologia] = { 
					noResults: maxTipologia,
					columns: maxTipologia * _.size(intestazione_size),
					intestazione: intestazione_size
				};

			}.bind(this));
			

			return intestazione;

		}
	},
	methods: {
		colorOf: function(k) {

			return this.playerFasiDato.tipologieSessione[k].colore;

		},
		createHeading: function(colonna) {

			var nomeCarino = colonna.split('|')[3];
			var posizione = parseInt(colonna.split('|')[1].split('_').splice(-1)[0]);

			if(posizione == 0) return nomeCarino;
			return nomeCarino + ' ' + (posizione+1)

		},
		typeOfDato: function(colonna) {
			return 'SessioneMatrice' + _.capitalize(colonna.type)
		},
		stampaColonna: function(p, colonna) {
			
			var tipologia = colonna.split('|')[0];
			var tipoForm = colonna.split('|')[1].split('_');
			var etichetta = colonna.split('|')[2];
			var tipoFormIndex = _.last(tipoForm);
			tipoForm.splice(-1);
			var tipoForm = tipoForm.join('_');

			

			if(!p.griglia[tipologia]) return false;
			if(!_.filter(p.griglia[tipologia], {tipologia: tipoForm})) return false;
			if(!_.filter(p.griglia[tipologia], {tipologia: tipoForm})[tipoFormIndex]) return false;

			var intestazione = _.filter(p.griglia[tipologia], {tipologia: tipoForm})[tipoFormIndex].valori.intestazione[etichetta];
			var valori = _.filter(p.griglia[tipologia], {tipologia: tipoForm})[tipoFormIndex].valori[etichetta];

			return {intestazione, valori};

			if(!p.stats) return false;
			if(!p.stats.fase_form[colonna.fase_form]) return false;
			if(!p.stats.fase_form[colonna.fase_form][colonna.index]) return false;
			//return colonna.label;
			return p.stats.fase_form[colonna.fase_form][colonna.index][colonna.key];

		}
	},
	mounted: function() {

		// $('[full]', this.$el).each(function() {
		// 	var altezza = $(this).parent().parent().height();
		// 	$('div', this).height(altezza);
		// 	//console.log(altezza);
		// });

	}
}
</script>