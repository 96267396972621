<template>
	<div v-if="sessione_tipi">
		<b-list-group>
			<b-list-group-item v-for="p in entity.pack" @click="do_swap(p)" :style="'background-color: ' + p.CalendarObject.colore">
				<b-form-checkbox style="display: inline" v-if="ux.delete" type="checkbox" v-model="selected" :value="p.id" />
				<strong>{{ p.CalendarObject.inizio | dt }}</strong> {{ p.CalendarObject.nome }}
			</b-list-group-item>
		</b-list-group>

		<div v-if="ux.delete == false" class="mt-2">
			<b-button variant="outline-secondary" @click.prevent="ux.delete = true">Azioni Massive</b-button>
		</div>
		<div v-else class="mt-2">
			<b-button-group size="sm">
				<b-button class="mr-0" variant="outline-secondary" @click.prevent="selected = _.map(entity.pack, 'id')">Seleziona Tutti</b-button>
				<b-button class="mr-0" variant="outline-secondary" @click.prevent="selected = []">Deseleziona Tutti</b-button>
				<b-button class="mr-0" variant="outline-danger" @click.prevent="deleteSelected" :disabled="selected.length == 0">Elimina Selezionati</b-button>
			</b-button-group>
			<b-button size="xs" class="float-right" variant="secondary" @click.prevent="ux.delete = false">Anulla</b-button>
		</div>
	</div>
</template>

<script>

import _ from 'lodash'
import moment from 'moment'
var Auth = require('../../../auth.js');
import * as types from '@/store/types'
import { mapState } from 'vuex';

export default {
	props: [ 'entity', 'player_options', 'team', 'date' ],
	created: function() {
		this.getTipiSessione();
	},
	computed: {
		_: function() {
			return _;
		},
		selectedIds: function() {
			return this.selected;
			return _.map(this.entity.pack, 'id');
		},
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
		entityPayload: function() {

			var data = moment(this.model.inizio).format('YYYY-MM-DD');
			
			var cp = _.cloneDeep(this.model);
			delete cp.CalendarObject;

			cp.persone = this.persone;
			cp.inizio = data + ' ' + this.from_hour;
			cp.fine = data + ' ' + this.to_hour;

			return cp;

		},
		user: function() {
			return Auth.user;
		}
	},
	watch: {
		'entity': {
			deep: true,
			handler: function() {

				if(this.entity == null) {
					this.initModel();
				} else {
					this.model = _.cloneDeep(this.entity);
				}

				this.createPersone();
				this.createTimes();
			},
			immediate: true
		}
	},
	methods: {
		deleteSelected: function() {

			if(!confirm('Confermi la rimozione di ' + this.selectedIds.length + ' sessioni?'))
				return false;

			this.$http.post(this.$store.state.apiEndPoint + '/sessioni/delete-multiple', { ids: this.selectedIds }).then(function(response) {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.closeModal();
				}
			}, function(response) {
				this.errors = response.data;
				for (var index in this.errors) {
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
				}
			})

		},
		do_swap: function(a) {
			if(this.ux.delete == false)
				this.$emit('swap', { base: a });
			else {
				if(this.selected.indexOf(a.id) === -1)
					this.selected.push(a.id);
				else
					this.selected.splice(this.selected.indexOf(a.id), 1);
			}
		},
		createTimes: function() {

			this.from_hour = moment(this.model.inizio).format('HH:mm')
			this.to_hour = moment(this.model.fine).format('HH:mm')

		},
		createPersone: function() {
			this.persone = _.map(this.model.persone, function(persona) {
				return {
					name: persona.cognome + ' ' + persona.nome,
					id: persona.id
				}
			});
		},
		getTipiSessione() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessione/tipi').then((response) => {
				if (response.ok)
				{
					this.sessione_tipi = response.data;
					for(var i in this.sessione_tipi) {
						this.levels.push(this.sessione_tipi[i].id);
					}
				}
			}, (response) => {
			});
		},
		closeModal: function() {
			this.$emit('close');
		},
		handleOk (evt, action) {
			evt.preventDefault();
			this.handleSubmit(action);
		},
		update(data, id){
			this.$http.post(this.$store.state.apiEndPoint + '/sessione/update/' + id, data).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.closeModal();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		create(action){
			
			this.$http.post(this.$store.state.apiEndPoint + '/sessione', this.entityPayload).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.closeModal();
					if(action == 'edit') {
						this.$router.push({ name: 'medical_sessione', params: { id: response.data.sessione_id, tipo: l }});
					}
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		eliminaSessione() {
			if(confirm("Confermi l'eliminazione della sessione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/sessione/delete/' + this.entityPayload.id).then((response) => {

					this.refreshEvents();
					this.closeModal();

				}, (response) => {
				});
			}
		},
		refreshEvents: function() {
			this.$emit('refresh');
		},
		handleSubmit (action = 'close') {

			if(this.entityPayload.sessione_tipo_glo_id == 0) {
				alert("Devi selezionare una tipologia di sessione!");
				return;
			}
			if(this.entityPayload.persone.length == 0) {
				alert("Devi selezionare almeno un giocatore della rosa!");
				return;
			}
			if(!this.entityPayload.id){
				this.create(action);
			} else {
				if(Auth.isLastSeason(this.entityPayload.stagione_id)) {
					this.update(this.entityPayload,  this.entityPayload.id);
				} else {
					this.closeModal();
				}
			}
			
		},
		initModel: function() {
			this.model = {
				team_id: this.team.id,
				categoria_id: this.team.id_categoria,
				inizio: moment(this.date).add({'hour': 9}).format('YYYY-MM-DD HH:mm:ss'),
				fine: moment(this.date).add({'hour': 10}).format('YYYY-MM-DD HH:mm:ss'),
				nome: null,
				persone: [],
				sessione_tipo_glo_id: null,
				stagione_id: this.seasonId,
			};
		}
	},
	filters: {
		dt: function(a) {
			return moment(a).format('HH:mm');
		}
	},
	data: function() {
		return {
			model: false,
			from_hour: null,
			to_hour: null,
			persone: null,
			levels: [],
			sessione_tipi: null,
			selected: [],
			ux: {
				delete: false,
				creating: false
			}
		}
	}
};

</script>