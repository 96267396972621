<template>
	<div>

		<form @submit.stop.prevent="handleSubmit">
			<SessioneIntestazione @refresh="$emit('refresh')" :illnesses="illnesses" :injuries="injuries" :playersPreset="playersPreset" :sessione="sessione" :tipoSessione="sessioneCorrente" :persone="playerOptions"></SessioneIntestazione>
			
			<b-row class="my-2">
				<b-col>
					<b-button size="xs" variant="primary" class="mr-0" @click.prevent="ux.showVideo = !ux.showVideo"><i :class="'fa fa-arrow-' + (ux.showVideo ? 'up' : 'down')"></i> Video ({{ countVideo }})</b-button>
				</b-col>
				<b-col class="text-right">
					<b-button-group>
						<b-button size="xs" class="mr-0" @click.prevent="expandAll">Espandi Tutto</b-button>
						<b-button size="xs" class="mr-0" @click.prevent="collapseAll">Collassa Tutto</b-button>
					</b-button-group>
				</b-col>
			</b-row>
			<VideoManager :collapsed="!ux.showVideo" entity="Sessione" :entityId="sessione.id" :readonly="!isManagerSessione" :personeIds="personeIds" @countVideo="updateCountVideo" class="mb-2" :id="$route.params.id" :tipo="$route.params.tipo"></VideoManager>

			<Fase 
				ref="faseRef" 
				class="mb-2" 
				@sposta="sposta(k, $event)" 
				@remove="removeFase(k)" 
				@duplica="duplicaFase(k)" 
				:sessioneCorrente="sessioneCorrente" 
				:index="k+1" 
				:fase="fase" 
				:injuries="injuries" 
				:illnesses="illnesses" 
				:isManagerSessione="isManagerSessione" 
				:estensioniFase="estensioniFase"
				:disabled="isPhaseDisabled(fase)"
				v-for="fase, k in sessione.fasi" :sessione="sessione" 
			>
				<div :is="fase.entita" :disabled="isPhaseDisabled(fase)" :sessioneCorrente="sessioneCorrente"  :sessione="sessione" :fase="fase" :glossario="glossario" :isManagerSessione="isManagerSessione"></div>
			
			</Fase>

			<b-button-group class="mt-3" v-if="isManagerSessione">
				<b-button v-if="canAddPhase" class="mr-0" variant="outline-primary" @click="showAddFasi"><i class="fa fa-plus fa-fw"></i> Aggiungi Fase</b-button>
				<b-button ref="submit" type="submit" variant="primary" v-if="auth.isLastSeason(seasonId)" class="text-uppercase ml-0"><strong>Salva</strong></b-button>
			</b-button-group>

		</form>

	</div>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';
import sessionFasiMixin from '@/mixins/sessioneFasi.js';

import axios from 'axios';
import moment from 'moment'

import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	props: [ 'sessione', 'playersPreset', 'playerOptions', 'sessioneTipi', 'seasonId', 'fasiGeneriche', 'estensioniFase', 'team' ],
	name: 'Sessione',
	mixins: [
		sessionMixin,
		sessionFasiMixin,
	],
	data: function(){
		return{
			ux: {
				newFase: false,
				showMatrice: false,
				showVideo: false
			},
			injuries: [],
			illnesses: [],
			countVideo: 0,
		}
	},
	methods: {
		expandAll: function() {
			_.each(this.$refs.faseRef, function(fase) {
				fase.visible = true;
			})
		},
		collapseAll: function() {
			_.each(this.$refs.faseRef, function(fase) {
				fase.visible = false;
			})
		},
		getInjuriesByPlayers(player_ids) {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/injury/list/players', {params: { player_ids: player_ids }}).then((response) => {
				if (response.ok) {
					this.injuries = response.data.map(item => ({	
						id: item.id,							
						data: item.data,
						pathology: item.pathology,
						persona_id: item.persona_id
					}));
				}
			}, (response) => {});
		},
		getIllnessesByPlayers(player_ids) {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/illness/list/players', {params: { player_ids: player_ids }}).then((response) => {
				if (response.ok) {
					this.illnesses = response.data.map(item => ({	
						id: item.id,							
						data: item.data,
						illness_types: item.illness_types,
						illness_other_types: item.illness_other_types,
						persona_id: item.persona_id
					}));
				}
			}, (response) => {});
		},
		handleSubmit: function () {
			this.$emit('submit');
		},
		updateCountVideo: function(count) {
			this.countVideo = count;
		}
	},
	watch: {
		'sessione.players': {
			deep: true,
			immediate: true,
			handler: function() {

				var utentiPresenti = _.map(this.sessione.players, 'id');
				_.each(this.sessione.fasi, function(fase) {
					_.each(fase.players, function(singola, k) {
						if(utentiPresenti.indexOf(singola.id) === -1)
							fase.players.splice(k, 1);
					});
				});

				this.getInjuriesByPlayers(utentiPresenti);
				this.getIllnessesByPlayers(utentiPresenti);

			}
		},
		rosaTeamId() {
			this.getPlayers();
		}, 
	}
}
</script>