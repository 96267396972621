module.exports = {
    getRoutes()
    {
        var ROUTES_ALL = [];

        ROUTES_ALL = ROUTES_ALL.concat(
            [
                {
                    path: '/',
                    redirect: '/dashboard',
                },

                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: require('../components/pages/dashboard.vue').default,
                    meta: {
                        module: '',
                        label: 'Dashboard',
                        requiresAuth: true,
                        showInMenu: false,
                        parent: '',
                    }
                },
                {
                    path: '/test',
                    name: 'test',
                    component: require('../components/pages/test.vue').default,
                    meta: {
                        module: '',
                        label: 'Test',
                        requiresAuth: true,
                        showInMenu: false,
                        parent: '',
                    }
                },

                {
                    path: '/login',
                    name: 'login',
                    component: require('../components/pages/login.vue').default,
                    meta: {
                        module: '',
                        label: 'Login',
                        requiresAuth: false,
                        showInMenu: false,
                        parent: '',
                    }
                },
                {
                    path: '/profilo',
                    name: 'profilo',
                    component: require('../components/pages/profilo.vue').default,
                    meta: {
                        module: '',
                        label: 'Profilo',
                        requiresAuth: true,
                        showInMenu: false,
                        parent: '',
                        menusub: [ 'admin_utenti', 'admin_gruppi', 'admin_permessi', 'admin_squadre_referenti', 
                        'admin_agenti', 'admin_logs', 'admin_skills_ruoli'],
                    }
                },
                {
                    path: '*',
                    name: 'not_found',
                    component: require('../components/pages/not-found.vue').default,
                    meta: {
                        module: '',
                        label: 'Pagina non trovata',
                        requiresAuth: false,
                        showInMenu: false,
                        parent: '',
                    }
                },

            ],

            require('./football.js').ROUTES_FOOTBALL,
            require('./scouting.js').ROUTES_SCOUTING,
            require('./geomatch.js').ROUTES_GEOMATCH,
            require('./videotag.js').ROUTES_VIDEOTAG,
            require('./admin.js').ROUTES_ADMIN,
            require('./romanext.js').ROUTES_ROMANEXT,
            require('./prestiti.js').ROUTES_PRESTITI,
            require('./camera.js').ROUTES_CAMERA,
            require('./stats.js').ROUTES_STATS,
            require('./performance.js').ROUTES_PERFORMANCE,
            require('./academy.js').ROUTES_ACADEMY,
            require('./analysis.js').ROUTES_ANALYSIS, 
            require('./academy_ita.js').ROUTES_ACADEMY_ITA,
            require('./vision.js').ROUTES_VISION,   
            require('./romalab.js').ROUTES_ROMALAB,
            require('./usascout.js').ROUTES_USASCOUT,
            require('./medical.js').ROUTES_MEDICAL, 
            require('./newscout.js').ROUTES_NEWSCOUT,
            require('./youngscout.js').ROUTES_YOUNGSCOUT, 
            require('./youthscout.js').ROUTES_YOUTHSCOUT,   
            require('./academy_int.js').ROUTES_ACADEMY_INT, 
            require('./playeraccess.js').ROUTES_PLAYER_ACCESS, 
            require('./whiteboard.js').ROUTES_WHITEBOARD,  
            require('./filebrowser.js').ROUTES_FILEBROWSER,  
            require('./reports.js').ROUTES_REPORTS,                 
            require('./sessioni.js').ROUTES_SESSIONS,                 
            require('./contratti.js').ROUTES_CONTRATTI,                 
            require('./next-scout.js').ROUTES_NEXTSCOUT,                 
        );

        return ROUTES_ALL;
    }
}
