export const ROUTES_SCOUTING = [
    {
        path: '/scouting/dashboard',
        name: 'scouting_dashboard',
        component: require('../components/pages/scouting/archivio_giocatori.vue').default,
        meta: {
            module: 'scouting',
            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scouting',
        }
    },
    {
        path: '/scouting/nuovo/giocatore',
        name: 'scouting_nuovo_giocatore',
        component: require('../components/pages/scouting/nuovo_giocatore.vue').default,
        meta: {
            module: 'scouting',
            label: 'Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout_add_player',
        }
    },
    {
        path: '/scouting/view/giocatore/:id',
        name: 'scouting_view_giocatore',
        component: require('../components/pages/scouting/view_giocatore.vue').default,
        meta: {
            module: 'scouting',
            label: 'Scheda Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'scouting_nuova_valutazione',
            permesso: 'scouting',
        }
    },
    {
        path: '/scouting/edit/giocatore/:id',
        name: 'scouting_edit_giocatore',
        component: require('../components/pages/scouting/edit_giocatore.vue').default,
        meta: {
            module: 'scouting',
            label: 'Modifica Anagrafica',
            requiresAuth: true,
            showInMenu: false,
            parent: 'scouting_view_giocatore',
            menusub: ['scouting_view_giocatore'],
            permesso: 'scout_edit_player',
        }
    },
    {
        path: '/scouting/edit/valutazione/:id/:val_id',
        name: 'scouting_edit_valutazione',
        component: require('../components/pages/scouting/edit_valutazione.vue').default,
        meta: {
            module: 'scouting',
            label: 'Modifica Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'scouting_view_giocatore',
            menusub: ['scouting_view_giocatore', 'scouting_nuova_valutazione'],
            permesso: 'scout_edit_val',
        }
    },
    {
        path: '/scouting/nuova/valutazione/:id',
        name: 'scouting_nuova_valutazione',
        component: require('../components/pages/scouting/nuova_valutazione.vue').default,
        meta: {
            module: 'scouting',
            label: 'Nuova Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'scouting_view_giocatore',
            menusub: ['scouting_view_giocatore'],
            permesso: 'scout_add_val',
        }
    },
    {
        path: '/scouting/view/valutazione/:id/:val_id',
        name: 'scouting_view_valutazione',
        component: require('../components/pages/scouting/view_valutazione.vue').default,
        meta: {
            module: 'scouting',
            label: 'Valutazione Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'scouting_view_giocatore',
            menusub: ['scouting_view_giocatore'],
            permesso: 'scouting',
        }
    },
    {
        path: '/scouting/agenda',
        name: 'scouting_agenda',
        component: require('../components/pages/scouting/agenda.vue').default,
        meta: {
            module: 'scouting',
            label: 'Agenda',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_agenda',
        }
    },
    {
        path: '/scouting/partite',
        name: 'scouting_partite',
        component: require('../components/pages/scouting/partite.vue').default,
        meta: {
            module: 'scouting',
            label: 'Partite',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_partite',
        }
    },
    {
        path: '/scouting/videotag/giocatore/:id',
        name: 'scouting_videotag_giocatore',
        component: require('../components/pages/scouting/videotag_giocatore.vue').default,
        meta: {
            module: 'scouting',
            label: 'Videotag Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'scouting_view_giocatore',
            permesso: 'scout_videotag',
        }
    },
    {
        path: '/scouting/partite/giocatore/:id',
        name: 'scouting_partite_giocatore',
        component: require('../components/pages/scouting/partite_giocatore.vue').default,
        meta: {
            module: 'scouting',
            label: 'Partite Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'scouting_view_giocatore',
            permesso: 'scout_partite',
        }
    },
    {
        path: '/scouting/osservatori',
        name: 'scouting_osservatori',
        component: require('../components/pages/scouting/osservatori.vue').default,
        meta: {
            module: 'scouting',
            label: 'Osservatori',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_utenti',        
        }
    },
    {
        path: '/scouting/osservatore/paesi/:id',
        name: 'scouting_osservatore_paesi',
        component: require('../components/pages/scouting/osservatore_paesi.vue').default,
        meta: {
            module: 'scouting',
            label: 'Paesi',
            requiresAuth: true,
            showInMenu: false,
            parent: 'scouting_osservatori',
            permesso: 'scout_utenti',
            menusub: ['scouting_osservatore_paesi', 'scouting_osservatore_liste', 'scouting_osservatore_km'],         
        }
    },
    {
        path: '/scouting/osservatore/liste/:id',
        name: 'scouting_osservatore_liste',
        component: require('../components/pages/scouting/osservatore_liste.vue').default,
        meta: {
            module: 'scouting',
            label: 'Liste',
            requiresAuth: true,
            showInMenu: false,
            parent: 'scouting_osservatori',
            permesso: 'scout_utenti',  
            menusub: ['scouting_osservatore_paesi', 'scouting_osservatore_liste', 'scouting_osservatore_km'],   
        }
    },
    {
        path: '/scouting/osservatore/km/:id',
        name: 'scouting_osservatore_km',
        component: require('../components/pages/scouting/osservatore_km.vue').default,
        meta: {
            module: 'scouting',
            label: 'Kilometri',
            requiresAuth: true,
            showInMenu: false,
            parent: 'scouting_osservatori',
            permesso: 'scout_km',  
            menusub: ['scouting_osservatore_paesi', 'scouting_osservatore_liste', 'scouting_osservatore_km'],   
        }
    },
    {
        path: '/scouting/statistiche',
        name: 'scouting_statistiche',
        component: require('../components/pages/scouting/statistiche.vue').default,
        meta: {
            module: 'scouting',
            label: 'Statistiche',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_stats',        
        }
    },
    {
        path: '/scouting/squadre',
        name: 'scouting_squadre',
        component: require('../components/pages/scouting/squadre.vue').default,
        meta: {
            module: 'scouting',
            label: 'Squadre',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            permesso: 'scout_squadre',    
        }
    },
    {
        path: '/scouting/view/squadra/:id',
        name: 'scouting_view_squadra',
        component: require('../components/pages/scouting/view_squadra.vue').default,
        meta: {
            module: 'scouting',
            label: 'Scheda Squadra',
            requiresAuth: true,
            showInMenu: true,
            permesso: 'scout_squadre',
        }
    },
    {
        path: '/scouting/squadre/nuova',
        name: 'scouting_squadre_new',
        component: require('../components/pages/scouting/new_squadra.vue').default,
        meta: {
            module: 'scouting',

            label: 'Nuova Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'scouting_squadre',
            permesso: 'scout_squadre',
        }
    },

    {
        path: '/scouting/squadre/edit/:id',
        name: 'scouting_squadre_edit',
        component: require('../components/pages/scouting/edit_squadra.vue').default,
        meta: {
            module: 'scouting',
            label: 'Modifica Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'scouting_view_squadra',
            permesso: 'scout_squadre',
        }
    },

    {
        path: '/scouting/view/allenatore/:id',
        name: 'scouting_view_allenatore',
        component: require('../components/pages/scouting/view_allenatore.vue').default,
        meta: {
            module: 'scouting',
            label: 'Scheda Allenatore',
            requiresAuth: true,
            showInMenu: true,
            permesso: 'scout_squadre',
        }
    },
    {
        path: '/scouting/costi',
        name: 'scouting_costi',
        component: require('../components/pages/scouting/costi.vue').default,
        meta: {
            module: 'scouting',
            label: 'Costi',
            requiresAuth: true,
            showInMenu: true,
            parent: '',  
            permesso: 'scout_costi',    
        }
    },
    {
        path: '/scouting/appunti/:id',
        name: 'scouting_appunti',
        component: require('../components/pages/scouting/appunti.vue').default,
        meta: {
            module: 'scouting',
            label: 'Appunti',
            requiresAuth: true,
            showInMenu: false,
            parent: 'scouting_view_giocatore',
            menusub: ['scouting_view_giocatore'],
            permesso: 'scout_appunti',
        }
    },
];
