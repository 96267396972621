<template id="rosa">
	<section class="rosa">

		<headapp></headapp>

		<template id="menusub">
			<div class="menusub">
				<div class="card">
					<div class="card-body">
						<ul class="list-inline m-0">
							<li class="list-inline-item" v-if="team">
								<b-button variant="outline-primary" :to="{ name: `football_giocatore` }" v-if="auth.isLastSeason(seasonId) && auth.isUserEnable('football_rosa_mng')"><i class="fa fa-plus fa-fw"></i> aggiungi giocatore</b-button>
							</li>
							<div class="pull-right" v-if="team && team.wyscout && (auth.isAdmin() || auth.isAdminFootball()) && auth.isLastSeason(seasonId)">
								<b-button variant="outline-primary" @click="sync" :disabled="loading"><i class="fa fa-refresh fa-fw"></i> sync da wyscout</b-button>
							</div>
							<div class="pull-right mr-3" v-if="team && (auth.isAdmin() || auth.isAdminFootball() || auth.isSegretario()) && auth.isLastSeason(seasonId)">
								<b-button variant="outline-primary" @click="selectTeam"><i class="fa fa-arrow-up"></i> importa da stagione precente</b-button>
							</div>
							<div class="pull-right mr-3" v-if="team && auth.isLastSeason(seasonId) && auth.isUserEnable('rosa_tab_generali')">
								<b-button variant="outline-primary" @click="pdfListaContatti"><i class="fa fa-file-pdf-o"></i> stampa contatti</b-button>
							</div>
						</ul>
					</div>
				</div>
			</div>
		</template>

		<div class="card">
			<div class="card-body">
				<div class="content">
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
					<card v-if="team && render_player && !loading" comp="elencogiocatori" class="border-0 rounded-0"></card>
				</div>
			</div>
		</div>

		<b-modal id="selectTeamModal" ref="selectTeamModal" :title="'Seleziona squadra ' + user.seasons[1].stagione" ok-only ok-title="Chiudi" size="lg">
			<div>
				<b-row class="mb-3 mx-3">
					<b-col sm="6">
						<b-form-select v-model="teamIdSel">
							<option value="0">-- Categoria --</option>
							<option v-for="item in user.rosaTeams" :value="item.id" :key="item.id">{{ item.categoria.name }}</option>
						</b-form-select>
					</b-col>
					<b-col sm="6" v-if="players.length > 0" class="text-right">
						<b-button variant="primary" class="mr-1" @click="selectAll">Seleziona tutti</b-button>
						<b-button variant="primary" @click="unselectAll">Deseleziona tutti</b-button>
					</b-col>
				</b-row>
				<div v-if="loadingPlayers" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
				<div style="height: 400px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
					<b-row>
						<b-col v-for="player in players" cols="6" class="p-1">
							<b-card :bg-variant="players_ids.indexOf(player.id) > -1 ? 'warning' : 'default'">
								<b-row align-v="center">
									<b-col cols="2">
										<b-form-checkbox v-model="players_ids" :value="player.id"></b-form-checkbox>
									</b-col>
									<b-col cols="3">
										<img :src="player.avatar" height="50"/>
									</b-col>
									<b-col>
										<span>{{ player.name }} </span><br/>
										<span class="font-size-xs color-rosso">{{ player.ruolo }}</span>
									</b-col>
								</b-row>
							</b-card>
						</b-col>
					</b-row>					
				</div>
				<b-row class="mt-3 mx-3">
					<b-col v-if="players_ids.length > 0">
						<b-button variant="primary" class="mr-1" @click.prevent="importPlayers">Importa sulla stagione {{ user.seasons[0].stagione }} della squadra {{ team.categoria.name }}</b-button>
					</b-col>
				</b-row>
			</div>
		</b-modal>


		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../../auth.js');
import axios from 'axios';
import * as types from '../../../../store/types';
import { mapState } from 'vuex';
export default {
	data: function(){
		return {
			team: null,
			loading: false,
			loadingPlayers: false,
			errors: [],
			render_player: true,
			teamIdSel: 0,
			players: [],
			players_ids: [],
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.get_team();
		},
		teamIdSel() {
			this.getPlayers();
		},
	},
	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		card:               require('../../../layout/card.vue').default,
	},
	created: function () {
		this.get_team();
	},
	methods: {
		rerender() {
			this.render_player = false
			this.$nextTick(() => {
				this.render_player = true
				console.log('re-render start')
				this.$nextTick(() => {
					console.log('re-render end')
				})
			})
		},
		get_team() {
			if(this.rosaTeamId){
				axios.get(`/squadre/get/` + this.rosaTeamId)
				.then((res) => {
					this.team = res.data;
				}).catch((err) => {
				});
			}
		},
		sync() {	
			if(confirm('Confermi la sincronizzazione della squadra con Wyscout ?')) {		
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/opponents/ws/get/players/by/team/' + this.team.wyscout.chiave + '/' + this.team.id + '/' + this.team.id_categoria + '/rosa').then((response) => {
					if (response.ok)
					{
						this.wy_players = response.data;
						this.loading = false;
						this.rerender();
					}
				}, (response) => {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				});
			}
		},
		selectTeam: function() {
			this.teamIdSel = 0;
			this.players = [];
			this.players_ids =[];			
			this.$refs.selectTeamModal.show();
		},
		getPlayers: function() {		
			this.players = [];
			if(this.teamIdSel > 0) {
				this.loadingPlayers = true;
				this.$http.get(this.$store.state.apiEndPoint + '/rosa/get/players', {params: { team_id: this.teamIdSel, season_id: this.user.seasons[1].id, no_stats: true }}).then((response) => {
					if (response.ok)
					{
						if(response.data.players) {
							this.players = response.data.players.map(item => ({	
								id: item.id,							
								name:  item.nome + ' ' + item.cognome,
								ruolo: item.last_carriera_sportiva.ruolo_ideale && item.last_carriera_sportiva.ruolo_ideale.length > 0 ? item.last_carriera_sportiva.ruolo_ideale[0].nome : '',
								avatar: item.avatar_url,
							}));
						}
					}
					this.loadingPlayers = false;
				}, (response) => {
				});
			}
		},
		selectAll() {
			this.players_ids = [];
			for(var i in this.players) {
				this.players_ids.push(this.players[i].id);
			}				
		},
		unselectAll() {
			this.players_ids = [];
		},
		importPlayers() {
			if(confirm("Confermi l'import dei giocatori selezionati ?")) {			
				this.loading = true;
				this.$refs.selectTeamModal.hide();
				this.$http.get(this.$store.state.apiEndPoint + '/rosa/import/players', {params: { players_ids: this.players_ids, team_id: this.team.id, season_id: this.user.seasons[0].id }}).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.loading = false;
						this.rerender();
					}
				}, (response) => {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				});
			}
		},
		pdfListaContatti() {
			window.open(this.$store.state.apiEndPoint + '/football/pdf/lista/contatti/' + this.rosaTeamId + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
	}
}
</script>
