<template>
	<div class="text-center font-weight-bold">
		{{ fms }}
	</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	created: function() {


	},
	methods: {
		
	},
	watch: {
		dataLayer: {
			deep: true,
			handler: function() {

			}
		},
		fms: {
			immediate: true,
			handler: function() {
				this.test[this.field] = this.fms;
			}
		},
	},
	computed: {
		prefix: function() {
			if(this.field == 'fms') {
				return '';
			}
			return this.field.replace('fms', '');

		},
		deep_squat_fms: function() {
			if(this.test[this.prefix + 'deep_squat_score'] != null && !isNaN(this.test[this.prefix + 'deep_squat_score']) && this.test[this.prefix + 'deep_squat_score'] >=0 && this.test[this.prefix + 'deep_squat_score'] <=3) {
				return this.test[this.prefix + 'deep_squat_score'];
			}
			return 0;
		},
		hurdle_step_fms: function() {
			if(this.test[this.prefix + 'hurdle_step_l_score'] != null && !isNaN(this.test[this.prefix + 'hurdle_step_l_score']) && this.test[this.prefix + 'hurdle_step_l_score'] >=0 && this.test[this.prefix + 'hurdle_step_l_score'] <=3 && this.test[this.prefix + 'hurdle_step_r_score'] != null && !isNaN(this.test[this.prefix + 'hurdle_step_r_score']) && this.test[this.prefix + 'hurdle_step_r_score'] >=0 && this.test[this.prefix + 'hurdle_step_r_score'] <=3) {
				if(this.test[this.prefix + 'hurdle_step_l_score'] > this.test[this.prefix + 'hurdle_step_r_score']) {
					return this.test[this.prefix + 'hurdle_step_r_score'];
				}
				return this.test[this.prefix + 'hurdle_step_l_score'];
			}
			return 0;
		},
		inline_lounge_fms: function() {
			if(this.test[this.prefix + 'inline_lounge_l_score'] != null && !isNaN(this.test[this.prefix + 'inline_lounge_l_score']) && this.test[this.prefix + 'inline_lounge_l_score'] >=0 && this.test[this.prefix + 'inline_lounge_l_score'] <=3 && this.test[this.prefix + 'inline_lounge_r_score'] != null && !isNaN(this.test[this.prefix + 'inline_lounge_r_score']) && this.test[this.prefix + 'inline_lounge_r_score'] >=0 && this.test[this.prefix + 'inline_lounge_r_score'] <=3) {
				if(this.test[this.prefix + 'inline_lounge_l_score'] > this.test[this.prefix + 'inline_lounge_r_score']) {
					return this.test[this.prefix + 'inline_lounge_r_score'];
				}
				return this.test[this.prefix + 'inline_lounge_l_score'];
			}
			return 0;
		},
		shoulder_mobility_fms: function() {
			if(this.test[this.prefix + 'shoulder_mobility_l_score'] != null && !isNaN(this.test[this.prefix + 'shoulder_mobility_l_score']) && this.test[this.prefix + 'shoulder_mobility_l_score'] >=0 && this.test[this.prefix + 'shoulder_mobility_l_score'] <=3 && this.test[this.prefix + 'shoulder_mobility_r_score'] != null && !isNaN(this.test[this.prefix + 'shoulder_mobility_r_score']) && this.test[this.prefix + 'shoulder_mobility_r_score'] >=0 && this.test[this.prefix + 'shoulder_mobility_r_score'] <=3 && this.test[this.prefix + 'shoulder_mobility_pain'] != null && !isNaN(this.test[this.prefix + 'shoulder_mobility_pain']) && (this.test[this.prefix + 'shoulder_mobility_pain'] == 0 || this.test[this.prefix + 'shoulder_mobility_pain'] == 1)) {
				if(this.test[this.prefix + 'shoulder_mobility_l_score'] > this.test[this.prefix + 'shoulder_mobility_r_score']) {
					return (this.test[this.prefix + 'shoulder_mobility_r_score'] * this.test[this.prefix + 'shoulder_mobility_pain']);
				}
				return (this.test[this.prefix + 'shoulder_mobility_l_score'] * this.test[this.prefix + 'shoulder_mobility_pain']);
			}
			return 0;
		},
		active_straight_leg_raise_fms: function() {
			if(this.test[this.prefix + 'active_straight_leg_raise_l_score'] != null && !isNaN(this.test[this.prefix + 'active_straight_leg_raise_l_score']) && this.test[this.prefix + 'active_straight_leg_raise_l_score'] >=0 && this.test[this.prefix + 'active_straight_leg_raise_l_score'] <=3 && this.test[this.prefix + 'active_straight_leg_raise_r_score'] != null && !isNaN(this.test[this.prefix + 'active_straight_leg_raise_r_score']) && this.test[this.prefix + 'active_straight_leg_raise_r_score'] >=0 && this.test[this.prefix + 'active_straight_leg_raise_r_score'] <=3) {
				if(this.test[this.prefix + 'active_straight_leg_raise_l_score'] > this.test[this.prefix + 'active_straight_leg_raise_r_score']) {
					return this.test[this.prefix + 'active_straight_leg_raise_r_score'];
				}
				return this.test[this.prefix + 'active_straight_leg_raise_l_score'];
			}
			return 0;
		},
		trunk_stability_pushup_fms: function() {
			if(this.test[this.prefix + 'trunk_stability_pushup_score'] != null && !isNaN(this.test[this.prefix + 'trunk_stability_pushup_score']) && this.test[this.prefix + 'trunk_stability_pushup_score'] >=0 && this.test[this.prefix + 'trunk_stability_pushup_score'] <=3 && this.test[this.prefix + 'trunk_stability_pushup_pain'] != null && !isNaN(this.test[this.prefix + 'trunk_stability_pushup_pain']) && (this.test[this.prefix + 'trunk_stability_pushup_pain'] == 0 || this.test[this.prefix + 'trunk_stability_pushup_pain'] == 1)) {
				return (this.test[this.prefix + 'trunk_stability_pushup_score'] * this.test[this.prefix + 'trunk_stability_pushup_pain']);
			}
			return 0;
		},
		rotary_stability_fms: function() {
			if(this.test[this.prefix + 'rotary_stability_l_score'] != null && !isNaN(this.test[this.prefix + 'rotary_stability_l_score']) && this.test[this.prefix + 'rotary_stability_l_score'] >=0 && this.test[this.prefix + 'rotary_stability_l_score'] <=3 && this.test[this.prefix + 'rotary_stability_r_score'] != null && !isNaN(this.test[this.prefix + 'rotary_stability_r_score']) && this.test[this.prefix + 'rotary_stability_r_score'] >=0 && this.test[this.prefix + 'rotary_stability_r_score'] <=3 && this.test[this.prefix + 'rotary_stability_pain'] != null && !isNaN(this.test[this.prefix + 'rotary_stability_pain']) && (this.test[this.prefix + 'rotary_stability_pain'] == 0 || this.test[this.prefix + 'rotary_stability_pain'] == 1)) {
				if(this.test[this.prefix + 'rotary_stability_l_score'] > this.test[this.prefix + 'rotary_stability_r_score']) {
					return (this.test[this.prefix + 'rotary_stability_r_score'] * this.test[this.prefix + 'rotary_stability_pain']);
				}
				return (this.test[this.prefix + 'rotary_stability_l_score'] * this.test[this.prefix + 'rotary_stability_pain']);
			}
			return 0;
		},
		fms: function() {
			var fms = 0;
			if(!isNaN(this.deep_squat_fms)) {
				fms += parseInt(this.deep_squat_fms);
			}
			if(!isNaN(this.hurdle_step_fms)) {
				fms += parseInt(this.hurdle_step_fms);
			}
			if(!isNaN(this.inline_lounge_fms)) {
				fms += parseInt(this.inline_lounge_fms);
			}
			if(!isNaN(this.shoulder_mobility_fms)) {
				fms += parseInt(this.shoulder_mobility_fms);
			}
			if(!isNaN(this.active_straight_leg_raise_fms)) {
				fms += parseInt(this.active_straight_leg_raise_fms);
			}
			if(!isNaN(this.trunk_stability_pushup_fms)) {
				fms += parseInt(this.trunk_stability_pushup_fms);
			}
			if(!isNaN(this.rotary_stability_fms)) {
				fms += parseInt(this.rotary_stability_fms);
			}
			return fms;
		},		
	}
}

</script>