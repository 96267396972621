<template>
	<div>
		<b-modal size="full" :title="hasSetup(sessione, 'matrixTitle', 'Activity Check')" scrollable v-model="ux.show" @hide="close()" @close="close()" @ok="close()" @cancel="close()" ok-only ok-title="Chiudi">
			<template #modal-footer>

				<div class="d-flex justify-content-between" style="width: 100%">
					<b-form-checkbox size="sm" class="mt-2" v-model="ux.showDelete">Mostra tasti di rimozione</b-form-checkbox>

					<b-form @submit.prevent="handleSubmit" v-if="sessione && isManagerSessione">
						<b-button type="submit">Salva</b-button>
					</b-form>
				</div>
				
			</template>
			<div v-if="sessione">
				<b-table-simple striped small responsive fixed>
					<b-thead>
						<b-tr>
							<b-th class="small font-weight-bold"></b-th>
							<b-th class="small font-weight-bold text-center" v-for="campo, k in tabella" :colspan="campo.length" :style="'background-color: ' + colori.coloriIntestazione[k]">
								{{ sessione.forms.fasi[k.replace('f', '')] }}
							</b-th>
						</b-tr>
						<b-tr>
							<b-th class="small font-weight-bold">Giocatore</b-th>
							<b-th class="small font-weight-bold text-center" v-for="head, hk in sessione.forms.intestazioni" :style="'background-color:' + colori.colori[hk]">
								{{ head.label }}
								<small v-if="hk.indexOf('activity_check_nota') == -1"><a href="" @click.prevent="applyAll(hk)"><i title="Applica a tutti" class="fa fa-clipboard" aria-hidden="true"></i></a></small>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="player,k in sessione.forms.table">
							<b-td class="small" style="vertical-align: middle">
								{{ player.nome }}
								<a href="" v-if="hasActivityCheck" @click.prevent="do_activityCheckToggle(player)">✔︎</a>
							</b-td>
							<b-td class="text-center" v-for="head,k in sessione.forms.intestazioni" :style="'background-color:' + colori.colori[k]">
								<div class="d-flex align-items-center">
									<div class="flex-grow-1" v-if="_.has(player, k)" style="height: 100%" :is="typeOf(head.type)" :test="player" :field="k" :options="head" :dataLayer="dataLayer"></div>
									<div class="pl-2 small" style="cursor: pointer; color: #ba2e3b" v-if="player[k] != null && ux.showDelete == true" @click.prevent="askEmpty(player, k)">
										<i class="fa fa-trash" aria-hidden="true"></i>
									</div>
								</div>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>


			</div>
		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'
import sessionMixin from '@/mixins/sessione.js';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	data: function() {
		return {
			ux: {
				showDelete: false
			}
		}
	},
	mixins: [
	sessionMixin
	],
	components: {
		SessioneMatriceBoolean: require('@/components/layout/Sessioni/Matrix/Boolean.vue').default,
		SessioneMatriceActivitycheck: require('@/components/layout/Sessioni/Matrix/ActivityCheck.vue').default,
		SessioneMatriceNumber: require('@/components/layout/Sessioni/Matrix/Number.vue').default,
		SessioneMatriceSelector: require('@/components/layout/Sessioni/Matrix/Selector.vue').default,
		SessioneMatriceTextarea: require('@/components/layout/Sessioni/Matrix/Textarea.vue').default,
		SessioneMatriceText: require('@/components/layout/Sessioni/Matrix/Text.vue').default,
		SessioneMatriceEnum: require('@/components/layout/Sessioni/Matrix/Enum.vue').default,
		SessioneMatriceDate: require('@/components/layout/Sessioni/Matrix/Date.vue').default,

		SessioneMatriceCustomfms: require('@/components/layout/Sessioni/Matrix/Custom/Fms.vue').default,
		
	},
	watch: {
		sessioneId: {
			deep: true,
			handler: function() {
				this.do_getSessione();
				this.show();
			},
			immediate: true
		},
		'ux.show': {
			deep: true,
			handler: function() {}
		},
		sessione: {
			deep: true,
			handler: function() {

				var selectors = _.filter(this.sessione.forms.intestazioni, { type: 'selector' });

				_.each(selectors, function(sel) {
					if(!_.find(this.dataLayer, { url: sel.url })) {
						this.$http.get(this.$store.state.apiEndPoint + sel.url).then(function(data) {
							this.dataLayer.push({
								url: sel.url,
								data: data.data
							});
						});
					}
				}.bind(this));

			}
		}
	},
	created: function() {

		

	},
	props: [ 'sessioneId', 'team' ],
	data: function() {
		return {
			sessione: false,
			fasi: [],
			dataLayer: [],
			ux: {
				quick_search: '',
				show: true
			}
		}
	},
	
	computed: {
		hasActivityCheck: function() {

			return _.filter(this.sessione.forms.intestazioni, function(i) {
				return i.type == 'activityCheck';
			}).length > 0;

		},
		colori: function() {

			var i = 0;
			var colori = {};
			var coloriIntestazione = {};

			_.each(this.tabella, function(gruppo, chiave) {
				
				var colore = '';
				if(i++%2 == 1)
					colore = 'transparent';
				else
					colore = 'rgba(0,0,0,.1)';

				coloriIntestazione[chiave] = colore;

				_.each(gruppo, function(colonna) {
					colori[colonna.join('_')] = colore;
				});

			});

			return { coloriIntestazione, colori };

		},
		tabella: function() {

			// gruppi e dimensioni!
			var chiavi = _.groupBy(_.map(_.keys(this.sessione.forms.intestazioni), function(c) {
				return c.split('_');
			}), 0);

			return chiavi;

		},

		auth: function() {
			return Auth;
		},

		isManagerSessione: function() {
			if(this.sessione) {
				if(this.sessione && this.team && this.sessione.categoria_id != this.team.id_categoria) {
					return false;
				}
				return this.auth.isUserEnable('sessione_' + this.sessione.sessione_tipo.nome.toLowerCase().replaceAll(' ', '') + '_mng');
			}
			return true;
		},

		_: function() {
			return _;
		}
	},
	methods: {
		askEmpty: function(player, k) {
			if(player[k] != null)
				player[k] = null;
		},
		do_activityCheckToggle: function(player) {
			console.log(player);

			_.each(player, function(a, b) {
				if(b.indexOf('activity_check_checked') !== -1) {
					if(a == null)
						player[b] = 1;
					else if(a == 1)
						player[b] = 0;
					else
						player[b] = 1;
				}
			});

		},
		applyAll: function(key) {

			var valore = _.first(_.values(this.sessione.forms.table))[key];

			_.each(this.sessione.forms.table, function(s) {
				s[key] = valore;
			})

			console.log(valore);

		},
		do_getSessione: function() {
			this.apiGetSessione(this.sessioneId).then(function(data) {
				this.sessione = data;
			});
		},
		handleSubmit: function() {

			this.$http.post(this.$store.state.apiEndPoint + '/sessione/'+this.sessione.id+'/updateMatrice', this.sessione.forms).then(function(data) {
				this.$store.commit(types.ALERTS_PUSH, { msg: data.data.message, status: 1 });
				this.$emit('save');
			}, function(response) {
				var errors = response.data;
				for (var index in errors) {
					this.$store.commit(types.ALERTS_PUSH, { msg: errors[index], status: 0 });
				}
			});

		},
		typeOf: function(tipo) {
			return 'SessioneMatrice' + _.capitalize(tipo);
		},
		close: function() {
			this.ux.show = false;
			this.$emit('close');
		},
		show: function() {
			this.ux.show = true;
		}
	}
}

</script>