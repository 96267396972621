<template>
	<div style="max-height: 500px; overflow-y: auto">
		<div class="d-flex ai-center mb-3" v-if="singoloGiocatore.raw_osservato">
			<div style="position: relative">
				<div :style="'width: 110px; height: 110px; border-radius: 100%; border: 3px solid gray; background-image: url('+singoloGiocatore.raw_osservato.avatar_url+'); background-position: center center; background-size: cover'"></div>
				<div style="position: absolute; bottom: -5px; right: 0px" v-if="singoloGiocatore.raw_carriera">
					<img :src="singoloGiocatore.raw_carriera.squadra.logo_url" style="max-width: 30px" />
				</div>
			</div>
			<div class="pl-2">
				<div style="text-transform: uppercase; font-size: 120%; font-weight: bold; font-style: italic; line-height: 1">{{ singoloGiocatore.raw_osservato.cognome }}</div>
				<div style="font-size: 110%">{{ singoloGiocatore.raw_osservato.nome }}</div>

				<div v-if="singoloGiocatore.raw_carriera">
					<div class="mt-2" v-if="singoloGiocatore.raw_carriera.ruoli.length > 0">{{ singoloGiocatore.raw_carriera.ruoli[0].nome }}</div>
					<div><small>{{ dataNascita(singoloGiocatore.raw_osservato.nascita_data) }}</small></div>
				</div>
			</div>
		</div>

		<div v-for="partite, k in giocatore">
			<b-table-simple style="min-width: 350px" small striped hover responsive>
				<b-thead>
					<b-tr>
						<b-th>{{ partite[0].partita }}</b-th>
						<b-th style="width:50px !important;"></b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="item in partite" :key="item.id">
						<b-td> {{ item.osservatore }} </b-td>
						<b-td> {{ item.gradimento }} </b-td>	
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</div>
</template>

<script>

import moment from 'moment';

export default {
	props: [ 'id', 'ds' ],
	computed: {
		singoloGiocatore: function() {
			return _.find(this.ds, { raw_osservato_id: this.id });
		},
		first: function(coll) {
			return _.first(coll);
		},
		giocatore: function() {
			return _.groupBy(_.filter(this.ds, { raw_osservato_id: this.id }), 'raw_id_partita');
		}
	},
	methods: {
		findPartita: function(a) {
			return _.find(this.ds, { id_partita: parseInt(a) });
		},
		dataNascita: function(a) {
			return moment(a).format('DD/MM/YYYY');
		}
	},
	filters: {
		uppercase: function(a) {
			return 'b';
		}
	}
}

</script>