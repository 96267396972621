<template id="ginjuries_rtp">
	<div class="ginjuries_rtp">
		<b-table striped show-empty
		ref="injuriesTable"
		:items="injuries_tbl"
		:fields="fields"
		>
		<template #cell(data)="row">{{ row.value | formatDate }}</template>
		<template #cell(return)="row">{{ row.value | formatDate }}</template>
		<template #cell(reinjury)="row">{{ row.value ? reinjury_label[row.value] : 'No'}}</template>
		<template #cell(pathology)="row">{{ row.value ? row.value.name : 'N/D' }}</template>
		<template #cell(test_rtp)="row">{{ row.value.length }}</template>
		<template #cell(actions)="row"><span class="color-rosso" style="cursor:pointer"><i class="fa fa-info-circle" aria-hidden="true" @click="selectInjury(row.item.id)"></i></span></template>
	</b-table>
	<b-button size="sm" :to="{ name: 'medical_injuries_rtp', params: { id: params.player_id }}" variant="outline-primary">Go to Injuries rtp</b-button>
	<b-modal id="injuryModal" ref="injuryModal" title="Injury rtp" size="xl" class="fs-modal" ok-only ok-title="Chiudi">
		<div v-if="injury.id">
			<b-card>
				<b-row>
					<b-col cols="2">
						<b-form-group label="Date of Injury">
							<b-form-input type="date" v-model="injury.data" readonly></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group label="Second Injury" description="Is this a second injury?">
							<b-form-select v-model="injury.reinjury">
								<option :value="0">No</option>
								<option :value="2">Subsequent new injury</option>
								<option :value="3">Subsequent local injury</option>
								<option :value="4">Exacerbation</option>
								<option :value="1">Reinjury</option>
							</b-form-select>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group v-if="injury.reinjury" label="Previous injuries">
							<b-form-select v-model="injury.id_injury">
								<option :value="null">Select</option>
								<option v-if="!injury.id || (injury.id && injury.id != item.id)" v-for="item in injuries" :value="item.id">Injury of {{item.data | formatDate }} - {{ item.pathology ? item.pathology.name : 'N/D'}} - Data of return {{ item.return | formatDate }}</option>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group v-if="injury.reinjury" label="Date of return from previous injury">
							<b-form-input type="date" v-model="injury.return_reinjury"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="2">
						<b-form-group label="Body Area">
							<b-form-select v-model="region_id">
								<option :value="null">Select</option>
								<option v-for="item in regions" :value="item.id"> {{ item.name }} </option>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group label="Classification">
							<b-form-select v-model="region_class_id">
								<option :value="null">Select</option>
								<option v-for="item in classifications" :value="item.pivot.id"> {{ item.name }} </option>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Pathologies">
							<b-form-select v-model="injury.id_pathology">
								<option :value="null">Select</option>
								<option v-for="item in pathologies" :value="item.id"> {{ item.name }} </option>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col cols="1">
						<b-form-group label="OSICS code">
							<b-form-input v-model="osics_code" readonly></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="1">
						<b-form-group label="MLG-R code">
							<b-form-input readonly v-model="injury.code"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</b-card>

			<b-card class="my-1">
				<b-table responsive bordered show-empty
				ref="testTable"
				:items="items_test_variant"
				:fields="fields_test"
				>
				<template #cell(data)="row">
					<b-form-input type="date" v-model="items_test[row.index].data"></b-form-input>
				</template>
				<template  v-slot:[`cell(${item.id})`]="row" v-for="item in test">
					<b-form-select v-model="items_test[row.index].test[item.id]">
						<option :value="null"> - </option>
						<option value="si"> SI </option>
						<option value="no"> NO </option>	
					</b-form-select>
				</template>
			</b-table>
		</b-card>
	</div>
</b-modal>
</div>

</template>

<script>
import moment from 'moment'
export default {
	props: {
		params: {
			type: Object,
			required: false
		}
	},
	data: function() {
		return {
			injuries: [],
			regions: [],
			classifications: [],
			pathologies: [],
			region_id: null,
			classification_id: null,
			region_class_id: null,
			set_region_class_id: null,
			set_patology_id: null,
			osics_code: null,
			fields: [
			{ key: 'data', label: 'Date of Injury' },
			{ key: 'return', label: 'Date of return' },
			{ key: 'reinjury', label: 'Second Injury' },
			{ key: 'pathology', label: 'Type of Injury' },
			{ key: 'test_rtp', label: 'Test' },
			{ key: 'actions', label: '' },
			],
			injury: {
				id: null,
				persona_id: this.$route.params.id,
				data: null,
				return: null,
				reinjury: 0,
				id_pathology: null,
				code: null,
			},
			test: [],
			items_test: [],
			reinjury_label: [ 'No', 'Reinjury', 'Subsequent new injury', 'Subsequent local injury', 'Exacerbation'],
		}
	},
	components: {
	},
	methods: {
		getInjuries() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/injuries/rtp/player/' + this.$route.params.id).then((response) => {
				if (response.ok) {
					this.rosa = response.data.rosa;
					this.injuries = response.data.injuries;
					if(this.injury.id) {
						this.items_test = [];
						var item = this.injuries.find(item => item.id === this.injury.id);
						for(var i in item.test_rtp) {
							var test = {};
							test.id = item.test_rtp[i].id;
							test.data = item.test_rtp[i].data;
							test.test = JSON.parse(item.test_rtp[i].test); 	
							test._cellVariants = {};
							for(var j in this.test) {
								if(test.test[this.test[j].id] == 'si') {
									test._cellVariants[this.test[j].id] = 'success';
								} else if(test.test[this.test[j].id] == 'no') {
									test._cellVariants[this.test[j].id] = 'danger';
								} else {
									test._cellVariants[this.test[j].id] = '';
								}
							}
							this.items_test.push(test);	
						}
					}
				}
			}, (response) => {
			});
		},

		getRegions() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/regions').then((response) => {
				if (response.ok) {
					this.regions = response.data; 
				}
			}, (response) => {
			});
		},

		getPathologies() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/phatologies/' + this.region_class_id).then((response) => {
				if (response.ok) {
					this.pathologies = response.data; 
					if(this.set_pathology_id) {
						this.injury.id_pathology = this.set_pathology_id;
						this.set_pathology_id = null;
					}
				}
			}, (response) => {
			});
		},

		getTest() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/test').then((response) => {
				if (response.ok) {
					this.test = response.data; 
				}
			}, (response) => {
			});
		},

		selectInjury(id) {
			var item = this.injuries.find(item => item.id === id);
			this.injury.id = item.id;
			this.injury.data = item.data;
			this.injury.return = item.return;
			this.injury.reinjury = item.reinjury;

			if(item.pathology) {
				this.set_region_class_id = item.pathology.region_classification.id;
				this.set_pathology_id = item.id_pathology;
				this.region_id = item.pathology.region_classification.id_region;
			}
			this.injury.code = item.code;
			this.items_test = [];
			for(var i in item.test_rtp) {
				var test = {};
				test.id = item.test_rtp[i].id;
				test.data = item.test_rtp[i].data;
				test.test = JSON.parse(item.test_rtp[i].test); 	
				test._cellVariants = {};
				for(var j in this.test) {
					if(test.test[this.test[j].id] == 'si') {
						test._cellVariants[this.test[j].id] = 'success';
					} else if(test.test[this.test[j].id] == 'no') {
						test._cellVariants[this.test[j].id] = 'danger';
					} else {
						test._cellVariants[this.test[j].id] = '';
					}
				}
				this.items_test.push(test);	
			}
			this.$refs.injuryModal.show();	
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
	created: function () {
		this.getInjuries();
		this.getRegions();
		this.getTest();
	},
	watch: {
		region_id: function () {
			this.region_class_id = null;
			if(this.region_id) {
				for(var i in this.regions) {
					if(this.regions[i].id == this.region_id) {
						this.classifications = this.regions[i].classifications;
						if(this.set_region_class_id) {
							this.region_class_id = this.set_region_class_id;
							this.set_region_class_id = null;
						}
						break;
					} 
				}
			} else {
				this.classifications = [];
			}
		},
		region_class_id: function () {
			this.injury.id_pathology = null;
			this.pathologies = [];
			if(this.region_class_id) {
				this.getPathologies();
				for(var i in this.classifications) {
					if(this.classifications[i].pivot.id == this.region_class_id) {
						this.classification_id = this.classifications[i].id;
						break;
					}
				}
			} else {
				this.classification_id = null;
			}
		},
		'injury.id_pathology': function () {
			this.osics_code = null;
			if(this.injury.id_pathology) {
				for(var i in this.pathologies) {
					if(this.pathologies[i].id == this.injury.id_pathology) {
						this.osics_code = this.pathologies[i].code;
					} 
				}		
			}
		}
	},
	computed: {
		injuries_tbl: function() {
			var injuries_rtp = [];
			for(var i in this.injuries) {
				var item = this.injuries[i];
				if(!item.return || item.test_rtp.length > 0) {
					injuries_rtp.push(item);
				}
			}
			return injuries_rtp;
		},

		fields_test: function() {
			var fields = [];
			var data = {
				key: 'data',
				label: 'Test date *',
			}
			fields.push(data);
			for(var i in this.test) {
				var item = {
					key: '' + this.test[i].id,
					label: this.test[i].name,
				}
				fields.push(item);
			}
			var action = {
				key: 'action',
				label: '',
			}
			fields.push(action);
			return fields;
		},

		items_test_variant: function() {
			var items_variant = [];
			for(var i in this.items_test) {
				var item = this.items_test[i];
				for(var j in this.test) {
					if(item.test[this.test[j].id] == 'si') {
						item._cellVariants[this.test[j].id] = 'success';
					} else if(item.test[this.test[j].id] == 'no') {
						item._cellVariants[this.test[j].id] = 'danger';
					} else {
						item._cellVariants[this.test[j].id] = '';
					}
				}
				items_variant.push(item);
			}
			return items_variant;
		}
	}
}
</script>
