export const ROUTES_PLAYER_ACCESS = [
    {
        path: '/playeraccess/attivita',
        name: 'player_access_attivita',
        component: require('../components/pages/playeraccess/attivita.vue').default,
        meta: {
            module: 'player_access',

            label: 'Activities',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            menusub: [ 'player_access_richieste', 'player_access_attivita'],
            permesso: 'player_access',
        }
    },
    
    {
        path: '/playeraccess/richieste',
        name: 'player_access_richieste',
        component: require('../components/pages/playeraccess/richieste.vue').default,
        meta: {
            module: 'player_access',

            label: 'Requests',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            menusub: [ 'player_access_richieste', 'player_access_attivita'],
            permesso: 'player_access_req',
        }
    },

    {
        path: '/playeraccess/richieste/new',
        name: 'player_access_richieste_new',
        component: require('../components/pages/playeraccess/richieste_new.vue').default,
        meta: {
            module: 'player_access',

            label: 'New request',
            requiresAuth: true,
            showInMenu: false,
            parent: 'player_access_richieste',
            menusub: [ 'player_access_richieste', 'player_access_attivita'],
            permesso: 'player_access_req_mng',
        }
    },

    {
        path: '/playeraccess/richieste/edit/:id',
        name: 'player_access_richieste_edit',
        component: require('../components/pages/playeraccess/richieste_edit.vue').default,
        meta: {
            module: 'player_access',

            label: 'Request',
            requiresAuth: true,
            showInMenu: false,
            parent: 'player_access_richieste',
            menusub: [ 'player_access_richieste', 'player_access_attivita'],
            permesso: 'player_access_req_mng',
        }
    },

    {
        path: '/playeraccess/richieste/view/:id',
        name: 'player_access_richieste_view',
        component: require('../components/pages/playeraccess/richieste_view.vue').default,
        meta: {
            module: 'player_access',

            label: 'Request',
            requiresAuth: true,
            showInMenu: false,
            parent: 'player_access_richieste',
            menusub: [ 'player_access_richieste', 'player_access_attivita'],
            permesso: 'player_access_req',
        }
    },
];
