<template id="diagonale">

	<svg version="1.1" id="divisadiagonale" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		 viewBox="0 0 55.7 68.5" style="enable-background:new 0 0 55.7 68.5;" xml:space="preserve">
	<g id="maglia">
		<path id="XMLID_56_" class="st0" :style="styleprimario" d="M0.6,10.6c0,0,0.9-0.9,2.3-2.3C3.4,7.7,4,7.2,4.6,6.6C5.2,6.1,5.8,5.5,6.4,5
			C7,4.7,7.6,4.3,8.1,3.9c4-3,9.2-5.1,13.7-3c0.5,0.3,1.1,0.5,1.7,0.9c0.1,0.1,0.3,0.1,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3
			c0.2,0.7,1.2,5.2,4.6,7.1c4.6,2,7-1.4,7-1.4s5,2.2,9.6,6.8c4.4,4.9,9.2,11.6,9.2,11.6s3.3,3.3-3.1,9.6c-5.1,4-6.2,3.4-6.2,3.4
			s-0.3,2.7-0.3,2.9c-0.5,4-0.7,8-1,13.4c-0.3,2.4-0.2,7.4-1,8.8c-2.3,3.2-14,6.1-24.7,1.1c-10.2-5.3-8.9-11-8-13.3
			c1.4-9.9,1.8-18.6-0.2-29.7c-0.7-0.6-1.7-0.9-3.1-0.9c-1-0.6-2.2-1.3-3.2-2.1S2.1,18,1.4,17C-1.2,13.3,0.6,10.6,0.6,10.6z"/>
	</g>
	<g id="Livello_5">
		<path id="XMLID_31_" class="st1" :style="stylesecondario" d="M21.4,39.9c-1.8-5.8-3.5-11.7-4.3-17.8C16.6,18.5,15.4,9,18.5,4.8c0.4-1,1.2-1.6,1.7-1.8
			c0.3-0.2,2.2-0.8,3.6-0.7l0.8,0.2c0,0,0,0.2,0.1,0.5l0,0c-0.1-0.2-0.1-0.4-0.1-0.5c-0.2-0.1-0.3-0.2-0.4-0.3
			C24,2.1,23.9,2,23.8,1.9c-0.6-0.3-1.2-0.6-1.7-0.9c-2.6-1.2-5.5-1-8.2-0.1c-1.8,1-3,2.7-3.5,6.5C9.6,19,12.2,28.7,14.5,36.7
			c2.5,8.5,6,17,9.7,26c0.8,1.9,1.8,3.7,2.8,5.6c2.8,0.4,5.6,0.3,7.9-0.1c-3.2-5.1-7.9-14-8.4-15.1C24.7,48.9,22.8,44.5,21.4,39.9z"
			/>
	</g>
	<g id="ombre_m">
		
			<linearGradient id="XMLID_2_" gradientUnits="userSpaceOnUse" x1="-641.3394" y1="71.4719" x2="-633.6006" y2="62.5171" gradientTransform="matrix(-1 0 0 -1 -611.1872 74.5318)">
			<stop  offset="0" style="stop-color:#000000;stop-opacity:0.3"/>
			<stop  offset="1" style="stop-color:#000000;stop-opacity:0.8"/>
		</linearGradient>
		<path id="XMLID_4_" class="st2" d="M25.2,12.6c-3-0.7-6.6-2.9-7-5.9C18,4.5,19.4,3.3,20.1,3c0.3-0.2,2.2-0.8,3.6-0.7l0.8,0.2
			c0,0,0.9,5.2,5,7.3c4.2,1.5,6.5-1.6,6.5-1.6s-0.7,1.5-2.7,2.8C31.1,12.2,28.2,13.1,25.2,12.6z"/>
		
			<radialGradient id="XMLID_3_" cx="11.2736" cy="22.3409" r="53.6732" gradientTransform="matrix(1 0 0 -1 0 74.5318)" gradientUnits="userSpaceOnUse">
			<stop  offset="0.7799" style="stop-color:#FFFFFF;stop-opacity:0"/>
			<stop  offset="1" style="stop-color:#FFFFFF;stop-opacity:0.6"/>
		</radialGradient>
		<path id="XMLID_26_" class="st3" d="M54.8,26.5c0,0-4.8-6.7-9.2-11.6c-4.5-4.6-9.5-6.8-9.5-6.8s-2.4,3.3-7,1.4
			c-3.4-1.9-4.4-6.4-4.6-7.1c-0.2-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.6-0.3-1.2-0.6-1.7-0.9c-4.5-2.1-9.7-0.1-13.7,3
			C7.7,4.3,7.2,4.6,6.6,5C6,5.5,5.4,6.1,4.8,6.6C4.2,7.1,3.7,7.7,3.1,8.3c-1.4,1.3-2.3,2.3-2.3,2.3s-1.7,2.5,0.6,6.3
			c0.1-0.1,0.2-0.1,0.3-0.2c4.4-5.1,12.2-7.6,18.7-6.4c4.6,0.9,8.8,3.1,12.9,5.5c4.4,2.9,8,5.3,10.5,9.6c0.9,0.6,1.5,1.7,2,2.8
			c1.5,2.4,1.2,5,4.8,4.2c-2.8,1.5-2,1.2,1.8,0.4c0.6,0.4,0.8,1.1,0.9,1.8C57.6,29.3,54.8,26.5,54.8,26.5z"/>
		<g id="XMLID_18_">
			
				<radialGradient id="XMLID_5_" cx="-630.7299" cy="49.2" r="72.2726" gradientTransform="matrix(-1 0 0 -1 -611.1872 74.5318)" gradientUnits="userSpaceOnUse">
				<stop  offset="8.135840e-02" style="stop-color:#000000;stop-opacity:0"/>
				<stop  offset="0.3253" style="stop-color:#000000;stop-opacity:0.25"/>
				<stop  offset="0.4354" style="stop-color:#000000;stop-opacity:0.25"/>
				<stop  offset="0.7321" style="stop-color:#000000;stop-opacity:0"/>
			</radialGradient>
			<path id="XMLID_21_" class="st4" d="M9,18.4c4.9,6.7,10.3,10.4,16.8,11.6c6.4,0.2,7.9-0.9,8.8-1.5c0.9-0.5,1.2-0.6,4.4,4.2
				c2.9,7.8,5.8,7,6.5,6.9c0,0,0,0.7-0.9,7.9c-1,9.4-0.3,14.7-1.2,17.1c-2.6,3.3-14.4,6.2-25,1.2c-10.2-5.3-8.9-11-8-13.3
				c1.4-9.9,1.8-18.4-0.2-29.6C10.2,22.9,9.6,20.8,9,18.4z"/>
			
				<radialGradient id="XMLID_6_" cx="-638.0938" cy="47.2667" r="110.2261" gradientTransform="matrix(-1 0 0 -1 -611.1872 74.5318)" gradientUnits="userSpaceOnUse">
				<stop  offset="8.135840e-02" style="stop-color:#000000;stop-opacity:0"/>
				<stop  offset="0.3253" style="stop-color:#000000;stop-opacity:0.25"/>
				<stop  offset="0.4354" style="stop-color:#000000;stop-opacity:0.25"/>
				<stop  offset="0.7321" style="stop-color:#000000;stop-opacity:0"/>
			</radialGradient>
			<path id="XMLID_20_" class="st5" d="M7.2,21.9C7.1,21.8,7.1,21.8,7.2,21.9L7.2,21.9C7.1,21.8,7.1,21.8,7.2,21.9z"/>
		</g>
		
			<linearGradient id="XMLID_7_" gradientUnits="userSpaceOnUse" x1="-655.8022" y1="34.018" x2="-664.1797" y2="48.3795" gradientTransform="matrix(-1 0 0 -1 -611.1872 74.5318)">
			<stop  offset="0" style="stop-color:#000000;stop-opacity:0.2"/>
			<stop  offset="1" style="stop-color:#000000;stop-opacity:0.6"/>
		</linearGradient>
		<path id="XMLID_17_" class="st6" d="M51.7,35.9c-5.1,4.6-7.9,3.7-7.9,3.7c-3.3-4,1.8-9,1.8-9s7-5.7,9.2-4
			C54.8,26.5,58.1,29.8,51.7,35.9z"/>
		
			<linearGradient id="XMLID_8_" gradientUnits="userSpaceOnUse" x1="-639.6985" y1="46.7471" x2="-656.0984" y2="30.3472" gradientTransform="matrix(-1 0 0 -1 -611.1872 74.5318)">
			<stop  offset="0" style="stop-color:#000000;stop-opacity:0"/>
			<stop  offset="1" style="stop-color:#000000;stop-opacity:0.4"/>
		</linearGradient>
		<path id="XMLID_16_" class="st7" d="M37.7,43.6c-0.7-1.1-1-2.4-1.4-3.6c-0.4-1.3-0.7-2.5-1-3.8c-0.4-2.1-0.8-4.2-0.8-6.3
			c0-0.4-0.1-1.2,0.3-1.4c0.9-0.7,2,0.7,4.2,4c1.4,3.7,3.2,7.6,6,7.1c0.1,0,0.1-0.1,0.1,5c0,0.3-2.3,0.9-2.7,1
			c-1.5,0.3-2.9-0.2-4-1.2C38.1,44.2,37.9,43.9,37.7,43.6z"/>
		
			<linearGradient id="XMLID_9_" gradientUnits="userSpaceOnUse" x1="-615.5428" y1="61.6529" x2="-620.3755" y2="50.1079" gradientTransform="matrix(-1 0 0 -1 -611.1872 74.5318)">
			<stop  offset="0" style="stop-color:#000000;stop-opacity:0.1"/>
			<stop  offset="1" style="stop-color:#000000;stop-opacity:0.6"/>
		</linearGradient>
		<path id="XMLID_15_" class="st8" d="M5.1,11.5c1.9-1,4-1.2,3.5,1.8c-0.2,2.5,1.6,9.5,1.6,9.5c-0.7-0.6-1.7-0.9-3.1-0.9
			c-1-0.6-2.2-1.3-3.2-2.1s-1.7-1.6-2.4-2.4c-0.5-1.3-0.6-2.8,0.8-4.1C2.9,12.8,4,12,5.1,11.5z"/>
	</g>
	</svg>


</template>

<style>
	#divisadiagonale .st0{fill:#A81D26;}
	#divisadiagonale .st1{fill:#F39200;}
	#divisadiagonale .st2{fill:url(#XMLID_2_);}
	#divisadiagonale .st3{fill:url(#XMLID_3_);}
	#divisadiagonale .st4{fill:url(#XMLID_5_);}
	#divisadiagonale .st5{fill:url(#XMLID_6_);}
	#divisadiagonale .st6{fill:url(#XMLID_7_);}
	#divisadiagonale .st7{fill:url(#XMLID_8_);}
	#divisadiagonale .st8{fill:url(#XMLID_9_);}
</style>

<script>
	
export default {

	data: function(){
		return {

			dummy: null

		}
	},

	props: {

		primario: {
			type: String,
			required: false
		},

		secondario: {
			type: String,
			required: false
		},

		terziario: {
			type: String,
			required: false
		}

	},

	computed: {

		styleprimario: function(){

			return 'fill: '+this.primario;

		},

		stylesecondario: function(){

			return 'fill: '+this.secondario;

		},

		styleterziario: function(){

			return 'fill: '+this.terziario;

		}

	}

}

</script>