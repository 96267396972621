<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<b-card no-body class="border-0">
				<div v-if="auth.isUserEnable('whiteboard_config')">
				<b-button variant="link" class="nav-link" href="/plain/monitor/#/screen/edit" target="_blank"><span style="font-size: 24px;font-family: 'bebasneue', Arial, sans-serif;">BOARD EDITOR</span></b-button>
				</div>
				<b-embed type="iframe"
				src="/plain/monitor/#/"
				allowfullscreen
				></b-embed>
			</b-card>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
	},

	computed: {
		auth: function() {
			return Auth;
		},
	}
}
</script>
