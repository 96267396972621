<template>
	<b-card>
		<template #header>
			<div class="d-flex align-items-center justify-content-between">
				<div>
					{{ injury.pathology_name }} 
					<b-badge variant="warning" class="mr-1">{{ injury.pathology_code }}</b-badge>
					<b-badge>{{ injury.injury_classification }}</b-badge>
				</div>
				<div class="text-muted small" style="text-transform: uppercase">{{ injury.side }} {{ injury.injury_region }}</div>
				<!-- <div class="text-muted small"><router-link :to="{}" target="_blank">Vai all'infortunio</router-link></div> -->
			</div>
		</template>
		<b-row>
			<b-col cols="2">
				<div class="d-flex flex-column justify-content-around text-center" style="height: 100%">
					<div class="font-weight-bold text-danger">{{ moment(injury.data).format('DD/MM/YYYY') }}</div>
					<div class="text-muted">
						<em>{{ injuryDuration }} giorni</em>
						<div class="text-muted small">
							<span :style="'color: ' + ['', 'green', '#bbbb40', 'orange', 'red'][injury.severity]">{{ ['', 'Minimal', 'Mild', 'Moderate', 'Severe'][injury.severity] }}</span>
						</div>
					</div>
					<div class="text-success small" v-if="injury.return">{{ moment(injury.return).format('DD/MM/YYYY') }}</div>
					<div class="text-warning small" v-else><u>Attivo</u></div>
				</div>
			</b-col>
			<b-col cols="10">

				<b-nav tabs class="mb-2">
					<b-nav-item @click.prevent="ux.tab = 'details'" :active="ux.tab == 'details'">Dettagli</b-nav-item>
					<b-nav-item @click.prevent="ux.tab = 'graph'" :active="ux.tab == 'graph'">Grafico</b-nav-item>
					<b-nav-item @click.prevent="ux.tab = 'sessions'" :active="ux.tab == 'sessions'">Sessioni</b-nav-item>
				</b-nav>

				<div class="py-1">
					<div v-if="ux.tab == 'details'">

						<b-row>
							<b-col>
								<div class="little-title">Second Injury</div>
								{{ ['No', 'Reinjury', 'Subsequent new injury', 'Subsequent local injury', 'Exacerbation'][injury.reinjury] }}
							</b-col>
							<b-col>
								<div class="little-title">Body Area</div>
								<div>{{ injury.injury_region }}, {{ injury.side }}</div>
							</b-col>
							<b-col>
								<div class="little-title">Classification</div>
								<div>{{ injury.injury_classification }}</div>
							</b-col>
							<b-col>
								<div class="little-title">Pathology</div>
								<div>{{ injury.pathology_name }} ({{ injury.pathology_code }})</div>
							</b-col>
						</b-row>
						<hr />

						<div v-if="injury.muscle">
							
							<b-row>
								<b-col>
									<div>{{ injury.muscle == 'functional' ? 'Functional': 'Structural' }}</div>
									
									<div v-if="injury.muscle == 'functional'">
										<span v-if="injury.functional == 'doms'">DOMS/overuse</span>
										<span v-if="injury.functional == 'neuromuscular'">Neuromuscular</span>
									</div>
								</b-col>

								<b-col v-if="injury.muscle == 'structural'">
									<div class="little-title">Location</div>
									<div>{{ {proximal: 'Proximal', medial: 'Medial', distal: 'Distal' }[injury.location] }}</div>
								</b-col>
								<b-col v-if="injury.muscle == 'structural' && injury.mtj">
									<div class="little-title">Tendon or muscle junction interested</div>
									<div>{{ {proximal: 'Proximal MTJ', distal: 'Distal MTJ' }[injury.mtj] }}</div>
								</b-col>
								<b-col v-if="injury.muscle == 'structural' && injury.grade">
									<div class="little-title">Grade</div>
									<div>{{ ['0 (MRI negative)', '1 (only edema)', '2 (minimal GAP/edema interfascia)', '3 (misurable GAP/edema, tendon detension)'][injury.grade] }}</div>
									<div v-if="injury.r">r (tendon disruption/retraction)</div>
								</b-col>
								<b-col v-if="injury.muscle == 'structural'">
									<div class="little-title">Mechanism</div>
									<div>{{ { direct: 'Direct', indirect: 'Indirect' }[injury.mechanism] }}</div>
									<div v-if="injury.mechanism == 'indirect'">
										{{ [{sprinting: 'Sprinting', stretching: 'Stretching'}][injury.indirect] }}
									</div>
									<div v-if="injury.mechanism == 'direct'">
										{{ [{player: 'YES with other player', object: 'YES with object'}][injury.direct] }}
									</div>
								</b-col>

							</b-row>

							<div class="mt-2">
								Muscle injury code (MLG-R)
								<code>{{injury.code}}</code>
							</div>

							<hr />

						</div>

						<b-row>
							<b-col>
								<div class="little-title">Injury Mode</div>
								{{ injury.contact == 1 ? 'Contact' : 'Not Contact' }}
								<ul>
									<li v-for="mech in injury.mechanism_injuries">{{ mech.mechanism.name }}</li>
								</ul>
							</b-col>
							<b-col>
								<div class="little-title">Injury Mechanism</div>
								<div>{{ injury.note }}</div>
							</b-col>
							<b-col cols="12">
								<div class="little-title">Did the injury occur outside the pitch perimeter?</div>
								<div>{{ injury.outside }}</div>
							</b-col>
						</b-row>
						<hr />
						<b-row>
							<b-col>
								<div class="little-title">{{ ['', 'Football Training', 'Football and other training', 'National team training', 'Reserve/Youth team training', 'Other', 'Friendly Match', 'League Match', 'UCL Match', 'EUL Match', 'Other CUP Match', 'Reserve/Youth team league Match', 'Reserve/Youth team UYL Match', 'National Team Match'][injury.id_match_event] }}</div>
								<div v-if="injury.event == 'match'">
									<div>Minute</div>
									<div>{{ injury.minute }}</div>
								</div>
							</b-col>
							<b-col>
								<div class="little-title">Examination</div>
								<div>
									<ul>
										<li v-for="exam in injury.examination_injuries">{{ exam.mechanism.name }}</li>
									</ul>
								</div>
								<div class="little-title">Diagnosis</div>
								<div>{{ injury.diagnosis }}</div>
							</b-col>
						</b-row>

					</div>
					<div v-if="vas && rpe && tqr && ux.tab == 'graph'">
						<E-Chart :key="ux.tab + injury.id + 'g1'"  style="width: 100%; height: 300px" :option="graphOption"></E-Chart>
						<!-- <pre>{{graphOption}}</pre> -->
					</div>
					<div v-if="sessions && ux.tab == 'sessions'">
						<E-Chart :key="ux.tab + injury.id + 'g2'" style="width: 100%; height: 300px" :option="graphOptionSessions"></E-Chart>
						<div class="small text-muted text-center" style="margin-top: -30px">Nel grafico sono riportati i MINUTI spesi nelle sessioni.</div>
						<E-Chart :key="ux.tab + injury.id + 'g3'" style="width: 100%; height: 300px" :option="graphOptionSessionsTotals"></E-Chart>
					</div>
					<b-alert show v-else>Attendi caricamento dei dati</b-alert>
				</div>

			</b-col>
			<!-- <pre>{{injury}}</pre> -->
			<!-- <code>{{ personId }}</code>
			<pre>{{vas}}</pre> -->
		</b-row>
	</b-card>
</template>

<style type="text/css">
	

.little-title {
	font-size: 85%;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: bold;
	color: rgb(120,120,120);
}

</style>

<script>

import eChart from '@/components/layout/E-Chart/Base.vue'
import moment from 'moment';

export default {
	props: [ 'injury' ],
	data: function() {
		return {
			ux: {
				tab: 'graph'
			},
			sessions: null,
			vas: null,
			tqr: null,
			rpe: null
		}
	},
	components: {
		'E-Chart': eChart,
	},
	computed: {
		moment: function() {
			return moment;
		},
		injuryDuration: function() {

			var mom_from = moment(this.injury.data);
			var mom_to = this.injury.return ? moment(this.injury.return) : moment();
			
			return mom_to.diff(mom_from, 'days');

		},
		graphOption: function() {

			var mom_from = moment(moment(this.injury.data).format('YYYY-MM-DD'));
			var mom_to = this.injury.return ? moment(this.injury.return) : moment();

			mom_from = mom_from.add(-7, 'days');
			mom_to = mom_to.add(+7, 'days');

			var labels = [];
			while(mom_from.isBefore(mom_to)) {
				labels.push(mom_from.format('DD/MM/YYYY'));
				mom_from = mom_from.add(1, 'day');
			}

			
			var markArea = {
				itemStyle: {
					color: 'rgba(255, 173, 177, 0.4)'
				},
				data: [
					[
						{ name: 'Injury', xAxis: moment(this.injury.data).format('DD/MM/YYYY') },
						{ xAxis: this.injury.return ? moment(this.injury.return).format('DD/MM/YYYY') : _.last(labels) }
					]
				]
			};

			var series = [];

			series.push({
				name: 'RPE',
				data: _.map(labels, function(a) {
					var b = _.find(this.rpe, function(b) {
						var dt = moment(b.date).format('DD/MM/YYYY');
						return dt === a
					});
					if(!b) return null;
					return b.value;
				}.bind(this)),
				type: 'bar',
				markArea
			});

			series.push({
				name: 'TQR',
				data: _.map(labels, function(a) {
					var b = _.find(this.tqr, function(b) {
						var dt = moment(b.date).format('DD/MM/YYYY');
						return dt === a
					});
					if(!b) return null;
					return b.value;
				}.bind(this)),
				yAxisIndex: 1,
				type: 'line',
				markArea
			});

			var vases = _.groupBy(this.vas, 'region');
			_.each(vases, function(c,d) {
				if(d == null) return false;
				series.push({
					name: 'Vas ' + d,
					data: _.map(labels, function(a) {
						var b = _.find(c, function(b) {
							var dt = moment(b.date).format('DD/MM/YYYY');
							return dt === a
						});
						if(!b) return null;
						return b.value;
					}.bind(this)),
					yAxisIndex: 0,
					type: 'line',
					markArea
				});
			})

			return {
				legend: {
					left: 'left'
				},
				toolbox: {
					show: true,
					feature: {
						saveAsImage: {}
					}
				},
				dataZoom: (labels.length < 50 ? null : [
					{
						type: 'slider',
						filterMode: 'weakFilter',
						showDataShadow: false,
						labelFormatter: ''
					},
					{
						type: 'inside',
						filterMode: 'weakFilter'
					}
				]),
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross'
					}
				},
				xAxis: {
					type: 'category',
					data: labels
				},
				yAxis: [{
					type: 'value',
					min: 0,
					max: 10
				},
				{
					type: 'value',
					min: 0
				}],
				series
			}

		},
		graphOptionSessionsTotals: function() {

			var s = _.mapValues(this.sessions, function(a,b) {

				return _.sum(_.map(a, function(e) {

					var mom_from = moment(e.date_start);
					var mom_to = moment(e.date_end);
					var duration = mom_to.diff(mom_from, 'minute');
					return duration;

				}));

			});

			var dataset = [];

			_.each(s, function(b, k) {
				dataset.push({ value: b, name: k });
			});

			return {series: {
				name: 'Divisione del Tempo',
				type: 'pie',
				radius: '50%',
				data: dataset
			}};

		},
		graphOptionSessions: function() {

			var mom_from = moment(moment(this.injury.data).format('YYYY-MM-DD'));
			var mom_to = this.injury.return ? moment(this.injury.return) : moment();

			mom_from = mom_from.add(-7, 'days');
			mom_to = mom_to.add(+7, 'days');

			var labels = [];
			while(mom_from.isBefore(mom_to)) {
				labels.push(mom_from.format('DD/MM/YYYY'));
				mom_from = mom_from.add(1, 'day');
			}

			var markArea = {
				itemStyle: {
					color: 'rgba(255, 173, 177, 0.4)'
				},
				data: [
					[
						{ name: 'Injury', xAxis: moment(this.injury.data).format('DD/MM/YYYY') },
						{ xAxis: this.injury.return ? moment(this.injury.return).format('DD/MM/YYYY') : _.last(labels) }
					]
				]
			};

			var series = [];

			_.each(this.sessions, function(a,b) {

				series.push({
					name: b,
					data: _.map(labels, function(l) {
						
						var has = _.filter(a, function(k) {
							return moment(k.date_start).format('DD/MM/YYYY') === l;
						});
						
						return _.sum(_.map(has, function(e) {

							var mom_from = moment(e.date_start);
							var mom_to = moment(e.date_end);
							var duration = mom_to.diff(mom_from, 'minute');
							return duration;
							
						}));

					}.bind(this)),
					type: 'bar',
					stack: 'Search Engine',
					markArea
				});

			});

			return {
				legend: {
					left: 'left'
				},
				toolbox: {
					show: true,
					feature: {
						saveAsImage: {}
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross'
					}
				},
				dataZoom: (labels.length < 50 ? null : [
					{
						type: 'slider',
						filterMode: 'weakFilter',
						showDataShadow: false,
						labelFormatter: ''
					},
					{
						type: 'inside',
						filterMode: 'weakFilter'
					}
				]),
				xAxis: {
					type: 'category',
					data: labels
				},
				yAxis: {
					type: 'value',
					min: 0
				},
				series
			}

		},
		personId: function() {
			return this.injury.person_id;
		}
	},
	mounted: function() {
		this.fetchVas(this.personId, this.injury.data, this.injury.return);
		this.fetchRpe(this.personId, this.injury.data, this.injury.return);
		this.fetchTQR(this.personId, this.injury.data, this.injury.return);
		this.fetchSessions(this.personId, this.injury.data, this.injury.return);
	},
	methods: {
		fetchSessions: function(playerId, from, to) {
			this.$http.get(this.$store.state.apiEndPoint + '/atlas/persona/sessioni', { params: { person_id: playerId, from: moment(from).add(-1, 'week').format('YYYY-MM-DD'), to: moment(to).add(1, 'week').format('YYYY-MM-DD'), types: [ 'Fisioterapia', 'Farmaco', 'Palestra', 'RTP', 'Prevenzione', 'Standard', 'Nutrition', 'Differenziato', 'Standard', 'Dexa', 'Recupero' ] } }).then(function(data) {
				this.sessions = _.groupBy(data.data.results, 'type');
			});
		},
		fetchVas: function(playerId, from, to) {
			this.$http.post(this.$store.state.apiEndPoint + '/atlas/persona/vas', { person_id: playerId, from, to }).then(function(data) {
				this.vas = data.data.results;
			});
		},
		fetchTQR: function(playerId, from, to) {
			this.$http.post(this.$store.state.apiEndPoint + '/atlas/persona/tqr', { person_id: playerId, from, to }).then(function(data) {
				this.tqr = data.data.results;
			});
		},
		fetchRpe: function(playerId, from, to) {
			this.$http.post(this.$store.state.apiEndPoint + '/atlas/persona/rpe', { person_id: playerId, from, to }).then(function(data) {
				this.rpe = data.data.results;
			});
		}
	}
}

</script>