<template>
	<div>
		<div v-if="fromParams.multi && fromParams.multi == true">
			
			<div v-for="option in dataset">
				<label>
					<input type="checkbox" v-model="picked" :value="option[ fromParams.value ]" />
					{{ option[ fromParams.label ] }}
				</label>
			</div>

		</div>
		<div v-else>
			
			<div v-for="option in dataset">
				<label>
					<input type="radio" v-model="picked" :value="option[ fromParams.value ]" />
					{{ option[ fromParams.label ] }}
				</label>
			</div>

		</div>

	</div>
</template>

<script>

export default {
	props: [ 'from', 'fromParams', 'selected', 'contratto' ],
	data: function() {
		return {
			dataset: false,
			picked: false
		}
	},
	watch: {
		selected: {
			immediate: true,
			deep: true,
			handler: function() {
				this.picked = this.selected;
				this.fetchOptions();
			}
		},
		picked: {
			deep: true,
			handler: function() {
				this.$emit('update', this.picked);
			}
		}
	},
	methods: {
		fetchOptions: function() {

			this.$http.get(this.$store.state.apiEndPoint + '/' + this.from, { params: { persona_id: this.contratto.persona_id } }).then(function(data) {
				this.dataset = data.data;
			});

		}
	}
};

</script>