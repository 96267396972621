<template>
	<div v-if="ux.show">
		<hr/>
		
		<div v-for="item in videos">

			<b-card class="mb-2">
				<div style="position: absolute; width: 96.3%; height: 92%;" v-if="item.video.notReady">
					<div style="height: 100%; width: 100%; position: absolute; z-index: 2" class="d-flex align-items-center justify-content-center">
						<b-alert :show="true" variant="warning">
							<p class="lead m-0">Video in elaborazione</p>
						</b-alert>
					</div>
				</div>
				<b-row :style="item.video.notReady ?'opacity: 0.1; pointer-events: none' : ''">
					<b-col cols="4">
						<b-embed type="video" controls aspect="16by9" v-if="item.video.tipo != 'youtube'" :poster="item.video.thumb ? item.video.thumb : '/assets/varie/missing.jpg'" preload="none" :controlsList="!auth.isUserEnable('video_download') ? 'nodownload' : ''" oncontextmenu="return false;">
							<source :src="item.video.video"/>		
						</b-embed>
						<b-embed type="iframe" aspect="16by9" :src="item.video" allowfullscreen v-if="item.video.tipo == 'youtube'"></b-embed>
					</b-col>
					<b-col cols="5" class="ml-3">
						<h5>{{ getTitle(item.video) }}</h5>
						<p class="card-text">
							Caricato da <span class="color-rosso font-bold"> {{item.video.utente.nomeCompleto}} </span>
						</p>
						<p class="card-text">
							il <span class="font-italic"> {{ item.video.data | formatDateHour}} </span>
						</p>
						<p class="card-text">
							Sorgente: <span class="font-bold"> {{ item.video.tipo }} </span>
						</p>
						<slot></slot>
					</b-col>
					<b-col class="text-right">
						<b-button v-if="auth.isUserEnable('videotag') && auth.isUserEnable('video_sessioni')" variant="primary" class="w-100 mb-2" size="sm" :to="{ name: $route.meta.module + '_sessione_videotag', params: { id: id, tipo: tipo, id_video: item.video.id }}">Videotags <i class="fa fa-tag" aria-hidden="true"></i></b-button>
						<b-button v-if="!readonly" variant="primary" class="w-100 mb-2" size="sm" @click="editVideo(item.id)">Modifica Video <i class="fa fa-pencil" aria-hidden="true"></i></b-button>
						<b-button variant="primary" class="w-100 mb-2" size="sm" @click="deleteVideo(item.id)" v-if="(auth.isAdmin() || auth.user.id == item.video.utente_id) && !readonly">Elimina Video <i class="fa fa-trash" aria-hidden="true"></i></b-button>
						<b-button variant="primary" class="w-100 mb-2" size="sm" @click="getLinkVideo(item.video.id)" v-if="auth.isUserEnable('video_link')">Link Video <i class="fa fa-link" aria-hidden="true"></i></b-button>
						<b-button variant="primary" class="w-100 mb-2" size="sm" v-if="auth.isUserEnable('video_download')" @click="downloadVideo(item.video.id)">Download Video <i class="fa fa-download" aria-hidden="true"></i></b-button>
						
						<b-button variant="primary" class="w-100 mb-2" size="sm" v-for="button in buttons" @click.prevent="$emit(button.event, item)">{{ button.name }}</b-button>

					</b-col>
				</b-row>
			</b-card>
		</div>
		<div v-if="videos.length == 0" class="mb-2">{{ nolabel ? nolabel : 'Non ci sono video' }}</div>

		<b-button variant="outline-secondary" size="sm" v-if="(!readonly && !hideNewVideo) || (model && model.id && !readonly)" @click.prevent="action_toggleMng">{{ model && model.id ? 'Modifica video' : 'Nuovo Video' }} <i v-if="ux.mng == true" class="fa fa-arrow-up" aria-hidden="true"></i><i v-else class="fa fa-arrow-down" aria-hidden="true"></i></b-button>
		<Form :entity="{ entity: entity, entityId: entityId }" :type="the_uploadType" :cutterSource="cutterSource" :skipRosa="skipRosa" v-if="ux.mng == true && !readonly" :model="model" @save="saveVideo" @upload="uploadVideo" @refresh="ux.mng = false; fetch()"></Form>
		<b-alert :show="ux.uploaded" variant="success" class="mt-2">Il video è stato caricato con successo, presto sarà disponibile per l'uso!</b-alert>

		<b-modal ref="linkModal" title="Link Video" size="lg" ok-only ok-title="Chiudi">
			<div>
				{{ link }}
			</div>
		</b-modal>	
		<!--hr/-->	
	</div>
</template>

<script>

import _ from 'lodash';
import moment from 'moment';
var Auth = require('../../../auth.js');

export default {
	props: [ 'layout', 'nolabel', 'entity', 'collapsed', 'entityId', 'readonly', 'personeIds', 'id', 'tipo', 'hideIfEmpty', 'hideNewVideo', 'buttons', 'skipRosa', 'uploadType', 'cutterSource'],
	computed: {
		the_uploadType: function() {

			if(!this.uploadType) return 'iframe';
			return this.uploadType;

		},
		the_buttons: function() {

		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		}
	},
	components: {
		Form: require('@/components/layout/video/form.vue').default,
	},
	watch: {
		collapsed: {
			immediate: true,
			handler: function() {
				if(this.collapsed == true) {
					this.ux.show = false;
					this.ux.mng = false;
				}
				else
					this.ux.show = true;
			}
		},
		videos: {
			deep: true,
			handler: function() {	
			}
		},
		entity: {
			deep: true,
			handler: function() {
				this.fetch();
			},
			immediate: true
		},
	},
	methods: {
		uploadVideo: function(target) {

			var formData = new FormData();
			formData.append('file', target);
			formData.append('type', 'scouting');

			this.ux.uploading = true;
			this.ux.uploaded = false;
			this.$http.post(this.$store.state.apiEndPoint + '/video/upload', formData).then(function(data) {
				this.ux.uploaded = true;
				this.ux.uploading = false;
				this.ux.mng = false;
			}, function(data) {
				console.log('Errore!');
			});

		},
		action_toggleMng: function() {
			this.ux.mng = !this.ux.mng;
			if(!this.ux.mng) {
				this.model = this.createModel();
			}
		},
		fetch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/of/' + this.entity + '/' + this.entityId).then(function(data) {
				this.videos = data.data;
				this.$emit('countVideo', this.videos.length);
				if(this.videos.length > 0 && !this.hideIfEmpty) {
					//	this.ux.show = true;
				} else if(this.videos.length == 0 && this.hideIfEmpty) {
					this.ux.show = false;
				} else if(this.hideIfEmpty && this.videos.length > 0)  {
					this.ux.show = true;
				}

				this.model = this.createModel();
			});
		},
		deleteVideo: function(entityId) {
			if(confirm("Confermi la rimozione del video?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/video/delete/' + entityId).then(function(data) {
					this.fetch();
				});	
			}
		},
		createModel: function() {
			return {
				type: 'asroma',
				url: '',
				titolo: '',
				fileNameVideo: null,
			}
		},
		editVideo: function(id) {
			var rel = this.videos.find(item => item.id == id);
			this.model = {
				id: rel.video.id,
				titolo: rel.video.titolo,
				type: rel.video.tipo,
				url: '',
				fileNameVideo: null,
			};
			this.ux.mng = true;
		},
		saveVideo: function() {
			var url = '';
			if(this.model.id) {
				url = '/video/edit';
			} else {
				url = '/video/of/' + this.entity + '/' + this.entityId + '/post';
			}
			this.$http.post(this.$store.state.apiEndPoint + url, this.model).then(function(data) {
				this.ux.mng = false;
				this.fetch();
			});
		},
		getLinkVideo(id) {
			this.link = null;	
			this.$http.get(this.$store.state.apiEndPoint + '/video/link/' + id).then((response) => {
				if (response.ok)
				{
					this.link = response.data;
					this.$refs.linkModal.show();
				}
			}, (response) => {
			});
		},
		getTitle(video) {
			var title = "";
			if(video.titolo != "") {
				title = video.titolo;
			} else {
				title = this.auth.isAdmin() ? video.video : video.file;
			}
			return title;
		},
		downloadVideo(id) {
			window.open(this.$store.state.apiEndPoint + '/video/download/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token);
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
	data: function() {
		return {
			model: false,
			videos: false,
			link: null,
			person_ids: [],
			video_id: null,
			ux: {
				mng: false,
				tag: false,
				show: false,
				uploading: false,
				uploaded: false
			}
		}
	}
};

</script>