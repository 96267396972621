<template>
	<div>
		<b-row class="mb-1">
			<b-col cols="12">
				<label>Nome</label>
				<b-form-input v-model="sessione.nome" type="text"></b-form-input>
			</b-col>
			<b-col cols="12" v-if="!noPersone || noPersone == false">
				<label>Giocatori</label>
				<multiselect v-model="sessione.players" :disabled="!hasSetup(sessione, 'playerPicker', true)" :options="persone" :multiple="true" placeholder="Seleziona" label="name" track-by="id" group-values="items" group-label="label" :group-select="true"></multiselect>
			</b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col>
				<label>Data</label>
				<b-form-input v-model="sessione.start_at" type="date"></b-form-input>
			</b-col>
			<b-col>
				<label>Dalle ore</label>
				<b-form-input v-model="sessione.from_hour" type="time"></b-form-input>
			</b-col>
			<b-col>
				<label>Alle ore</label>
				<b-form-input v-model="sessione.to_hour" type="time"></b-form-input>
			</b-col>
		</b-row>
		<b-row class="my-2" align-v="center">
			<b-col>
			</b-col>
			<b-col>
				<div class="pull-right">
					<b-button v-if="false" variant="outline-primary" @click="downloadPdf"><i class="fa fa-eye fa-fw"></i> stampa pdf</b-button>
				</div>
			</b-col>
		</b-row>

		<div v-if="sessione.sessione_tipo.estensione">
			<div :is="componenteIntestazione" :sessione="sessione" :estensione="sessione.estensione" @refresh="$emit('refresh')"></div>
		</div>

		<div class="mb-1" v-if="hasSetup(sessione, 'hasSkills', false)">
			<SkillPickerGroup cols="4" :model="sessione.skills_rel" pills :config="{ gruppi: hasSetup(sessione, 'hasSkillsGroups', false), radici: hasSetup(sessione, 'hasSkills', false), level: 1, onlyone: true, hideRating: true }"></SkillPickerGroup>
		</div>

		<div class="mb-1" v-if="hasSetup(sessione, 'hasTools', false)">
			<ToolPicker :tollerant="true" :model="sessione.tools_rel" :config="hasSetup(sessione, 'hasTools', false)"></ToolPicker>
		</div>

		<div class="mb-1" v-if="hasSetup(sessione, 'massiveActivityCheck', false)">

			<b-card>
				<PlayersActivityCheckPresets :sessione="sessione" :illnesses="illnesses" :injuries="injuries" :playersPreset="playersPreset"></PlayersActivityCheckPresets>
			</b-card>
			
		</div>

		<!-- <div class="mb-1" v-if="hasSetup(sessione, 'disableAutoPlayerInPhase', false)">
			{{ sessione.sessioneTipo.setup.disableAutoPlayerInPhase }}
		</div> -->

	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '../../../store/types';
var Auth = require('../../../auth.js');
export default {
	mixins: [
		sessionMixin
	],
	components: {
		SessioneIntestazioneNutrition: require('@/components/layout/Sessioni/Medical/Intestazione_Sessione_Nutrition.vue').default,
		SessioneIntestazionePsicologica: require('@/components/layout/Sessioni/Medical/Intestazione_Sessione_Piscologica.vue').default,
		SessioneIntestazionePalestra: require('@/components/layout/Sessioni/Football/Intestazione_Sessione_Palestra.vue').default,
		SessioneIntestazionePrevenzione: require('@/components/layout/Sessioni/Football/Intestazione_Sessione_Prevenzione.vue').default,
		SessioneIntestazioneVideo: require('@/components/layout/Sessioni/Football/Intestazione_Sessione_Video.vue').default,
		SessioneIntestazioneDexa: require('@/components/layout/Sessioni/Dexa/Intestazione_Sessione.vue').default,
		SessioneIntestazioneLingua: require('@/components/layout/Sessioni/Generica/Intestazione_Sessione_Lingua.vue').default,
		SessioneIntestazioneNazionale: require('@/components/layout/Sessioni/Generica/Intestazione_Sessione_Nazionale.vue').default,
		SkillPickerGroup: require('@/components/layout/Sessioni/Generica/SkillPickerGroup.vue').default,
		ToolPicker: require('@/components/layout/Sessioni/Generica/ToolPicker.vue').default,
		PlayersActivityCheckPresets: require('@/components/layout/Sessioni/Generica/PlayersActivityCheckPresets.vue').default,
	},
	props: [ 'sessione', 'persone', 'playersPreset', 'illnesses', 'injuries', 'noPersone' ],
	data: function(){
		return {

		}
	},
	computed: {
		componenteIntestazione: function() {
			return 'SessioneIntestazione' + this.sessione.sessione_tipo.nome;
		}
	},
	methods: {
		
	}
}
</script>