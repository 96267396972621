<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		

		<div class="card">
			<b-container fluid>

				<b-row class="py-1">
					<b-col>

						<b-card title="Alerts">

							<table class="table table-striped table-sm">
								<thead>
									<tr>
										<th width="35%">Contratto</th>
										<th width="35%">Bonus</th>
										<th>Progresso</th>
									</tr>
								</thead>
								<tbody>
									<tr class="cursor-pointer" v-for="i in watchdog" @click.prevent="$router.push({ name: 'contratti_detail', params: { id: i.base.entity.id } })">
										<td>
											<strong>{{ i.base.entity.persona.cognome }}</strong>
											{{ i.base.entity.persona.nome }}
										</td>
										<td>
											{{i.bonus.entity.name}}
										</td>
										<td>
											<b-progress variant="warning" show-progress animated show-value>
												<b-progress-bar :value="100*i.bonus.progress.toFixed(2)" :label="100*i.bonus.progress.toFixed(2) + '%'"></b-progress-bar>
											</b-progress>
										</td>
									</tr>
								</tbody>
							</table>

						</b-card>

					</b-col>
					<b-col>
						<b-card title="Ultimi Bonus">
							
							<b-table-simple small striped>
								<b-thead>
									<b-tr>
										<b-th>Contratto</b-th>
										<b-th>Bonus</b-th>
										<b-th></b-th>
										<b-th>Data</b-th>
									</b-tr>
								</b-thead>
								<b-tbody>
									<b-tr v-for="bonus in bonus_recenti">
										<b-td>
											<strong>{{ bonus.persona.persona.cognome }}</strong>
											 {{ bonus.persona.persona.nome }}
										</b-td>
										<b-td>
											{{bonus.bonus.name}}
										</b-td>
										<b-td>
											{{bonus.amount | money }}
										</b-td>
										<b-td>
											{{bonus.date | formatDate}}
										</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>

						</b-card>
					</b-col>
					
					<!--
					<b-col>
						<b-card title="Contratti">

							<table class="table table-striped table-sm" v-if="contratti">
								<thead>
									<tr>
										<th colspan="2"></th>
										<th colspan="2" class="text-center text-muted small" style="background-color: rgba(0,0,0,.05)">Stagione <em>in corso</em></th>
									</tr>
									<tr>
										<th>Contratto</th>
										<th>Scadenza</th>
										<th>Importo Fisso</th>
										<th>Bonus Maturati</th>
									</tr>
								</thead>
								<tbody>
									<tr class="cursor-pointer" v-for="c in contratti" @click.prevent="$router.push({ name: 'contratti_detail', params: { id: c.entity.id } })">
										<td>
											<strong>{{ c.entity.persona.cognome }}</strong>
											{{ c.entity.persona.nome }}
										</td>
										<td>{{ c.entity.fine | formatDate }}</td>
										<td>{{ seasonFixed(c) ? seasonFixed(c).importo_lordo : 0 | money }}</td>
										<td>{{ c.amount_display }} <small class="text-muted">({{ howManyBonus(c) }})</small></td>
									</tr>
								</tbody>
							</table>
							<div v-else>Attendi il caricamento dei contratti…</div>

						</b-card>
					</b-col>
					-->
				</b-row>

				<b-row class="mt-1">
					<b-col>
						<b-card title="Ultimi Pagamenti">

							<table class="table table-striped table-sm">
								<thead>
									<tr>
										<th>Contratto</th>
										<th>Pagato</th>
										<th width="40%">Importo</th>
									</tr>
								</thead>
								<tbody v-if="pagati.length">
									<tr class="cursor-pointer" v-for="c in pagati" @click.prevent="$router.push({ name: 'contratti_detail', params: { id: c.contratto.id } })">
										<td>{{ c.contratto.persona.cognome }}</td>
										<td class="small">
											{{ c.pagato_il | formatDate }}
										</td>
										<td>{{ c.stipendio_annuo_lordo | money }}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="3" class="text-center">Non ci sono pagamenti pagati</td>
									</tr>
								</tbody>
							</table>

						</b-card>
					</b-col>
					<b-col>
						<b-card title="Scaduti da Pagare">

							<table class="table table-striped table-sm">
								<thead>
									<tr>
										<th>Contratto</th>
										<th>Scadenza</th>
										<th width="40%">Importo</th>
									</tr>
								</thead>
								<tbody v-if="scaduti.length">
									<tr class="cursor-pointer" v-for="c in scaduti" @click.prevent="$router.push({ name: 'contratti_detail', params: { id: c.contratto.id } })">
										<td>{{ c.contratto.persona.cognome }}</td>
										<td class="small">{{ c.data_previsto_pagamento | formatDate }}</td>
										<td>{{ c.stipendio_annuo_lordo | money }}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="3" class="text-center">Non ci sono pagamenti scaduti</td>
									</tr>
								</tbody>
							</table>

						</b-card>
					</b-col>
					<b-col>
						<b-card title="In Scadenza">

							<table class="table table-striped table-sm">
								<thead>
									<tr>
										<th>Contratto</th>
										<th>Scadenza</th>
										<th width="40%">Importo</th>
									</tr>
								</thead>
								<tbody v-if="inScadenza.length">
									<tr class="cursor-pointer" v-for="c in inScadenza" @click.prevent="$router.push({ name: 'contratti_detail', params: { id: c.contratto.id } })">
										<td>{{ c.contratto.persona.cognome }}</td>
										<td class="small">{{ c.data_previsto_pagamento | formatDate }}</td>
										<td>{{ c.stipendio_annuo_lordo | money }}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="3" class="text-center">Non ci sono pagamenti in scadenza</td>
									</tr>
								</tbody>
							</table>

						</b-card>
					</b-col>
				</b-row>

			</b-container>
		</div>

		<footerapp></footerapp>
	</section>
</template>

<script>

import _ from 'lodash';

var Auth = require('../../../auth.js');
//import axios from 'axios';
//import * as types from '../../../store/types';
import moment from 'moment';
import { mapState } from 'vuex';
export default {
	data: function(){
		return {
			loading: false,
			contratti: false,
			scaduti: false,
			pagati: false,
			bonus_recenti: false,
			inScadenza: false
		}
	},
	computed: {
		watchdog: function() {

			var v = _.filter(this.contratti, function(i) {
				return _.filter(_.map(i.bonuses, 'watchdogs')).length > 0
			});

			var alerts = [];

			_.each(v, function(a) {

				_.each(a.bonuses, function(b) {
					if(b.watchdog == true) {

						for(var c in b.watchdogs) {
							if(b.watchdogs[c]) {
								alerts.push({
									base: a,
									bonus: b,
									wd: b.entity.watchdogs[c]
								})		
							}
						}

						console.log(alerts);
						//console.log(b.entity.watchdogs);
						// alerts.push({
						// 	base: a,
						// 	bonus: b
						// })
					}
				})

			});

			return alerts;

		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.getContratti();
		},
		seasonId() {
			this.getContratti();
		},
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		Contratto: require('../../layout/contratti/Contratto.vue').default,
		TestBlockly: require('@/components/layout/contratti/testBlockly.vue').default
	},
	created: function () {
		this.getContratti();
		this.getScaduti();
		this.getPagati();
		this.getInScadenza();
		this.getBonusRecenti();
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid())
				return m.format('DD/MM/YYYY'); 

			return "N/D";
		},
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return '€ ' + formatter.format(v).replace('€', '');
		}
	},
	methods: {
		seasonFixed: function(c) {
			return _.find(c.entity.periodi, function(e) {
				return this.seasonId == e.variabili_squadra.stagione_id
			}.bind(this));
		},
		howManyBonus: function(c) {

			var bonusValidi = _.filter(c.bonuses, function(e) {
				return e.result === true;
			});

			return bonusValidi.length + '/' + c.bonuses.length;

		},
		getScaduti: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/pagamenti/scaduti').then(function(data) {
				this.scaduti = data.data;
			});
		},
		getPagati: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/pagamenti/pagati').then(function(data) {
				this.pagati = data.data;
			});
		},
		getInScadenza: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/pagamenti/in-scadenza').then(function(data) {
				this.inScadenza = data.data;
			});
		},
		getBonusRecenti: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/latest-bonus', {params: { team_id: this.rosaTeamId, season_id: this.seasonId  }}).then(function(data) {
				this.bonus_recenti = data.data;
			});
		},
		getContratti: function() {				
			this.players = [];
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/dashboard', {params: { team_id: this.rosaTeamId, season_id: this.seasonId  }}).then((response) => {
				if (response.ok) {
					this.contratti = response.data;
					this.loading = false;
				}
			});
		},
	}
}
</script>