export const ROUTES_MEDICAL = [
{
    path: '/medical/calendar',
    name: 'medical_calendar',
    component: require('../components/pages/calendar.vue').default,
    meta: {
        module: 'medical',

        label: 'Calendario',
        requiresAuth: true,
        showInMenu: true,
        parent: '',
        enableRosaTeam: true,
        permesso: 'medical',
    }
},

{
    path: '/medical/dashboard',
    name: 'medical_rosa',
    component: require('../components/pages/medical/dashboard.vue').default,
    meta: {
        module: 'medical',

        label: 'Rosa',
        requiresAuth: true,
        showInMenu: true,
        parent: '',
        enableRosaTeam: true,
        showSeason: true,
        enableSeason: true,
        permesso: 'medical',
    }
},

{
    path: '/medical/player/:id',
    name: 'medical_giocatore_scheda',
    component: require('../components/pages/medical/player.vue').default,
    meta: {
        module: 'medical',
        label: 'Scheda Giocatore',
        requiresAuth: true,
        showInMenu: false,
        parent: 'medical_rosa',
        permesso: 'medical',
    }
},

{
    path: '/medical/player/edit/:id',
    name: 'medical_rosa_edit',
    component: require('../components/pages/football/rosa/edit.vue').default,
    meta: {
        module: 'medical',

        label: 'Modifica Anafrafica',
        requiresAuth: true,
        showInMenu: false,
        parent: 'medical_giocatore_scheda',
        menusub: [ 'medical_giocatore_scheda'],
        permesso: 'football_rosa_edit',
        showSeason: true,
    }
},

{
    path: '/medical/injuries/:id',
    name: 'medical_injuries',
    component: require('../components/pages/medical/injuries.vue').default,
    meta: {
        module: 'medical',
        label: 'Injuries',
        requiresAuth: true,
        showInMenu: false,
        parent: 'medical_rosa',
        showSeason: true,
        permesso: 'medical',
    }
},

{
    path: '/medical/illnesses/:id',
    name: 'medical_illnesses',
    component: require('../components/pages/medical/illnesses.vue').default,
    meta: {
        module: 'medical',
        label: 'Illnesses',
        requiresAuth: true,
        showInMenu: false,
        parent: 'medical_rosa',
        showSeason: true,
        permesso: 'medical',
    }
},

{
    path: '/medical/injuries/rtp/:id',
    name: 'medical_injuries_rtp',
    component: require('../components/pages/medical/injuries_rtp.vue').default,
    meta: {
        module: 'medical',
        label: 'Injuries rtp',
        requiresAuth: true,
        showInMenu: false,
        parent: 'medical_rosa',
        showSeason: true,
        permesso: 'medical',
    }
},

{
    path: '/medical/sessione/:id/:tipo',
    name: 'medical_sessione',
        // component: require('../components/pages/medical/sessione_stage.vue').default, ////// <== Inverti questo con la riga sotto, per provarla...
    component: require('../components/pages/medical/sessione.vue').default,
    meta: {
        module: 'medical',

        label: 'Sessione',
        requiresAuth: true,
        parent: '',
        permesso: 'medical',
    }
},

{
    path: '/medical/sessione/video/:id/:tipo',
    name: 'medical_sessione_video',
    component: require('../components/pages/football/video/video_sessione.vue').default,
    meta: {
        module: 'medical',

        label: 'Video',
        requiresAuth: true,
        parent: '',
        menusub: ['medical_sessione'],
        permesso: 'video_sessioni',
    }
},

{
    path: '/medical/sessione/videotag/:id/:tipo/:id_video',
    name: 'medical_sessione_videotag',
    component: require('../components/pages/football/video/videotag_sessione.vue').default,
    meta: {
        module: 'medical',

        label: 'Videotag',
        requiresAuth: true,
        parent: '',
        menusub: ['medical_sessione', 'medical_sessione_video'],
        permesso: 'video_sessioni',
    }
},

{
    path: '/medical/sessione/presentazione/:id/:tipo',
    name: 'medical_sessione_presentazione',
    component: require('../components/pages/football/sessione_presentazione.vue').default,
    meta: {
        module: 'medical',

        label: 'Presentazione',
        requiresAuth: true,
        parent: '',
        permesso: 'video_sessioni',
    }
},

{
    path: '/medical/glossario',
    name: 'medical_glossario',
    component: require('../components/pages/medical/glossario.vue').default,
    meta: {
        module: 'medical',

        label: 'Glossario',
        requiresAuth: true,
        showInMenu: true,
        enableRosaTeam: true,
        parent: '',
        permesso: 'medical_glossario',
    }
},

{
    path: '/medical/palestra/esercizi',
    name: 'medical_palestra_esercizi',
    redirect: '/medical/glossario',
},

{
    path: '/medical/palestra/esercizi/new',
    name: 'medical_palestra_esercizi_new',
    component: require('../components/pages/football/palestra/esercizi_new.vue').default,
    meta: {
        module: 'medical',

        label: 'Nuovo esercizio',
        requiresAuth: true,
        showInMenu: false,
        parent: '',
        permesso: 'palestra_ese_mng',
    }
}, 

{
    path: '/medical/palestra/esercizi/edit/:id',
    name: 'medical_palestra_esercizi_edit',
    component: require('../components/pages/football/palestra/esercizi_edit.vue').default,
    meta: {
        module: 'medical',

        label: 'Esercizio',
        requiresAuth: true,
        showInMenu: false,
        parent: '',
        permesso: 'palestra_esercizi',
    }
},

{
    path: '/medical/piscina/esercizi',
    name: 'medical_piscina_esercizi',
    redirect: '/medical/glossario',
},

{
    path: '/medical/piscina/esercizi/new',
    name: 'medical_piscina_esercizi_new',
    component: require('../components/pages/football/piscina/esercizi_new.vue').default,
    meta: {
        module: 'medical',

        label: 'Nuovo esercizio',
        requiresAuth: true,
        showInMenu: false,
        parent: '',
        permesso: 'piscina_ese_mng',
    }
}, 

{
    path: '/medical/piscina/esercizi/edit/:id',
    name: 'medical_piscina_esercizi_edit',
    component: require('../components/pages/football/piscina/esercizi_edit.vue').default,
    meta: {
        module: 'medical',

        label: 'Esercizio',
        requiresAuth: true,
        showInMenu: false,
        parent: '',
        permesso: 'piscina_esercizi',
    }
},
];
