<template id="dashboard">
	<section class="allenamento">
		<headapp></headapp>
		<div v-if="sessione">
			<menusub></menusub>
			<div class="card border-0 rounded-0" v-if="sessione">
				<div class="card-body">
					<div class="container-fluid">

						<ModalMatrice v-if="ux.showMatrice" @close="ux.showMatrice = false;" @save="ux.showMatrice = false; getSessione()" :sessioneId="ux.showMatrice"></ModalMatrice>
						<b-row>
							<b-col>
								<div class="card-title">
									<span>{{ getTipoSessione }}</span>
								</div>
							</b-col>
							<b-col class="text-right">
								<b-button size="sm" variant="outline-primary" v-if="auth.isUserEnable('football_partite') && sessione.partita_rel" :to="{ name: 'football_partita', params: { id: sessione.partita_rel.partita_id }}"> <i class="fa fa-futbol-o" aria-hidden="true"></i> Partita</b-button>
								<b-button size="sm" variant="outline-primary" v-if="$route.params.tipo == 19" :to="{ name: $route.meta.module + '_sessione_presentazione', params: { id: $route.params.id, tipo: $route.params.tipo }}"> <i class="fa fa-desktop" aria-hidden="true"></i>  Presentazione</b-button>
								<b-button size="sm" variant="outline-primary" v-if="auth.isUserEnable('video_sessioni')" :to="{ name: $route.meta.module + '_sessione_video', params: { id: $route.params.id, tipo: $route.params.tipo }}"> <i class="fa fa-camera" aria-hidden="true"></i> Video</b-button>
								<b-button size="sm" variant="outline-primary" v-if="hasStampa" @click="downloadPdf"> Stampa pdf</b-button>
								<b-button size="sm" v-if="hasSetup(sessione, 'matrixHideButton', false) == false" variant="outline-secondary" @click.prevent="ux.showMatrice = sessione.id">{{ hasSetup(sessione, 'matrixLabel', 'Activity Check') }}</b-button>
								<div class="card-subtitle text-muted mt-1" v-if="auth.isUserEnable('sessione_view_autore')" style="font-size:0.9rem">
									<span>Creata da: <strong>{{ sessione.utente ? sessione.utente.nomeCompleto : 'Autore non disponibile'}}</strong> il <strong>{{ sessione.created_at | formatDateHour }}</strong></span>
								</div>
								<div class="card-subtitle text-muted mt-1" v-if="auth.isUserEnable('sessione_ultima_modifica')" style="font-size:0.9rem">
									<span>Modificata da: <strong>{{ sessione.editore ? sessione.editore.nomeCompleto : 'Editore non disponibile'}}</strong> il <strong>{{ sessione.updated_at | formatDateHour }}</strong></span>
								</div>
							</b-col>
						</b-row>
						<div class="content">

							<div :is="hasSetup(sessione, 'component', 'VistaDettaglio')"
								:playerPreset="playersPreset"
								:playerOptions="player_options"
								:sessione="sessione"
								:sessioneTipi="sessione_tipi"
								:fasiGeneriche="fasiGeneriche"
								:estensioniFase="estensioniFase"
								:seasonId="season_id"
								:team="team"
								@submit="handleSubmit"
								@refresh="getSessione"
								@newFase="showAddFasi"
								@removeFase="removeFase($event)"
								@duplicateFase="duplicaFase($event)"
								@moveFase="sposta($event.index, $event.dove)">
							</div>
							<!-- <pre>{{payloadUpdate}}</pre> -->

							<ModalNewFase @close="ux.newFase = false" @selected="aggiungiFasi" :sessione="sessione" :glossario="glossario" :sessioneCorrente="sessioneCorrente" v-if="ux.newFase"></ModalNewFase>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';

import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	name: 'Sessione',
	mixins: [
		sessionMixin
	],
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		treedbody:            require('../../layout/3dbody.vue').default,
		VistaDettaglio: require('@/components/layout/Sessioni/Generica/VistaDettaglio.vue').default,
		VistaProva: require('@/components/layout/Sessioni/Generica/VistaTemplate.vue').default,
		VistaAttivita: require('@/components/layout/Sessioni/Generica/VistaAttivita.vue').default,
		ModalNewFase: require('../../layout/Sessioni/ModalNewFase.vue').default,
		ModalMatrice: require('@/components/layout/Sessioni/Matrice.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
	},
	data: function(){
		return{
			playersPreset: [],
			ux: {
				newFase: false,
				showMatrice: false,
				showVideo: false
			},
			sessione: null,
			player_options: [],
			sessione_tipi: [],
			season_id: null,
			fasiGeneriche: null,
			estensioniFase: [],
			team: null,
		}
	},
	created: function () {
		this.getTipiSessione();
		this.getPlayers();
		this.getFasiGeneriche();
		this.getSessione();
		this.getSessioneEstensioniFase();
		this.getTeam();
	},
	methods: {
		getSessioneEstensioniFase: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessioni/estensioni/glossario', { params: { area: this.$route.params.tipo ? this.$route.params.tipo : '' }}).then(function(res) {
				this.estensioniFase = res.data;
			});
		},
		aggiungiFasi: function(fasi) {
			this.sessione.fasi = _.union(this.sessione.fasi, fasi);
		},
		getTipiSessione() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessione/tipi').then((response) => {
				if (response.ok) {
					this.sessione_tipi = response.data;
				}
			}, (response) => {
			});
		},
		getFasiGeneriche() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessioni/glossario', {params: { area: this.$route.params.tipo ? this.$route.params.tipo : '', team_id: this.rosaTeamId}} ).then(function(data) {
				this.fasiGeneriche = data.data;
			})
		},
		getSessione() {

			if(this.$route.params.id) {
				axios.get(`/sessione/` + this.$route.params.id)
				.then((res) => {

					res.data.players = res.data.persone.map(item => ({
						name: item.cognome + ' ' + item.nome,
						id: item.id,
					}));
					
					res.data.start_at = moment(res.data.inizio).format("YYYY-MM-DD");
					res.data.from_hour 	= moment(res.data.inizio).format("HH:mm:ss");
					res.data.to_hour 	= moment(res.data.fine).format("HH:mm:ss");
					res.data.season_id  = res.data.stagione_id;
					this.season_id  = res.data.stagione_id;
					this.sessione = res.data;
					this.sessione.fasi = _.map(this.sessione.fasi, function(fase) {

						if(!fase.durata_reale) {
							fase.durata_reale = fase.durata;
						}
						this.$set(fase, 'players', _.map(this.sessione.persone, function(item) {
							var realItem = _.find(fase.persone, { id: item.id });
							return {
								name: item.cognome + ' ' + item.nome,
								id: item.id,
								inPhase: _.map(fase.persone, 'id').indexOf(item.id) !== -1,
								activity_check: { reason: (realItem ? realItem.pivot.reason : null), checked: (realItem ? realItem.pivot.checked : null), nota: (realItem ? realItem.pivot.nota : '') }
							}
						}));

						/* Prima o poi.... 👇🏻 */

						this.$set(fase, 'injuries', _.map(fase.injuries, function(item) {
							return {
								id: item.id,							
								data: item.data,
								pathology: item.pathology,
								persona_id: item.persona_id
							}
						}));

						this.$set(fase, 'illnesses', _.map(fase.illnesses, function(item) {
							return {
								id: item.id,							
								data: item.data,
								illness_types: item.illness_types,
								illness_other_types: item.illness_other_types,
								persona_id: item.persona_id
							}
						}));
						
						return fase;

					}.bind(this));
				}).catch((err) => {
					this.loading = false;
				});

			}
		},

		handleSubmit: function () {

			if(!this.isManagerSessione) {
				return false;
			}

			const formData = new FormData();
			formData.append('payloadUpdate', JSON.stringify(this.payloadUpdate));
    		this.payloadUpdate.fasi.forEach(function(element, i) {
    			if( element != undefined && element.entity && element.entity.file) {
    				formData.append("fase_image_" + i, element.entity.file);
    			}
    		});

			this.$http.post(this.$store.state.apiEndPoint + '/sessione/update/' + this.sessione.id, formData).then(function(response) {

				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getSessione();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		showAddFasi: function () {
			this.ux.newFase = true;
		},
		removeFase: function (k) {
			if(confirm("Confermi l'eliminazione della fase?")) {
				this.sessione.fasi.splice(k, 1);
			}
		},
		duplicaFase: function (k) {
			var newFase = _.cloneDeep(this.sessione.fasi[k]);
			newFase.id = null;
			if(newFase.entita_id) {
				delete newFase.entita_id;
			}
			if(newFase.entity) {
				if(newFase.entity.id) {
					newFase.entity.id = null;
				}
				if(newFase.entity.entita_id) {
					delete newFase.entity.entita_id;
				}
			}
			this.sessione.fasi.push(newFase);
		},
		sposta(index, dove) {
			if(dove == 1) {
				var temp = this.sessione.fasi.splice(index, 1); 
				this.sessione.fasi.splice(index + 1, 0, temp[0]);
			} else {
				var temp = this.sessione.fasi.splice(index, 1); 
				this.sessione.fasi.splice(index - 1, 0, temp[0]);
			}
		},
		getPlayers: function() {				
			this.player_options = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: this.rosaTeamId, season_id: this.season_id }}).then((response) => {
				if (response.ok)
				{
					this.player_options = [
					{
						label: 'Seleziona tutti',
						items: response.data.map(item => ({								
							name: item.cognome + ' ' + item.nome,
							id: item.id,
						})),
					}
					];
				}
			}, (response) => {
			});
		},
		downloadPdf() {
			window.open(this.$store.state.apiEndPoint + '/sessione/pdf/' + this.sessione.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
					if (response.ok)
					{
						this.team = response.data;
					}
				}, (response) => {
				});
			}
		},

	},
	computed: {
		glossario: function() {
			return this.fasiGeneriche;
		},
		payloadUpdate: function() {
			return {
				nome: this.sessione.nome,
				inizio: this.sessione.start_at + ' ' + this.sessione.from_hour,
				fine: this.sessione.start_at + ' ' + this.sessione.to_hour,
				sessione_tipo_glo_id: this.sessione.sessione_tipo_glo_id,
				persone: this.sessione.players,
				estensione: this.sessione.estensione,
				skills_rel: this.sessione.skills_rel,
				tools_rel: this.sessione.tools_rel,
				fasi: this.sessione.fasi,
				setup: this.sessione.setup
			}
		},
		sessioneCorrente: function() {
			return _.find(this.sessione_tipi, { id: this.sessione.sessione_tipo_glo_id });
		},
		personeCorrenti: function() {
			return _.map(this.sessione.persone, function(a) {
				return {
					name: a.cognome + ' ' + a.nome,
					id: a.id
				}
			});
		},
		personeIds: function() {
			return _.map(this.personeCorrenti, 'id');
		},
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId']),
		getTipoSessione: function() {
			if(this.sessione_tipi.length > 0) {
				return this.sessione_tipi.find((item) => item.id == this.$route.params.tipo).label;	
			} return 'Sessione';
		},
		isManagerSessione: function() {
			if(this.sessione && this.sessione.sessione_tipo) {

				if(this.team && this.sessione.categoria_id != this.team.id_categoria) {
					return false;
				}

				return this.auth.isUserEnable('sessione_' + this.sessione.sessione_tipo.nome.toLowerCase().replaceAll(' ', '') + '_mng');
			}
			return false;
		},
		hasStampa() {
			return this.hasSetup(this.sessione, 'hasStampa', false);
		},
	},
	watch: {
		'sessione.players': {
			deep: true,
			handler: function() {

				var inPhaseDefault = !(this.hasSetup(this.sessione, 'disableAutoPlayerInPhase', false));

				_.each(this.sessione.players, function(player) {

					var the_player = _.cloneDeep(player);
					the_player.inPhase = inPhaseDefault;
					the_player.activity_check = {
						checked: null,
						nota: null,
						reason: null
					};

					_.each(this.sessione.fasi, function(fase) {

						var playerFase = _.cloneDeep(the_player);
						if(fase.setup != undefined && fase.setup.ifInPhaseAlsoActivityCheck != undefined && inPhaseDefault)
							playerFase.activity_check.checked = true;
						if(!_.find(fase.players, { id: player.id }))
							fase.players.push(playerFase);

					}.bind(this));

				}.bind(this));

			}
		},
		rosaTeamId() {
			this.getPlayers();
		}, 
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return date;
		}
	}, 
}
</script>