<template id="giocatore">
	<section class="giocatore">
		<div>
			<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="giocatore">
				<div class="card-body">
					<div class="row flex-items-xs-middle">
						<div class="col-5">
							<div class="media">
								<img v-bind:src="giocatore.avatar_url" class="" height="100">
								<div class="media-body align-self-center ml-3">
									<h3 class="text-primary mb-0"><strong>{{giocatore.nome}} {{giocatore.cognome}}</strong></h3>
									<span class="text-muted">{{ giocatore.last_carriera_sportiva && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 ? giocatore.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</span>
									
									<div v-if="giocatore.info_economica">
										<a href="" @click.prevent="showDetail = !showDetail">
											<font-awesome-icon icon="circle-info" class="mr-1 text-muted" />
											<small class="text-muted">Visualizza info giocatore</small>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-7 text-right">

							<div v-if="showDetail == true">
								
								<b-card no-body class="rounded-0 border-0 m-0 h-100">
									<b-tabs card>
										<b-tab title="Generali" active>
											<table class="table table-sm table-giocatore mb-0 p-0">
												<tr> <td>Nome</td> <td>{{ giocatore.nome }}</td> </tr>
												<tr> <td>Cognome</td> <td>{{ giocatore.cognome }}</td> </tr>
												<tr> <td>Data di nascita</td> <td> {{ giocatore.nascita_data | formatDate }}</td> </tr>
												<tr v-if="giocatore.nascita_paese"> <td>Paese di nascita</td> <td><flag :country="giocatore.nascita_paese.sigla" :size="16"></flag> {{ giocatore.nascita_paese.paese }}</td> </tr>
												<tr> <td>Luogo di nascita</td> <td>{{ giocatore.info_extra ? giocatore.info_extra.nascita_comune : ''}} {{ giocatore.nascita_paese_id == 118 && giocatore.info_extra && giocatore.info_extra.nascita_provincia ? '(' + giocatore.info_extra.nascita_provincia + ')' : '' }}</td> </tr>
												<tr v-if="giocatore.indirizzi.length > 0 && giocatore.indirizzi[0].indirizzo.paese"> <td>Paese di residenza</td> <td><flag :country="giocatore.indirizzi[0].indirizzo.paese.sigla" :size="16"></flag> {{ giocatore.indirizzi[0].indirizzo.paese.paese }}</td> </tr>
												<tr v-if="giocatore.indirizzi.length > 0"> <td>Luogo di residenza</td> <td>{{giocatore.indirizzi[0].indirizzo.citta}} {{ giocatore.indirizzi[0].indirizzo.paese_id == 118 && giocatore.indirizzi[0].indirizzo.cap && giocatore.indirizzi[0].indirizzo.provincia ? ', ' + giocatore.indirizzi[0].indirizzo.cap +' (' + giocatore.indirizzi[0].indirizzo.provincia + ')' : '' }}</td> </tr>
												<tr v-if="giocatore.indirizzi.length > 0"> <td>Indirizzo di residenza</td> <td>{{giocatore.indirizzi[0].indirizzo.indirizzo }} </td> </tr>
												<tr v-if="email"> <td>Email</td> <td>{{ email }}</td> </tr>
												<tr v-if="pec"> <td>Email PEC</td> <td>{{ pec }}</td> </tr>
												<tr v-for="(numero, index) in telefoni"> <td>Telefono {{ index > 0 ? index + 1 : '' }}</td> <td>{{ numero }}</td> </tr>
												<tr v-if="giocatore.giocatore && giocatore.giocatore.id_gps"> <td>ID GPS</td> <td>{{giocatore.giocatore.id_gps}}</td> </tr>
											</table>
										</b-tab>
										<b-tab title="Corporatura">
											<table class="table table-sm table-giocatore">
												<tr> <td>Altezza</td> <td><span v-if="giocatore.info_fisica">{{ giocatore.info_fisica.altezza }} cm</span></td> </tr>
												<tr> <td>Peso</td> <td><span v-if="giocatore.info_fisica">{{ giocatore.info_fisica.peso }} kg</span></td> </tr>
												<tr> <td>Piede</td> <td><span v-if="giocatore.giocatore">{{ piede[giocatore.giocatore.piede] }}</span></td> </tr>
												<tr> <td>Numero Piede</td> <td><span v-if="giocatore.info_fisica">{{ giocatore.info_fisica.num_piede }}</span></td> </tr>
												<tr> <td>Baricentro</td> <td><span v-if="giocatore.info_fisica">{{ baricentro[parseInt(giocatore.info_fisica.baricentro)] }}</span></td> </tr>
												<tr> <td>Somatotipo</td> <td ><span v-if="giocatore.info_fisica">{{ somatotipo[parseInt(giocatore.info_fisica.somatotipo)] }}</span></td> </tr>
												<tr> <td>Costituzione</td> <td><span v-if="giocatore.info_fisica">{{ costituzione[parseInt(giocatore.info_fisica.costituzione)] }}</span></td> </tr>
											</table>
										</b-tab>
										<b-tab title="Carriera">
											<table class="table table-sm table-giocatore">
												<tr> <td>Matricola FIGC</td> <td><span v-if="giocatore.info_extra">{{ giocatore.info_extra.matricola_figc }}</span></td> </tr>
												<tr> <td>N. tessera</td> <td><span v-if="tessera">{{ tessera.numero }}</span></td> </tr>
												<tr> <td>Ultima Visita Medica</td> <td><span v-if="idoneita">{{ idoneita.emissione | formatDate }}</span></td> </tr>
												<tr> <td>Prossima Visita Medica</td> <td><span v-if="idoneita">{{ idoneita.scadenza | formatDate }}</span></td> </tr>
												<tr v-if="giocatore.last_carriera_sportiva"> <td>Numero Maglia</td> <td>{{ giocatore.last_carriera_sportiva.maglia }}</td> </tr>
												<tr> <td>Ruolo Ideale</td> <td> {{ giocatore.last_carriera_sportiva && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 ? giocatore.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</td> </tr>
											</table>
										</b-tab>
										<b-tab title="Dati economici" v-if="auth.isUserEnable('contratti')">
											<table class="table table-sm table-giocatore">
												<tr> <td>Contratto</td> <td>{{giocatore.info_economica && giocatore.info_economica.has_contratto ? 'SI' : 'NO' }}</td> </tr>
												<tr> <td>Scadenza Contratto</td> <td><span v-if="contratto"> {{ contratto.scadenza | formatDate }} </span></td> </tr>
												<tr v-for="(agente, index) in agenti"> <td>Agente {{ index > 0 ? 'genitore ' + (index + 1) : '' }}</td> <td>{{ agente.cognome + ' ' + agente.nome }}</td> </tr>
												<tr> <td>Valore Gicatore</td> <td>{{ giocatore.info_economica && giocatore.info_economica.valore && giocatore.info_economica.valore > 0 ? '€ ' + giocatore.info_economica.valore : 'N/D' }}</td> </tr>
												<tr> <td>Rimborso spese</td> <td>{{ giocatore.info_economica && giocatore.info_economica.rimborso_spese ? 'SI' : 'NO' }}</td> </tr>
												<tr> <td>Stipendio/Rimborso lordo fisso annuo</td> <td> {{ giocatore.contratto && giocatore.contratto.stipendio_lordo && giocatore.contratto.stipendio_lordo > 0 ? '€ ' + giocatore.contratto.stipendio_lordo : 'N/D'}}</td> </tr>
												<tr> <td>IBAN</td> <td>{{ giocatore.info_economica ? giocatore.info_economica.iban : ''}}</td> </tr> 
												<tr> <td>Intestatario IBAN</td> <td>{{ giocatore.info_economica ?  giocatore.info_economica.intestatario_iban : '' }}</td> </tr>
												<tr> <td>Scuola AS Roma</td> <td>{{ giocatore.giocatore && giocatore.giocatore.frequenta_scuola_asroma ? 'SI' : 'NO' }}</td> </tr>
												<tr> <td>Scuola a carico AS Roma</td> <td>{{ giocatore.info_economica && giocatore.info_economica.scuola_a_carico ? 'SI' : 'NO' }}</td> </tr>
											</table>
										</b-tab>
										<b-tab title="In prova" v-if="prova">
											<table class="table table-sm">
												<th colspan="3">Periodi</th>
												<tr v-for="periodo in prova_periodi"> <td>{{ periodo.emissione | formatDate }} - {{ periodo.scadenza | formatDate }} </td><td><b-button v-if="auth.isUserEnable('egnyte') && periodo.path" size="sm" variant="primary" title="Visualizza Nulla Osta" @click="downloadDoc(periodo.id)" class="ml-2">Nulla Osta <i class="fa fa-search" aria-hidden="true"></i></b-button></td></tr>
											</table>
											<table class="table table-sm">
												<th colspan="2">Carriera</th>
												<tr v-for="carriera in prova_carriera"> <td>{{ carriera.stagione ? carriera.stagione.stagione : 'N/D'}}</td> <td> {{ carriera.societa.nome }}</td></tr>
											</table>
											<table class="table table-sm">
												<th colspan="2">Altri documenti</th>
												<tr v-for="doc in prova_documenti"> <td>{{ doc.nome }} </td> <td> <b-button v-if="auth.isUserEnable('egnyte') && doc.documento" size="sm" variant="primary" title="Visualizza Documento" @click="downloadDoc(doc.id)" class="ml-2">Documento <i class="fa fa-search" aria-hidden="true"></i></b-button></td></tr>
											</table>
										</b-tab>
										<b-tab title="Documenti">
											<table class="table table-sm table-giocatore mb-0 p-0">
												<tr v-for="(passaporto, index) in passaporti"> <td>Passaporto {{ index + 1 }} <flag v-if="passaporto.paese" :country="passaporto.paese.sigla" :size="16"></flag> </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ passaporto.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ passaporto.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && passaporto.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(passaporto.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-if="cartaidentita"> <td>Carta d'identità </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ cartaidentita.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ cartaidentita.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && cartaidentita.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(cartaidentita.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-if="codicefiscale"> <td>Tessera sanitaria / Codice fiscale </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ codicefiscale.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ codicefiscale.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && codicefiscale.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(codicefiscale.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-for="(patente, index) in patenti"> <td>Patente {{ index + 1 }} <flag v-if="patente.paese" :country="patente.paese.sigla" :size="16"></flag> </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ patente.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ patente.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && patente.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(patente.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-if="circolazione"> <td>Permesso di circolazione </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ circolazione.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && circolazione.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(circolazione.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-if="soggiorno"> <td>Permesso di soggiorno </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ soggiorno.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && soggiorno.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(soggiorno.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-for="(mandato, index) in mandati"> <td>Mandato agente {{ index > 0 ? (index + 1) : ''}}</td> <td>  <b-button v-if="auth.isUserEnable('egnyte') && mandato.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(mandato.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-if="idoneita && idoneita.path && auth.isUserEnable('egnyte')"> <td>Certificato idoneità sportiva </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ idoneita.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && idoneita.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(idoneita.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-if="tessera && tessera.path && auth.isUserEnable('egnyte')"> <td>Tessera </td> <td> <b-button v-if="auth.isUserEnable('egnyte') && tessera.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(tessera.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-if="contratto && auth.isUserEnable('egnyte') && auth.isUserEnable('contratti')"> <td>Contratto </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ contratto.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && auth.isUserEnable('contratti') && contratto.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(contratto.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
												<tr v-if="greenpass"> <td>Green Pass </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ greenpass.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && greenpass.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(greenpass.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
											</table>
										</b-tab>
										<b-tab title="Parenti" v-if="parenti.length > 0">
											<table class="table table-sm table-giocatore">
												<tr v-for="parente in parenti"> <td>{{ parente.tipo.nome.charAt(0).toUpperCase() + parente.tipo.nome.slice(1) }}</td> <td>{{ parente.relazionante.nome }} {{ parente.relazionante.cognome }} </td></tr>
											</table>
										</b-tab>
										<b-tab title="Player Access" v-if="auth.isUserEnable('player_access_football') && giocatore.player_access">
											<table class="table table-sm table-giocatore">
												<tr> <td>Personal engagements</td> <td>{{giocatore.player_access.personal_engagements}}</td></tr>
												<tr v-if="giocatore.info_extra && giocatore.info_extra.spoken_languages"> <td>Spoken Languages</td> <td> <span v-for="lingua in JSON.parse(giocatore.info_extra.spoken_languages)" :title="lingua.nome" class="mr-2"> <flag :country="lingua.sigla" :size="24"></flag> </span></td> </tr>
												<tr> <td>Restrictions / Notes</td> <td>{{ giocatore.player_access.restrictions }}</td> </tr>
												<tr> <td>Ranking</td> <td>{{ giocatore.player_access.ranking }}</td> </tr>
											</table>
										</b-tab>
										<b-tab title="Medical" v-if="giocatore.cronologia_medica.length > 0" class="p-0">
											<b-tabs pills>
												<b-tab :title="tipo.gruppo" v-for="tipo in cronologia_medica_tipi" v-if="giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length > 0">
													<b-col v-if="tipo.id == 1">
														<table class="table table-sm table-giocatore" v-if="anamnesi_medica_selected">
															<tr v-if="anamnesi_medica_selected.data"> <td>Data</td> <td>{{ anamnesi_medica_selected.data | formatDate }}</td></tr>
															<tr v-if="anamnesi_medica_selected.nome"> <td>Nome</td> <td>{{ anamnesi_medica_selected.nome }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.gruppo_sanguigno"> <td>Gruppo sanguigno</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.gruppo_sanguigno }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.visita_leva_abile !== null"> <td>Visita leva abile</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.visita_leva_abile ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.tipo_studio"> <td>Tipo studio</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.tipo_studio }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.altri_sport"> <td>Altri sport</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.altri_sport }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.appetito_normale !== null"> <td>Appetito normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.appetito_normale ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.digestione_normale !== null"> <td>Digestione normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.digestione_normale ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.alvo_normale !== null"> <td>Alvo normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.alvo_normale ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.diuresi_normale !== null"> <td>Diuresi normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.diuresi_normale ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.sonno_normale !== null"> <td>Sonno normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.sonno_normale ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.fumo !== null"> <td>Fumo</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.fumo ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.alcol !== null"> <td>Alcol</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.alcol ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.caffe !== null"> <td>Caffe</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.caffe ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.nota_anamnesi_fisiologica"><td>Anamnesi fisiologica</td> <td><span class="color-rosso ml-1"><i :id="'nota_anamnesi_fisiologica_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Anamnesi fisiologica" :target="'nota_anamnesi_fisiologica_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_anamnesi_fisiologica }}</b-popover></td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.anamnesi_familiare"> <td>Anamnesi familiare</td> <td><span class="color-rosso ml-1"><i :id="'anamnesi_familiare_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Anamnesi familiare" :target="'anamnesi_familiare_' + anamnesi_medica_selected.id" triggers="hover" placement="top">{{ anamnesi_medica_selected.anamnesi_medica.anamnesi_familiare }}</b-popover></td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.vaccinazione_antitetanica !== null"> <td>Vaccinazione antitetanica</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.vaccinazione_antitetanica ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.vaccinazione_antiepatite_virale_b !== null"> <td>Vaccinazione antiepatite virale B</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.vaccinazione_antiepatite_virale_b ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.altre_visite_idoneita_agonistica !== null"> <td>Altre visite idoneità agonistica</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.altre_visite_idoneita_agonistica ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.dichiarato_idoneo_per_meno_di_12_mesi !== null"> <td>Dichiarato idoneo per meno di 12 mesi <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_idoneo_per_meno_di_12_mesi" :id="'nota_idoneo_per_meno_di_12_mesi_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota idoneo per meno di 12 mesi" :target="'nota_idoneo_per_meno_di_12_mesi_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_idoneo_per_meno_di_12_mesi }}</b-popover></td> <td>{{ anamnesi_medica_selected.anamnesi_medica.dichiarato_idoneo_per_meno_di_12_mesi ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.dichiarato_inidoneo !== null"> <td>Dichiarato inidoneo <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_inidoneo" :id="'nota_inidoneo_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota inidoneo" :target="'nota_inidoneo_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_inidoneo }}</b-popover></td> <td>{{ anamnesi_medica_selected.anamnesi_medica.dichiarato_inidoneo ? 'Si' : 'No' }}</td></tr>
															<tr v-if="anamnesi_medica_selected.anamnesi_medica.provvedimenti_disciplinari_doping !== null"> <td>Provvedimenti disciplinari doping <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_doping" :id="'nota_doping_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota doping" :target="'nota_doping_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_doping }}</b-popover> </td> <td>{{ anamnesi_medica_selected.anamnesi_medica.provvedimenti_disciplinari_doping ? 'Si' : 'No' }}</td></tr>
														</table>
														<b-pagination v-if="giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length > 1" size="sm" align="center" :total-rows="giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length" v-model="anamnesi_medica_count" :per-page="1" @change="selectAnamnesiMedica"></b-pagination>
													</b-col>
													<b-col v-else>
														<table class="table table-sm">
															<tr>
																<th v-if="tipo.has_data">Data</th>
																<th v-if="tipo.has_nome">Nome</th>
																<th v-if="tipo.has_data_scadenza">Scadenza</th>
																<th v-if="tipo.has_numero_lotto">N° lotto</th>
																<th v-if="tipo.has_data_rinnovo">Rinnovo</th>
																<th v-if="tipo.has_nota">Nota</th>
															</tr>
															<tr v-for="item in giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id)"> 
																<td v-if="tipo.has_data">{{ item.data | formatDate }}</td>
																<td v-if="tipo.has_nome">{{ item.nome }}</td>
																<td v-if="tipo.has_data_scadenza">{{ item.data_scadenza | formatDate }}</td>
																<td v-if="tipo.has_numero_lotto">{{ item.numero_lotto }}</td>
																<td v-if="tipo.has_data_rinnovo">{{ item.data_rinnovo | formatDate }}</td>
																<td v-if="tipo.has_nota"><span class="color-rosso ml-1"><i v-if="item.nota" :id="'nota_' + item.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota" :target="'nota_' + item.id" triggers="hover" placement="right">{{ item.nota }}</b-popover></td>
															</tr>
														</table>
													</b-col>
												</b-tab>
											</b-tabs>
										</b-tab>
									</b-tabs>
								</b-card>

							</div>
							<div v-else>
								<slot></slot>
							</div>

						</div>
					</div>


					<b-popover custom-class="bigdrop" placement="bottom" triggers="hover" target="showmore" title="Dettagli">

						<table class="table table-sm table-giocatore">
							<tr> <td>Contratto</td> <td>{{giocatore.contratto ? 'SI' : 'NO' }}</td> </tr>
							<tr> <td>Scadenza Contratto</td> <td><span v-if="giocatore.contratto"> {{ giocatore.contratto.fine | formatDate }} </span></td> </tr>
							<tr> <td>Valore Gicatore</td> <td>{{ giocatore.info_economica && giocatore.info_economica.valore && giocatore.info_economica.valore > 0 ? '€ ' + giocatore.info_economica.valore : 'N/D' }}</td> </tr>
							<tr> <td>Rimborso spese</td> <td>{{ giocatore.info_economica && giocatore.info_economica.rimborso_spese ? 'SI' : 'NO' }}</td> </tr>
							<tr> <td>Stipendio/Rimborso lordo fisso annuo</td> <td> {{ giocatore.contratto && giocatore.contratto.stipendio_lordo && giocatore.contratto.stipendio_lordo > 0 ? '€ ' + giocatore.contratto.stipendio_lordo : 'N/D'}}</td> </tr>
							<tr> <td>IBAN</td> <td>{{ giocatore.info_economica ? giocatore.info_economica.iban : ''}}</td> </tr> 
							<tr> <td>Intestatario IBAN</td> <td>{{ giocatore.info_economica ?  giocatore.info_economica.intestatario_iban : '' }}</td> </tr>
							<tr> <td>Scuola AS Roma</td> <td>{{ giocatore.giocatore && giocatore.giocatore.frequenta_scuola_asroma ? 'SI' : 'NO' }}</td> </tr>
							<tr> <td>Scuola a carico AS Roma</td> <td>{{ giocatore.info_economica && giocatore.info_economica.scuola_a_carico ? 'SI' : 'NO' }}</td> </tr>
						</table>

						<!-- <pre>{{pickedData}}</pre> -->
					</b-popover>
					
					
				</div>
			</div>
		</div>
	</section>
</template>

<script>
var Auth = require('../../auth.js');
import axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
	components: {
		FontAwesomeIcon,
		flag: 			require('./flag.vue').default,
		radarchart: require('./charts/radar.vue').default,
		card: 			require('./card.vue').default,
		tagscategorie: 		require('./tagscategorie.vue').default,
		thumbtag:   require('./thumbtag.vue').default,
		perfezionamento:   require('./perfezionamento.vue').default,
	},
	props: {
		player_id: {
			type: Number,
			required: true
		}
	},
	created: function () {
		if(this.player_id){
			axios.get(`/rosa/` + this.player_id).then(function(res) {
				this.giocatore 	= res.data;
			}.bind(this)).catch();
		}
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid())
				return m.format('DD/MM/YYYY'); 

			return "N/D";
		},
	},
	data: function() {
		return {
			showDetail: false,
			giocatore: null,
			team: false,
			skills: [],
			piede: [ 'Destro', 'Sinistro', 'Ambidestro' ],
			relazioni: [ 'Padre', 'Madre', 'Tutor' ],
			stato: ["in rosa", "in prova", "archiviato"],
			skills_tags: [],
			generic_tags: [],
			skill_tags_sel: null,
			videotags: [],
			tag_sel: null,
			count: 0,
			title: '',
			reload: false,
			riassunti: [],
			playlist: [],
			titleRiassunto: '',
			squadre_societa: [],
			squadra_sel: null,
			errors_player: {
				4: {
					headline: 'Video non disponibile',
					type: '',
					message: 'La clip non è stata ancora generata.<br/>Riprova più tardi.',
				}
			},
			perf_exe_skills: [],
			perf_exe_skills_portiere: [],
			team: null,
			stats: [],
			palestra_allenamenti: [],
			player_access_attivita: [],
			baricentro: ['Basso', 'Medio', 'Alto'],
			somatotipo: ['Normolineo', 'Brevilineo', 'Longilineo'],
			costituzione: ['Esile', 'Media', 'Robusta'],
			cronologia_medica_tipi: [],
			anamnesi_medica_count: 1,
			anamnesi_medica_selected: null,
		}
	},
	computed: {
		email: function() {
			for(var i in this.giocatore.contatti) {
				var item = this.giocatore.contatti[i];
				if(item.contatto.tipo == 'email') {
					return item.contatto.valore;
				}
			}
			return null;
		},
		pec: function() {
			for(var i in this.giocatore.contatti) {
				var item = this.giocatore.contatti[i];
				if(item.contatto.tipo == 'pec') {
					return item.contatto.valore;
				}
			}
			return null;
		},
		telefoni: function() {
			var telefoni = [];
			for(var i in this.giocatore.contatti) {
				var item = this.giocatore.contatti[i];
				if(item.contatto.tipo == 'telefono') {
					telefoni.push(item.contatto.valore);
				}
			}
			return telefoni;
		},
				contratto: function() {
					var doc = null;
					for(var i in this.giocatore.documenti) {
						var item = this.giocatore.documenti[i];
						if(item.documento.documento_tipo_glo_id == 12) {
							if(!doc) {
								doc = item.documento;
							} else {
								if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
									!doc.scadenza || !item.documento.scadenza) {
									doc = item.documento;
							}
						}
					}
				}
				return doc;
			},
			agenti: function() {
				var persone = [];
				for(var i in this.giocatore.relazioni) {
					var item = this.giocatore.relazioni[i];
					if(item.persona_relazioni_glo_id == 4) {
						persone.push(item.relazionante);
					}
				}
				return persone;
			},
			passaporti: function() {
				var docs = [];
				for(var i in this.giocatore.documenti) {
					var item = this.giocatore.documenti[i];
					if(item.documento.documento_tipo_glo_id == 2) {
						if(docs.length == 0) {
							docs.push(item.documento);
						} else {
							var found = false;
							for(var j in docs) {
								if(docs[j].numero == item.documento.numero) {
									found = true;
									if(item.documento.scadenza > docs[j].scadenza) {
										docs[j] = item.documento;
									}
									break;
								}
							}
							if(!found) {
								docs.push(item.documento);
							}
						}
					}
				}
				return docs;
			},
			cartaidentita: function() {
				var doc = null;
				for(var i in this.giocatore.documenti) {
					var item = this.giocatore.documenti[i];
					if(item.documento.documento_tipo_glo_id == 3) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
								!doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
						}
					}
				}
			}
			return doc;
		},
		codicefiscale: function() {
			var doc = null;
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 4) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
					}
				}
			}
		}
		return doc;
		},
		patenti: function() {
			var docs = [];
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 5) {
					if(docs.length == 0) {
						docs.push(item.documento);
					} else {
						var found = false;
						for(var j in docs) {
							if(docs[j].numero == item.documento.numero) {
								found = true;
								if(item.documento.scadenza > docs[j].scadenza) {
									docs[j] = item.documento;
								}
								break;
							}
						}
						if(!found) {
							docs.push(item.documento);
						}
					}
				}
			}
			return docs;
		},
		circolazione: function() {
			var doc = null;
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 6) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
					}
				}
			}
		}
		return doc;
		},
		soggiorno: function() {
			var doc = null;
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 7) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
					}
				}
			}
		}
		return doc;
		},
		mandati: function() {
			var docs = [];
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 8) {
					docs.push(item.documento);
				}
			}
			return docs;
		},
		idoneita: function() {
			var doc = null;
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 9 || item.documento.documento_tipo_glo_id == 10) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
						}
					}
				}
			}
			return doc;
		},
		tessera: function() {
			var doc = null;
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 11) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
						}
					}
				}
			}
			return doc;
		},
		greenpass: function() {
			var doc = null;
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 15) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
						}
					}
				}
			}
			return doc;
		},
		parenti: function() {
			var relazioni = [];
			for(var i in this.giocatore.relazioni) {
				var item = this.giocatore.relazioni[i];
				if(item.persona_relazioni_glo_id <= 3) {
					relazioni.push(item);
				}
			}
			return relazioni;
		},
		prova: function() {
			for(var i in this.giocatore.carriere_sportive) {
				var carriera = this.giocatore.carriere_sportive[i];
				if(carriera.tipologia == 'prova') {
					return true;
				}
			}
			return false;
		},
		prova_periodi: function() {
			var docs = [];
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 13) {
					docs.push(item.documento);
				}
			}
			return docs;
		},
		prova_carriera: function() {
			var carriere = [];
			for(var i in this.giocatore.carriere_sportive) {
				var item = this.giocatore.carriere_sportive[i];
				if(item.societa_id != 1) {
					carriere.push(item);
				}
			}
			return carriere;
		},
		prova_documenti: function() {
			var docs = [];
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 14) {
					docs.push(item.documento);
				}
			}
			return docs;
		},
		auth: function() {
			return Auth;
		},
		route: function() {
			return this.$route;
		}
	}
}
</script>
