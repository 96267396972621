import Vue from 'vue';
import _ from 'lodash';

export default {
  /**
   * Updates the data that comes from the API
   *
   * @param {Object} state The vuex state object
   * @param {Object} data
   */
  updateData(state, data) {
    if (typeof data !== 'object') return;

    Vue.set(state, 'data', data);
  },

  /**
   * Sets the error in the vuex state if the request fails
   *
   * @param {Object} state The vuex state object
   * @param {Object} error
   */
  updateError(state, error) {
    Vue.set(state, 'error', error);
  },

  /**
   * Sets the video length on the vuex store
   *
   * @param {Object} state
   * @param {integer} lengthInSeconds
   */
  setDuration(state, lengthInSeconds) {
    let start = new Date();
    start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const end = new Date(start.getTime() + (1000 * lengthInSeconds));
    Vue.set(state.data.video, 'start', start);
    Vue.set(state.data.video, 'end', end);
    Vue.set(state.data.video, 'duration', (end - start) / 1000);
 /*   Vue.set(state.data, 'video', {
      start,
      end,
      duration: (end - start) / 1000,
    }); */
  },

  /**
   * Sets the current time of the video.
   * This is mostly used to sync the timeline with the video
   *
   * @param {Object} state
   * @param {Number} currentTime
   */
  updateCurrentTime(state, currentTime) {
    Vue.set(state, 'currentTime', currentTime);
  },

  /**
   * This is used to seek the video based on the timeline
   *
   * @param {Object} state
   * @param {Number} newTime
   */
  seekTo(state, newTime) {
    Vue.set(state, 'seekTo', newTime);
  },

  /**
   * Saves the specified field in the `newTag` attribute
   *
   * @param {Object} state
   * @param {Object} payload
   */
  updateTagField(state, payload) {
    if (payload.tag) {
      Vue.set(state, 'newTag', payload.tag);
      return;
    }

    if (!payload.key || payload.value === undefined) return;

    // If the newTag doesn't exists, create it and save the
    // start and end time
    if (!state.newTag || !_.isFinite(state.newTag.start) || !_.isFinite(state.newTag.end)) {
      const now = state.currentTime || 0;
      const secondsBefore = state.data && state.data.tag_setting ? state.data.tag_setting.secondi_prima : 10;
      const secondsAfter = state.data && state.data.tag_setting ? state.data.tag_setting.secondi_dopo : 5;

      const videoEnd = new Date(state.data.video.end);
      const tagEnd = new Date(new Date(state.data.video.start).getTime() +
        (1000 * (now + secondsAfter)));

      const start = (now - secondsBefore < 0) ? 0 : (now - secondsBefore);
      const end = tagEnd > videoEnd ? state.data.video.duration : (now + secondsAfter);

      Vue.set(state, 'newTag', { start, end });
    }

    if (payload.key === 'start') {
      let value = payload.value;
      if (value < 0) value = 0;
      Vue.set(state.newTag, payload.key, value);
      return;
    }

    if (payload.key === 'end') {
      let value = payload.value;
      if (value > state.data.video.duration) value = state.data.video.duration;
      Vue.set(state.newTag, payload.key, value);
      return;
    }

    if (state.newTag.team && payload.key === 'player') {
      Vue.delete(state.newTag, 'team');
    } else if (state.newTag.player && payload.key === 'team') {
      Vue.delete(state.newTag, 'player');
    } else if (state.newTag.coach && payload.key === 'coach') {
      Vue.delete(state.newTag, 'coach');
    }

    if (payload.key === 'skill') {
      if (!state.newTag.skills) Vue.set(state.newTag, 'skills', []);

      state.newTag.skills.push(payload.value);
    } else {
      Vue.set(state.newTag, payload.key, payload.value);
    }
  },

  removeSkill(state, id) {
    if (!state.newTag || !Array.isArray(state.newTag.skills)) return;

    const index = _.findIndex(state.newTag.skills, ['id', id]);

    if (index !== -1) {
      state.newTag.skills.splice(index, 1);
      if (state.newTag.skills.length === 0) {
        Vue.delete(state.newTag, 'skills');
      }
    }
  },

  replaceTag(state, tag) {
    const index = _.findIndex(state.data.tags, ['id', tag.id]);

    if (index !== -1) {
      state.data.tags.splice(index, 1, tag);
    }
  },

  toggleRecording(state) {
    if (!state.recording) {
      Vue.set(state, 'recording', {});
    }

    if (!state.isRecording && !state.recording.start && !state.recording.finish) {
      Vue.set(state.recording, 'start', state.currentTime);
      Vue.set(state, 'isRecording', true);
    } else if (state.isRecording && state.recording.start && !state.recording.finish) {
      Vue.set(state.recording, 'finish', state.currentTime);
      Vue.set(state, 'isRecording', false);
    }
  },

  cancelRecording(state) {
    Vue.set(state, 'recording', {});
    Vue.set(state, 'isRecording', false);
  },

  updateTagDuration(state, payload) {
    if (!payload.start || !payload.end || !state.newTag) return;

    const start = parseInt((payload.start - new Date(state.data.video.start)) / 1000, 10);
    const end = parseInt((payload.end - new Date(state.data.video.start)) / 1000, 10);

    Vue.set(state.newTag, 'start', start);
    Vue.set(state.newTag, 'end', end);
  },

  cancelNewTag(state) {
    Vue.delete(state, 'newTag');
  },

  tagSaved(state, tag) {
    const index = _.findIndex(state.data.tags, item => item.id === tag.id);
    if (index !== -1) {
      state.data.tags.splice(index, 1, tag);
    } else {
      state.data.tags.push(tag);
    }
    Vue.set(state, 'newTag', { saved: true });
  },

  tagError(state, error) {
    Vue.set(state, 'newTag', { error });
  },

  editTag(state, tag) {
    Vue.set(state, 'newTag', Object.assign({}, tag, { editing: true }));
  },

  deleteTag(state, tagId) {
    if (!state.data || !state.data.tags) return;

    const index = _.findIndex(state.data.tags, ['id', Number(tagId)]);

    if (index !== -1) {
      Vue.delete(state.data.tags, index);
    }
  },

  setFilteredTags(state, filteredTags) {
    Vue.set(state, 'filteredTags', filteredTags);
  },

  bookmarkSaved(state, bookmark) {
    const video = _.get(state, 'data.video');

    if (!video) return;

    if (!state.data.video.bookmarks) {
      Vue.set(state.data.video, 'bookmarks', []);
    }

    const index = _.findIndex(state.data.video.bookmarks, ['id', bookmark.id]);

    if (index !== -1) {
      state.data.video.bookmarks.splice(index, 1, bookmark);
    } else {
      state.data.video.bookmarks.push(bookmark);
    }
  },

  deleteBookmark(state, bookmarkId) {
    if (!_.get(state, 'data.video.bookmarks')) return;

    const index = _.findIndex(state.data.video.bookmarks, ['id', bookmarkId]);

    if (index !== -1) {
      Vue.delete(state.data.video.bookmarks, index);
    }
  },

  notification(state, data) {
    Vue.set(state, 'notification', data);
  },

  enableTagList(state) {
    Vue.set(state, 'tagListEnabled', true);
  },

  disableTagList(state) {
    Vue.set(state, 'tagListEnabled', false);
  },

  createSetting(state, setting) {
    if (!state.data.settings) {
      Vue.set(state.data, 'settings', []);
    }

    state.data.settings.push(setting);
  },

  deleteSetting(state, id) {
    if (!state.data.settings) return;

    const index = _.findIndex(state.data.settings, ['id', id]);

    if (index !== -1) {
      Vue.delete(state.data.settings, index);
    }
  },

  updateSetting(state, setting) {
    if (!state.data.settings || !setting.id) return;

    const index = _.findIndex(state.data.settings, ['id', setting.id]);

    if (index !== -1) {
      state.data.settings.splice(index, 1, setting);
    }
  },

  applySetting(state, id) {
    if (!state.data.settings || !id) return;

    const setting = _.find(state.data.settings, ['id', id]);

    if (setting) {
      Vue.set(state, 'activeSetting', setting);
    }
  },

  restoreDefaultSetting(state) {
    Vue.delete(state, 'activeSetting');
  },

  play(state) {
    Vue.set(state, 'playing', true);
  },

  pause(state) {
    Vue.set(state, 'playing', false);
  },

  enableFullScreen(state) {
    Vue.set(state, 'fullScreen', true);
    Vue.set(state, 'sidebarHidden', true);
  },

  exitFullScreen(state) {
    Vue.set(state, 'fullScreen', false);
    Vue.set(state, 'sidebarHidden', false);
  },

  toggleSidebar(state) {
    Vue.set(state, 'sidebarHidden', !state.sidebarHidden);
  },

  toggleRecording(state) {
    if (!state.isRecording &&
      (!state.recording || (!state.recording.start && !state.recording.finish))) {
      Vue.set(state, 'recording', { start: state.currentTime });
      Vue.set(state, 'isRecording', true);
    } else if (state.isRecording &&
      state.recording.start && !state.recording.finish) {
      Vue.set(state, 'recording', _.merge({
        finish: state.currentTime,
      }, state.recording));
      Vue.set(state, 'isRecording', false);
    }
  },

  recordingSaved(state) {
    Vue.set(state, 'recording', {});
    Vue.set(state, 'isRecording', false);
  },

  changePlaybackRate(state, rate) {
    Vue.set(state, 'currentPlaybackRate', rate);
  },

  goalkeeperMode(state, goalkeeperMode) {
    state.goalkeeperMode = goalkeeperMode;
  },

  toggleGoalkeeperMode(state) {
    state.goalkeeperMode = !state.goalkeeperMode;
  },

  changeScoutingCategory(state, category) {
    Vue.set(state, 'scoutingCategory', category);
  },

  changeRosaTeamId(state, team_id) {
    sessionStorage.setItem(`rosaTeamId`,team_id);
    Vue.set(state, 'rosaTeamId', team_id);
  },

  changeCategoriaId(state, categoria_id) {
    sessionStorage.setItem(`categoriaId`,categoria_id);
    Vue.set(state, 'categoriaId', categoria_id);
  },

  changeAcademyCatId(state, cat_id) {
    Vue.set(state, 'academyCatId', cat_id);
  },

  changeAcademyId(state, acad_id) {
    Vue.set(state, 'academyId', acad_id);
  },

  changeAcademyIntId(state, acad_id) {
    Vue.set(state, 'academyIntId', acad_id);
  },

  setPrivateTag(state, privateTag) {
    Vue.set(state, 'privateTag', privateTag);
  },

  changeRomalabCatId(state, cat_id) {
    Vue.set(state, 'romalabCatId', cat_id);
  },
  changeSeasonId(state, season_id) {
    sessionStorage.setItem(`seasonId`,season_id);
    Vue.set(state, 'seasonId', season_id);
  },
  changeInProva(state, value) {
    Vue.set(state, 'inProva', value);
  },
  changeLevels(state, values) {
    Vue.set(state, 'levels', values);
  },
  changeSessionLevels(state, values) {
    sessionStorage.setItem(`sessionLevels`, JSON.stringify(values));
    Vue.set(state, 'sessionLevels', values);
  },
  changeSessionPlayers(state, values) {
    sessionStorage.setItem(`sessionPlayers`, JSON.stringify(values));
    Vue.set(state, 'sessionPlayers', values);
  },
  changeCalendarNew(state, value) {
    sessionStorage.setItem(`calendarNew`,value);
    Vue.set(state, 'calendarNew', value);
  },
};
