<template>
	<div>

		<!-- <pre>{{fase}}</pre> -->
		<div v-if="fase.entity.risultati">
			<label>Nome</label>
			<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>
			<b-row v-if="hasSetupTipo(this.fase, 'hideFormDuration', false) == false">
				<b-col>
					<label>Durata</label>
					<b-form-input :disabled="disabled" v-model="fase.durata" type="time"></b-form-input>
				</b-col>
				<b-col>
					<label>Durata effettiva</label>
					<b-form-input :disabled="disabled" v-model="fase.durata_reale" type="time"></b-form-input>
				</b-col>
			</b-row>
			<div class="mt-3">
				<div :is="matriceComponent" :fase="fase" :risultati="fase.entity.risultati"></div>
			</div>
		</div>
		<b-alert show v-else>Per poter inserire i valori della raccolta dati, devi prima salvare la sessione.</b-alert>
		


	</div>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';

import axios from 'axios';
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [ sessionMixin ],
	props: [ 'sessione', 'fase', 'disabled' ],
	components: {
		MiniMatrice: require('@/components/layout/Sessioni/MiniMatrice.vue').default,
		MiniMatriceInvertita: require('@/components/layout/Sessioni/MiniMatriceInvertita.vue').default,
	},
	data: function(){
		return {
			
		}
	},
	computed: {
		matriceComponent: function() {
			return this.fase.setup.matrixComponent != undefined ? this.fase.setup.matrixComponent : 'MiniMatrice';
		}
	}
}
</script>