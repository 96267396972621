import Geomatch from '../components/pages/geomatch/Geomatch.vue'
import GeomatchHome from '../components/pages/geomatch/Home.vue';
import GeomatchAdmin from '../components/pages/geomatch/admin/Admin.vue';
import Stadiums from '../components/pages/geomatch/admin/Stadiums.vue';
import Matches from '../components/pages/geomatch/admin/Matches.vue';
import Teams from '../components/pages/geomatch/admin/Teams.vue';
import Competizioni from '../components/pages/geomatch/admin/Competizioni.vue';

export const ROUTES_GEOMATCH = [
    {
      path: '/geomatch',
      name: 'Geomatch',
      component: Geomatch,
      meta: {
          requiresAuth: true,
          permesso: 'geomatch',
      }, 
      children: [
        { path: '', component: GeomatchHome },
        {
          path: 'admin',
          component: GeomatchAdmin,
          children: [
            { path: '', component: Stadiums },
            { path: 'matches', component: Matches },
            { path: 'teams', component: Teams },
            { path: 'competizioni', component: Competizioni },
          ],
        },
      ],
    },
];
