<template>
	<div style="position: relative">

		<b-card :border-variant="fase.setup && fase.setup.automationPhase ? 'success' : ''" :bg-variant="faseStato.length == 0 ? 'light':''" :style="faseStato.length == 0 ? 'border-left: 5px solid darkgray':''">
			<b-row>
				<b-col :aria-controls="'fase_'" @click="collapse()" style="cursor:pointer"><h3 class="d-inline-block card-title" style="border:0;">{{ index + ' - ' + fase.nome }}</h3></b-col>
				<b-col cols="1" v-if="!faseStato.length == 0 && showTimer">
					<b-button v-if="!ux.running" class="mr-0" variant="outline-primary" size="xs" @click="startT"><i class="fa fa-play" aria-hidden="true"></i> Start timer</b-button>
					<b-button v-if="ux.running" class="mr-0" variant="outline-primary" size="xs" @click="stopT"><i class="fa fa-stop" aria-hidden="true"></i> Stop timer</b-button>
				</b-col>
				<b-col cols="1" v-show="ux.running">
					<StopWatch :running="ux.running" :resetWhenStart="true" @durata="setDurataFase"/>
				</b-col>
				<b-col class="text-right">
					<div v-if="!visible" style="display: inline" class="mr-1">
						<b-badge variant="success" class="mr-2" v-if="fase.durata && fase.durata != '00:00:00'">⏰ {{ fase.durata | time }}</b-badge>
						<span v-if="playersNotAsSessione"><b-badge v-for="p in fase.players" class="mr-1" v-if="p.inPhase">{{ p.name }}</b-badge></span>
						<span v-else><b-badge variant="primary" class="mr-1">Tutti</b-badge></span>
					</div>
					<b-button-group v-if="isManagerSessione">
						<b-button v-if="sessione.fasi.length > 1" :disabled="index == 1" class="mr-0" variant="outline-primary" size="xs" @click="sposta(0)"><i class="fa fa-long-arrow-up"></i> Sposta su</b-button>
						<b-button v-if="sessione.fasi.length > 1" :disabled="index == sessione.fasi.length" class="mr-0" variant="outline-primary" size="xs" @click="sposta(1)"><i class="fa fa-long-arrow-down"></i> Sposta giù</b-button>
						<b-button class="mr-0" variant="success" size="xs" @click="duplicaFase"><i class="fa fa-clone" aria-hidden="true"></i> Duplica fase</b-button>
						<b-button v-if="canRemovePhase" class="mr-0" variant="danger" size="xs" @click="removeFase"><i class="fa fa-trash fa-fw"></i> Rimuovi fase</b-button>
					</b-button-group>

				</b-col>
			</b-row>

			<b-collapse v-model="visible">
				<div v-if="visible">

					<!-- Queste sono le estensioni di SESSIONE, di default vengono iniettate e non possono essere tolte. -->
					<div v-if="hasSetupTipo(sessione.sessione_tipo, 'hasFaseExt', false)">
						<b-row v-for="ext in hasSetupTipo(sessione.sessione_tipo, 'hasFaseExt', false)">
							<b-col>
								<div ref="ext" :is="ext" :estensione="fase[ext] ? fase[ext] : {}" :field="ext" :model="fase" :sessione="sessione"></div>
							</b-col>
						</b-row>
					</div>


					<slot></slot>

					<!-- Queste sono le estensioni di FASE, anche loro di default sono attive, ma si possono spegnere ^_^ -->
					<div v-if="estensioniFaseComputed.length > 0" class="mt-1">

						<div class="text-right pb-1">
							<small class="text-muted">Estensioni: </small>
							<b-button-group>
								<b-button size="xs" style="margin-right: 0" :variant="fase[ext] ? 'outline-primary' : 'outline-secondary'" @click.prevent="toggleEstensione(fase, ext)" v-for="ext in estensioniFaseComputed">{{ ext.replace('Fase_Ext_', '') }}</b-button>
							</b-button-group>
						</div>

						<b-row v-for="ext in estensioniFaseComputed" v-if="fase[ext]">
							<b-col>
								<b-card>
									<div style="position: absolute; bottom: 0; left: 0; text-transform: uppercase; background-color: rgba(0,0,0,.05)" class="small px-1">{{ext.replace('Fase_Ext_', '')}}</div>
									<div ref="ext" :is="ext" :estensione="fase[ext]" :field="ext" :model="fase" :sessione="sessione"></div>
								</b-card>
							</b-col>
						</b-row>

					</div>

					<div v-if="fase.entity.nota !== undefined">

						<label>Note della Fase</label>
						<b-textarea :disabled="disabled" v-model="fase.entity.nota" max-rows="6"></b-textarea>

					</div>

					<hr />
					
					<b-row v-if="showActivityCheck || hasSetup(this.sessione, 'disableAutoPlayerInPhase', false)">
						<b-col cols="12">
							<h6><a href="" @click.prevent="ux.activity_check = !ux.activity_check">Pianificazione</a></h6>
							<div v-if="ux.activity_check == true">
								<b-table-simple small>
									<b-tr>
										<b-th width="20%" class="small"><small><a class="text-muted" href="" @click.prevent="selectAllPeople">Seleziona tutti</a></small></b-th>
										<b-th width="15%" class="small" v-if="showColumn('reason')">Motivo</b-th>
										<b-th width="15%" class="small" v-if="showColumn('injuries')">Infortuni</b-th>
										<b-th width="15%" class="small" v-if="showColumn('illnesses')">Malattie</b-th>
										<b-th width="10%" class="small text-center">Fatto</b-th>
										<b-th width="25%" class="small">Specifica</b-th>
									</b-tr>
									<b-tr v-for="player in fase.players">
										<b-td>
											<label>
												<b-form-checkbox @input="checkConfigInPhase(player)" style="display: inline" :true-value="1" :false-value="0" v-model="player.inPhase"></b-form-checkbox>
												{{ player.name }}
											</label>
										</b-td>
										<b-td class="small" v-if="showColumn('reason')">
											<b-form-select size="sm" :disabled="isDisabled(player)" v-model="player.activity_check.reason">
												<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
												<b-form-select-option value="Mantenimento">Mantenimento</b-form-select-option>
												<b-form-select-option value="Prevenzione">Prevenzione</b-form-select-option>
												<b-form-select-option value="Defaticante">Defaticante</b-form-select-option>
												<b-form-select-option value="Pregara">Pregara</b-form-select-option>
												<b-form-select-option value="Altro">Altro</b-form-select-option>
											</b-form-select>
										</b-td>
										<b-td class="small" v-if="showColumn('injuries')">
											<b-form-checkbox-group :disabled="isDisabled(player)" v-model="fase.injuries" :name="'injuries' + fase.id">
												<b-form-checkbox v-for="injury, idx in injuriesOf(player.id)" :value="injury" v-if="ux.showAllInjuries[player.id] || (!ux.showAllInjuries[player.id] && idx < 2)">{{ injury.data | formatDate }}
													<span>{{ injury.pathology ? injury.pathology.name : 'Missing' }}</span>
												</b-form-checkbox>
											</b-form-checkbox-group>
											<b-button v-if="injuriesOf(player.id).length > 2" class="mt-2" variant="secondary" size="xs" @click="switchShowInjuries(player.id)"><i :class="'fa fa-arrow-' + (ux.showAllInjuries[player.id] ? 'up' : 'down')" aria-hidden="true"></i>
											{{ ux.showAllInjuries[player.id] ? 'Mostra meno' : 'Mostra tutti'}}</b-button>
										</b-td>
										<b-td class="small" v-if="showColumn('illnesses')">
											<b-form-checkbox-group :disabled="isDisabled(player)" v-model="fase.illnesses" :name="'illnesses' + fase.id">
												<b-form-checkbox v-for="illness, idx in illnessOf(player.id)" :value="illness" v-if="ux.showAllIllnesses[player.id] || (!ux.showAllIllnesses[player.id] && idx < 2)">{{ illness.data | formatDate }}
													<span v-for="type in illness.illness_types">{{ type.name ? type.name : 'Missing' }}</span>
													<span v-for="type in illness.illness_other_types">{{ type.name ? type.name : 'Missing' }}</span>
												</b-form-checkbox>
											</b-form-checkbox-group>
											<b-button v-if="illnessOf(player.id).length > 2" class="mt-2" variant="secondary" size="xs" @click="switchShowIllnesses(player.id)"><i :class="'fa fa-arrow-' + (ux.showAllIllnesses[player.id] ? 'up' : 'down')" aria-hidden="true"></i>
											{{ ux.showAllIllnesses[player.id] ? 'Mostra meno' : 'Mostra tutti'}}</b-button>
										</b-td>
										<b-td class="text-center">

											<b-button-group>
												<b-button class="mr-0" size="sm" @click="checkChange(player, 1)" :variant="player.activity_check.checked == 1 ? 'primary': 'outline-secondary'" :disabled="!player.inPhase">Sì</b-button>
												<b-button size="sm" @click="checkChange(player, 0)" :variant="player.activity_check.checked == 0 ? 'primary': 'outline-secondary'" :disabled="!player.inPhase">No</b-button>
											</b-button-group>

											<!--<b-form-radio-group @click="checkChange(player)" buttons size="xs" :button-variant="player.activity_check.checked == 1 ? 'outline-primary': 'outline-secondary'" :disabled="!player.inPhase" v-model="player.activity_check.checked">
												<b-form-radio v-on:click="checkChange(player)" :value="1" class="mr-0">Sì</b-form-radio><br />
												<b-form-radio v-on:click="checkChange(player)" :value="0">No</b-form-radio>
											</b-form-radio-group>-->
										</b-td>
										<b-td><b-textarea size="sm" small :disabled="isDisabled(player)" class="form-control" v-model="player.activity_check.nota" rows="2" max-rows="6"></b-textarea></b-td>
									</b-tr>
								</b-table-simple>
							</div>
						</b-col>

						<hr />

					</b-row>
					<div v-if="showDocs || showVideos">
						<div v-if="fase.id">
							<b-row v-if="showDocs">
								<b-col>
									<h6><a href="" @click.prevent="ux.allegati = !ux.allegati">Allegati ({{ countDoc }})</a></h6>
									<DocumentiManager :collapsed="ux.allegati" entity="Fase" :entityId="fase.id" :tipoId="1" path="" :readonly="!isManagerSessione || faseStato.length == 0" @countDoc="updateCountDoc"></DocumentiManager>
								</b-col>
							</b-row>
							<hr />
							<b-row v-if="showVideos">
								<b-col>
									<h6><a href="" @click.prevent="ux.video = !ux.video">Video ({{ countVideo }})</a></h6>
									<VideoManager :collapsed="ux.video" entity="Fase" :entityId="fase.id" :readonly="!isManagerSessione || faseStato.length == 0" :personeIds="fasePlayerRealiIds" @countVideo="updateCountVideo" :id="$route.params.id" :tipo="$route.params.tipo"></VideoManager>
								</b-col>
							</b-row>
						</div>
						<div v-else class="text-muted">
							<em>Per poter inserire Allegati e Video, devi prima salvare la sessione</em>
						</div>
					</div>
				</div>
			</b-collapse>

			<div v-if="fase.setup && fase.setup.automationPhase" class="small text-right px-2" style="position: absolute; right: 0; bottom: 0; background-color: rgb(200,200,200); color: rgb(70,70,70); border-top-left-radius: 5px">♥ Fase Generata Automaticamente</div>

		</b-card>
	</div>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
import _ from 'lodash';
export default {
	name: 'FaseContenitore',
	mixins: [ sessionMixin ],
	components: {
		DocumentiManager: require('@/components/layout/documenti/manager.vue').default,
		VideoManager: require('@/components/layout/video/manager.vue').default,
		StopWatch: require('@/components/layout/stopwatch.vue').default,
		Fase_Ext_Prevenzione: require('@/components/layout/Sessioni/Football/Fase_Ext_Prevenzione.vue').default,
		Fase_Ext_BodyParts: require('@/components/layout/Sessioni/Medical/Fase_Ext_BodyParts.vue').default,
	},
	props: [ 'sessioneCorrente', 'sessione', 'title', 'fase', 'injuries', 'illnesses', 'index', 'isManagerSessione', 'estensioniFase', 'noPersone', 'disabled' ],
	data: function(){
		return {
			visible: false,
			ux: {
				activity_check: true,
				allegati: true,
				video: true,
				showAllInjuries: {},
				showAllIllnesses: {},
				running: false,
			},
			countVideo: 0,
			countDoc: 0,
			startingContent: false
		}
	},
	created: function() {

		if(this.fase.id)
			this.visible = false;
		else
			this.visible = true;

		if(this.sessione.fasi.length == 1)
			this.visible = true

		if(this.hasSetup(this.sessione, 'collapsedActivityCheck', false) == true)
			this.ux.activity_check = false;

	},
	watch: {
		fase: {
			deep: true,
			handler: function() {
				if(this.startingContent == false)
					this.startingContent = JSON.stringify(this.fase);
			},
			immediate: true
		}
	},
	computed: {
		estensioniFaseComputed: function() {
			return this.hasSetupTipo(this.fase, 'hasFaseExt', [])
			return _.filter(this.estensioniFase, function(i) {

				if(this.mergedConfig.hasFaseExt == undefined) return false;
				return this.mergedConfig.hasFaseExt.indexOf(i) !== -1;

			}.bind(this));

		},
		_: function() {
			return _;
		},
		isChanged: function() {
			if(JSON.stringify(this.fase) != this.startingContent)
				return true;
			return false;
		},
		mergedConfig: function() {
			//return this.sessione.sessione_tipo.setup;
			var start = _.cloneDeep(this.sessione.sessione_tipo.setup);

			if(this.fase.setup) {
				_.each(this.fase.setup, function(val, key) {
					if(start[key] != undefined) {
						if(_.isArray(val)) {
							start[key] = _.union(start[key], val);
						} else {
							start[key] = val;
						}
					} else {
						start[key] = val;
					}
				});
			}

			return start;

			//return start;
			var mc = _.extend(_.cloneDeep(this.sessione.sessione_tipo.setup), this.fase.setup);
			

			return mc;
		},
		canRemovePhase: function() {
			return !this.mergedConfig || this.mergedConfig.rimozioneFase == undefined || this.mergedConfig.rimozioneFase == true;
		},
		showAttachment: function() {
			if(!this.mergedConfig || this.mergedConfig.hideAttachment == undefined || this.mergedConfig.hideAttachment == false) return true;
			return false;
		},
		showActivityCheck: function() {

			if(!this.mergedConfig || (this.mergedConfig.hideActivityCheck == undefined || this.mergedConfig.hideActivityCheck == false)) return true;
			return false;

		},
		showVideos: function() {

			if(!this.mergedConfig || (this.mergedConfig.hidePhaseVideos == undefined || this.mergedConfig.hidePhaseVideos == false)) return true;
			return false;

		},
		showDocs: function() {

			if(!this.mergedConfig || (this.mergedConfig.hidePhaseDocs == undefined || this.mergedConfig.hidePhaseDocs == false)) return true;
			return false;

		},
		showTimer: function() {

			if(!this.mergedConfig || (this.mergedConfig.hidePhaseTimer == undefined || this.mergedConfig.hidePhaseTimer == false)) return true;
			return false;

		},
		faseStato: function() {

			if(this.noPersone && this.noPersone == true) {
				return [ true ];
			}

			return _.filter(this.fase.players, function(a) {
				return a.inPhase == true && (a.activity_check.checked == 1 || a.activity_check.checked == null);
			});
		},
		fasePlayerReali: function() {
			return _.filter(this.fase.players, { inPhase: true });
		},
		playersNotAsSessione: function() {
			return this.fasePlayerReali.length !== this.sessione.players.length;
		},
		injuriesFase: function() {
			return _.filter(this.injuries, function(injury) {
				var playerIds = _.map(_.filter(this.fase.players, { inPhase: true }), 'id');
				return playerIds.indexOf(injury.persona_id) !== -1;
			}.bind(this));
		},
		illnessesFase: function() {
			return _.filter(this.illnesses, function(illness) {
				var playerIds = _.map(_.filter(this.fase.players, { inPhase: true }), 'id');
				return playerIds.indexOf(illness.persona_id) !== -1;
			}.bind(this));
		},
		auth: function() {
			return Auth;
		},
		fasePlayerRealiIds: function() {
			return _.map(this.fasePlayerReali, 'id');
		},
		...mapState(['rosaTeamId']),
	},
	methods: {
		toggleEstensione: function(fase, name) {

			if(this.fase[name] == undefined)
				this.$set(fase, name, {});
			else {

				var vm = _.find(this.$refs.ext, { field: name });

				if(!vm.canBeDeleted) {
					var pass = window.confirm("Procedendo con la disattivazione, rimuoverai tutti i dati collegati all'estensione");
				} else var pass = true;

				if(pass) {
					this.$delete(fase, name);
				}


			}

		},
		selectAllPeople: function() {
			_.each(this.fase.players, function(i) {
				i.inPhase = true;
			});
		},
		checkConfigInPhase: function(player) {

			if(player.inPhase == true) {
				if(this.mergedConfig && this.mergedConfig.ifInPhaseAlsoActivityCheck != undefined)
					player.activity_check.checked = true;

				if(this.sessione.setup && this.sessione.setup.actity_check_preset) {
					var def = _.find(this.sessione.setup.actity_check_preset.players, { persona_id: player.id });
					if(def) {
						player.activity_check.reason = def.reason;

						_.each(def.illnesses, function(a) {
							this.fase.illnesses.push(a);
						}.bind(this));

						_.each(def.injuries, function(a) {
							this.fase.injuries.push(a);
						}.bind(this));

					}
				}

			} else {
				player.activity_check.checked = null;
				player.activity_check.reason = null;
				player.activity_check.nota = '';

				this.fase.injuries = _.filter(this.fase.injuries, function(a) {
					return a.persona_id != player.id;
				});

				this.fase.illnesses = _.filter(this.fase.illnesses, function(a) {
					return a.persona_id != player.id;
				});

			}

		},
		showColumn: function(columnName) {
			if(this.mergedConfig != undefined && !this.mergedConfig.hideActivityCheckColumns) return true;
			if(this.mergedConfig != undefined && this.mergedConfig.hideActivityCheckColumns.indexOf(columnName) !== -1) return false;
			return true;
		},
		checkChange: function(player, value) {
			if(player.activity_check.checked == value)
				player.activity_check.checked = null;
			else
				player.activity_check.checked = value;
		},
		isDisabled: function(persona) {
			if(!persona.inPhase) return true;
			if(persona.activity_check.checked == 0) return true;
		},
		injuriesOf: function(pId) {
			return _.filter(this.injuries, function(inj) {
				return inj.persona_id == pId;
			}.bind(this));
		},
		illnessOf: function(pId) {
			return _.filter(this.illnesses, function(inj) {
				return inj.persona_id == pId;
			}.bind(this));
		}, 
		sposta: function(direzione) {
			this.$emit('sposta', direzione);
		},
		removeFase: function() {
			this.$emit('remove');
		},
		duplicaFase: function() {
			this.$emit('duplica');
		},
		persona: function(id) {
			return _.find(this.fase.players, { id });
		},
		collapse: function() {
			this.visible = !this.visible;
		},
		switchShowInjuries: function(playerId) {
			if(this.ux.showAllInjuries[playerId] == undefined) {
				this.ux.showAllInjuries[playerId] = true;
				this.ux.showAllInjuries = Object.assign({}, this.ux.showAllInjuries);

			} else {
				this.ux.showAllInjuries[playerId] = !this.ux.showAllInjuries[playerId];
			}
		},
		switchShowIllnesses: function(playerId) {
			if(this.ux.showAllIllnesses[playerId] == undefined) {
				this.ux.showAllIllnesses[playerId] = true;
				this.ux.showAllIllnesses = Object.assign({}, this.ux.showAllIllnesses);
			} else {
				this.ux.showAllIllnesses[playerId] = !this.ux.showAllIllnesses[playerId];
			}
		},
		updateCountVideo: function(count) {
			this.countVideo = count;
		},
		updateCountDoc: function(count) {
			this.countDoc = count;
		},
		stopT: function() {
			this.ux.running = false;
		},
		startT: function() {
			this.ux.running = true;
		},
		setDurataFase: function(time) {
			var formatTime = moment().startOf("day").seconds(Math.ceil(time/60)*60).format("HH:mm");
			this.fase.durata_reale = formatTime;
		}
	},
	filters: {
		time: function(data) {
			return moment('2021-01-01 ' + data).format('HH:mm'); 
		},
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YY'); 
			}
			return date;
		}
	}
}
</script>