var routes = [];

var aree = [ 'new', 'young', 'youth', 'football' ];

for(var b in aree) {

    var a = aree[b];

/*    routes.push({
        path: '/next/' + a + '/new/giocatore',
        name: a + '_next_new_giocatore',
        component: require('../components/pages/next-scouting/giocatori/new.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Next Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: '',  
            permesso: 'next_scouting',
        }
    }); */

    routes.push({
        path: '/next/' + a + '/view/giocatore/:id',
        name: a + '_next_view_giocatore',
        component: require('../components/pages/next-scouting/giocatori/view.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Scheda Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: a + '_next_new_valutazione',  
            menusub: [ a + '_next_edit_giocatore', a + '_next_new_valutazione'],
            permesso: a + 'scout',
        }
    });

    routes.push({
        path: '/next/' + a + '/edit/giocatore/:id',
        name: a + '_next_edit_giocatore',
        component: require('../components/pages/next-scouting/giocatori/edit.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Modifica Anagrafica',
            requiresAuth: true,
            showInMenu: true,
            parent: a + '_next_view_giocatore',  
            menusub: [ a + '_next_view_giocatore'],
            permesso: 'scout_edit_player',
        }
    });

    routes.push({
        path: '/next/' + a + '/view/valutazione/:id/:val_id',
        name: a + '_next_view_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/view.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            menusub: [ a + '_next_view_giocatore', a + '_next_edit_valutazione', a + '_next_new_valutazione'],
            permesso: a + 'scout',
            currentAndPotential: a == 'new' ? true : false
        }
    });

    routes.push({
        path: '/next/' + a + '/view/valutazione/football/:id/:val_id',
        name: a + '_next_view_valutazione_football',
        component: require('../components/pages/next-scouting/valutazioni/view_football.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Valutazione Football',
            requiresAuth: true,
            showInMenu: false,
            parent: a + '_next_view_giocatore',  
            menusub: [ a + '_next_view_giocatore'],
            permesso: a + 'scout',
        }
    });

    routes.push({
        path: '/next/' + a + '/edit/valutazione/:id/:val_id',
        name: a + '_next_edit_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/edit.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Modifica Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            menusub: [ a + '_next_view_giocatore', a + '_next_view_valutazione', a + '_next_new_valutazione'],
            permesso: 'scout_edit_val',
            currentAndPotential: a == 'new' ? true : false
        }
    });

    routes.push({
        path: '/next/' + a + '/new/valutazione/:id',
        name: a + '_next_new_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/new.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nuova Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            menusub: [ a + '_next_view_giocatore'],
            permesso: 'scout_add_val',
            currentAndPotential: a == 'new' ? true : false
        }
    });

    routes.push({
        path: '/next/' + a + '/videotag/giocatore/:id',
        name: a + '_next_videotag_giocatore',
        component: require('../components/pages/next-scouting/giocatori/videotag.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Videotag Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: a + '_next_view_giocatore',  
            permesso: 'scout_videotag',
        }
    });

    routes.push({
        path: '/next/' + a + '/partite/giocatore/:id',
        name: a + '_next_partite_giocatore',
        component: require('../components/pages/next-scouting/giocatori/partite.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Partite Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: a + '_next_view_giocatore',  
            permesso: 'scout_partite',
        }
    });

/*  routes.push({
        path: '/next/' + a + '/partite',
        name: a + '_next_partite',
        component: require('../components/pages/next-scouting/partite.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Next Partite',
            requiresAuth: true,
            showInMenu: true,
            parent: '',  
            permesso: 'next_scouting',
        }
    }); */

    routes.push({
        path: '/next/' + a + '/club',
        name: a + '_next_club',
        component: require('../components/pages/next-scouting/paesi.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Club',
            requiresAuth: true,
            showInMenu: true,
            routeExactMatch: true,
            parent: '',  
            permesso: 'scout_club',
        }
    });

    routes.push({
        path: '/next/' + a + '/nazionali',
        name: a + '_next_nazionali',
        component: require('../components/pages/next-scouting/paesi-nazionali.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: true,
            routeExactMatch: true,
            parent: '',  
            permesso: 'scout_nazionali',
        }
    });


    routes.push({
        path: '/next/' + a + '/nazionali/:paese_id',
        name: a + '_next_nazionali_paese',
        component: require('../components/pages/next-scouting/paese-nazionali.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout_nazionali',
        }
    });


    routes.push({
        path: '/next/' + a + '/club/:paese_id',
        name: a + '_next_club_paese',
        component: require('../components/pages/next-scouting/competizioni.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            permesso: 'scout_club',
        }
    });

    routes.push({
        path: '/next/' + a + '/club/:paese_id/:competizione_id',
        name: a + '_next_club_paese_competizione',
        component: require('../components/pages/next-scouting/competizione.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            permesso: 'scout_club',
        }
    });

    routes.push({
        path: '/next/' + a + '/club/:paese_id/:competizione_id/:squadra_id',
        name: a + '_next_club_paese_competizione_squadra',
        component: require('../components/pages/next-scouting/squadra.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            permesso: 'scout_club',
            contesto: 'giocatori'
        }
    });

    routes.push({
        path: '/next/' + a + '/nazionali/:paese_id/:squadra_id',
        name: a + '_next_nazione_squadra',
        component: require('../components/pages/next-scouting/squadra.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            permesso: 'scout_nazionali',
            contesto: 'giocatori'
        }
    });

    routes.push({
        // partite manuali
        path: '/next/' + a + '/club/:paese_id/:competizione_id/:squadra_id/evento-partite/:partite_id',
        name: a + '_next_club_paese_competizione_squadra_partita_manuale',
        component: require('../components/pages/next-scouting/squadra.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            permesso: 'scout_club',
            contesto: 'partita-partite',
            currentAndPotential: a == 'new' ? true : false
        }
    });

    routes.push({
        // Partite WYScout e inserite su Partite
        path: '/next/' + a + '/club/:paese_id/:competizione_id/:squadra_id/evento-partite-wyscout/:partite_id/:wy_id',
        name: a + '_next_club_paese_competizione_squadra_partita_partitewy',
        component: require('../components/pages/next-scouting/squadra.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            permesso: 'scout_club',
            contesto: 'partita-partite-wyscout',
            currentAndPotential: a == 'new' ? true : false
        }
    });

    routes.push({
        // Partite SOLO SU WYScout
        path: '/next/' + a + '/club/:paese_id/:competizione_id/:squadra_id/evento-wyscout/:wy_id',
        name: a + '_next_club_paese_competizione_squadra_partita_wy',
        component: require('../components/pages/next-scouting/squadra.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            permesso: 'scout_club',
            contesto: 'partita-wyscout',
            currentAndPotential: a == 'new' ? true : false
        }
    });

    routes.push({
        // partite manuali
        path: '/next/' + a + '/partita/evento-partite/:partite_id',
        name: a + '_next_partita_manuale',
        component: require('../components/pages/next-scouting/partita.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '', 
            routeExactMatch: true, 
            permesso: 'scout_partite',
            contesto: 'partita-partite',
            currentAndPotential: a == 'new' ? true : false
        }
    });

    routes.push({
        // Partite WYScout e inserite su Partite
        path: '/next/' + a + '/partita/evento-partite-wyscout/:partite_id/:wy_id',
        name: a + '_next_partita_partitewy',
        component: require('../components/pages/next-scouting/partita.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            routeExactMatch: true,
            permesso: 'scout_partite',
            contesto: 'partita-partite-wyscout',
            currentAndPotential: a == 'new' ? true : false
        }
    });

    routes.push({
        // Partite SOLO SU WYScout
        path: '/next/' + a + '/partita/evento-wyscout/:wy_id',
        name: a + '_next_partita_wy',
        component: require('../components/pages/next-scouting/partita.vue').default,
        meta: {
            area: a,
            module: a + 'scout',
            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',  
            routeExactMatch: true,
            permesso: 'scout_partite',
            contesto: 'partita-wyscout',
            currentAndPotential: a == 'new' ? true : false
        }
    });

}

export const ROUTES_NEXTSCOUT = routes;
