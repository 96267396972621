<template id="esercizicard">
	<div class="esercizicard">

		<div class="card" :id="'exe_' + ex.id">
			<router-link :to="'/football/esercizi/edit/'+ex.id">
				<img :src="image" class="card-img-top img-fluid" />
			</router-link>
			<div class="card-body">
				<p class="text-truncate mb-0" :title="ex.name"><strong>{{ex.name}}</strong></p>
				<p class="mb-0 font-size-sm text-truncate" v-if="ex.utente">di <span class="color-rosso">{{ex.utente.nomeCompleto}}</span></p>
				<div v-if="!ex.portiere">
				<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Principi di gioco">PG:</span> <span class="color-rosso" :title="label_skills_1">{{ label_skills_1 }}</span></p>
				<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Componenti tecniche">CT:</span> <span class="color-rosso" :title="label_skills_2">{{ label_skills_2 }}</span></p>
				<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacita motorie">CM:</span> <span class="color-rosso" :title="label_skills_3">{{ label_skills_3 }}</span></p>
				<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
				</div>
				<div v-if="ex.portiere">
				<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica podalica">TP:</span> <span class="color-rosso" :title="label_skills_4">{{ label_skills_4 }}</span></p>
				<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica di base">TB:</span> <span class="color-rosso" :title="label_skills_5">{{ label_skills_5 }}</span></p>
				<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Qualità tattiche">QT:</span><span class="color-rosso" :title="label_skills_6">{{ label_skills_6 }}</span></p>
				<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacità motorie">CM:</span> <span class="color-rosso" :title="label_skills_7">{{ label_skills_7 }}</span></p>
				</div>
				<span class="color-rosso" v-if="ex.certificato == 2" style="position:absolute; top:2rem; left:3rem;font-size: 2rem;"><strong>RIFIUTATO</strong></span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		ex: { type: Object },

		skills: { type: Array },
		skills_portiere: { type: Array }
	},

	computed: {

		image: function() {

			return this.ex.canvas_url;
		},

		label_skills_1: function() {
			var label = "";
			var skill_rel = this.ex.skills_rel.find(item => item.skill_glo_root_id == 1);
			if(skill_rel) {
				label = this.getLabel(skill_rel.skill_glo_id, this.skills.find(item => item.id == 1));
			}
			return (label != "" ? label : "N/D");		
		},
		label_skills_2: function() {
			var label = "";
			var skill_rel = this.ex.skills_rel.find(item => item.skill_glo_root_id == 30);
			if(skill_rel) {
				label = this.getLabel(skill_rel.skill_glo_id, this.skills.find(item => item.id == 30));
			}
			return (label != "" ? label : "N/D");		
		},
		label_skills_3: function() {
			var label = "";
			var skill_rel = this.ex.skills_rel.find(item => item.skill_glo_root_id == 61);
			if(skill_rel) {
				label = this.getLabel(skill_rel.skill_glo_id, this.skills.find(item => item.id == 61));
			}
			return (label != "" ? label : "N/D");		
		},
		label_skills_4: function() {
			var label = "";
			var skill_rel = this.ex.skills_rel.find(item => item.skill_glo_root_id == 86);
			if(skill_rel) {
				label = this.getLabel(skill_rel.skill_glo_id, this.skills_portiere.find(item => item.id == 86));
			}
			return (label != "" ? label : "N/D");		
		},
		label_skills_5: function() {
			var label = "";
			var skill_rel = this.ex.skills_rel.find(item => item.skill_glo_root_id == 99);
			if(skill_rel) {
				label = this.getLabel(skill_rel.skill_glo_id, this.skills_portiere.find(item => item.id == 99));
			}
			return (label != "" ? label : "N/D");		
		},
		label_skills_6: function() {
			var label = "";
			var skill_rel = this.ex.skills_rel.find(item => item.skill_glo_root_id == 124);
			if(skill_rel) {
				label = this.getLabel(skill_rel.skill_glo_id, this.skills_portiere.find(item => item.id == 124));
			}
			return (label != "" ? label : "N/D");		
		},
		label_skills_7: function() {
			var label = "";
			var skill_rel = this.ex.skills_rel.find(item => item.skill_glo_root_id == 61);
			if(skill_rel) {
				label = this.getLabel(skill_rel.skill_glo_id, this.skills_portiere.find(item => item.id == 61));
			}
			return (label != "" ? label : "N/D");		
		},
	},

	methods: {
		getLabel(id_skill, skills) {
			var label = "";
			if(!skills.campi) return label;
			var skill_items = skills.campi;
			for(var key in skill_items) {
				var skill = skill_items[key];
				if(skill.id == id_skill) {
					label = skill.nome;
					return label;
				}
				if(skill.campi) {
					var items = skill.campi;
					for(var key2 in items) {
						var subskill = items[key2];
						if(subskill.campi) {
							var items2 = subskill.campi;
							for(var key3 in items2) {
								var subskill2 = items2[key3];
								if(subskill2.id == id_skill) {
									label = skill.nome + " -> " + subskill.nome + " -> " + subskill2.nome;
									return label;
								}  
							}
						} else {
							if(subskill.id == id_skill) {
								label = skill.nome + " -> " + subskill.nome;
								return label;
							} 		
						}
					}
				} else {
					if(skill.id == id_skill) {
						label = skill.nome;
						return label;
					}
				}
			}
			return label;
		},
	},


	data: function() {
		return {
			dummy: false,
		}
	},
}
</script>
