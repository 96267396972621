<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<b-overlay :show="loading" no-wrap></b-overlay>
		<b-card title="Players On Loan">
			<b-row class="mb-2" align-v="center">
				<b-col cols="2">
					<b-datepicker v-model="filters.from" placeholder="From" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
				</b-col>
				<b-col cols="2">
					<b-datepicker v-model="filters.to" placeholder="To" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>		
				</b-col>
				<b-col cols="1">
					<b-button size="sm" variant="primary" @click="getForInterval"title="Get players on loan for interval"><i class="fa fa-search" aria-hidden="true"></i></b-button> 
					<b-button size="sm" @click="defaultInterval" title="Default interval"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
				</b-col>
				<b-col cols="2">
					<b-checkbox size="sm" v-model="filters.only_active" :value="true"> Active loans only </b-checkbox>
				</b-col>
			</b-row>

			<b-table-simple sticky-header="73vh" hover bordered responsive fixed>
				<b-thead>
					<b-tr>
						<b-th v-for="col, index in columns" :style="'font-size: 0.70rem;background-color:#FAFAFA;width:' + col.width + 'px !important'" :sticky-column="index > 2 ? false : true">
							<div v-b-tooltip.top="{ customClass: 'tooltipClassHeader' }" :title="col.label">{{ col.header }}</div>
						</b-th>
						<b-th class="text-center" style="border:none;">	
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="player in items" :key="player.id">
						<b-td v-for="col, index in columns" :style="'background-color:#FFFFFF;padding: 0;font-size: 0.85rem;text-align:' + (index > 2 ? 'center' : 'left') + ';'" :sticky-column="index > 2 ? false : true" :class="rowClass(player)">
							<div v-if="col.field == 'categoria'" :style="'width:100%;padding:7px;background:' + (player['nazionale'] ? '#33CCFF' :  '') +';'">
							{{ player['nazionale'] ? '&nbsp;' :  player[col.field] }}
							</div>
							<div v-else-if="col.field == 'squadra'" :style="'width:100%;padding:7px;background:' + (player['nazionale'] ? '#33CCFF' :  '') +';'">
								{{ player[col.field] }}
							</div>
							<div v-else-if="col.field == 'utilizzo' || col.field == 'minuti_presenze_90'" :style="'width:100%;padding:7px;color:#FFFFFF;background:' + getBgPerc(player[col.field]) +';'">
								{{ player[col.field] + (!isNaN(player[col.field]) ? '%' : '') }}
							</div>
							<div v-else-if="col.field == 'media_voto'" :style="'width:100%;padding:7px;color:#FFFFFF;background:' + getBgVoto(player[col.field]) +';'">
								{{ player[col.field] > 0 ? player[col.field] : 'N/A'}}
							</div>
							<div v-else style="width:100%;padding:7px;">
								{{ player[col.field] }}
							</div>
						</b-td>	
					<b-td style="background-color:#FFFFFF;border:none;">	
					</b-td>
				</b-tr>
				</b-tbody>
			</b-table-simple>

		<b-row class="mt-1" align-v="center">
			<b-col cols="9">
				<b-badge style="background: red" class="mr-1">
					0% - 33%
				</b-badge>
				<b-badge style="background: orange" class="mr-1">
					34% - 65%
				</b-badge>
				<b-badge style="background: green" class="mr-1">
					66% - 100%
				</b-badge>
			</b-col>
			<b-col cols="3" class="text-right">
				<b-button size="sm" variant="primary" title="Download report" class="mr-2" @click="download"><i class="fa fa-download" aria-hidden="true"></i></b-button>
			</b-col>
		</b-row>
	</b-card>
	<footerapp></footerapp>
</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
var Auth = require('@/auth.js');
export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			loading: false,
			items: [],
			columns: [],
			filters: {
				from: null,
				to: null,
				only_active: true,
			}
		}
	},
	mounted() {
		this.fetchPlayersOnLoan();
	},
	computed: {
		user: function() {
			return Auth.user;
		}
	},
	watch: {
	},
	methods: {
		fetchPlayersOnLoan: function() {
			this.items = [];
			this.columns = [];
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/report/players/onloan', {params: this.filters }).then(function(data) {
				var flagRow = false;
				var player_id = null;
				this.columns = data.data.fields;
				data.data.items.forEach(item => {
					if(!player_id) {
						player_id = item.player_id;
					} else if(player_id != item.player_id) {
						player_id = item.player_id;
						flagRow = !flagRow;
					}
					item.flagRow = flagRow;
				});
				this.items = data.data.items;
				this.filters.from = data.data.from;
				this.filters.to = data.data.to;
				this.loading = false;
			});
		},
		getForInterval: function() {
			if(this.filters.from && this.filters.to) {
				this.fetchPlayersOnLoan();
			} else {
				alert('You must select a valid date range!');
			}	
		},
		defaultInterval: function() {
			this.filters.from = null;
			this.filters.to = null;
			this.fetchPlayersOnLoan();
		},
		download: function() {
			window.open(this.$store.state.apiEndPoint + '/report/download/players/onloan/' + (this.filters.from ? this.filters.from : null) + '/' + (this.filters.to ? this.filters.to : null) + '/' + this.filters.only_active + '/' + this.user.id + "/" + this.user.api_token, "_blank");
		},
		getBgPerc: function(perc) {
			var bg = 'transparent';
			if(perc >= 0 && perc <= 33) {
				bg = 'red';
			} else if(perc >= 34 && perc <= 65) {
				bg = 'orange';
			} else if(perc >= 66 && perc <= 100) {
				bg = 'green';
			} 
			return bg;
		},
		getBgVoto: function(voto) {
			var bg = 'gray';
			if(voto >= 6 && voto <= 10) {
				bg = 'green';
			} else if(voto >= 5 && voto <= 5.9) {
				bg = 'orange';
			} else if(voto > 0 && voto  <= 4.9) {
				bg = 'red';
			} 
			return bg;
		},
		rowClass: function(data) {
			return data.flagRow ? 'row-even': null;
		}
	}
};
</script> 
<style>
.row-even {
	background-color: rgba(204, 204, 204,.3) !important;
}
</style>  