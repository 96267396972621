<template>
  <b-card no-body class="h-100 p-1">
    <div class="text-center" style="cursor:pointer;" @click="getInfoPersona">
      <b-img :src="persona.avatar_url" :width="130"></b-img>
      <div class="font-bold text-center">{{ persona.cognome && persona.cognome.trim() != '' ? persona.cognome : 'N/D' }} {{ persona.nome && persona.nome.trim() != '' ? persona.nome : 'N/D' }}</div>
       <div>{{ persona.nascita_data | dateFormat }}</div>
       <div><small>created at: {{ persona.created_at | dateTimeFormat }}</small></div>
        <div><small>updated at: {{ persona.updated_at | dateTimeFormat }}</small></div>
    </div>
  </b-card>
</template>

<script>
import moment from 'moment';
export default {
  data: function() {
    return {
    }
  },
  props: ['persona'],

  computed: {
  },
  methods: {
    getInfoPersona() {
       this.$emit('getInfoPersona', this.persona.id);
    }
  },
  filters: {
    dateFormat: function (date) {
      if(date) {
        return moment(date).format('DD/MM/YYYY');
      } 
      return 'DD/MM/YYYY';
    },
    dateTimeFormat: function (date) {
      return moment(date).format('DD/MM/YYYY HH:MM');
      
    }
  }
}
</script>
