<template id="opponents">
	<section class="opponents">

		<headapp></headapp>

		<template id="menusub">
			<div class="menusub">

				<div class="card">
					<div class="card-body">

						<ul class="list-inline m-0">
							<li class="list-inline-item">
								<b-button variant="outline-primary" :to="{ path: `/football/squadre`}"><i class="fa fa-arrow-left fa-fw"></i> Torna alla ricerca della squadra</b-button>
								<b-button v-if="auth.isLastSeason(seasonId)" variant="outline-primary" :to="{ name: `football_opponents_new`, params: {wy_id: this.$route.params.team_id, category_id: this.$route.params.category_id  }}"><i class="fa fa-plus fa-fw"></i> Aggiungi giocatore</b-button>
								<b-button variant="outline-primary" :to="{ path: `/football/squadre/view/` + $route.params.team_id}">Scheda squadra</b-button>
							</li>
							<div class="pull-right" v-if="auth.isLastSeason(seasonId) && team && team.wyscout">
								<b-button variant="outline-primary" @click="sync" :disabled="loading"><i class="fa fa-refresh fa-fw"></i> Sync da wyscout</b-button>
							</div>
							<div class="pull-right mr-3" v-if="team && auth.isLastSeason(seasonId)">
								<b-button variant="outline-primary" @click="showTeams"><i class="fa fa-arrow-up"></i> Importa da stagione precente</b-button>
							</div>
						</ul>
					</div>
				</div>
			</div>
		</template>
		<div class="card">
			<div class="card-body">

				<div class="row flex-items-xs-middle">
					<div class="col-1">
						<img v-bind:src="team.logo_url" height="80">
					</div>
					<div class="col-5">
						<div class="text-uppercase color-rosso font-bold font-size-lg">
							{{ team.officialName }}
						</div>
						<div class="text-muted">
							{{ team.city }}
						</div>
					</div>
					<div class="col-6 text-right">
					</div>
				</div>
			</div>
		</div>

		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="content">
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
					<players v-if="render_player && !loading" class="border-0 rounded-0"></players>
				</div>
			</div>
		</div>

		<b-modal id="selectTeamModal" ref="selectTeamModal" :title="'Seleziona squadra ' + user.seasons[1].stagione" ok-only ok-title="Chiudi" size="lg">
			<div>
				<b-row class="mb-1" v-if="!teamSel">
					<b-col sm="3">
						<b-form-select v-model="filters.paese">
							<option value="0">-- Paese --</option>
							<option v-for="item in paesi" :value="item.id">{{item.paese}}</option>
						</b-form-select>
					</b-col>
					<b-col sm="3">
						<b-form-select v-model="filters.categoria">
							<option value="0">-- Categoria --</option>
							<option v-for="item in categorie" :value="item.id" :key="item.id">{{item.name}}</option>
						</b-form-select>
					</b-col>
					<b-col sm="6">
						<b-input-group>
							<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
							<b-input-group-append>
								<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
							</b-input-group-append>
						</b-input-group>  	
					</b-col>
				</b-row>
				<b-row class="mb-3 mx-3" v-if="teamSel" align-v="end">
					<b-col sm="3">
						<b-card class="small text-center">
							<img :src="teamSel.logo_url" width="50" /><br/>
							<strong>{{teamSel.officialName}}</strong><br/>
							<span>{{ teamSel.categoria ? teamSel.categoria.name : '&nbsp;' }}</span><br/>
						</b-card>
					</b-col>
					<b-col sm="9" v-if="players.length > 0" class="text-right">
						<b-button variant="primary" class="mr-1" @click="selectAll">Seleziona tutti</b-button>
						<b-button variant="primary" @click="unselectAll">Deseleziona tutti</b-button>
					</b-col>
				</b-row>
				<div v-if="loadingTeams" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
				<div v-if="loadingPlayers" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
				<div style="height: 400px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
					<b-row v-if="!teamSel">
						<b-col cols="4" v-for="team in teams.data" v-bind:key="team.id" style="padding: 0.2rem">
							<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="selectTeam(team)">
								<img :src="team.logo_url" width="50" /><br/>
								<strong>{{team.officialName}}</strong><br/>
								<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
							</b-card>
						</b-col>
					</b-row>
					<b-row v-if="teamSel">
						<b-col v-for="player in players" cols="6" class="p-1">
							<b-card :bg-variant="players_ids.indexOf(player.id) > -1 ? 'warning' : 'default'">
								<b-row align-v="center">
									<b-col cols="2">
										<b-form-checkbox v-model="players_ids" :value="player.id"></b-form-checkbox>
									</b-col>
									<b-col cols="3">
										<img :src="player.avatar" height="50"/>
									</b-col>
									<b-col>
										<span>{{ player.name }} </span><br/>
									</b-col>
								</b-row>
							</b-card>
						</b-col>
					</b-row>					
				</div>
				<b-row v-if="!teamSel && teams.current_page">
					<b-col cols="6" style="padding: 0.2rem">
						<a v-if="teams.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(teams.current_page) - 1)" >Indietro</a>
					</b-col>	
					<b-col cols="6" style="padding: 0.2rem">
						<a v-if="teams.current_page < teams.last_page" class="page-link" @click="paginate(parseInt(teams.current_page) + 1)">Avanti</a>
					</b-col>	
				</b-row>
				<b-row class="mt-3 mx-3">
					<b-col v-if="players_ids.length > 0">
						<b-button variant="primary" class="mr-1" @click.prevent="importPlayers">Importa sulla stagione {{ user.seasons[0].stagione }} della squadra {{ team.officialName }} - {{ team.categoria.name }}</b-button>
					</b-col>
				</b-row>
			</div>
		</b-modal>

		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import * as types from '../../../../store/types';
var Auth = require('../../../../auth.js');
export default {
	data: function(){
		return {
			team: {},
			wy_players: [],
			loading: false,
			render_player: true,
			loadingTeams: false,
			loadingPlayers: false,
			teamSel: null,
			players: [],
			players_ids: [],
			filters: {
				owner: 0,			
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
			paesi: [],
			teams: {
				data: []
			},
			categorie: [],
			page: 1,
		}
	},
	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		players:            require('./players.vue').default,
	},
	created() {
		this.get_team();
		this.getCategories();
		this.getPaesi();
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['seasonId']),
	},
	methods: {
		rerender(){
			this.render_player = false
			this.$nextTick(() => {
				this.render_player = true
				console.log('re-render start')
				this.$nextTick(() => {
					console.log('re-render end')
				})
			})
		},
		get_team() {
			if(this.$route.params.team_id){
				axios.get(`/squadre/get/` + this.$route.params.team_id)
				.then((res) => {
					this.team = res.data;
				}).catch((err) => {
					this.loading = false;
				});
			}
		},
		getPaesi() {
			this.$http.get(this.$store.state.apiEndPoint + '/paesi/squadre').then((response) => {
				if (response.ok)
				{
					this.paesi = response.data; 
				}
			}, (response) => {
			});
		},
		getCategories() {
			this.$http.get(this.$store.state.apiEndPoint + '/exercise/categories').then((response) => {
				if (response.ok)
				{
					this.categorie = response.data; 
				}
			}, (response) => {
			});
		},
		sync(){			
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/opponents/ws/get/players/by/team/' + this.team.wyscout.chiave + '/' + this.$route.params.team_id + '/' + this.$route.params.category_id + '/opponents').then((response) => {

				if (response.ok)
				{
					this.wy_players = response.data;
					this.loading = false;
					this.rerender();
				}
			}, (response) => {

			});
		},
		showTeams: function() {
			this.teamSel = null;
			this.players = [];
			this.players_ids =[];
			this.teams = {
				data: []
			};		
			this.$refs.selectTeamModal.show();
		},
		selectTeam(team) {
			this.teamSel = team;
			this.getPlayers();
		},
		getPlayers: function() {				
			this.players = [];
			if(this.teamSel) {
				this.loadingPlayers = true;
				this.$http.get(this.$store.state.apiEndPoint + '/opponents/' + this.teamSel.id + '/' + this.teamSel.id_categoria + '/' + this.user.seasons[1].id).then((response) => {
					if (response.ok)
					{
						if(response.data.players) {
							this.players = response.data.players.map(item => ({								
								name: item.nome + ' ' + item.cognome,
								id: item.id,
								avatar: item.avatar_url,
							}));
						}
					}
					this.loadingPlayers = false;
				}, (response) => {
				});
			}
		},
		selectAll() {
			this.players_ids = [];
			for(var i in this.players) {
				this.players_ids.push(this.players[i].id);
			}				
		},
		unselectAll() {
			this.players_ids = [];
		},
		importPlayers() {
			if(confirm("Confermi l'import dei giocatori selezionati ?")) {			
				this.loading = true;
				this.$refs.selectTeamModal.hide();
				this.$http.get(this.$store.state.apiEndPoint + '/rosa/import/players', {params: { players_ids: this.players_ids, team_id: this.team.id, season_id: this.user.seasons[0].id }}).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.loading = false;
						this.rerender();
					}
				}, (response) => {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				});
			}
		},
		paginate(page) {
			this.page = page;
			this.getTeams();
		},
		filtraArchivio() {
			this.loadingTeams = true;
			this.teams = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then((response) => {
				// success callback
				if (response.ok)
				{
					this.teams = response.data;
				}
				this.loadingTeams = false;
			}, (response) => {
				// error callback
			});
		},
		getTeams: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then((response) => {
				// success callback
				if (response.ok)
				{
					this.teams = response.data;						
				}
			}, (response) => {
				// error callback
			});
		},
	}
}
</script>
