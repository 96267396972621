<template>
	<div>
		<b-form-group v-for="type, k in rule" :label="type.name">
			
			<div v-if="type.type == 'int'">
				<b-input type="number" v-model.number="configuration[k]"></b-input>
			</div>
			<div v-if="type.type == 'from'">
				<Type-From :contratto="contratto" :from="type.from" :from-params="type.from_params" :selected="configuration[k]" @update="configuration[k] = $event"></Type-From>
			</div>
			<div v-if="type.type == 'bool'">
				<label><input type="checkbox" v-model="configuration[k]" :value-true="1" :value-false="0"> Sì</label>
			</div>
			<div v-if="type.type == 'date'">
				<b-input type="date" v-model="configuration[k]"></b-input>
			</div>

		</b-form-group>

		<!-- <pre>{{configuration}}</pre>
		<pre>{{config}}</pre>
		<hr />
		<pre>{{rule}}</pre> -->
		
	</div>
</template>

<script>

export default {
	props: [ 'config', 'rule', 'contratto' ],
	data: function() {
		return {
			configuration: false
		}
	},
	components: {
		TypeFrom: require('./RegolaTypeFrom.vue').default
	},
	watch: {
		configuration: {
			deep: true,
			handler: function() {
				this.$emit('update', JSON.stringify(this.configuration));
			}
		},
		config: {
			immediate: true,
			deep: true,
			handler: function() {
				this.configuration = JSON.parse(this.config);
			}
		}
	}
};

</script>