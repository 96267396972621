<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<b-overlay :show="loading" no-wrap></b-overlay>
		<b-card title="Players Disciplinary">
			<b-tabs v-model="activeIndex">
				<b-tab v-for="(tab, index) in tabs" :key="tab.id" :title="tab.label">
					<b-table-simple sticky-header="70vh" striped hover bordered responsive fixed>
						<b-thead>
							<b-tr>
								<b-th style="background-color:#FAFAFA;width:270px !important;" sticky-column>Player</b-th>
								<b-th style="background-color:#FAFAFA;width:100px !important;" v-for="col,i in columns[index]" :key="col.id" class="text-center"> 
									{{i + 1}}	
								</b-th>
								<b-th class="text-center" style="border:none;">	
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="player in items[index]" :key="player.id">
								<b-td style="padding: 0; vertical-align: middle;" sticky-column>{{ player.player }}</b-td>
								<b-td style="padding: 0" class="text-center" v-for="col in columns[index]">
									<div v-if="player[col]" :style="'width:100%;padding:5px;' + getStyleByState(player[col].state)" v-b-tooltip.top.html="{ customClass: 'tooltipClassCell' }" :title="player.player + '<br/>' + (getMatch(index, player[col].match_id).home ? getMatch(index, player[col].match_id).opponent + ' - AS Roma' : 'AS Roma - ' + getMatch(index, player[col].match_id).opponent) + '<br/>' + getMatch(index, player[col].match_id).score + '<br/>' + getMatch(index, player[col].match_id).date">
										gara {{ player[col].day }}
									</div>
									<div v-else style="width:100%;padding:5px;">
										&nbsp;
									</div>
								</b-td>
								<b-td style="background-color:#FFFFFF;border:none;">	
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-tab>
			</b-tabs>

			<b-row class="mt-1" align-v="center">
				<b-col cols="9">
					<b-badge :style="getStyleByState(1)" class="mr-1">
						Ammonito
					</b-badge>
					<b-badge :style="getStyleByState(6)" class="mr-1">
						Pre Diffida
					</b-badge>
					<b-badge :style="getStyleByState(2)" class="mr-1">
						Diffidato
					</b-badge>
					<b-badge :style="getStyleByState(5)">
						Squalificato
					</b-badge>
				</b-col>
				<b-col cols="3" class="text-right" v-if="tabs.length > 0 && activeIndex > -1">
					<b-button size="sm" variant="primary" :title="'Download report ' + tabs[activeIndex].label" class="mr-2" @click="download(tabs[activeIndex].id)">{{ tabs[activeIndex].label }} <i class="fa fa-download" aria-hidden="true"></i></b-button>
					<b-button size="sm" variant="primary" title="Download report completo" class="mr-2" @click="download('')"><i class="fa fa-download" aria-hidden="true"></i></b-button>
				</b-col>
			</b-row>
		</b-card>
		<footerapp></footerapp>
	</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
import { mapState } from 'vuex';
var Auth = require('@/auth.js');
export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			loading: false,
			tabs: [],
			items: [],
			columns: [],
			matches: [],
			activeIndex: 0,
		}
	},
	mounted() {
		this.fetchPlayersMatches();
	},
	computed: {
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId']),
	},
	watch: {
		rosaTeamId() {
			this.fetchPlayersMatches();
		},	
	},
	methods: {
		fetchPlayersMatches: function() {
			this.tabs = [];
			this.items = [];
			this.columns = [];
			this.activeIndex = 0;
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/report/players/matches', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then(function(data) {
				Object.entries(data.data).forEach(([key, item]) => {
					this.tabs.push({id: key, label: item.name});
					this.matches.push(item.matches);
					this.columns.push(Array.from({length: (item.countEvents)}, (_, i) => 'event_id_' + (i + 1)));
					const sortedPlayers = _.sortBy(Object.values(item.players), ['player']);
					const playersYellows = sortedPlayers.map(obj => {
						return Object.assign({}, {player: obj.player}, obj.events);	
					});
					this.items.push(playersYellows);
				});
				this.loading = false;
			});
		},
		getMatch: function(index, match_id) {
			return this.matches[index].find((item) => item.id == match_id);
		},
		download: function(league) {
			var type = '0';
			if(league != '') {
				type = league;
			}
			window.open(this.$store.state.apiEndPoint + '/report/download/players/yellows/' + this.rosaTeamId + '/' + type + '/' + this.user.id + "/" + this.user.api_token, "_blank");
		},
		getStyleByState: function(state)  {
			var bg = 'background:transparent';
			if(state == 1) {
				bg = 'background:#FFEB3B;color:black';
			} else if(state == 2) {
				bg = 'background:orange;color:black';
			} else if(state == 5) {
				bg = 'background:black;color:white';
			} else if(state == 6) {
				bg = 'background:#FFCA28;color:black';
			}
			return bg;
		}
	}
};
</script> 
<style>
.tooltipClassHeader {
	text-align: center;
	min-width:250px;
}

.tooltipClassCell {
	text-align: center;
	font-size: 0.9rem !important;
	min-width:250px;
}
</style>   
