<template id="videotag">
	<section class="videotag">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">

					<div class="row flex-items-xs-middle">
						<div class="col-4">

							<div class="text-uppercase color-rosso font-bold font-size-lg">
								{{osservato.cognome}} {{osservato.nome}}
							</div>
							<div class="text-muted">
								{{osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}
							</div>

						</div>
						<div class="col-8 text-right">
							<ul class="list-inline mb-0">
								<li class="list-inline-item"><a :href="'#/next/' + area + '/view/giocatore/' + osservato.id">Scheda giocatore</a></li>
								<li class="list-inline-item" v-if="osservato.valutazioni && osservato.valutazioni.length > 0"><a :href="'#/next/' + area + '/view/valutazione/' + osservato.id + '/' + osservato.valutazioni[0].id">Valutazioni</a></li>
								<li class="list-inline-item" v-if="osservato.valutazioni && osservato.valutazioni.length == 0"><a :href="'#/next/' + area + '/new/valutazione/' + osservato.id">Valutazioni</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Videotag</span>
					</div>
					<div class="mb-2">
						<b-btn variant="primary" title="Seleziona partita" class="mr-1" @click="showPartite">Seleziona partita</b-btn>
						<b-btn variant="primary" title="Archivio tags" class="mx-1" @click="showArchivio">Archivio tags</b-btn>	
						<span class="font-size-lg ml-5"> {{ utente }} </span>
					</div>
					<div v-if="loading_player" class="text-center"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<videotag v-if="partita && !loading_player" :goalVisible="false"></videotag>
				</div>
			</div>
		</div>
		<b-modal id="matchesModal" ref="matchesModal" title="Partite giocatore" ok-only ok-title="Chiudi">
			<div v-if="!show_archivio">
				<div v-if="loading"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<p v-if="!loading && !matches.length">Nessuna partita trovata!</p>
				<div v-if="!loading && matches.length">
					<p>Trovate {{matches.length}} partite!</p>
					<hr/>
					<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="match in matches" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPartita(match.matchId, null)">
							<div class="col-md-9 my-1">
								<h5 style="display: inline;">{{ match.label }}</h5><br />
								<span class="text-muted">{{ match.date | formatDateHour }}</span><br />	
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="show_archivio">
				<p v-if="!videos.length">Nessuna partita con tags in archivio!</p>
				<div v-if="videos.length">
					<p>Trovate {{videos.length}} partite con tags!</p>
					<hr/>
					<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="video in videos" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPartita(video.partita.wyscout_id, video)">
							<div class="col-md-9 my-1">
								<h5 style="display: inline;">{{ partitaLabel(video.partita.squadre) }}</h5><br />
								<span class="text-muted">{{ video.partita.competizione.nome }}</span><br />	
								<span class="text-muted">{{ video.partita.data | formatDateHour }} UTC</span><br />	
								<span v-if="video.utente" class="color-rosso">{{ video.utente.nomeCompleto }}</span><br />
							</div>
						</div>
					</div>
				</div>
			</div>	
		</b-modal>
		<footerapp></footerapp>
	</section>
</template>

<script>

import moment from 'moment'

import * as types from '@/store/types'

export default {

	data: function() {
		return {
			matches: [],
			loading: false,
			loading_player: false,
			osservato: [],
			partita: null,
			data: [],
			videos: [],
			show_archivio: false,
			utente: '',
		}
	},

	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		videotag: require('@/components/layout/videotag/Home.vue').default,
		
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/scouting/giocatore/' + this.$route.params.id + '/new').then((response) => {
			this.osservato = response.data;
			this.$http.get(this.$store.state.apiEndPoint + '/videotag/partite/giocatore/' + this.osservato.id).then((response) => {
				this.videos = response.data;
			});
			if(this.osservato.wyscout && this.osservato.wyscout.chiave != '') {
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/matches/by/player/' + this.osservato.wyscout.chiave).then((response) => {
					this.matches = response.data;
					this.loading = false;
				});
			}		
		});	 
	},
	methods: {
		showArchivio() {
			this.show_archivio = true;
			this.$refs.matchesModal.show();
		},
		showPartite() {
			this.show_archivio = false;
			this.$refs.matchesModal.show();
		},
		partitaLabel(squadre) {
			if(squadre.length > 0) {
				if(squadre[0].pivot.side == 'home') {
					return squadre[0].name + " - " + squadre[1].name;
				} else return squadre[1].name + " - " + squadre[0].name;
			} else {
				return "Home - Away"
			}
		},
		selezionaPartita: function(ws_partita_id, video) {
			this.loading_player = true;
			this.partita = ws_partita_id;
			var id_video = 0;
			if(video) {
				id_video = video.id;
				if(video.utente) {
					this.utente = video.utente.nomeCompleto;
				}
			} else {
				this.utente = '';
			}
			this.$http.get(this.$store.state.apiEndPoint + '/videotag/partita/giocatore/' + this.partita + "/" + this.osservato.id + "/" + id_video).then((response) => {
				if (response.ok)
				{
					this.data = response.data;
					this.$store.commit('cancelNewTag');
					this.$store.commit('setPrivateTag', false);
					this.$store.commit('updateData', this.data);
					this.loading_player = false;
				}
			}, (response) => {

			});
			this.$refs.matchesModal.hide();	
		},
	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}
</script>