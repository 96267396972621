<template>
	<div>

		<b-row>
			<b-col v-if="currentTotals.on_going">
				La stagione <strong>è in corso</strong>
			</b-col>
			<b-col class="text-right small my-2">
				<a @click.prevent="expandAll" href="">Espandi Tutto</a> /
				<a @click.prevent="collapseAll" href="">Collassa Tutto</a>
			</b-col>
		</b-row>

		<table class="table small table-strieped y">
			<thead>
				<tr>
					<th width="12%">Bonus</th>
					<th width="5%">Target</th>
					<th width="18%">Competizioni</th>
					<th width="5%" class="text-center">Bonus Maturato</th>
					<th width="10%">Contatore Bonus</th>
					<th width="10%" class="text-center">Pag. Fine Stag</th>
					<th width="10%" class="text-center">Netto Gar.</th>
					<th width="10%">Importo Bonus Lordo (Netto)</th>
					<th width="10%">Importo Bonus Maturato</th>
				</tr>
			</thead>
			<tbody v-for="bonus, kk in bonuses" style="border-bottom: 2px solid rgba(0,0,0,.3)">
				<tr style="background-color: rgba(0,0,0,.03)">
					<td>
						<a style="color: black" :class="{ 'text-success': bonus.isBonusMaturated, 'text-black': !bonus.isBonusMaturated }" href="" @click.prevent="toggle(kk)"><strong>{{ bonus.name }}</strong></a>
					</td>
					<td colspan="2">
						<span class="text-muted" v-if="bonus.target && !isExpanded(kk)">({{ bonus.target }})</span>
						<em>{{ bonus.validation }}</em>
						<em class="text-muted small ml-1" v-if="bonus.multiplier_type == 'repeat'">(Il bonus è ripetibile)</em>
					</td>
					<td class="text-center">
						<span>{{ bonus.isBonusMaturated ? '✅' : '' }}</span>
						<small v-if="(bonus.multiplier_value > 1 || bonus.multiplier_type == 'repeat') && bonus.value">
							x {{ bonus.multiplier_value }}
						</small>
						<div v-if="bonus.isBonusMaturated && !bonus.con_ultima_mensilita" v-b-popover.hover.top="'Il bonus risulta raggiunto, ma non saldato.'" title="Bonus Raggiunto">⚠️</div>
					</td>
					<td class="text-center">
						<b-progress v-if="bonus.contatore && !isExpanded(kk)" :value="lastProgress(progressInfo(bonus)) < 1 ? lastProgress(progressInfo(bonus)) * 100 : 100" :variant="bonus.isBonusMaturated ? 'success' : (bonus.watchdog ? 'warning' : 'secondary')" :animated="currentTotals.on_going && (!bonus.isBonusMaturated || bonus.multiplier_type == 'repeat')"></b-progress>
					</td>
					<td class="text-center">
						{{ bonus.con_ultima_mensilita ? 'Sì' : '' }}
					</td>
					<td class="text-center">
						{{ bonus.netto_garantito ? 'Sì' : '' }}
					</td>
					<td class="small text-right">
						{{ bonus.importo | money }}
						<small v-if="(bonus.multiplier_value > 1 || bonus.multiplier_type == 'repeat') && bonus.value">
							x {{ bonus.multiplier_value }}
						</small>
					</td>
					<td v-if="bonus.value" class="text-right">
						{{ bonus.value_display }}
					</td>
					<td v-else></td>
				</tr>

				<tr v-for="r in isExpanded(kk) ? bonus.rules : []">
					<td>
						<b-badge>{{r.posizione}}</b-badge>
						{{ r.name }}

						<div v-if="r.model.params.length > 0" style="background-color: rgba(0,0,0,.05); padding: 0.4em; margin-top: 0.3em; border-radius: 5px">
							<div v-for="p in r.model.params"><small>{{p}}:</small> {{ r.payload[p] }}</div>
						</div>
					</td>
					<td>
						<span v-if="r.payload.target && r.payload.target != 0">
							{{ r.payload.target }}
						</span>
					</td>
					<td>{{ r.competizioni.join(', ') }}</td>
					<td class="text-center">
						{{ r.result ? '✓' : '' }}
						<small v-if="bonus.multiplier_value && isBonusRipetibile(r, bonus)">({{ bonus.multiplier_value }})</small>
					</td>
					<td class="text-center" @mouseenter="pick(r, bonus)" @mouseleave="unpick(r)" :id="'hover-' + r.id">
						{{ r.progress.amount }}<span v-if="r.payload.target">/{{ r.payload.target }}</span>
						
						<div v-if="isBonusRipetibile(r, bonus)">
							<b-progress class="mb-1" v-for="pp in bonus.multi_progress" :value="pp < 1 ? pp * 100 : 100" :variant="pp >= 1 ? 'success' : (bonus.watchdog ? 'warning' : 'secondary')"></b-progress>
						</div>
						<div v-else>
							<b-progress v-if="r.payload.target" :animated="currentTotals.on_going && r.progress.progress < 1" :value="r.progress.progress < 1 ? r.progress.progress * 100 : 100" :variant="r.progress.progress >= 1 ? 'success' : (bonus.watchdog ? 'warning' : 'secondary')"></b-progress>
						</div>

					</td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>

			</tbody>
			<tfoot>
				<tr>
					<td colspan="8" class="text-right">
						Totale Bonus Maturati
					</td>
					<th class="text-right">{{ currentTotals.bonuses | money }}</th>
				</tr>

				<tr @click.prevent="triggerFixedAmounts">
					<td colspan="8" class="text-right">
						<span v-if="isExpiredOne() && !showFixedAmounts" v-b-popover.hover.top="'Il pagamento risulta scaduto e non ancora saldato.'" title="Pagamento Scaduto">⚠️</span>
						Totale Importi Fissi
					</td>
					<th class="text-right">{{ currentTotals.fixed | money }}</th>
				</tr>

				<!--<tr v-for="importo in contratto_data.entity.importi" v-if="showFixedAmounts" style="background-color: rgba(0,0,0,.1)">
					<td colspan="8" class="text-right">

						<b-btn size="xs" variant="outline-primary" @click.prevent="pickPayment(importo)">
							<NotesHas entity="Persona_Contratto_Importo" :entityId="importo.id"></NotesHas>
							<span v-if="isExpired(importo.data_previsto_pagamento) && importo.pagato == 0" v-b-popover.hover.top="'Il pagamento risulta scaduto e non ancora saldato.'" title="Pagamento Scaduto">⚠️</span>
							<span v-else-if="importo.pagato == 0">💰</span>
							<span class="text-success" v-else>✔︎</span>
						</b-btn>

						{{ importo.dal | dt }} • {{ importo.al | dt }}
					</td>
					<th class="text-right">{{ importo.stipendio_annuo_lordo | money }}</th>
				</tr>-->

				<tr>
					<th colspan="8" class="text-right">
						Gran Totale {{ season.variabili_squadra.name }}
					</th>
					<th class="text-right">{{ currentTotals.total_grand | money }}</th>
				</tr>
				

			</tfoot>
		</table>
		
		<b-popover v-if="picked" custom-class="bigdrop" :show="true" :target="'hover-' + picked.id" title="Dettagli">

			<div v-if="pickedData">
				<div v-if="pickedData.bonus.watchdogs">
					<strong>Alerts ({{pickedData.bonus.watchdogs.length}})</strong>
					<ul class="list-unstyled">
						<li v-for="ws,k in pickedData.bonus.watchdogs" v-if="pickedData.bonus.watchdogs[k]">
							<span v-if="pickedData.bonus.watchdogs[k]">🔔</span>
							{{ ws.name }}
						</li>
					</ul>
				</div>

				<p v-if="pickedData.bonus.multiplier_type == 'repeat'">Questa bonus può essere maturato più di una volta.</p>

				<table width="100%" class="taeble small table-striped">
					<tbody v-for="m in pickedData.events" :style="pickedBonus.isBonusMaturated ? 'border-bottom: 2px solid green':''">
						<tr v-for="single,kk in m">
							<td width="10%" class="text-center" v-if="pickedData.rule.payload && pickedData.rule.payload.target ? m.length == pickedData.rule.payload.target : false">{{kk + 1}}</td>
							<td width="50%">
								{{ single.home.name }} - {{ single.away.name }}
							</td>
							<td width="40%" class="text-center">
								<small>{{ single.data }}</small>
							</td>
						</tr>
					</tbody>
				</table>

			</div>

			<!-- <pre>{{pickedData}}</pre> -->
		</b-popover>

	</div>
</template>

<style type="text/css" scpoped>

.bigdrop {
	width: 500px;
}

</style>

<script>

import _ from 'lodash'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
	props: [ 'bonuses', 'season', 'contratto_data' ],
	data: function() {
		return {
			expanded: [],
			rules: false,
			hasModified: false,
			modifierCheck: false,
			picked: false,
			pickedBonus: false,
			pickedData: false,
			pickedPayment: false,
			showFixedAmounts: false,
		}
	},
	computed: {
		pickedEvents: function() {
			return _.chunk(this.pickedData.events, 10);
		},
		currentTotals: function() {

			if(this.contratto_data.totals[this.season.id])
			 	return this.contratto_data.totals[this.season.id];
			return {
				fixed: 0,
				bonuses: 0,
				total_grand: 0
			};

		}
	},
	filters: {
		dt: function(a) {
			return moment(a).format('DD/MM/Y')
		},
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return '€ ' + formatter.format(v).replace('€', '');
		},
	},
	methods: {
		triggerFixedAmounts: function() {
			this.showFixedAmounts = !this.showFixedAmounts;
		},
		isExpired: function(date) {
			return moment().isAfter(moment(date));
		},
		isExpiredOne: function(date) {

			var has = _.filter(_.map(this.contratto_data.entity.importi, function(a) {
				return this.isExpired(a.data_previsto_pagamento) && a.pagato == 0;
			}.bind(this)));

			return has.length > 0;

		},
		isBonusRipetibile: function(rule, bonus) {

			if(bonus.multiplier_type == 'repeat' && 'rule' + rule.posizione == bonus.multiplier)
				return true;

			return false;

		},
		progressInfo: function(a) {
			if(a.entity.multiplier_type == 'repeat')
				return a.multi_progress;
			else
				return [ a.progress ];
		},
		lastProgress: function(a) {
			if(_.isArray(a)) {
				return _.last(a);
			}
			return a;
		},
		expandAll: function() {
			this.expanded = _.map(_.keys(this.contratto_data.analysis.bonuses), function(a) {return parseInt(a)});
		},
		collapseAll: function() {
			this.expanded = [];
		},
		toggle: function(k) {
			var p = this.expanded.indexOf(k);
			if(p !== -1) {
				this.expanded.splice(p, 1);
			} else {
				this.expanded.push(k);
			}
		},
		isExpanded: function(k) {
			return this.expanded.indexOf(k) !== -1;
		},
		pick: function(r, bonus) {
			console.log(r);
			this.pickedData = false;
			this.pickedBonus = bonus;
			this.$http.get(this.$store.state.apiEndPoint + '/contratto/'+this.contratto_data.entity.id+'/detail-new/' + r.persona_contratto_bonus_id + '?rule_id=' + r.id).then(function(data) {
				this.pickedData = data.data;
			});
			this.picked = r;
		},
		unpick: function(r) {
			this.picked = false;
			this.pickedData = false;
			this.pickedBonus = false;
			console.log(r);
		},
	}
};

</script>