import moment from 'moment';

export default {
  computed: {
    isInCalendarView: function() {
      return this.$route.name.indexOf('_calendar') !== -1
    }
  },
  methods: {
  	hasSetup: function(sessione, label, base) {

  		//if(sessione.sessione_tipo && sessione.sessione_tipo.setup && sessione.sessione_tipo.setup[label] != undefined) return sessione.sessione_tipo.setup[label];
  		return this.hasSetupTipo(sessione.sessione_tipo, label, base);

  	},
    hasSetupTipo: function(provided, label, base) {

      if(provided && provided.setup && provided.setup != null && provided.setup[label] != undefined) return provided.setup[label];
      return base;

    },
    apiGetSessione: function(sessioneId) {

    	return this.$http.get(this.$store.state.apiEndPoint + '/sessione/' + sessioneId).then(function(res) {
    		
    		res.data.players = res.data.persone.map(item => ({
    			name: item.cognome + ' ' + item.nome,
    			id: item.id,
    		}));
    		
    		res.data.start_at = moment(res.data.inizio).format("YYYY-MM-DD");
    		res.data.from_hour 	= moment(res.data.inizio).format("HH:mm:ss");
    		res.data.to_hour 	= moment(res.data.fine).format("HH:mm:ss");
    		res.data.season_id  = res.data.stagione_id;

    		var sessione = res.data;
    		sessione.fasi = _.map(sessione.fasi, function(fase) {

    			this.$set(fase, 'players', _.map(sessione.persone, function(item) {
    				var realItem = _.find(fase.persone, { id: item.id });
    				return {
    					name: item.cognome + ' ' + item.nome,
    					id: item.id,
    					inPhase: _.map(fase.persone, 'id').indexOf(item.id) !== -1,
    					activity_check: { reason: (realItem ? realItem.pivot.reason : null), checked: (realItem ? realItem.pivot.checked : null), nota: (realItem ? realItem.pivot.nota : '') }
    				}
    			}));

    			this.$set(fase, 'injuries', _.map(fase.injuries, function(item) {
    				return {
    					id: item.id,							
    					data: item.data,
    					pathology: item.pathology,
    					persona_id: item.persona_id
    				}
    			}));

    			this.$set(fase, 'illnesses', _.map(fase.illnesses, function(item) {
    				return {
    					id: item.id,							
    					data: item.data,
    					illness_types: item.illness_types,
    					illness_other_types: item.illness_other_types,
    					persona_id: item.persona_id
    				}
    			}));
    			
    			return fase;

    		}.bind(this));

    		return sessione;

    	});

    }
  },
};