<template>
	<div>
		<div v-if="risultati">
			<b-table-simple striped style="overflow-y: scroll" small>
				<b-thead style="background-color: white; position: sticky; top: 0; z-index: 2;">
					<b-tr>
						<b-th>Giocatore</b-th>
						<b-th v-for="head in risultati.intestazioni" :class="{ 'rotate': fase.setup.matrixLabels && fase.setup.matrixLabels == 'vertical'}">
							<span>{{ head.label }}</span>
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="player,k in risultati.table">
						<b-td class="small">{{ getPlayer(k).cognome + ' ' + getPlayer(k).nome }}</b-td>
						<b-td v-for="head,k in risultati.intestazioni">
							<div class="d-flex align-items-center">
								<div class="flex-grow-1" :is="typeOf(head.type)" :test="player" :field="k" :options="head" :dataLayer="dataLayer"></div>
								<div class="pl-2 pr-1 small" style="cursor: pointer; color: #ba2e3b" v-if="player[k] != null" @click.prevent="askEmpty(player, k)">
									<i class="fa fa-trash" aria-hidden="true"></i>
								</div>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>

	</div>
</template>

<style type="text/css">

th.rotate {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: left;
}

th.rotate span {
	display: block;
	padding-left: 10px;
	margin-bottom: 5px;
	transform: rotate(180deg);
}

</style>

<script>

import _ from 'lodash'
import sessionMixin from '@/mixins/sessione.js';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	mixins: [
		sessionMixin
	],
	components: {
		SessioneMatriceBoolean: require('@/components/layout/Sessioni/Matrix/Boolean.vue').default,
		SessioneMatriceNumber: require('@/components/layout/Sessioni/Matrix/Number.vue').default,
		SessioneMatriceSelector: require('@/components/layout/Sessioni/Matrix/Selector.vue').default,
		SessioneMatriceTextarea: require('@/components/layout/Sessioni/Matrix/Textarea.vue').default,
		SessioneMatriceText: require('@/components/layout/Sessioni/Matrix/Text.vue').default,
		SessioneMatriceEnum: require('@/components/layout/Sessioni/Matrix/Enum.vue').default,
		SessioneMatriceDate: require('@/components/layout/Sessioni/Matrix/Date.vue').default,

		SessioneMatriceCustomfms: require('@/components/layout/Sessioni/Matrix/Custom/Fms.vue').default,
	},
	watch: {
		risultati: {
			deep: true,
			handler: function() {

				var selectors = _.filter(this.risultati.intestazioni, { type: 'selector' });

				_.each(selectors, function(sel) {
					if(!_.find(this.dataLayer, { url: sel.url })) {
						this.$http.get(this.$store.state.apiEndPoint + sel.url).then(function(data) {
							this.dataLayer.push({
								url: sel.url,
								data: data.data
							});
						});
					}
				}.bind(this));

			},
			immediate: true
		}
	},
	created: function() {

		

	},
	props: [ 'risultati', 'fase' ],
	data: function() {
		return {
			dataLayer: [],
			ux: {
				show: true
			}
		}
	},
	
	computed: {
		tabella: function() {
			var chiavi = _.keys(this.risultati.intestazioni);
			return chiavi;
		}
	},
	methods: {
		askEmpty: function(player, k) {
			if(player[k] != null) {
				if(confirm('Confermi la rimozione del dato?'))
					player[k] = null;
			}
		},
		getPlayer: function(id) {
			return _.find(this.fase.persone, { id: parseInt(id) });
		},
		typeOf: function(tipo) {
			return 'SessioneMatrice' + _.capitalize(tipo);
		}
	}
}

</script>