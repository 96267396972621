export const ROUTES_REPORTS = [/*
    {
        path: '/reports/dashboard',
        name: 'reports_dashboard',
        component: require('../components/pages/reports/dashboard.vue').default,
        meta: {
            module: 'reports',
            //showMenu: false,
            label: 'Reports',
            requiresAuth: true,
            showInMenu: false,
            //parent: 'medical_recovery_dashboard',
            permesso: 'reports',
        }
    }, */
    {
        path: '/reports/dashboard',
        name: 'reports_dashboard',
        component: require('../components/pages/reports/SportsDirection.vue').default,
        meta: {
            module: 'reports',
            //showMenu: false,
            label: 'Reports',
            requiresAuth: true,
            permesso: 'reports',
        }
    },
    {
        path: '/reports/players/matches',
        name: 'reports_players_matches',
        component: require('../components/pages/reports/PlayersMatches.vue').default,
        meta: {
            module: 'reports',
            label: 'Players Matches',
            showInMenu: false,
            requiresAuth: true,
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
            menusub: [ 'reports_dashboard'],
            permesso: 'report_players_matches',
        }
    },
    {
        path: '/reports/players/yellows',
        name: 'reports_players_yellows',
        component: require('../components/pages/reports/PlayersYellows.vue').default,
        meta: {
            module: 'reports',
            //showMenu: false,
            label: 'Players Disciplinary',
            requiresAuth: true,
            showInMenu: false,
            menusub: [ 'reports_dashboard'],
            enableRosaTeam: true,
            permesso: 'report_players_yellows',
        }
    },
    {
        path: '/reports/players/onloan',
        name: 'reports_players_onloan',
        component: require('../components/pages/reports/PlayersOnLoan.vue').default,
        meta: {
            module: 'reports',
            label: 'Players On Loan',
            requiresAuth: true,
            showInMenu: false,
            menusub: [ 'reports_dashboard'],
            permesso: 'report_players_onloan',
        }
    },
    {
        path: '/reports/players/matches/attendances',
        name: 'reports_players_matches_attendances',
        component: require('../components/pages/reports/PlayersMatchesAttendances.vue').default,
        meta: {
            module: 'reports',
            label: 'Players Matches Attendances',
            requiresAuth: true,
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
            showInMenu: false,
            menusub: [ 'reports_dashboard'],
            permesso: 'report_players_matches_attendance',
        }
    },
    {
        path: '/reports/scouting',
        name: 'reports_scouting',
        component: require('../components/pages/reports/ScoutingReport.vue').default,
        meta: {
            module: 'reports',
            label: 'Scouting Report',
            requiresAuth: true,
            showInMenu: false,
            menusub: [ 'reports_dashboard'],
            permesso: 'report_scouting',
        }
    },
    {
        path: '/medical/recovery-dashboard',
        name: 'medical_recovery_dashboard',
        component: require('../components/pages/medical/recoveryDashboard.vue').default,
        meta: {
            module: 'reports',
            label: 'Report Recovery',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_recovery',
            menusub: [ 'reports_dashboard'],
        }
    },
    {
        path: '/medical/prevenzione-dashboard',
        name: 'medical_prevenzione_dashboard',
        component: require('../components/pages/medical/prevenzioneDashboard.vue').default,
        meta: {
            module: 'reports',
            label: 'Report Recovery',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_recovery',
            menusub: [ 'reports_dashboard'],
        }
    },
    {
        path: '/medical/dashboard/fisioterapia',
        name: 'medical_fisioterapia_dashboard',
        component: require('../components/pages/reports/singleDashboard.vue').default,
        meta: {
            module: 'reports',
            label: 'Report Fisioterapia',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_fisioterapia',
            menusub: [ 'reports_dashboard'],
            report: {
                dashboardType: 'DashboardTypeA',
                title: 'Fisioterapia',
                itemSingular: 'Fisioterapia',
                itemPlural: 'Fisioterapie',
                endpoint: '/atlas/dashboard/fisioterapia',
                itemIdField: 'fisioterapia_glo_id'
            }
        }
    },
    {
        path: '/medical/dashboard/fisioterapia-test',
        name: 'medical_fisioterapia_test_dashboard',
        component: require('../components/pages/reports/singleDashboard.vue').default,
        meta: {
            module: 'reports',
            label: 'Report Fisioterapia',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_test_fisioterapia',
            menusub: [ 'reports_dashboard'],
            report: {
                dashboardType: 'DashboardTypeA',
                title: 'Test Fisioterapia',
                itemSingular: 'Test Fisioterapia',
                itemPlural: 'Test Fisioterapie',
                endpoint: '/atlas/dashboard/fisioterapia-test',
                itemIdField: 'fisioterapia_test_glo_id'
            }
        }
    },
    {
        path: '/medical/dashboard/nutrition',
        name: 'medical_nutrition_dashboard',
        component: require('../components/pages/reports/singleDashboard.vue').default,
        meta: {
            module: 'reports',
            label: 'Report Nutrition',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_nutrition',
            menusub: [ 'reports_dashboard'],
            report: {
                dashboardType: 'DashboardTypeA',
                title: 'Nutrition',
                itemSingular: 'Nutrition',
                itemPlural: 'Nutrition',
                endpoint: '/atlas/dashboard/nutrition',
                itemIdField: 'nutrition_glo_id'
            }
        }
    },
    {
        path: '/medical/dashboard/farmaco',
        name: 'medical_farmaco_dashboard',
        component: require('../components/pages/reports/singleDashboard.vue').default,
        meta: {
            module: 'reports',
            label: 'Report Farmaci',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_farmaco',
            menusub: [ 'reports_dashboard'],
            report: {
                dashboardType: 'DashboardTypeA',
                title: 'Farmaco',
                itemSingular: 'Farmaco',
                itemPlural: 'Farmaci',
                endpoint: '/atlas/dashboard/farmaco',
                itemIdField: 'farmaco_glo_id'
            }
        }
    },
    {
        path: '/medical/dashboard/peso',
        name: 'medical_peso_dashboard',
        component: require('../components/pages/reports/singleDashboard.vue').default,
        meta: {
            module: 'reports',
            label: 'Report Peso',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_peso',
            menusub: [ 'reports_dashboard'],
            report: {
                dashboardType: 'DashboardTypeDebug',
                title: 'Peso',
                itemSingular: 'Farmaco',
                itemPlural: 'Farmaci',
                endpoint: '/atlas/dashboard/peso',
            }
        }
    },
    {
        path: '/medical/dashboard/infortuni',
        name: 'medical_injuries_dashboard',
        component: require('../components/pages/medical/injuriesDashboard.vue').default,
        meta: {
            module: 'reports',
            label: 'Report Infortuni',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_infortuni',
            menusub: [ 'reports_dashboard']
        }
    },
    {
        path: '/sessioni-statistiche/analizza',
        name: 'sessioni_statistiche',
        component: require('../components/pages/reports/sessioniStatistiche.vue').default,
        meta: {
            module: 'reports',
            label: 'Sessioni',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            showSeason: false,
            enableSeason: true,
            permesso: 'report_farmaco',
            menusub: [ 'reports_dashboard']
        }
    },
];
