export const ROUTES_ADMIN = [
    {
        path: '/admin/utenti',
        name: 'admin_utenti',
        component: require('../components/pages/admin/utenti.vue').default,
        meta: {
            module: '',

            label: 'Utenti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_utenti',
        }
    },
    {
        path: '/admin/tavola-permessi/:persona_id',
        name: 'admin_utenti_permessi',
        component: require('../components/pages/admin/tavola-permessi.vue').default,
        meta: {
            module: '',
            label: 'Utenti',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'admin_utenti',
        }
    },
    {
        path: '/admin/gruppi',
        name: 'admin_gruppi',
        component: require('../components/pages/admin/gruppi.vue').default,
        meta: {
            module: '',

            label: 'Gruppi',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_gruppi',
        }
    },
    {
        path: '/admin/permessi',
        name: 'admin_permessi',
        component: require('../components/pages/admin/permessi.vue').default,
        meta: {
            module: '',

            label: 'Permessi',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_permessi',
        }
    },
    {
        path: '/admin/squadre/referenti',
        name: 'admin_squadre_referenti',
        component: require('../components/pages/admin/referenti.vue').default,
        meta: {
            module: '',

            label: 'Referenti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_referenti',
        }
    },
    {
        path: '/admin/notifiche/utenti',
        name: 'admin_notifiche_utenti',
        component: require('../components/pages/admin/notifiche_utenti.vue').default,
        meta: {
            module: '',

            label: 'Notifiche',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            menusub: [ 'admin_notifiche_tipi'],
            permesso: 'admin_notifiche',
        }
    },
    {
        path: '/admin/notifiche/tipi',
        name: 'admin_notifiche_tipi',
        component: require('../components/pages/admin/notifiche_tipi.vue').default,
        meta: {
            module: '',

            label: 'Configura tipi di notifiche',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'admin_notifiche',
        }
    },
    {
        path: '/admin/agenti',
        name: 'admin_agenti',
        component: require('../components/pages/admin/agenti.vue').default,
        meta: {
            module: '',

            label: 'Agenti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_agenti',
        }
    },
    {
        path: '/admin/logs',
        name: 'admin_logs',
        component: require('../components/pages/admin/logs.vue').default,
        meta: {
            module: '',

            label: 'Logs',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_logs',
        }
    },
    {
        path: '/admin/skills/ruoli',
        name: 'admin_skills_ruoli',
        component: require('../components/pages/admin/skills_ruoli.vue').default,
        meta: {
            module: '',

            label: 'Skills Ruoli',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_skills_ruoli',
        }
    }, 
    {
        path: '/admin/servizi',
        name: 'admin_servizi',
        component: require('../components/pages/admin/servizi.vue').default,
        meta: {
            module: '',

            label: 'Servizi',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_servizi',
        }
    },

    {
        path: '/admin/persone',
        name: 'admin_persone',
        component: require('../components/pages/admin/persone.vue').default,
        meta: {
            module: '',
            label: 'Persone',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            menusub: ['admin_persone', 'admin_duplicati', 'admin_modificati'],
            permesso: 'admin_persone',
        },
    },

    {
        path: '/admin/duplicati',
        name: 'admin_duplicati',
        component: require('../components/pages/admin/duplicati.vue').default,
        meta: {
            module: '',

            label: 'Duplicati',
            requiresAuth: true,
            showInMenu: true,
            parent: 'admin_persone',
            menusub: ['admin_persone', 'admin_duplicati', 'admin_modificati'],
            permesso: 'admin_persone',
        }
    },

    {
        path: '/admin/modificati',
        name: 'admin_modificati',
        component: require('../components/pages/admin/modificati.vue').default,
        meta: {
            module: '',

            label: 'Modificati',
            requiresAuth: true,
            showInMenu: true,
            parent: 'admin_persone',
            menusub: ['admin_persone', 'admin_duplicati', 'admin_modificati'],
            permesso: 'admin_persone',
        }
    },

    {
        path: '/admin/news',
        name: 'admin_news',
        component: require('../components/pages/admin/news.vue').default,
        meta: {
            module: '',

            label: 'News',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'admin_news',
        }
    },
];
