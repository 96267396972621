export const ROUTES_ACADEMY_ITA = [
    {
        path: '/academyita/dashboard',
        name: 'academy_ita_dashboard',
        component: require('../components/pages/academy_ita/dashboard.vue').default,
        meta: {
            module: 'academy_ita',

            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            showAcademyCategory: true,
            parent: '',
            permesso: 'academy_ita',
        }
    },

    {
        path: '/academyita/societa',
        name: 'academy_ita_societa',
        component: require('../components/pages/academy_ita/societa/societa.vue').default,
        meta: {
            module: 'academy_ita',

            label: 'Academy',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'academy_societa_mng',
        }
    },

    {
        path: '/academyita/societa/nuova',
        name: 'academy_ita_societa_new',
        component: require('../components/pages/academy_ita/societa/new.vue').default,
        meta: {
            module: 'academy_ita',

            label: 'Nuova Academy',
            requiresAuth: true,
            showInMenu: true,
            parent: 'academy_ita_societa',
            permesso: 'academy_societa_mng',
        }
    },

    {
        path: '/academyita/societa/edit/:id',
        name: 'academy_ita_societa_edit',
        component: require('../components/pages/academy_ita/societa/edit.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Academy',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_ita_societa',
            menusub: [ 'academy_ita_societa_edit', 'academy_ita_societa_allenamenti'],
            permesso: 'academy_societa_mng',
        }
    },

    {
        path: '/academyita/societa/allenamenti/:id',
        name: 'academy_ita_societa_allenamenti',
        component: require('../components/pages/academy_ita/societa/allenamenti.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Allenamenti',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_ita_societa',
            menusub: [ 'academy_ita_societa_edit', 'academy_ita_societa_allenamenti'],
            permesso: 'academy_societa_allen',
        }
    },

    {
        path: '/academyita/societa/allenamento/:id/:allen_id',
        name: 'academy_ita_societa_allenamento',
        component: require('../components/pages/academy_ita/societa/allenamento.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_ita_societa_allenamenti',
            menusub: [ 'academy_ita_societa_edit', 'academy_ita_societa_allenamenti'], 
            permesso: 'academy_societa_allen',           
        }
    },

    {
        path: '/academyita/pdf',
        name: 'academy_ita_pdf',
        component: require('../components/pages/academy_ita/pdf.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Documenti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'academy_pdf',
        }
    },

    {
        path: '/academyita/pdf/archivio',
        name: 'academy_ita_pdf_archivio',
        component: require('../components/pages/academy_ita/pdf_archivio.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Archivio Documenti',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'academy_ita',
        }
    },

    {
        path: '/academyita/esercizi',
        name: 'academy_ita_esercizi',
        component: require('../components/pages/academy_ita/esercizi.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Esercizi',
            requiresAuth: true,
            showInMenu: true,
            showAcademyCategory: true,
            parent: '',
            permesso: 'academy_ita_esercizi',
        }
    },

    {
        path: '/academyita/esercizi/new',
        name: 'academy_ita_esercizi_new',
        component: require('../components/pages/academy_ita/esercizio_new.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Nuovo Esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'academy_ita_ese_mng',
        }
    },

    {
        path: '/academyita/esercizio/:id',
        name: 'academy_ita_esercizio',
        component: require('../components/pages/academy_ita/esercizio.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'academy_ita_esercizi',
        }
    },

    {
        path: '/academyita/allenamenti',
        name: 'academy_ita_allenamenti',
        component: require('../components/pages/academy_ita/allenamenti.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Allenamenti',
            requiresAuth: true,
            showInMenu: true,
            showAcademyCategory: true,
        //  showAcademyList: true,
            parent: '',
            permesso: 'academy_ita_allenamenti',
        }
    },

    {
        path: '/academyita/allenamento/:id',
        name: 'academy_ita_allenamento',
        component: require('../components/pages/academy_ita/allenamento.vue').default,
        meta: {
            module: 'academy_ita',

            label: 'Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_ita_allenamenti', 
            permesso: 'academy_ita_allenamenti',           
        }
    },

    {
        path: '/academyita/video',
        name: 'academy_ita_video',
        component: require('../components/pages/academy_ita/video.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Video',
            requiresAuth: true,
            showInMenu: true,
            showAcademyCategory: true,
            parent: '',
            permesso: 'academy_ita_video',
        }
    },

    {
        path: '/academyita/video/new',
        name: 'academy_ita_video_new',
        component: require('../components/pages/academy_ita/video_new.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Nuovo Video',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_ita_video',
            permesso: 'academy_ita_video_mng',
        }
    },

    {
        path: '/academyita/video/edit/:id',
        name: 'academy_ita_video_edit',
        component: require('../components/pages/academy_ita/video_edit.vue').default,
        meta: {
            module: 'academy_ita',
            label: 'Modifica Video',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_ita_video',
            permesso: 'academy_ita_video_mng',
        }
    },
];
