<template id="nuova_valutazione">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card card-block title_page">
				<div class="card-body">
					<div class="card-title">
						<span>Nuova Valutazione</span>
					</div>
				</div>
			</div>	
			<div class="content_page">
				<b-form @submit="onSubmit">
					<b-row>
						<b-col cols="3">
							<div class="card" style="height:100%">
								<div style="text-align:center;">
									<b-img :src="osservato.avatar_url" :alt="osservato.cognome + ' ' + osservato.nome" :width="130"></b-img>
								</div>
								<div class="card-body">
									<div class="mb-1 font-bold text-center">{{ osservato.nome }} {{ osservato.cognome }}</div>
									<div class="text-center font-size-sm color-rosso">{{ osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</div>
									<div class="text-center font-size-sm">Classe {{osservato.nascita_data | formatYear}}</div>
									<div class="text-center font-size-sm color-rosso">{{osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.squadra ? osservato.last_carriera_sportiva.squadra.name : ''}}</div>
									<div class="text-center font-size-sm mt-3">
										<b-form-radio-group
										stacked
										buttons
										v-model="form.gradimento"	
										size="sm">
										<b-form-radio @click.native="selectGradimento(grad.id)" v-if="grad.newscout" v-for="grad in gradimenti" :value="grad.id" :class="(grad.id == form.gradimento ? 'btn-grad-active' : 'btn-grad')" :style="'background-color:' + (grad.id == form.gradimento ? grad.colore : grad.colore + '7F')" :title="grad.title">{{grad.new_name ? grad.new_name : grad.nome}}</b-form-radio>
									</b-form-radio-group>
									<div v-if="$route.meta.currentAndPotential">
										<b-row align-h="center" class="mt-3">
											<b-col cols="6" class="text-left">
												<b-form-checkbox-group v-model="form.alerts" stacked>
													<b-form-checkbox v-for="alert in alerts" :value="alert.value"> {{ alert.text }} </b-form-checkbox>
												</b-form-checkbox-group>
											</b-col>
										</b-row>
										<b-row align-h="center" class="mt-3">
											<b-col cols="6">
												<b-form-group id="currentGroup" label="Current" :label-cols="3" label-for="current">
													<b-form-input id="current" type="number" min="0" max="10" v-model="form.current" step="0.01" :style="'background-color:' + current_bg"></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row align-h="center">
											<b-col cols="6">
												<b-form-group id="potentialGroup" label="Potential" :label-cols="3" label-for="potential">
													<b-form-input id="potential" type="number" min="0" max="10" v-model="form.potential" step="0.01" :style="'background-color:' + potential_bg"></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
									</div>
								</div>
							</div>
						</div>
					</b-col>
					<b-col>
						<b-tabs>
							<b-tab :title="'Partita ' + (index + 1)" v-for="(item, index) in form.partite" :key="index">	
								<b-row>
									<b-col>
										<b-form-group id="competizioneGroup" horizontal label="Competizione" :label-cols="3" label-for="competizione">
											<b-form-input id="competizione" type="text" v-model="item.competizione"></b-form-input>
										</b-form-group>
										<b-form-group id="partitaGroup" horizontal label="Partita" :label-cols="3">
											<b-input-group>
												<b-form-input id="partita" type="text" v-model="item.partita"></b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="searchPartita(item)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
										<b-row>
											<b-col cols="8">
												<b-form-group id="dataGroup" horizontal label="Data / Ora" :label-cols="3" label-for="data">
													<b-form-input id="data" type="date" v-model="item.data"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col cols="4">
												<b-form-group id="oraGroup" horizontal label="/" :label-cols="1" label-for="ora">
													<b-form-input id="ora" type="time" v-model="item.ora"></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
										<b-form-group id="sistemaGiocoGroup" horizontal label="Sistema di gioco" :label-cols="3">
											<b-form-select v-model="item.sistema_gioco" :options="sistemi" value-field="id" text-field="sistema_gioco"></b-form-select>
										</b-form-group>
										<b-form-group id="ruoloPrimarioGroup" horizontal label="Ruolo primario" :label-cols="3">
											<b-form-select v-model="item.ruolo_primario" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
										</b-form-group>
										<b-form-group id="ruoloSecondarioGroup" horizontal label="Ruolo secondario" :label-cols="3">
											<b-form-select v-model="item.ruolo_secondario" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
										</b-form-group>
										<b-form-group id="votoOpponentGroup" horizontal label="Valore avversario" :label-cols="3" label-for="voto_opponent">
											<b-form-select id="voto_opponent" v-model="item.voto_opponent">
												<option :value="null">-- Valore --</option>
												<option :value="1">+ forte</option>
												<option :value="2">equivalente</option>
												<option :value="3">+ bassa</option>
											</b-form-select>	
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group id="meteoGroup" horizontal label="Meteo" :label-cols="3">
											<b-form-select v-model="item.meteo" :options="meteo" value-field="id" text-field="nome"></b-form-select>
										</b-form-group>
										<b-form-group id="ventiGroup" horizontal label="Vento" :label-cols="3">
											<b-form-select v-model="item.vento" :options="venti" value-field="id" text-field="nome"></b-form-select>
										</b-form-group>
										<b-form-group id="tipoCampoGroup" horizontal label="Tipologia campo" :label-cols="3">
											<b-form-select v-model="item.tipo_campo" :options="tipi_campo" value-field="id" text-field="nome"></b-form-select>
										</b-form-group>
										<b-form-group id="condizioniCampoGroup" horizontal label="Condizioni campo" :label-cols="3">
											<b-form-select v-model="item.condizioni_campo" :options="condizioni_campo" value-field="id" text-field="nome"></b-form-select>
										</b-form-group>
										<b-form-group id="infortunioGroup" horizontal label="Infortunio" :label-cols="3">
											<b-form-select v-model="item.infortunio" :options="infortuni" value-field="id" text-field="nome"></b-form-select>
										</b-form-group>	
										<b-form-group id="modalitaGroup" horizontal label="Modalità" :label-cols="3" label-for="modalita">
											<b-form-radio-group id="modalita" v-model="item.modalita">
												<b-form-radio value="allenamento">Allenamento</b-form-radio>
												<b-form-radio value="stadio">Stadio</b-form-radio>
												<b-form-radio value="video">Video</b-form-radio>
												<b-form-radio value="actions_report">Actions report</b-form-radio>
											</b-form-radio-group>
										</b-form-group>
										<b-form-group id="doveGroup" label="Giocato in" :label-cols="3" label-for="home" horizontal>
											<b-form-radio-group id="home" v-model="item.home">
												<b-form-radio :value="1">Casa</b-form-radio>
												<b-form-radio :value="0">Trasferta</b-form-radio>
											</b-form-radio-group>
										</b-form-group>
										<b-button variant="primary" class="float-right ml-1" @click="removePartita(item)" title="Rimuovi partita" v-if="form.partite.length > 1 && form.partite.length == (index + 1)"><i class="fa fa-trash" aria-hidden="true"></i>
										</b-button>		
									</b-col>
								</b-row>
							</b-tab>
							<b-nav-item slot="tabs" @click.prevent="addPartita" href="#" title="Aggiungi partita">
								<i class="fa fa-plus" aria-hidden="true"></i>
							</b-nav-item>
						</b-tabs>
						<div class="card p-2 mt-2">
							<div class="d-flex justify-content-end">
								<div style="min-width: 40%" class="text-right">
									<languageselector :lingue="lingue" :model="form.lingua"></languageselector>
								</div>
							</div>
							<b-row>
								<b-col>
									<textarealingua label="Caratteristiche fisiche" :model="form.lingua.note_2" :linguaModel="form.lingua" :lingua_selected="form.lingua.selected" :lingua_master="form.lingua.master"></textarealingua>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<textarealingua label="Caratteristiche tecniche" :model="form.lingua.note_3" :linguaModel="form.lingua" :lingua_selected="form.lingua.selected" :lingua_master="form.lingua.master"></textarealingua>							
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<textarealingua label="Caratteristiche tattiche" :model="form.lingua.note_4" :linguaModel="form.lingua" :lingua_selected="form.lingua.selected" :lingua_master="form.lingua.master"></textarealingua>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<textarealingua label="Caratteristiche comportamentali" :model="form.lingua.note_5" :linguaModel="form.lingua" :lingua_selected="form.lingua.selected" :lingua_master="form.lingua.master"></textarealingua>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<textarealingua label="Giudizio personale" :model="form.lingua.note_1" :linguaModel="form.lingua" :lingua_selected="form.lingua.selected" :lingua_master="form.lingua.master"></textarealingua>
								</b-col>
							</b-row>
						</div>
					</b-col>
				</b-row>

				<b-row class="mt-1" v-if="!auth.isUserEnable('scout_not_skills')">
					<b-col v-for="item in skills">
						<b-card :title="item[$i18n.t('lang')]">
							<b-list-group>
								<b-list-group-item v-for="skill in item.fields">
									<b-row style="width:100%;">
										<b-col cols="8" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
											{{skill[$i18n.t('lang')]}}
										</b-col>
										<b-col cols="1" class="text-right">
											<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(skill, skill.it)">{{ skill.count_tags }}</span>
										</b-col>
										<b-col cols="1" class="text-center">
											<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="skill.count_tags" :title="skill.count_tags" @click="selectSkillTags(skill, skill.it)"></i>
										</b-col>
										<b-col cols="2" class="text-center" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
											<i v-if="skill.fields" class="skill-closed fa fa-plus-square"></i>
											<i v-if="skill.fields" class="skill-opened fa fa-minus-square"></i>
											<b-form-select v-if="!skill.fields" :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id]">
												<template slot="first">
													<option value="0"></option>
												</template>
											</b-form-select>
										</b-col>
									</b-row>						
									<b-collapse :id="skill.id" style="width:100%">
										<b-list-group v-if="skill.fields">
											<b-list-group-item v-for="subskill in skill.fields">

												<b-row style="width:100%">
													<b-col cols="8" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
														{{subskill[$i18n.t('lang')]}}
													</b-col>
													<b-col cols="1" class="text-right">
														<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(subskill, skill.it + ' -> ' + subskill.it)">{{ subskill.count_tags }}</span>
													</b-col>
													<b-col cols="1" class="text-center">
														<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="subskill.count_tags" :title="subskill.count_tags" @click="selectSkillTags(subskill, skill.it + ' -> ' + subskill.it)"></i>
													</b-col>
													<b-col cols="2" class="text-center" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
														<i v-if="subskill.fields" class="subskill-closed fa fa-plus-square"></i>
														<i v-if="subskill.fields" class="subskill-opened fa fa-minus-square"></i>
														<b-form-select v-if="!subskill.fields" :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id][subskill.id]">
															<template slot="first">
																<option value="0"></option>
															</template>
														</b-form-select>
													</b-col>
												</b-row>						
												<b-collapse :id="subskill.id" style="width:100%">
													<b-list-group v-if="subskill.fields">
														<b-list-group-item v-for="subskill2 in subskill.fields">
															<b-row style="width:100%">
																<b-col cols="8">
																	{{subskill2[$i18n.t('lang')]}}
																</b-col>
																<b-col cols="1" class="text-right">
																	<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it)">{{ subskill2.count_tags }}</span>
																</b-col>
																<b-col cols="1" class="text-center">
																	<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="subskill2.count_tags" :title="subskill2.count_tags" @click="selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it)"></i>
																</b-col>
																<b-col cols="2" class="text-center">
																	<b-form-select :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id][subskill.id][subskill2.id]">
																		<template slot="first">
																			<option value="0"></option>
																		</template>
																	</b-form-select>
																</b-col>
															</b-row>
														</b-list-group-item>
													</b-list-group>
												</b-collapse>
											</b-list-group-item>
										</b-list-group>
									</b-collapse>
								</b-list-group-item>
							</b-list-group>
						</b-card>
					</b-col>
				</b-row>
				<b-button type="submit" variant="primary" class="mt-1">Salva</b-button>
			</b-form>
		</div>
	</div>

	<b-modal id="searchMatchesModal" ref="searchMatchesModal" title="Ricerca partita" ok-only ok-title="Chiudi">
		<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
		<p v-if="!is_search && !matches.length">Nessuna partita trovata!</p>
		<div v-if="!is_search && matches.length">
			<p>Trovate {{matches.length}} partite!</p>
			<hr/>
			<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
				<div v-for="(match, index) in matches" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPartita(match)">
					<div class="col-md-9 my-1">
						<h5 style="display: inline;">{{ match.label }}</h5><br />
						<span class="text-muted">{{ match.date | formatDateHour }}</span><br />	
					</div>
				</div>
			</div>
		</div>
	</b-modal>
	<b-modal id="tagsModal" size="xl" ref="tagsModal" :title="title" class="fs-modal" @hidden="onHidden" @shown="onShow" ok-only ok-title="Chiudi">
		<b-row v-if="skill_tags_sel">
			<b-col cols="3" style="overflow-y:auto;height:550px;">
				<div v-for="(tag, index) in videotags">
					<thumbtag :tag="tag" :count="index+1" @selectTag="selectTag"></thumbtag>
				</div>
			</b-col>
			<b-col cols="9">
				<video
				id="videotags-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}">
			</video>
			<b-pagination size="sm" align="center" :total-rows="videotags.length" v-model="count" :per-page="1" @change="selectPageTag"></b-pagination>
			<div v-if="tag_sel">
				<p class="m-1">{{ count }} - {{ skillName }}</p>
				<p class="m-1">{{ tag_sel.inizio }} / {{ tag_sel.fine }}</p>
				<p class="m-1">{{ tag_sel.peso }} / {{ tag_sel.tipo }} / {{ tag_sel.dove }}</p>
				<p class="m-1" v-if="tag_sel.video.utente">{{ tag_sel.video.utente.nomeCompleto }}</p>
				<p class="m-1">{{ tag_sel.nota }}</p>
			</div>
		</b-col>
	</b-row>
</b-modal>
</div>
<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('@/auth.js');
import * as types from '@/store/types';
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-playlist';

export default {
	data: function() {
		return {
			osservato: [],
			voti: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			form: {
				persona_id: 0,
				partite: [{
					competizione: '',
					partita: '',
					data: '',
					ora: '',
					sistema_gioco: 0,
					ruolo_primario: 0,
					ruolo_secondario: 0,
					meteo: 0,
					vento: 0,
					tipo_campo: 0,
					condizioni_campo: 0,
					infortunio: 0,
					wyscout_partita_id: '',
					modalita: '',
					voto_opponent: null,
					home: null,
				}],	
				gradimento: 0,
				fields: {},
				note: '',
				note2: null,
				note3: null,
				note4: null,
				note5: null,
				no_skills: 0,
				academy: 3,
				current: null,
				potential: null,
				alerts: [],
				lingua: {
					selected: 1,
					master: 1,
					note_1: {},
					note_2: {},
					note_3: {},
					note_4: {},
					note_5: {},
				}
			},
			errors: [],
			infortuni: [],
			ruoli: [],
			sistemi: [],
			gradimenti: [],
			meteo: [],
			venti: [],
			tipi_campo: [],
			condizioni_campo: [],
			skills: [],
			matches: [],
			is_search: false,
			tab_partita: '',
			skill_tags_sel: null,
			videotags: [], 
			tag_sel: null,
			count: 0,
			title: '',
			ws_partite_ids: [],
			playlist: [],
			alerts: [
				{value: 1, text: 'Funzionale'}, 
				{value: 2, text: 'Occasione di mercato'}, 
				{value: 3, text: 'Urgente'}
				],
			lingue: []
		}
	},

	components: {
		headapp: 	require('@/components/layout/headapp.vue').default,
		menusub:    require('@/components/layout/menusub.vue').default,
		footerapp:  require('@/components/layout/footerapp.vue').default,
		thumbtag:   require('@/components/layout/thumbtag.vue').default,
		textarealingua: require('@/components/layout/multilingua/textarea.vue').default,
		languageselector: require('@/components/layout/multilingua/language-selector.vue').default,
	},
	created: function () {
		this.form.academy = this.academy;
		this.$http.get(this.$store.state.apiEndPoint + '/scouting/giocatore/' + this.$route.params.id + '/' + this.area).then((response) => {
			this.osservato = response.data;
			this.form.persona_id = this.osservato.id;
			this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
				if(this.osservato.last_carriera_sportiva && this.osservato.last_carriera_sportiva.ruolo_ideale && this.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 && this.osservato.last_carriera_sportiva.ruolo_ideale[0].id == 3) {
					this.skills = JSON.parse(response.data.special);
				} else {
					this.skills = JSON.parse(response.data.standard);
				} 
			});
		});

		this.$http.get(this.$store.state.apiEndPoint + '/lingue').then((response) => {
			this.lingue = response.data;
		});

		this.$http.get(this.$store.state.apiEndPoint + '/infortuni').then((response) => {
			this.infortuni = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
			this.ruoli = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
			this.sistemi = response.data;    
		});  

		this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
			this.gradimenti = response.data;    	
		});

		this.$http.get(this.$store.state.apiEndPoint + '/meteo').then((response) => {
			this.meteo = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/venti').then((response) => {	
			this.venti = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/tipi/campo').then((response) => {
			this.tipi_campo = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/condizioni/campo').then((response) => {
			this.condizioni_campo = response.data;    
		});
	},
	watch: {
		skills: function () {
			var jsonObject = {};
			for (var k in this.skills) {
				var item = this.skills[k];          
				if(item.fields) {
					for (var j in item.fields) {
						var item2 = item.fields[j];
						if(item2.fields) {
							for (var y in item2.fields) {
								var item3 = item2.fields[y];
								if(item3.fields) {
									for (var x in item3.fields) {
										var item4 = item3.fields[x];
										jsonObject = this.nest(jsonObject, [item.id, item2.id, item3.id, item4.id], 0);
									}
								} else {
									jsonObject = this.nest(jsonObject, [item.id, item2.id, item3.id], 0);
								}	 
							}
						} else {
							jsonObject = this.nest(jsonObject, [item.id, item2.id], 0);		
						}	 
					}
				}
			}
			this.form.fields = jsonObject;
		},
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		}
	},
	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			if(Auth.isUserEnable('scout_not_skills')) {
				this.form.no_skills = 1;
			}
			if(this.$route.meta.currentAndPotential) {
				if(this.form.current < 0 || this.form.current > 10) {
					alert("ATTENZIONE! Il campo 'current' deve essere un valore compreso tra 0 e 10");
					return;
				}
				if(this.form.potential < 0 || this.form.potential > 10) {
					alert("ATTENZIONE! Il campo 'potential' deve essere un valore compreso tra 0 e 10");
					return;
				}
			}
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/add/valutazione', this.form).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					if(response.data.valutazione_id > 0) {	
						this.$router.push({ name: this.area + "_next_view_valutazione", params: { id: this.osservato.id, val_id: response.data.valutazione_id} });
					} else {
						this.$router.push({ name: this.area + "_next_view_giocatore", params: { id: this.osservato.id } });
					}
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		nest: function(obj, keys, v) {
			if (keys.length === 1) {
				obj[keys[0]] = v;
			} else {
				var key = keys.shift();
				obj[key] = this.nest(typeof obj[key] === 'undefined' ? {} : obj[key], keys, v);
			}

			return obj;
		},

		searchPartita: function(tab_partita) {
			var wsId = this.osservato.wyscout ? this.osservato.wyscout.chiave : '';
			if(wsId != "" && wsId != 0 && wsId != null) {
				this.tab_partita = tab_partita;
				this.matches = [];
				this.is_search = true;
				this.$refs.searchMatchesModal.show();
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/matches/by/player/' + wsId).then((response) => {

					if (response.ok)
					{
						this.matches = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			} else {
				alert("Nessuna partita disponibile!");
			}
		},

		selezionaPartita: function(partita) {
			var competitionId = partita.competitionId;
			if(competitionId != "" && competitionId != 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/competition/' + competitionId).then((response) => {

					if (response.ok)
					{	
						var competizione = response.data;
						this.tab_partita.competizione = competizione.name;
						this.tab_partita.partita = partita.label;
						this.tab_partita.data = partita.date.substr(0, 10);
						this.tab_partita.ora = partita.date.substr(11, 5);
						this.tab_partita.wyscout_partita_id = partita.matchId;

						this.setWsPartiteIds();

						this.$refs.searchMatchesModal.hide();   							
					}
				}, (response) => {

				});
			}
		},

		addPartita: function () {
			this.form.partite.push({ competizione: '',
				partita: '',
				data: '',
				ora: '',
				sistema_gioco: 0,
				ruolo_primario: 0,
				ruolo_secondario: 0,
				meteo: 0,
				vento: 0,
				tipo_campo: 0,
				condizioni_campo: 0,
				infortunio: 0,
				wyscout_partita_id: '',
				modalita: '',
				voto_opponent: null,
				home: null });
		},
		removePartita: function (item) {
			var index = this.form.partite.indexOf(item);	
			this.form.partite.splice(index, 1);
			this.setWsPartiteIds();	
		},
		selectGradimento(id) {
			this.form.gradimento = id;
		},
		selectSkillTags(skill_tags, title) {
			this.title = title;	
			this.videotags = [];
			this.skill_tags_sel = skill_tags;
			if(this.skill_tags_sel.fields) {
				for(var i in this.skill_tags_sel.fields) {
					var item = this.skill_tags_sel.fields[i];
					if(item.fields) {
						for(var j in item.fields) {
							var item2 = item.fields[j];
							if(item2.videotags) {
								this.videotags.push(...item2.videotags);
							}
						}
					} else {
						if(item.videotags) {
							this.videotags.push(...item.videotags);
						}
					}
				}
			} else {
				if(this.skill_tags_sel.videotags) {
					this.videotags = this.skill_tags_sel.videotags;
				}
			}
			var idx_tags = [];
			var tags = [];
			for(var i in this.videotags) {
				var tag = this.videotags[i];
				if(idx_tags.indexOf(tag.id) == -1) {
					tags.push(tag);
					idx_tags.push(tag.id);
				}
			}
			this.videotags = tags;
			this.tag_sel = this.videotags[0];
			this.count = 1;
			this.playlist = [];
			for(var i in this.videotags) {
				var videotag = this.videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.tagsModal.show();
		},
		selectTag(tag, count) {
			this.tag_sel = tag;
			this.count = count;
			this.$video_tags.playlist.currentItem(count - 1); 
		},
		selectPageTag(page) {
			this.tag_sel = this.videotags[page - 1]; 
			this.$video_tags.playlist.currentItem(page - 1);   
		},
		autoSelectTag() {
			this.tag_sel = this.videotags[this.$video_tags.playlist.currentIndex()];
			this.count = (this.$video_tags.playlist.currentIndex() + 1);
		},
		onShow() {
			if(!this.$video_tags) {
				this.$video_tags = videojs('videotags-wrapper');
				this.$video_tags.on('playlistitem', this.autoSelectTag);
			}
			this.$video_tags.playlist(this.playlist);
			this.$video_tags.playlist.autoadvance(0);
			this.$video_tags.play();
		},
		onHidden() {
			this.$video_tags.playlist([]);
			this.$video_tags.pause();
			this.$video_tags.reset();
			this.$video_tags.dispose();
			this.$video_tags = null;
			this.tag_sel = null;
		},
		setWsPartiteIds() {
			var array = [];
			for(var index in this.form.partite) {
				var partita = this.form.partite[index];
				if(partita.wyscout_partita_id != '') {
					array.push(partita.wyscout_partita_id);
				}
			}
			this.ws_partite_ids = array;
		}
	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		academy: function() {
			if(this.area == 'new') {
				return 3;
			} else if(this.area == 'youth') {
				return 4;
			} else if(this.area == 'young') {
				return 5;
			}
			return 0;
		},
		auth: function() {
			return Auth;
		},
		skillName: function() {
			if(this.tag_sel) {
				var nome = "";
				for(var i in this.tag_sel.azioni) {
					var azione = this.tag_sel.azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			}
			return "Azione generica";
		},

		current_bg: function() {
			if(this.gradimenti.length > 0) {
				if(this.form.current >= 8.5 && this.form.current <= 10) {
					return this.gradimenti[0].colore + '7F';
				} else if(this.form.current >= 6.5 && this.form.current <= 8.49) {
					return this.gradimenti[1].colore + '7F';
				} else if(this.form.current >= 4.5 && this.form.current <= 6.49) {
					return this.gradimenti[2].colore + '7F';
				} else if(this.form.current >= 2.5 && this.form.current <= 4.49) {
					return this.gradimenti[3].colore + '7F';
				} else if(this.form.current >= 0 && this.form.current <= 2.49) {
					return this.gradimenti[4].colore + '7F';
				} else {
					return '#FFFFFF';
				}
			}
			return '#FFFFFF';
		},

		potential_bg: function() {
			if(this.gradimenti.length > 0) {
				if(this.form.potential >= 8.5 && this.form.potential <= 10) {
					return this.gradimenti[0].colore + '7F';
				} else if(this.form.potential >= 6.5 && this.form.potential <= 8.49) {
					return this.gradimenti[1].colore + '7F';
				} else if(this.form.potential >= 4.5 && this.form.potential <= 6.49) {
					return this.gradimenti[2].colore + '7F';
				} else if(this.form.potential >= 2.5 && this.form.potential <= 4.49) {
					return this.gradimenti[3].colore + '7F';
				} else if(this.form.potential >= 0 && this.form.potential <= 2.49) {
					return this.gradimenti[4].colore + '7F';
				} else {
					return '#FFFFFF';
				}
			}
			return '#FFFFFF';
		},
	},
	beforeDestroy() {
		if(this.$video_tags) {
			this.$video_tags.dispose();
			this.$video_tags = null;
		}
	},
}

</script>
