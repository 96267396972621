<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>

		<div style="position: fixed; bottom: 0; left: 0; width: 100%; z-index: 9999">
			<b-button size="lg" variant="primary" style="position: absolute; bottom: 30px; left: 20px;border-radius:100%" v-b-toggle.sidebar-1><i :class="'fa fa-' + (shown ? 'compress' : 'expand')" aria-hidden="true"></i></b-button>
		</div>

		<b-sidebar id="sidebar-1" title="Reports" shadow v-model="shown">
			<div class="px-3 py-2">
				<b-form-group label="Seleziona i reports che vuoi visualizzare sulla dashboard:">
					<b-row v-for="report in reports">
						<b-col cols="10"><b-form-checkbox v-model="reports_config.selected" name="reports" v-if="auth.isUserEnable(report.permesso)" :value="report.permesso"> {{ report.titolo }} </b-form-checkbox></b-col>
						<b-col><b-button size="xs" @click.stop="goTo(report.link)"><i class="fa fa-search" aria-hidden="true"></i></b-button></b-col>
					</b-row>
				</b-form-group>
				<b-button size="sm" @click="saveLayoutConfig">Salva configurazione attuale</b-button>
			</div>
		</b-sidebar>


		<div class="container-fluid m-y-1">
			<b-card no-body class="border-0" style="height: 90vh; overflow-y:scroll;">

				<smart-widget-grid :layout="layout_shown">
					<smart-widget v-for="report in reports_shown" :slot="report.permesso" :title="report.titolo" v-if="auth.isUserEnable(report.permesso)">
						<template slot="toolbar">
							<div style="margin: 0 12px;">
								<b-button size="sm" @click="goTo(report.link)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
							</div>
						</template>
						<div class="layout-center">
							<router-link :to="{ name: report.link }">
								<div class="p-1">
									<img :alt="report.titolo" :src="report.img" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</router-link>
						</div>
					</smart-widget>
				</smart-widget-grid>




			<!--div class="my-1">
				<div class="d-flex jc-center">	
					<div class="m-2 ai-strech" v-if="auth.isUserEnable('report_players_matches')">
						<b-card>
							<template #header>
								<h5 @click="goTo('reports_players_matches')" style="cursor:pointer;white-space: pre;"> Players Matches </h5>
							</template>	
							<router-link :to="{ name: 'reports_players_matches' }">
								<div class="p-1">
									<img alt="Players Matches" src="/assets/screens/player-matches.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</router-link>
						</b-card>
					</div>
					<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_players_yellows')">
						<b-card>
							<template #header>
								<h5 @click="goTo('reports_players_yellows')" style="cursor:pointer;white-space: pre;"> Players Disciplinary </h5>
							</template>	
							<router-link :to="{ name: 'reports_players_yellows' }">
								<div class="p-1">
									<img alt="Players Yellows" src="/assets/screens/player-disciplinary.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</router-link>
						</b-card>
					</div>
					<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_players_onloan')">
						<b-card>
							<template #header>
								<h5 @click="goTo('reports_players_onloan')" style="cursor:pointer; white-space: pre;"> Players On Loan </h5>
							</template>	
							<router-link :to="{ name: 'reports_players_onloan' }">
								<div class="p-1">
									<img alt="Players On Loan" src="/assets/screens/player-on-loan.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</router-link>
						</b-card>
					</div>
				</div>
			</div>
			<div class="my-1">
				<div class="d-flex jc-center">
					<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_scouting')">
						<b-card>
							<template #header>
								<h5 @click="goTo('reports_scouting')" style="cursor:pointer; white-space: pre;"> Scouting Report </h5>
							</template>	
							<router-link :to="{ name: 'reports_scouting' }">
								<div class="p-1">
									<img alt="Scouting Report" src="/assets/screens/scouting.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</router-link>
						</b-card>
					</div>	
					<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_recovery')">
						<b-card>
							<template #header>
								<h5 @click="goTo('medical_recovery_dashboard')" style="cursor:pointer; white-space: pre;"> Report Recovery </h5>
							</template>	
							<a href="" @click.prevent="goTo('medical_recovery_dashboard')">
								<div class="p-1">
									<img alt="Scouting Report" src="/assets/screens/recovery.jpeg" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</a>
						</b-card>
					</div>
					<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_fisioterapia')">
						<b-card>
							<template #header>
								<h5 @click="goTo('medical_fisioterapia_dashboard')" style="cursor:pointer; white-space: pre;"> Report Fisioterapia </h5>
							</template>	
							<a href="" @click.prevent="goTo('medical_fisioterapia_dashboard')">
								<div class="p-1">
									<img alt="Scouting Report" src="/assets/screens/fisioterapia.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</a>
						</b-card>
					</div>
				</div>
			</div>
			<div class="my-1">
				<div class="d-flex jc-center">
					<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_test_fisioterapia')">
						<b-card>
							<template #header>
								<h5 @click="goTo('medical_fisioterapia_test_dashboard')" style="cursor:pointer; white-space: pre;"> Report Test Fisioterapia </h5>
							</template>	
							<a href="" @click.prevent="goTo('medical_fisioterapia_test_dashboard')">
								<div class="p-1">
									<img alt="Scouting Report" src="/assets/screens/test-fisioterapia.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</a>
						</b-card>
					</div>
					<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_nutrition')">
						<b-card>
							<template #header>
								<h5 @click="goTo('medical_nutrition_dashboard')" style="cursor:pointer; white-space: pre;"> Report Nutrition </h5>
							</template>	
							<a href="" @click.prevent="goTo('medical_nutrition_dashboard')">
								<div class="p-1">
									<img alt="Scouting Report" src="/assets/screens/nutrition.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</a>
						</b-card>
					</div>
					<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_farmaco')">
						<b-card>
							<template #header>
								<h5 @click="goTo('medical_farmaco_dashboard')" style="cursor:pointer; white-space: pre;"> Report Farmaci </h5>
							</template>	
							<a href="" @click.prevent="goTo('medical_farmaco_dashboard')">
								<div class="p-1">
									<img alt="Scouting Report" src="/assets/screens/farmaco.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</a>
						</b-card>
					</div>				
				</div>
			</div>
			<div class="my-1">
				<div class="d-flex jc-center">
					<div class="m-2 ai-stretch" style="width: 32.5%" v-if="auth.isUserEnable('report_players_matches_attendance')">
						<b-card>
							<template #header>
								<h5 @click="goTo('reports_players_matches_attendances')" style="cursor:pointer;white-space: pre;"> Players Matches Attendances </h5>
							</template>	
							<router-link :to="{ name: 'reports_players_matches_attendances' }">
								<div class="p-1">
									<img alt="Players Matches" src="/assets/screens/attendance.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</router-link>
						</b-card>
					</div>
					<div class="m-2 ai-stretch" style="width: 32.5%" v-if="auth.isUserEnable('report_infortuni')">
						<b-card>
							<template #header>
								<h5 @click="goTo('medical_injuries_dashboard')" style="cursor:pointer;white-space: pre;"> Report Infortuni </h5>
							</template>	
							<a href="" @click.prevent="goTo('medical_injuries_dashboard')">
								<div class="p-1">
									<img alt="Players Matches" src="/assets/screens/infortuni.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</a>
						</b-card>
					</div>
					<div class="m-2 ai-stretch" style="width: 32.5%" v-if="auth.isUserEnable('report_peso')">
						<b-card>
							<template #header>
								<h5 @click="goTo('medical_peso_dashboard')" style="cursor:pointer;white-space: pre;">Report Peso</h5>
							</template>	
							<a href="" @click.prevent="goTo('medical_peso_dashboard')">
								<div class="p-1">
									<img alt="Players Matches" src="/assets/screens/peso.png" style="height: 100%; width: 100%; object-fit: contain">
								</div>
							</a>
						</b-card>
					</div>
				</div>
			</div-->
		</b-card>
	</div>
	<footerapp></footerapp>
</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
var Auth = require('@/auth.js');
export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			reports: [ 
				{titolo: 'Players Matches', permesso: 'report_players_matches', link: 'reports_players_matches', img: '/assets/screens/player-matches.png'},
				{titolo: 'Players Disciplinary', permesso: 'report_players_yellows', link: 'reports_players_yellows', img: '/assets/screens/player-disciplinary.png'}, 
				{titolo: 'Players On Loan', permesso: 'report_players_onloan', link: 'reports_players_onloan', img: '/assets/screens/player-on-loan.png'}, 
				{titolo: 'Scouting Report', permesso: 'report_scouting', link: 'reports_scouting', img: '/assets/screens/scouting.png'}, 
				{titolo: 'Report Recovery', permesso: 'report_recovery', link: 'medical_recovery_dashboard', img: '/assets/screens/recovery.jpeg'}, 
				{titolo: 'Report Fisioterapia', permesso: 'report_fisioterapia', link: 'medical_fisioterapia_dashboard', img: '/assets/screens/fisioterapia.png'}, 
				{titolo: 'Report Test Fisioterapia', permesso: 'report_test_fisioterapia', link: 'medical_fisioterapia_test_dashboard', img: '/assets/screens/test-fisioterapia.png'}, 
				{titolo: 'Report Nutrition', permesso: 'report_nutrition', link: 'medical_nutrition_dashboard', img: '/assets/screens/nutrition.png'}, 
				{titolo: 'Report Farmaci', permesso: 'report_farmaco', link: 'medical_farmaco_dashboard', img: '/assets/screens/farmaco.png'}, 
				{titolo: 'Players Matches Attendances', permesso: 'report_players_matches_attendance', link: 'reports_players_matches_attendances', img: '/assets/screens/attendance.png'}, 
				{titolo: 'Report Infortuni', permesso: 'report_infortuni', link: 'medical_injuries_dashboard', img: '/assets/screens/infortuni.png'}, 
				{titolo: 'Report Peso', permesso: 'report_peso', link: 'medical_peso_dashboard', img: '/assets/screens/peso.png'},  
				],
			layout: [
				{ x: 0, y: 0, w: 4, h: 8, i: 'report_players_matches' },
				{ x: 4, y: 0, w: 4, h: 8, i: 'report_players_yellows' },
				{ x: 8, y: 0, w: 4, h: 8, i: 'report_players_onloan' },
				{ x: 0, y: 8, w: 4, h: 8, i: 'report_scouting' },
				{ x: 4, y: 8, w: 4, h: 8, i: 'report_recovery' },
				{ x: 8, y: 8, w: 4, h: 8, i: 'report_fisioterapia' },
				{ x: 0, y: 16, w: 4, h: 8, i: 'report_test_fisioterapia' },
				{ x: 4, y: 16, w: 4, h: 8, i: 'report_nutrition' },
				{ x: 8, y: 16, w: 4, h: 8, i: 'report_farmaco' },
				{ x: 0, y: 24, w: 4, h: 8, i: 'report_players_matches_attendance' },
				{ x: 4, y: 24, w: 4, h: 8, i: 'report_infortuni' },
				{ x: 8, y: 24, w: 4, h: 8, i: 'report_peso' }
				],
			shown: false,
			selected: [],
			reports_config: {
				selected: [],
				layout: [],
			},
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		reports_shown: function() {
			return this.reports.filter(item => this.reports_config.selected.includes(item.permesso));
		},
		layout_shown: function() {
			return this.reports_config.layout.filter(item => this.reports_config.selected.includes(item.i));
		}
	},
	methods: {
		goTo: function(where) {
			this.$router.push({name: where});	
		},
		saveLayoutConfig: function() {
			this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/set-persone', { chiave: 'reportsConfig', persone: this.reports_config }).then(function(data) {
				this.auth.refreshUser(this.$root.$children[0]);
			});
		},
	},
	created: function() {
		if(this.auth.presets().reportsConfig != undefined) {
			this.reports_config = _.cloneDeep(this.auth.presets().reportsConfig)
		} else {
			this.reports_config.layout = this.layout;
			for(var i in this.reports) {
				var report = this.reports[i];
				if(this.auth.isUserEnable(report.permesso)) {
					this.reports_config.selected.push(report.permesso);
				}
			}
		}
	},
};

</script>