<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<b-overlay :show="loading" no-wrap></b-overlay>
		<b-card title="Players Matches">
			<b-tabs v-model="activeIndex">
				<b-tab v-for="(tab, index) in tabs" :key="tab.id" :title="tab.label">
					<b-table-simple sticky-header="70vh" striped hover bordered responsive fixed>
						<b-thead>
							<b-tr>
								<b-th style="background-color:#FAFAFA;vertical-align: middle;padding: 0; width:270px !important;" sticky-column>Player</b-th>
								<b-th style="background-color:#FAFAFA;vertical-align: middle;padding: 0; width:47px !important;" v-for="col in columns[index]" :key="col.id" class="text-center"> 
									<div class="text-center" style="width:100%" v-b-tooltip.top.html="{ customClass: 'tooltipClassHeader' }" :title="(col.home ? col.opponent + ' - AS Roma' : 'AS Roma - ' + col.opponent) + '<br/>' + col.score + '<br/>' + col.date">
										<div :title="col.opponent"><img :src="col.logo" width="30" height="30"/></div>
										<div :style="'text-align:center;color:#FFFFFF;background-color:' + (col.home ? 'gray' : 'green')" :title="col.date">{{ col.day }}</div>
									</div>
								</b-th>
								<b-th style="background-color:#CCCCCC; vertical-align:middle; padding:0; width:60px !important;" class="text-center">
									<div v-b-tooltip.top="{ customClass: 'tooltipClassHeader' }" title="Totale minuti" style="width:100%"><i class="fa fa-clock-o" style="fontSize: 1.8rem; color:#666666" aria-hidden="true"></i></div>
								</b-th>
								<b-th style="background-color:#CCCCCC; vertical-align:middle; padding:0; width:47px !important;" class="text-center">
									<div v-b-tooltip.top="{ customClass: 'tooltipClassHeader' }" title="Totale ammonizioni" style="width:100%"><i class="fa fa-clone" style="fontSize: 1.8rem; color:#FFFF00" aria-hidden="true"></i></div>
								</b-th>
								<b-th style="background-color:#CCCCCC; vertical-align:middle; padding:0; width:47px !important;" class="text-center">
									<div v-b-tooltip.top="{ customClass: 'tooltipClassHeader' }" title="Totale espulsioni" style="width:100%"><i class="fa fa-clone" style="fontSize: 1.8rem; color:red" aria-hidden="true"></i></div>
								</b-th>
								<b-th style="background-color:#CCCCCC; vertical-align:middle; padding:0; width:47px !important;" class="text-center">
									<div v-b-tooltip.top="{ customClass: 'tooltipClassHeader' }" title="Totale giorni di squalifica" style="width:100%"><i class="fa fa-ban" style="fontSize: 1.8rem; color:black" aria-hidden="true"></i></div>
								</b-th>
								<b-th class="text-center" style="border:none;">	
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="player in items[index]" :key="player.id">
								<b-td style="padding: 0; vertical-align: middle;" sticky-column>{{ player.player }}</b-td>
								<b-td style="padding: 0" class="text-center" v-for="col in columns[index]">
									<div :style="'width:100%;padding:5px;' + getStyleByState(player['match_id_' + col.id].state)" v-b-tooltip.top.html="{ customClass: 'tooltipClassCell' }" :title="player.player + '<br/>' + (col.home ? col.opponent + ' - AS Roma' : 'AS Roma - ' + col.opponent) + '<br/>' + col.score + '<br/>' + col.date + '<br/>' + (col.days > 0 ? 'Giorni di squalifica: ' + col.days : '')">
									{{ player['match_id_' + col.id].minutes > 0 ? player['match_id_' + col.id].minutes : '&nbsp;' }}
									</div>
								</b-td>
								<b-td style="padding:0;" class="text-center">
									<div style="width:100%;padding:5px;" v-b-tooltip.top="{ customClass: 'tooltipClassCell' }" :title="player.player">{{ player.totMinutes }}</div>
								</b-td>
								<b-td style="padding:0;" class="text-center">
									<div style="width:100%;padding:5px;" v-b-tooltip.top="{ customClass: 'tooltipClassCell' }" :title="player.player">{{ player.totYellow }}</div>
								</b-td>
								<b-td style="padding:0;" class="text-center">
									<div style="width:100%;padding:5px;" v-b-tooltip.top="{ customClass: 'tooltipClassCell' }" :title="player.player">{{ player.totRed }}</div>
								</b-td>
								<b-td style="padding:0;" class="text-center">
									<div style="width:100%;padding:5px;" v-b-tooltip.top="{ customClass: 'tooltipClassCell' }" :title="player.player">{{ player.totDays }}</div>		
								</b-td>
								<b-td style="background-color:#FFFFFF;border:none;">	
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-tab>
			</b-tabs>

			<b-row class="mt-1" align-v="center">
				<b-col cols="9">
					<b-badge style="background: green" class="mr-1">
						Partite in casa
					</b-badge>
					<b-badge style="background: gray" class="mr-1">
						Partite in trasferta
					</b-badge>
					<b-badge :style="getStyleByState(1)" class="mr-1">
						Ammonizione
					</b-badge>
					<b-badge :style="getStyleByState(2)" class="mr-1">
						Diffida
					</b-badge>
					<b-badge :style="getStyleByState(3)" class="mr-1">
						Doppia Ammonizione
					</b-badge>
					<b-badge :style="getStyleByState(4)" class="mr-1">
						Espulsione
					</b-badge>
					<b-badge :style="getStyleByState(5)">
						Giornata di squalifica
					</b-badge>
				</b-col>
				<b-col cols="3" class="text-right" v-if="tabs.length > 0 && activeIndex > -1">
					<b-button size="sm" variant="primary" :title="'Download report ' + tabs[activeIndex].label" class="mr-2" @click="download(tabs[activeIndex].id)">{{ tabs[activeIndex].label }} <i class="fa fa-download" aria-hidden="true"></i></b-button>
					<b-button size="sm" variant="primary" title="Download report completo" class="mr-2" @click="download('')"><i class="fa fa-download" aria-hidden="true"></i></b-button>
				</b-col>
			</b-row>
		</b-card>
		<footerapp></footerapp>
	</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
import { mapState } from 'vuex';
var Auth = require('@/auth.js');
export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			loading: false,
			tabs: [],
			items: [],
			columns: [],
			activeIndex: 0,
		}
	},
	mounted() {
		this.fetchPlayersMatches();
	},
	computed: {
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.fetchPlayersMatches();
		},
		seasonId() {
			this.fetchPlayersMatches();
		},
	},
	methods: {
		fetchPlayersMatches: function() {
			this.tabs = [];
			this.items = [];
			this.columns = [];
			this.activeIndex = 0;
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/report/players/matches', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then(function(data) {
				Object.entries(data.data).forEach(([key, item]) => {
					this.tabs.push({id: key, label: item.name});
					this.columns.push(item.matches);
					const sortedPlayers = _.sortBy(Object.values(item.players), ['player']);
					this.items.push(sortedPlayers);
				});
				this.loading = false;
			});
		},
		download: function(league) {
			var type = '0';
			if(league != '') {
				type = league;
			}
			window.open(this.$store.state.apiEndPoint + '/report/download/players/matches/' + this.rosaTeamId + '/' + this.seasonId + '/' + type + '/' + this.user.id + "/" + this.user.api_token, "_blank");
		},
		getStyleByState: function(state)  {
			var bg = 'background:transparent';
			if(state == 1 || state == 6) {
				bg = 'background:#ffeb3b;color:black';
			} else if(state == 2) {
				bg = 'background:orange;color:black';
			} else if(state == 3) {
				bg = 'background:linear-gradient(90deg, #ffeb3b 50%, rgba(248, 113, 113, 1) 50%);;color:black';
			} else if(state == 4) {
				bg = 'background:rgba(248, 113, 113, 1);;color:black';
			} else if(state == 5) {
				bg = 'background:black;color:white';
			} 
			return bg;
		}
	}
};
</script> 
<style>
.tooltipClassHeader {
	text-align: center;
	min-width:250px;
}

.tooltipClassCell {
	text-align: center;
	font-size: 0.9rem !important;
	min-width:250px;
}
</style>   
