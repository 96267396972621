<template>
	<div v-if="sessione_tipi">
		<form @submit.stop.prevent="handleSubmit">

			<b-row class="mb-3">
				<b-col v-if="!entityPayload.id">
					<div v-for="gruppo, k in sessione_tipi_grouped" class="d-flex">
						<span style="display: inline-block" class="mr-2 text-muted text-right"><span style="text-transform: uppercase; font-size: 80%; min-width: 60px; display: inline-block">{{ k }}:</span></span>
						<b-form-radio-group id="level" v-model="model.sessione_tipo_glo_id" name="radioLevel" :disabled="entityPayload.id != null">
							<b-form-radio v-for="tipo in gruppo" :value="tipo.id">{{ tipo.label }}</b-form-radio>
						</b-form-radio-group>
					</div>
				</b-col>
				<b-col v-else class="text-muted">
					Sessione di tipo <em>{{ model.sessione_tipo.label }}</em>
				</b-col>
			</b-row>

			<div v-if="sessioneTipoCorrente">

				<b-row class="mb-1">
					<b-col>
						<b-row class="mb-2">
							<b-col cols="12">
								<label>Nome</label>
								<b-form-input v-model="model.nome" type="text"></b-form-input>
							</b-col>
							<b-col cols="12" v-if="hasSetupTipo(sessioneTipoCorrente, 'noPersone', false) == false">
								<label>Giocatori</label>
								<div v-if="sessioneTipoCorrente && (sessioneTipoCorrente.setup && (sessioneTipoCorrente.setup.tuttaLaRosa != undefined && sessioneTipoCorrente.setup.tuttaLaRosa == true))">
									Tutta la Rosa.
								</div>
								<div v-else-if="sessioneTipoCorrente && (!sessioneTipoCorrente.setup || sessioneTipoCorrente.setup.playerPicker == undefined || sessioneTipoCorrente.setup.playerPicker == true)">
									<multiselect v-model="persone" :options="player_options" :multiple="true" placeholder="Seleziona" label="name" track-by="id" group-values="items" group-label="label" :group-select="true"></multiselect>
								</div>
							</b-col>
						</b-row>
						<b-row class="mb-2" v-if="hasSetupTipo(sessioneTipoCorrente, 'hideTime', false) == false">
							<b-col>
								<label>Dalle ore</label>
								<b-form-input v-model="from_hour" type="time"></b-form-input>
							</b-col>
							<b-col>

								<label>Alle ore</label>
								<b-form-input v-model="to_hour" type="time"></b-form-input>
							</b-col>
						</b-row>
					</b-col>

				</b-row>

				<div v-if="hasSetupTipo(sessioneTipoCorrente, 'componentCalendar') && entityPayload.id">
					<div :is="hasSetupTipo(sessioneTipoCorrente, 'componentCalendar')" :sessione="entityPayload"></div>
				</div>

				<div class="mb-1" v-if="hasSetupTipo(sessioneTipoCorrente, 'splitPerPerson', false) && !entityPayload.id">
					<b-form-checkbox v-model="model.splitSession" :true-value="1" :false-value="0"> Crea una sessione per ogni partecipante</b-form-checkbox>
				</div>

				<div v-if="hasEstensione">
					<div v-if="!splitSessionEnabled">
						<div ref="specifichesessioni" :is="componenteIntestazione" :estensione="model.estensione ? model.estensione : {}" field="estensione" :model="model"></div>
					</div>
					<div class="mb-2" v-else>
						
						<b-row align-v="center" v-for="p, k in persone" class="mb-1">
							<b-col cols="3">
								<strong>{{ p.name }}</strong>
							</b-col>
							<b-col>
								<div ref="specifichesessioni" :is="componenteIntestazione" :compact="true" :estensione="model.estensione[k].estensione ? model.estensione[k].estensione : {}" :model="model.estensione[k]" field="estensione"></div>
							</b-col>
						</b-row>

					</div>
					
				</div>

				<div class="mb-1" v-if="hasSetupTipo(sessioneTipoCorrente, 'hasSkills', false)" header="Skills">
					<SkillPickerGroup size="sm" cols="4" :model="model.skills_rel" :tabs="true" :config="{ gruppi: hasSetupTipo(sessioneTipoCorrente, 'hasSkillsGroups', false), radici: hasSetupTipo(sessioneTipoCorrente, 'hasSkills', false), onlyone: true, level: 1, hideRating: true }"></SkillPickerGroup>
				</div>
				<div class="mb-1" v-if="hasSetupTipo(sessioneTipoCorrente, 'hasTools', false)" header="Tools">
					<ToolPicker :closed="true" ref="interactivecomp" @changed="verifyRequiredComponenti()" :tollerant="model.id != undefined" :model="model.tools_rel" :config="hasSetupTipo(sessioneTipoCorrente, 'hasTools', false)"></ToolPicker>
				</div>

				<!--- -->
			</div>
			<div v-else>
				<p>👆🏻 Seleziona una tipologia di sessione per continuare!</p>
			</div>


			<b-row>
				<b-col>
					<b-button-group>
						<b-button :disabled="!model.sessione_tipo_glo_id || !isManagerSessione || isDataValid" class="mr-0" variant="outline-primary" @click="handleOk($event, 'close')">Salva e chiudi</b-button>
						<b-button :disabled="!model.sessione_tipo_glo_id || !isManagerSessione || splitSessionEnabled || isDataValid" class="mr-0" variant="outline-primary" @click="handleOk($event, 'edit')">Salva e personalizza</b-button>
						<b-button v-if="entityPayload.id && auth.isLastSeason(entityPayload.stagione_id) && isManagerSessione" class="mr-0" @click="showDuplica" variant="outline-primary">Duplica</b-button>
						<b-button v-if="entityPayload.id && auth.isLastSeason(entityPayload.stagione_id) && isManagerSessione" @click="eliminaSessione" variant="outline-primary">Elimina</b-button>
					</b-button-group>
				</b-col>
				<b-col v-if="entityPayload.id && isViewSessione" class="text-right">
					<b-button-group>
						<b-button class="mr-0" variant="outline-primary" @click="$emit('activityCheck', entityPayload.id)" v-if="hasSetup(entity, 'matrixHideButton', false) == false">{{hasSetup(entity, 'matrixLabel', 'Activity Check')}}</b-button>
						<b-button class="mr-0" v-show="entityPayload.id && auth.isUserEnable('video_sessioni')" :to="{ path: `/${this.$route.meta.module}/sessione/video/${this.entityPayload.id}/${this.entityPayload.sessione_tipo_glo_id}` }" variant="outline-primary">Video</b-button>
						<b-button class="mr-0" variant="outline-primary" v-if="hasStampa" @click="downloadPdf">Stampa pdf</b-button>
						<b-button class="mr-0" v-show="entityPayload.id && entityPayload.sessione_tipo_glo_id == 19" :to="{ path: `/${this.$route.meta.module}/sessione/presentazione/${this.entityPayload.id}/${this.entityPayload.sessione_tipo_glo_id}` }" variant="outline-primary">Presentazione</b-button>
						<b-button class="mr-0" v-show="entityPayload.id" :to="{ path: `/${this.$route.meta.module}/sessione/${this.entityPayload.id}/${this.entityPayload.sessione_tipo_glo_id}` }" variant="outline-primary">Personalizza</b-button>
					</b-button-group>
				</b-col>
			</b-row>

			<!-- -->

			<b-row class="mt-3" align-v="center" v-if="ux.clone">
				<b-col cols="6">
					<b-form-group label="Dal" description="Data specifica o inizio intervallo">
						<b-form-input v-model="clone_form.from" type="date"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col cols="6">
					<b-form-group label="Al" description="Data di fine intervallo (opzionale)">
						<b-form-input v-model="clone_form.to" type="date"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col cols="12">
					<b-form-group label="Giorni" description="Seleziona uno o più giorni specifici su cui vuoi duplicare la sessione nell'intervallo scelto (lascia vuoto per duplicare su tutti i giorni)">
						<b-form-checkbox-group
						v-model="clone_form.days"
						:options="days_options"
						name="days"
						></b-form-checkbox-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-button variant="outline-primary" v-if="entityPayload.id" class="mt-3" @click="duplicaSessione">Crea duplicato</b-button>
				</b-col>
			</b-row>

			<!-- -->

		</b-row>

	</form>
</div>
</template>

<script>

import sessionMixin from '@/mixins/sessione.js';
import _ from 'lodash'
import moment from 'moment'
var Auth = require('../../../auth.js');
import * as types from '@/store/types'
import { mapState } from 'vuex';

export default {
	props: [ 'entity', 'player_options', 'team', 'date', 'time' ],
	created: function() {
		this.getTipiSessione();
	},
	components: {
		SessioneIntestazioneNutrition: require('@/components/layout/Sessioni/Medical/Intestazione_Sessione_Nutrition.vue').default,
		SessioneIntestazionePsicologica: require('@/components/layout/Sessioni/Medical/Intestazione_Sessione_Piscologica.vue').default,
		SessioneIntestazionePalestra: require('@/components/layout/Sessioni/Football/Intestazione_Sessione_Palestra.vue').default,
		SessioneIntestazionePrevenzione: require('@/components/layout/Sessioni/Football/Intestazione_Sessione_Prevenzione.vue').default,
		SessioneIntestazioneVideo: require('@/components/layout/Sessioni/Football/Intestazione_Sessione_Video.vue').default,
		SessioneIntestazioneDexa: require('@/components/layout/Sessioni/Dexa/Intestazione_Sessione.vue').default,
		SessioneIntestazioneLingua: require('@/components/layout/Sessioni/Generica/Intestazione_Sessione_Lingua.vue').default,
		SessioneIntestazioneNazionale: require('@/components/layout/Sessioni/Generica/Intestazione_Sessione_Nazionale.vue').default,
		SkillPickerGroup: require('@/components/layout/Sessioni/Generica/SkillPickerGroup.vue').default,
		ToolPicker: require('@/components/layout/Sessioni/Generica/ToolPicker.vue').default,



		CalendarioMatch: require('@/components/layout/Sessioni/Match/componentCalendar.vue').default,
		CalendarioProgrammaGiornaliero: require('@/components/layout/Sessioni/ProgrammaGiornaliero/componentCalendar.vue').default,
		CalendarioDynamicFasi: require('@/components/layout/Sessioni/Generica/dynamicComponentCalendar.vue').default,
	},
	mixins: [
		sessionMixin
	],
	computed: {
		isDataValid: function() {

			if(this.model.tools_rel != undefined) {
				return !this.ux.ValidInteractive;
			}

			return false;

		},
		splitSessionEnabled: function() {

			if(this.model.splitSession && this.model.splitSession == true) return true;
			return false;

		},
		componenteIntestazione: function() {
			return 'SessioneIntestazione' + this.sessioneTipoCorrente.nome;
		},
		hasEstensione: function() {
			if(!this.sessioneTipoCorrente) return false;
			return this.sessioneTipoCorrente.estensione != null;
		},
		sessione_tipi_grouped: function() {
			return _.groupBy(this.sessione_tipi, 'gruppo');
		},
		sessioneTipoCorrente: function() {
			return _.find(this.sessione_tipi, { id: this.model.sessione_tipo_glo_id });
		},
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
		entityPayload: function() {

			var data = moment(this.model.inizio).format('YYYY-MM-DD');
			
			var cp = _.cloneDeep(this.model);

			cp.persone = this.persone;

			cp.inizio = data + ' ' + this.from_hour;
			cp.fine = data + ' ' + this.to_hour;

			// Se la sessione ha già l'id, allora non va generato un oggetto di persone partecipanti.. sono probabilmente già impostati nella sessione ^^
			if(cp.id != undefined) return cp;

			delete cp.CalendarObject;

			_.each(cp.fasi, function(fase) {
				fase.persone = this.persone;
			}.bind(this));

			return cp;

		},
		user: function() {
			return Auth.user;
		},
		isManagerSessione: function() {
			if(this.sessioneTipoCorrente) {
				if(this.model && this.model.categoria_id != this.team.id_categoria) {
					return false;
				}
			 	return this.auth.isUserEnable('sessione_' + this.sessioneTipoCorrente.nome.toLowerCase().replaceAll(' ', '') + '_mng');
			}
			return true;
		},
		isViewSessione: function() {
			if(this.sessioneTipoCorrente) {
			 return this.auth.isUserEnable('sessione_' + this.sessioneTipoCorrente.nome.toLowerCase().replaceAll(' ', '') + '_view');
			}
			return true;
		},
		hasStampa() {
			return this.hasSetupTipo(this.sessioneTipoCorrente, 'hasStampa', false);
		},
	},
	watch: {
		'persone': {
			deep: true,
			handler: function() {
				if(this.splitSessionEnabled && this.hasEstensione) {
					
					var index_to_remove = [];
					var personeId = _.map(this.persone, 'id');
					_.each(this.model.estensione, function(personaId, k) {
						if(personeId.indexOf(personaId.personaId) === -1) {
							index_to_remove.push(k);
						}
					}.bind(this));

					if(index_to_remove.length) {
						_.each(_.reverse(index_to_remove), function(a) {
							this.model.estensione.splice(a, 1);
						}.bind(this));
					}

					_.each(personeId, function(personaId) {
						if(!_.find(this.model.estensione, { personaId })) {
							this.model.estensione.push({ personaId, estensione: null });
						}
					}.bind(this))

				}
			}
		},
		'model.splitSession': {
			deep: true,
			handler: function(a,b) {
				if(this.splitSessionEnabled && this.hasEstensione) {

					var estensioni = [];
					_.each(this.persone, function(a) {
						estensioni.push({ personaId: a.id, estensione: null });
					})

					this.model.estensione = estensioni;

				} else if(this.hasEstensione && b == true) {
					delete this.model.estensione;
				}
			}
		},
		'model.sessione_tipo_glo_id': {
			//deep: true,
			handler: function(a,b) {

				if(!this.sessioneTipoCorrente) return false;

				this.persone = [];
				this.model.fasi = [];
				this.model.nome = this.sessioneTipoCorrente.nome;
				if(a != b)
					delete this.model.estensione;

				if(this.hasSetupTipo(this.sessioneTipoCorrente, 'hasSkills', false)) {
					this.$set(this.model, 'skills_rel', []);
				} else if(this.model.skills_rel)
					delete this.model.skills_rel;

				if(this.hasSetupTipo(this.sessioneTipoCorrente, 'hasTools', false)) {
					this.$set(this.model, 'tools_rel', []);
				} else if(this.model.tools_rel)
					delete this.model.tools_rel;

				if(this.hasSetupTipo(this.sessioneTipoCorrente, 'splitPerPerson', false)) {
					this.$set(this.model, 'splitSession', 0);
				} else if(this.model.splitSession)
					delete this.model.splitSession;

				if(this.sessioneTipoCorrente.setup && (this.sessioneTipoCorrente.setup.tuttaLaRosa != undefined && this.sessioneTipoCorrente.setup.tuttaLaRosa == true)) {
					this.persone = _.cloneDeep(this.player_options[0].items);
				}

				if(this.sessioneTipoCorrente.setup && (this.sessioneTipoCorrente.setup.fasiDefault != undefined && this.sessioneTipoCorrente.setup.fasiDefault.length > 0)) {

					this.model.fasi = _.map(this.sessioneTipoCorrente.setup.fasiDefault, function(item) {
						if(item.entity.entita)
							item.nome = item.entity.entita.replace('Fase_Dato_', '');
						else
							item.nome = this.sessioneTipoCorrente.nome;
						item.persone = _.cloneDeep(this.player_options[0].items);
						return item;
					}.bind(this));

				}

				console.log(this.hasSetup(this.sessioneTipoCorrente, 'defaultTime'));

				if(this.sessioneTipoCorrente.setup && this.sessioneTipoCorrente.setup.defaultTime != undefined) {

					this.from_hour = '00:00';
					this.to_hour = '00:00';

				} else {

					this.from_hour = this.time ? moment(this.date + ' ' + this.time).format('HH:mm')  : '09:00';
					this.to_hour = this.time ? moment(this.date + ' ' + this.time).add({'hour': 1}).format('HH:mm') : '10:00';

				}

			},
		},
		'entity': {
			deep: true,
			handler: function() {

				if(this.entity == null) {
					this.initModel();
				} else {
					this.model = _.cloneDeep(this.entity);

					if(this.model.sessione_tipo.setup != null && this.model.sessione_tipo.setup.componentCalendar) {

						// Ok, dobbiamo andare a prendere il vero dettaglio della sessione…
						// e sostituirlo all'attuale model, prima di poter caricare il componente.

						if(this.model.fasi == undefined)
							this.fetchSessione(this.model.id);

						console.log('YE');
					}

				}

				this.createPersone();
				this.createTimes();

				this.$emit('changeTitle', 'Sessione - ' + this.model && this.model.sessione_tipo ? this.model.sessione_tipo.label : '');

			},
			immediate: true
		}
	},
	methods: {
		verifyRequiredComponenti: function() {
			if(this.$refs.interactivecomp) {
				this.ux.ValidInteractive = this.$refs.interactivecomp.canSave;
			}
			/*console.log(pass);
			return pass;*/
		},
		createTimes: function() {

			this.from_hour = moment(this.model.inizio).format('HH:mm')
			this.to_hour = moment(this.model.fine).format('HH:mm')

		},
		createPersone: function() {
			this.persone = _.map(_.cloneDeep(this.model.persone), function(persona) {
				return {
					name: persona.cognome + ' ' + persona.nome,
					id: persona.id
				}
			});
		},
		getTipiSessione() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessione/tipi').then((response) => {
				if (response.ok)
				{
					var sessione_tipi = response.data;
					this.sessione_tipi = [];
					for(var i in sessione_tipi) {
						if(this.auth.isUserEnable('sessione_' + sessione_tipi[i].nome.toLowerCase().replaceAll(' ', '') + '_cal')) {
							this.sessione_tipi.push(sessione_tipi[i]);
							this.levels.push(sessione_tipi[i].id);
						}
					}
				}
			}, (response) => {
			});
		},
		closeModal: function() {
			this.$emit('close');
		},
		handleOk (evt, action) {

			var block = false;
			if(this.$refs.specifichesessioni != undefined && this.$refs.specifichesessioni.validate != undefined) {
				block = this.$refs.specifichesessioni.validate();
			}

			if(block) {
				alert(block);
				return false;
			}

			evt.preventDefault();
			this.handleSubmit(action);

		},
		update(data, id, action){
			var l = this.entityPayload.sessione_tipo_glo_id;
			this.$http.post(this.$store.state.apiEndPoint + '/sessione/update/' + id, data).then((response) => {
				this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				this.refreshEvents();
				this.closeModal();
				if(action == 'edit') {
					this.$router.push({ name: this.$route.meta.module + '_sessione', params: { id: id, tipo: l }});
				}
			}, (response) => {
				this.errors = response.data;
				for (var index in this.errors)
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
			});
		},
		create(action){
			var l = this.entityPayload.sessione_tipo_glo_id;
			this.$http.post(this.$store.state.apiEndPoint + '/sessione', this.entityPayload).then((response) => {
				this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				this.refreshEvents();
				this.closeModal();
				if(action == 'edit') {
					this.$router.push({ name: this.$route.meta.module + '_sessione', params: { id: response.data.sessione_id, tipo: l }});
				}
			}, (response) => {
				this.errors = response.data;
				for (var index in this.errors)
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
			});
		},
		eliminaSessione() {
			if(confirm("Confermi l'eliminazione della sessione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/sessione/delete/' + this.entityPayload.id).then((response) => {

					this.refreshEvents();
					this.closeModal();

				}, (response) => {
				});
			}
		},
		refreshEvents: function() {
			this.$emit('refresh');
		},
		handleSubmit (action = 'close') {

			if(this.entityPayload.sessione_tipo_glo_id == 0) {
				alert("Devi selezionare una tipologia di sessione!");
				return;
			}
			if(this.entityPayload.persone.length == 0 && this.hasSetupTipo(this.sessioneTipoCorrente, 'noPersone', false) == false) {
				alert("Devi selezionare almeno un giocatore della rosa!");
				return;
			}
			if(!this.entityPayload.id){
				this.create(action);
			} else {
				if(Auth.isLastSeason(this.entityPayload.stagione_id)) {
					this.update(this.entityPayload, this.entityPayload.id, action);
				} else {
					this.closeModal();
				}
			}
			
		},
		initModel: function() {

			this.model = {
				team_id: this.team.id,
				categoria_id: this.team.id_categoria,
				inizio: this.time ? moment(this.date + ' ' + this.time).format('YYYY-MM-DD HH:mm:ss') : moment(this.date).add({'hour': 9}).format('YYYY-MM-DD HH:mm:ss'),
				fine: this.time ? moment(this.date + ' ' + this.time).add({'hour': 1}).format('YYYY-MM-DD HH:mm:ss') : moment(this.date).add({'hour': 10}).format('YYYY-MM-DD HH:mm:ss'),
				nome: null,
				persone: [],
				fasi: [],
				sessione_tipo_glo_id: null,
				stagione_id: this.seasonId,
			};
		},
		showDuplica() {
			this.ux.clone = !this.ux.clone;
		},
		duplicaSessione() {
			if(!this.clone_form.from) {
				alert("Devi selezionare almeno una data su cui duplicare la sessione!");
				return;
			}
			if(confirm("Confermi la duplicazione della sessione ?")) {
				this.$http.post(this.$store.state.apiEndPoint + '/sessione/duplica/' + this.entityPayload.id, this.clone_form).then((response) => {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.closeModal();

				}, (response) => {
					this.errors = response.data;
					for (var index in this.errors)
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
				});
			}
		},
		downloadPdf() {
			window.open(this.$store.state.apiEndPoint + '/sessione/pdf/' + this.entityPayload.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
		fetchSessione: function(sessione_id) {

			this.$http.get(this.$store.state.apiEndPoint + '/sessione/' + sessione_id).then(function(data) {
				this.model = data.data;
			})

		}
	},
	filters: {
		
	},
	data: function() {
		return {
			model: false,
			from_hour: null,
			to_hour: null,
			persone: null,
			levels: [],
			sessione_tipi: null,
			ux: {
				ValidInteractive: false,
				creating: false,
				clone: false,
			},
			clone_form: {
				from: null,
				to: null,
				days: [],
			},
			days_options: [
			{ text: 'LUN', value: 1 },
			{ text: 'MAR', value: 2 },
			{ text: 'MER', value: 3 },
			{ text: 'GIO', value: 4 },
			{ text: 'VEN', value: 5 },
			{ text: 'SAB', value: 6 },
			{ text: 'DOM', value: 0 }
			]
		}
	}
};

</script>