<template>
	<b-form>
		<b-row align-v="center">
			<b-col cols="3">
				<b-form-group>
					<template #label><small>Stagione</small></template>
					<b-form-select size="sm" v-model="periodo.contratti_squadra_variabili_id">
						<b-form-select-option v-for="option in stagioniUsable" :value="option.id">{{ option.name }}</b-form-select-option>
					</b-form-select>
				</b-form-group>
			</b-col>
			<b-col cols="3">
				<b-form-group>
					<template #label>
						<b-row>
							<b-col class="small">Stipendio Lordo</b-col>
						</b-row>
					</template>
					<b-form-input :disabled="disabled" type="number" size="sm" v-model.number="periodo.importo_lordo"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="3">
				<b-form-group>
					<template #label>
						<b-row>
							<b-col class="small">Stipendio Netto</b-col>
						</b-row>
					</template>
					<b-form-input :disabled="disabled" type="number" size="sm" v-model.number="periodo.importo_netto"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="3" class="text-center">
				<b-button-group>
					<b-btn size="xs" :disabled="disabled" variant="outline-success" class="mr-0" @click.prevent="applyRow">Applica</b-btn>
					<b-btn v-if="periodo.id" size="xs" variant="outline-secondary" class="mr-0"><NotesHas entity="Persona_Contratto_Periodo" :entityId="periodo.id" :interact="true" @refresh="$emit('refresh')"></NotesHas></b-btn>
					<b-btn size="xs" :disabled="disabled" variant="outline-danger" @click.prevent="$emit('rim')" class="mr-0">Rimuovi</b-btn>
				</b-button-group>
			</b-col>
		</b-row>
	</b-form>
</template>

<script>


export default {
	props: [ 'periodo', 'stagioni', 'stagioniused' ],
	components: {
		NotesHas: require('./../notes/has.vue').default,
	},
	computed: {
		stagioniUsable: function() {
			return _.filter(this.stagioni, function(a) {
				console.log(a.id);
				return _.indexOf(this.stagioniused, a.id) === -1 || this.periodo.contratti_squadra_variabili_id == a.id;
			}.bind(this));
		},
		disabled: function() {
			return false;//this.importo.pagato == 1;
		}
	},
	methods: {
		applyRow: function() {

			this.$emit('netto', this.periodo.importo_netto);
			this.$emit('lordo', this.periodo.importo_lordo);

		}
	}
};

</script>