<template id="dashboard">
	<section class="sessioni" v-if="calendar_areas">
		<headapp></headapp>
		<div class="">
			<menusub></menusub>
			<div class="card border-0 rounded-0">
				<div class="card-body">
					<b-row class="mb-2">
						<b-col cols="6">
							<multiselect v-model="players_selected" :options="player_options" :multiple="true" placeholder="Filtra per giocatori" label="name" track-by="id" group-values="items" group-label="label" :group-select="true"></multiselect>
						</b-col>
						<b-col cols="6">
							<div class="text-right">
								<label>🔗 <input type="checkbox" v-model="aggregate"> Aggrega</label>
								<b-button class="m-0" variant="link" @click.prevent="$store.commit('changeCalendarNew', true)" title="Nuova versione del calendario!"><i class="fa fa-calendar" style="font-size:2rem" aria-hidden="true"></i><img src="/assets/varie/new.png" width="30px" style="position:relative; left:-13px;"></b-button>
							</div>
						</b-col>
					</b-row>
					<div class="content">
						<full-calendar ref="calendar" :event-sources="eventSources" @event-selected="eventSelected" @day-click="dayClick" :config="config" @event-drop="eventDrop" @event-resize="eventResize"></full-calendar>
						<b-row>
							<b-col cols="12" v-for="cal,k in calendar_areas" class="pt-2">

								<div class="d-flex" style="border-radius: 10px" :style="'background:' + (k.toLowerCase() != area ? cal.colore : '')">
									<div :style="(k.toLowerCase() != area ? 'color: ' + cal.text : '')" style="padding: 0.6em 1em;font-weight: normal;font-size: 85%; min-width: 110px; text-align: right" :class="{ 'font-weight-bold': area == k.toLowerCase() }" @click.prevent="selectUnselect(k)">{{k}}</div>
									<b-form-checkbox-group v-model="levels" class="pt-1">
										<b-form-checkbox style="margin-top: 2px" v-for="tipo in cal.tipologie" :value="k + '.' + tipo.nome">
											<b-badge @mouseleave="ux.tooltip = null" :style="'background:' + tipo.colore + ';padding: 0.5em 0.7em;font-weight: normal;font-size: 85%; margin-top: -2px'">{{ tipo.label }}</b-badge>
											
												<small v-if="tipo.descrizione != null" @mouseover="ux.tooltip = tipo" @click.prevent="" style="font-size: 90%" class="ml-1 fa fa-info-circle" :id="'popover-' + tipo.tipologia_id"></small>
												<b-popover :target="'popover-' + tipo.tipologia_id" triggers="hover focus">
													<template #title>{{ tipo.label }}</template>
													<p>{{tipo.descrizione}}</p>
													<!-- <GlossarioSessione v-if="ux.tooltip == tipo" :area="tipo" :teamId="rosaTeamId"></GlossarioSessione> -->
												</b-popover>

										</b-form-checkbox>
									</b-form-checkbox-group>
								</div>

							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</div>


		<footerapp></footerapp>

		<ModalMatrice v-if="ux.showMatrice" @close="hideMatrix" @save="hideMatrix" :sessioneId="ux.showMatrice" :team="team"></ModalMatrice>


		<b-modal hide-footer id="eventModal" ref="eventModal" :title="modalInteractionTitle" centered @close="closeModalCalendar" @hide="closeModalCalendar" @ok="handleCancel" size="xl" ok-only ok-title="Chiudi">
			
			<b-container fluid v-if="selected">
				<div @activityCheck="pickMatrix" @changeTitle="ux.modalTitle = $event" @swap="eventSelected($event)" @close="closeModalCalendar" @refresh="refreshEvents" :team="team" :is="selectedFormCalendar" :player_options="player_options" :entity="selected.base"></div>
			</b-container>
			<div v-else>
				<div :date="start_at" @changeTitle="ux.modalTitle = $event" @close="closeModalCalendar" @refresh="refreshEvents" :team="team" :is="createFormCalendar" :player_options="player_options"></div>
			</div>

		</b-modal>

		<b-modal ref="dateModal" title="Vai alla data" ok-only ok-title="Chiudi">
			<b-form-group label="Seleziona una data">
				<b-form-input v-model="go_to_date" type="date"></b-form-input>
			</b-form-group>
		</b-modal>

	</section>
</template>

<script>
import moment from 'moment'
import { FullCalendar } from 'vue-full-calendar'
//import '@/lang/agenda.js'
import _ from 'lodash';
import * as types from '@/store/types'
import { mapState } from 'vuex';
import axios from 'axios';
var Auth = require('@/auth.js');
export default {

	data: function(){
		return{
			ux: {
				showMatrice: false,
				tooltip: null,
				modalTitle: null
			},
			calendar_response: false,
			aggregate: true,
			calendar_areas: null,
			area: false,
			areaFromRoute: false,
			config:
			{
				disableResizing: false,
				editable: true,
				defaultView: 'month',
				locale: 'it',
				header: {
					left: 'prev,next prevYear,nextYear today custom',
				},
				customButtons: {
					custom: {
						text: 'date',
						click: function() {
							this.showDateModal();
						}.bind(this)
					},
				}
			},
			selected: null,
			event_id: null,
			level: 0,
			start_at: '',
			end_at: '',
			team: null,
			season_id: null,
			player_options: [],
			players: [],
			nome: null,
			from_hour: null,
			to_hour: null, 
			sessione_tipi: [],
			go_to_date: null,
		}
	},

	computed: {
		modalInteractionTitle: function() {

			if(this.event_id) {

				if(this.ux.modalTitle != null) return this.ux.modalTitle;

				return 'Sessione';
			}

			return 'Nuova Sessione';

		},
		levels: {
			get () {
				return this.$store.state.sessionLevels;
			},
			set (value) {
				this.$store.commit('changeSessionLevels', value);
			}
		},
		players_selected: {
			get () {
				return this.$store.state.sessionPlayers;
			},
			set (value) {
				this.$store.commit('changeSessionPlayers', value);
			}
		},
		selectedFormCalendar: function() {
			if(this.selected.base.CalendarObject.area == 'Pack')
				return 'FormCalendar' + this.selected.base.CalendarObject.area;
			return 'FormCalendarGenerica';
		},
		createFormCalendar: function() {
			return 'FormCalendarGenerica';
			return 'FormCalendar' + _.capitalize(this.area);
		},
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),

		eventSources: function() {
			const self = this;
			var events = [];
			if(self.levels.length > 0) {
				events.push({
					events(start, end, timezone, callback) {
						
						var rosaTeamId = $("#team_football option:selected").val();
						var start = moment(start).format("YYYY-MM-DD HH:mm:ss")
						var end = moment(end).format("YYYY-MM-DD HH:mm:ss")					
						const formData = new FormData();
						formData.append("start_at", start);
						formData.append("end_at", end);
						formData.append("team_id", rosaTeamId);
						if(self.aggregate == true)
							formData.append("aggregate", 1);
						formData.append("levels", self.levels);
						formData.append("players", _.map(self.players_selected, 'id'));

						formData.set('_method', 'POST');

						const opts = {
							url: `/calendar`,
							method: 'POST',
							data: formData,
						};

						axios(opts)
						.then((res) => {

							var data = _.map(res.data, function(item) {

								var text = '#FFF';
								var title = [""];
								if(item.CalendarObject && item.CalendarObject.nome)
									title.push(item.CalendarObject.nome);
								else {
									if(item.nome && item.nome != '' && item.nome != 'null') 
										title.push(item.nome);
									else
										title.push(_.find(self.sessione_tipi, {id: item.sessione_tipo_glo_id }).nome)
								}

								var colore = item.CalendarObject.colore;

								return {
									title: title.join(" "),
									nome: item.nome && item.nome != '' && item.nome != 'null' ? item.nome : null,
									start: item.CalendarObject.inizio,
									end: item.CalendarObject.inizio == item.CalendarObject.fine ? ( moment(item.CalendarObject.fine).add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss') ) : item.CalendarObject.fine,
									id: item.id,
									level: item.sessione_tipo_glo_id,
									color: colore,
									textColor: '#FFF',
									season_id: item.stagione_id,
									base: item,
									editable: item.CalendarObject.interactive
								}

							});

							callback(_.filter(data, function(a) {
								return self.levels.indexOf(a.base.CalendarObject.tipologia) != -1;
							}));

						}).catch((err) => {
							console.log(err);
						});
					},
					color: '#F7CA18',
					textColor: '#a80532',
				});

			}
			return events;
		} 
	},

	watch: {
		calendar_areas: {
			deep: true,
			handler: function() {

				var selected = [];

				_.each(this.calendar_areas, function(a, b) {

					var base = b;
					if(this.area == undefined || _.capitalize(this.area) == base) {
						_.each(a.tipologie, function(b) {
							selected.push(base + '.' + b.nome);
						}.bind(this));
					}

				}.bind(this));

				if(this.levels.length == 0) {
					if(selected.length > 0)
						this.levels = selected;
					else
						this.levels = [];
				}
			}
		},
		'aggregate': {
			deep: true,
			handler: function() {
				this.refreshEvents();
			}
		},
		'$route.params.area': {
			immediate: true,
			handler: function() {

				this.area = this.$route.params.area;
				if(this.$route.params.area)
					this.areaFromRoute = true;
				else
					this.areaFromRoute = false;

				// this is outside of the loop because
				// we need to fire it also if the route
				// hasen't the /:area
				this.refreshEvents();

			},
			deep: true
		},
		rosaTeamId() {
			this.getTeam();
			this.getPlayers();
			this.refreshEvents();
		},
		players_selected() {
			this.refreshEvents();
		},
		go_to_date() {
			if(this.go_to_date) {
				this.$refs.calendar.fireMethod('gotoDate', this.go_to_date);
			}	
		}
	},

	created() {
		//this.getTipiSessione();
		this.getTeam();
		this.getPlayers();
		this.getCalendarAreas();
	},

	mounted: function() {
		//console.log(this.$refs.calendar);
	},

	components: {
		headapp: require('../../layout/headapp.vue').default,
		menusub: require('../../layout/menusub.vue').default,
		footerapp: require('../../layout/footerapp.vue').default,
		ModalMatrice: require('../../layout/Sessioni/Matrice.vue').default,
		GlossarioSessione: require('../../layout/Sessioni/PopoverGlossarioSessione.vue').default,
		FormCalendarGenerica: require('../../layout/Sessioni/FormCalendar.vue').default,
		FormCalendarMedical: require('../../layout/Medical/FormCalendar.vue').default,
		FormCalendarFootball: require('../../layout/Football/FormCalendar.vue').default,
		FormCalendarPack: require('../../layout/Calendar/PackCalendar.vue').default,
		FullCalendar,
	},

	methods: {
		hideMatrix: function() {
			this.ux.showMatrice = false;
		},
		pickMatrix: function(sessioneId) {
			this.ux.showMatrice = sessioneId;
		},
		fetchCalendar: function(start, end, team_id) {

			var request = {
				start_at: moment(start).format("YYYY-MM-DD HH:mm:ss"),
				end_at: moment(end).format("YYYY-MM-DD HH:mm:ss"),
				team_id: $("#team_football option:selected").val(),
				aggregate: true,
			};

			if(!this.aggregate) delete request.aggregate;

			this.$http.post(this.$store.state.apiEndPoint + '/calendar', request).then(function(data) {
				this.calendar_response = data.data;
			});
			return false;

		},
		closeModalCalendar: function() {
			this.$refs.eventModal.hide();
			if(this.areaFromRoute == false)
				this.area = false;
		},
		selectUnselect: function(key) {
			var has = _.filter(this.levels, function(a) {
				return a.indexOf(key) !== -1;
			});

			if(has.length > 0) {

				var newLevels = _.filter(this.levels, function(a) {
					return a.indexOf(key) === -1;
				});

				this.levels = newLevels;

			} else {

				_.each(this.calendar_areas, function(a, b) {

					var base = b;
					if(base == key) {
						_.each(a.tipologie, function(b) {
							if(this.levels.indexOf(base + '.' + b.nome) === -1)
								this.levels.push(base + '.' + b.nome);
						}.bind(this));
					}

				}.bind(this));

			}
		},
		handleCancel (evt) {
			this.closeModalCalendar();
			this.event_id = null;
			this.ux.modalTitle = null;
			this.nome = null;
			this.level = 0;
			this.season_id 	= null;
			this.players = [];
		},
		eventSelected(event) {

			if(event.base.CalendarObject.interactive == false) return false;

			this.event_id = event.id;
			this.nome = event.nome;
			this.level = event.level;
			this.start_at = moment(event.start).format("YYYY-MM-DD");
			this.end_at = moment(event.end).format("YYYY-MM-DD");
			this.from_hour = moment(event.start).format("HH:mm:ss");
			this.to_hour = moment(event.end).format("HH:mm:ss");
			this.selected  = event;
			this.season_id = event.season_id;
			this.players = event.players;
			this.$refs.eventModal.show();
		},
		eventRender(event) {
		},

		dayClick(date, jsEvent, view) {
			this.selected = null;
			this.players = [];
			this.nome = null;
			this.event_id = null;
			this.level = 0;

			this.start_at = moment(date['_d']).format("YYYY-MM-DD");
			this.end_at = moment(date['_d']).format("YYYY-MM-DD");
			this.from_hour = moment(date['_d']).add({ hours: 7 }).format("HH:mm:ss");
			this.to_hour = moment(date['_d']).add({ hours: 8 }).format("HH:mm:ss");
			this.$refs.eventModal.show();
		},
		eventDrop(event, delta, revertFunc) {


			if(event.base.CalendarObject.interactive == false) return false;

			this.$http.post(this.$store.state.apiEndPoint + '/' + event.base.CalendarObject.controller + '/sposta-evento/' + event.base.id, {
				inizio: moment(event['start']['_d']).subtract({ hours: 2 }).format("YYYY-MM-DD HH:mm:ss"),
				fine: event.end ? moment(event['end']['_d']).subtract({ hours: 2 }).format("YYYY-MM-DD HH:mm:ss") : moment(event['end']['_d']).format("YYYY-MM-DD HH:mm:ss"),
			});

		},
		eventResize(event, delta, revertFunc) {

			if(event.base.CalendarObject.interactive == false) return false;

			this.$http.post(this.$store.state.apiEndPoint + '/' + event.base.CalendarObject.controller + '/sposta-evento/' + event.base.id, {
				inizio: moment(event['start']['_d']).subtract({ hours: 2 }).format("YYYY-MM-DD HH:mm:ss"),
				fine: event.end ? moment(event['end']['_d']).subtract({ hours: 2 }).format("YYYY-MM-DD HH:mm:ss") : moment(event['end']['_d']).format("YYYY-MM-DD HH:mm:ss"),
			});

		},
		refreshEvents() {
			if(this.$refs.calendar) {
				this.$refs.calendar.$emit('refetch-events');
				//this.$refs.calendar.fireMethod('changeView', 'basicDay');
				//this.$refs.calendar.fireMethod('changeView', 'month');
			}
		},

		create(action){
			const formData = new FormData();
			formData.append("nome", this.nome);
			formData.append("team_id", this.rosaTeamId);
			formData.append("sessione_tipo_glo_id", this.level);
			formData.append("inizio", this.start_at + ' ' + this.from_hour);
			formData.append("fine", this.end_at + ' ' + this.to_hour);
			formData.append("players", JSON.stringify(this.players));
			var l = this.level;
			this.$http.post(this.$store.state.apiEndPoint + '/sessione', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
					if(action == 'edit') {
						this.$router.push({ name: this.$route.meta.module + '_sessione', params: { id: response.data.sessione_id, tipo: l }});
					}
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		eliminaSessione() {
			if(confirm("Confermi l'eliminazione della sessione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/sessione/delete/' + this.event_id).then((response) => {

					if (response.ok)
					{
						this.event_id = null;
						this.nome = null;
						this.level = 0;
						this.season_id = null;
						this.players = [];
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
				});
			}
		},
		getCalendarAreas: function() {

			this.$http.get(this.$store.state.apiEndPoint + '/calendar/aree').then(function(data) {
				var auth_calendar_areas = {};
				var calendar_areas = data.data;
				for(var key in calendar_areas) {
					var area = calendar_areas[key];
					var auth_tipologie = [];
					for(var j in area.tipologie) {
						if(this.auth.isUserEnable('sessione_' + area.tipologie[j].nome.toLowerCase().replaceAll(' ', '') + '_cal')) {
							auth_tipologie.push(area.tipologie[j]);
						}
					}
					if(auth_tipologie.length > 0) {
						area.tipologie = auth_tipologie;
						auth_calendar_areas[key] = area;
					}
				}
				this.calendar_areas = auth_calendar_areas;
			});
		},
		downloadPdf(){
			window.open(this.$store.state.apiEndPoint + '/allenamento/' + this.event_id + "/pdf", "_blank");
		},
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
					if (response.ok)
					{
						this.team = response.data;
					}
				}, (response) => {
				});
			}
		},
		getTipiSessione() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessione/tipi').then((response) => {
				if (response.ok)
				{
					this.sessione_tipi = response.data;
					for(var i in this.sessione_tipi) {
						this.levels.push(this.sessione_tipi[i].id);
					}
				}
			}, (response) => {
			});
		},

		getPlayers: function() {				
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then((response) => {
				if (response.ok) {
					this.player_options = [
					{
						label: 'Seleziona tutti',
						items: response.data.map(item => ({								
							name: item.cognome + ' ' + item.nome,
							id: item.id,
						})),
					}
					]
				}
			}, (response) => {
			});
		},
		showDateModal: function() {
			this.go_to_date = null;
			this.$refs.dateModal.show();
		},
	}

}
</script>

<style>
.fc-resizer.fc-end-resizer {
	display: none;
}
</style>
