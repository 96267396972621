<template id="divisa">

	<div :is="type" :primario="primario" :secondario="secondario" :terziario="terziario"></div>

</template>

<script>
	
export default {

	components: {

		tinta:     		require('./tinta.vue').default,
		verticale:  	require('./verticale.vue').default,
		orizzontale:  	require('./orizzontale.vue').default,
		diagonale:  	require('./diagonale.vue').default

	},

	data: function(){
		return {

			dummy: null

		}
	},

	props: {

		type: {
			type: String,
			required: true
		},

		primario: {
			type: String,
			required: false
		},

		secondario: {
			type: String,
			required: false
		},

		terziario: {
			type: String,
			required: false
		}

	}

}

</script>