<template>
	<div class="d-flex">
		<div style="max-height: 500px; overflow-y: auto">
			<b-table-simple style="min-width: 250px" small striped hover responsive>
				<b-thead>
					<b-tr>
						<b-th style="width:100px !important;">Data</b-th>
						<b-th>Partita</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="item in dataset" :key="item.id" :variant="selected == item.id_partita  ? 'info': ''" @click="onRowSelect(item.id_partita)" style="cursor:pointer;">
						<b-td> {{ date(item.data_partita) }} </b-td>
						<b-td> {{ item.partita }} </b-td>	
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
		<div v-if="args.dettaglio">
			<SingolaPartita class="ml-2" :ds="ds" :id="args.dettaglio"></SingolaPartita>
		</div>
	</div>
</template>

<script>

import _ from 'lodash'
import moment from 'moment'
import SingolaPartita from "@/components/layout/report-scouting/DettaglioSingolaPartita.vue";

export default {
	props: [ 'ds', 'args' ],
	data: function() {
		return {
			selected: null,
		}
	},
	methods: {
		onRowSelect: function(a) {
			this.setA('dettaglio', a);
			this.selected = a; 
		},
		date: function(a) {
			return moment(a).format('DD/MM/YYYY');
		},
		setA: function(what, value) {
			this.args[what] = value;
		}
	},
	components: {
		SingolaPartita
	},
	computed: {
		dataset: function() {
			return _.filter(this.ds, function(a) {
				if(a.id_partita == null) return false;
				if(a.nazione_home_code == this.args.nazione) return true;
				if(a.nazione_away_code == this.args.nazione) return true;
			}.bind(this));
		}
	}
}

</script>