<template>
	<div style="max-height: 500px; overflow-y: auto;min-width:500px">

		<div class="d-flex ai-center">
			<div class="col-3 text-right">Data</div>
			<div class="col-9" style="font-size: 110%;">{{ partita[0].data_partita }}</div>
		</div>
		<div class="d-flex ai-center">
			<div class="col-3 text-right">Competizione</div>
			<div class="col-9" style="font-size: 110%;">{{ partita[0].competizione }}</div>
		</div>
		<div class="d-flex ai-center">
			<div class="col-3 text-right">Osservatori</div>
			<div class="col-9" style="font-size: 110%;"><b-badge v-for="o in osservatori" class="mr-2 mb-2">{{ o }}</b-badge></div>
		</div>
		<div class="d-flex">
			<div class="col-3 text-right">Osservati</div>
			<div class="col-9">
				<div v-for="oss in osservatiTable">
					<b-table-simple style="min-width: 350px" small striped hover responsive>
						<b-thead>
							<b-tr>
								<b-th>{{ oss.osservato + ' (' + oss.master.squadra_osservato + ')' }}</b-th>
								<b-th style="width:50px !important;"></b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="item in oss.valutazioni" :key="item.id">
								<b-td> {{ item.osservatore }} </b-td>
								<b-td> {{ item.gradimento }} </b-td>	
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

import _ from 'lodash';

export default {
	props: [ 'id', 'ds' ],
	computed: {
		partita: function() {
			return _.filter(this.ds, { raw_id_partita: this.id });
		},
		osservatori: function() {
			return _.uniq(_.map(this.partita, 'osservatore'));
		},
		osservati: function() {
			return _.uniq(_.map(this.partita, 'osservato'));
		},
		osservatiTable: function() {
			var out = [];
			return _.map(this.osservati, function(a) {
				return {
					osservato: a,
					master: _.find(this.partita, { osservato: a }),
					valutazioni: _.map(_.filter(this.partita, { osservato: a }), function(o) {
						return {
							osservatore: o.osservatore,
							gradimento: o.gradimento,
						}
					})
				}
			}.bind(this));
		}
	}
}

</script>