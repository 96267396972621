<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		<menusub></menusub>
		
		<div class="card">
			<div class="card-body">
				<div>
					<b-overlay :show="!dati">
						<div class="mb-4">
							<b-row>
								<b-col cols="4">
									<h3 class="font-bold font-italic m-0" style="text-transform: uppercase; color: #990a2c">Prevenzione <span v-if="datiOk.length">({{ datiOk.length }})</span></h3>
									<!-- <label><input type="checkbox" v-model="ux.hideDisabled" /> Gestisci Giocatori Nascosti</label> -->
									<b-button-group class="float-right">
										<b-button v-if="ux.hideDisabled" variant="outline-success" size="xs" class="mr-0 " @click.prevent="savePersone(hidePreset)">Aggiorna</b-button>
										<b-button v-if="ux.hideDisabled" variant="outline-warning" size="xs" @click.prevent="svuotaPersone()">Svuota</b-button>
									</b-button-group>
								</b-col>
								<b-col cols="8">
									
									<div class="d-flex justify-content-end align-content-center">
										<div class="mr-1">
											<div class="d-flex" style="min-width: 440px">
												<b-datepicker v-model="ux.from" placeholder="Dal" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
												<b-datepicker v-model="ux.to" placeholder="Al" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
												<b-button @click.prevent="getDashboard" variant="primary" :disabled="!canFilter" size="sm">
													<em class="fa fa-search"></em>
												</b-button>
												<!--<b-button @click.prevent="downloadDati" variant="outline-primary" :disabled="!dati || dati.length == 0" size="sm">
													<em class="fa fa-download"></em>
												</b-button>-->
											</div>
											<div class="text-center mt-1 small font-italic">
												<a href="" @click.prevent="applyPreset(-7)">Ultima Settimana</a>, 
												<a href="" @click.prevent="applyPreset(-7*4)">Ultimo Mese</a>, 
												<a href="" @click.prevent="applyPreset(-7*4*3)">Ultimo Trimestre</a>
											</div>
										</div>
										<div style="position: relative">
											<!-- <div style="position: absolute; top: -20px; text-transform: uppercase; letter-spacing: 1px; font-weight: bold" class="small text-muted">Ordina per</div> -->
										</div>
									</div>

								</b-col>
							</b-row>
						</div>

						<hr />

						<div v-if="ux.showFilter">
							
							<div style="max-height: 400px; overflow-y: scroll">
								<label v-for="g in glossario" style="margin-right: 5px">
									<input type="checkbox" v-model="scoped" :value="g"> {{ g.nome }} <small>({{g.tipologia_fase.replace('Fase_', '')}}) ({{g.amount}})</small>
								</label>
							</div>
							<b-button-group class="mt-3">
								<b-button size="sm" variant="outline-secondary" @click.prevent="ux.showFilter = false">Chiudi Pannello</b-button>
								<b-button size="sm" variant="outline-primary" @click.prevent="scoped = []" v-if="scoped.length > 0">Svuota Filtro</b-button>
							</b-button-group>
						</div>
						<div v-else>
							<b-button-group>
								<b-button size="sm" variant="outline-primary" @click.prevent="ux.showFilter = true">Filtra per Attività</b-button>
								<b-button size="sm" variant="outline-primary" @click.prevent="scoped = []" v-if="scoped.length > 0">Svuota Filtro</b-button>
							</b-button-group>
						</div>
						<div v-if="scoped.length > 0" class="d-flex my-2 align-items-center">
							<div class="small text-muted mr-3">Clicca per ordinare</div>
							<b-button-group>
								<b-button :variant="ux.sort == s.nome ? 'outline-primary' : 'outline-secondary'" size="sm" v-for="s in scoped" @click.prevent="ux.sort = s.nome">{{ s.tipologia_fase.replace('Fase_', '') }} | {{ s.nome }}</b-button>
							</b-button-group>
						</div>

						<hr />

						<b-row v-if="datiOk && datiOk.length">
							
							<b-col cols="4" v-if="listKpi(persona).length" v-for="persona in datiOk" :key="persona.persona_id" class="mb-4 px-3">
								
								<b-card class="h-100" :border-variant="ux.hideDisabled == true ? (( hidePreset.indexOf(persona.persona_id) !== -1 ) ? 'danger':'success') : ''" no-body>

									<div class="p-1 d-flex align-items-center" style="border-bottom: 1px solid rgb(100,100,100); background-color: rgb(240,240,240); border-top-left-radius: 3px; border-top-right-radius: 3px">

										<div class="ml-1 small text-muted">
											<span v-if="persona.ruolo">{{ persona.ruolo }}</span>
										</div>

										<div class="flex-grow-1 text-center">
											<label>
												<input v-if="ux.hideDisabled" type="checkbox" v-model="hidePreset" :value="persona.persona_id" />

												<strong style="color: ; text-transform: uppercase;">
													{{ persona.cognome }}
												</strong>
												<span>
													{{ persona.nome }}
												</span>
											</label>
										</div>

										<div class="mr-1 small text-muted">
											<span v-if="persona.maglia">{{ persona.maglia }}</span>
										</div>
									</div>

									<div class="d-flex">
										
										<div :style="'min-width: 150px; max-height: 255px; background-image: url('+persona.avatar+'); background-size: cover; background-position: center center'"></div>
										<div class="flex-grow-1 px-1" style="min-height: 255px">
											<!-- <pre>{{persona.kpi}}</pre> -->

											<div v-for="kpi in listKpi(persona)" class="mb-1">
												<div class="small">
													<span><strong>{{ kpi.kpi }}</strong> <small>{{ kpi.area }}</small></span>
												</div>

												<b-row no-gutters>
													<b-col :cols="6" :xl="8">
														<b-progress :max="kpi.programmato" height="1.2rem">
															<b-progress-bar :variant="kpi.amount == 0 ? 'lightGray' : ''" :value="kpi.amount || kpi.programmato">
															</b-progress-bar>
														</b-progress>
													</b-col>
													<b-col :cols="4" class="small font-weight-bold text-center">
														{{ kpi.amount }}/{{ kpi.programmato }}
													</b-col>
												</b-row>


											</div>
											
											<div v-if="persona.kpi.length != listKpi(persona).length && scoped.length == 0">
												<a href="javascript:;" @click.prevent="expanded.push(persona.persona_id)">Show All ({{ persona.kpi.length - listKpi(persona).length }}+)</a>
											</div>
											<div v-else-if="expanded.indexOf(persona.persona_id) !== -1">
												<a href="javascript:;" @click.prevent="expanded.splice(expanded.indexOf(persona.persona_id))">Hide</a>
											</div>

											<div class="mb-1" v-if="false">
												<div class="small">
													<span><strong>Acqua Fredda</strong></span>
												</div>
												<div v-if="persona.kpi6">

													<b-row no-gutters>
														<b-col :cols="6" :xl="8">
															<b-progress :max="persona.kpi6.programmato" height="1.2rem">
																<b-progress-bar :variant="persona.kpi6.fatto == 0 ? 'lightGray' : ''" :value="persona.kpi6.fatto || persona.kpi6.programmato">
																</b-progress-bar>
															</b-progress>
														</b-col>
														<b-col :cols="2 + (persona.kpi6.minuti_programmato > 0 ? 0 : 2)" class="small font-weight-bold text-center">
															{{ persona.kpi6.fatto }}/{{ persona.kpi6.programmato }}
														</b-col>
														<b-col v-if="persona.kpi6.minuti_programmato > 0" cols="2" class="small text-center">
															<span>
																<em class="fa fa-clock-o"></em> {{ Math.round(persona.kpi6.minuti_fatto/60) }}
															</span>
														</b-col>
													</b-row>
													
												</div>
												<div v-else>
													<b-row>
														<b-col cols="8" xl="8">
															<b-progress :max="10" height="1.2rem"><b-progress-bar variant="slightGray" :value="10"></b-progress-bar></b-progress>
														</b-col>
														<b-col class="text-center small text-muted" cols="4">N.D.</b-col>
													</b-row>
												</div>
											</div>

										</div>

									</div>
									

									<div v-if="true" class="text-center" style="border-top: 1px solid rgb(100,100,100); position: relative;">

										a

									</div>

								</b-card>

							</b-col>

						</b-row>
						<b-alert v-else-if="dati && dati.length == 0" variant="warning" show>
							Nessun dato trovato per la <strong>Squadra</strong> e il <strong>Periodo</strong> selezionato.
						</b-alert>
					</b-overlay>
				</div>
			</div>


		</div>
		<footerapp></footerapp>
	</section>
</template>

<style type="text/css">

.overall_progress {
	border-radius: 0px !important;
}

.bg-slightGray {
	background-color: #fbfbfb;
}

.bg-lightGray {
	background-color: #e9ecef;
	color: black;
}

</style>

<script>
// fbba00
// 990a2c
var Auth = require('../../../auth.js');
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
export default {
	data: function(){
		return {
			selected: null,
			i: 0,
			ux: {
				showFilter: false,
				hideDisabled: false,
				sort: null,
				order: 1,
				to: moment().format('YYYY-MM-DD'),
				from: moment().add(-7, 'days').format('YYYY-MM-DD'),
			},
			scoped: [],
			expanded: [],
			canFilter: false,
			dati: null,
			hidePreset: null
		}
	},
	computed: {
		glossario: function() {

			if(!this.dati) return false;

			return _.values(this.dati.fasi_glossario);

		},
		datiOk: function() {

			this.i++;

			if(!this.dati) return false;
			if(this.ux.sort == null)
				return this.dati.players;
			else
				console.log(this.ux.sort);
				return _.sortBy(this.dati.players, function(a) {
					var f = _.find(a.kpi, { kpi: this.ux.sort });
					if(f)
						return -1 * f.amount;
					return 0;
				}.bind(this));

		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		'ux.from': function(a,b) {
			if(b != null)
				this.canFilter = true;
		},
		'ux.to': function(a,b) {
			if(b != null)
				this.canFilter = true;
		},
		rosaTeamId: {
			immediate: true,
			handler: function() {
				this.getDashboard();
			}
		}
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},
	created: function () {

		if(this.user.presets().recoveryDashboardHide != undefined) {
			this.hidePreset = _.cloneDeep(this.user.presets().recoveryDashboardHide)
		} else {
			this.hidePreset = [];
		}

	},
	filters: {
		duration: function(seconds) {
			return new Date(parseFloat(seconds*1000)).toISOString().slice(11, 19);
		},
		data: function(i, format) {
			return moment(i).format(format || 'D/M/YY HH:mm')
		}
	},
	methods: {
		listKpi: function(persona) {

			if(this.scoped.length > 0) {

				return _.filter(persona.kpi, function(a) {

					var is = _.find(this.scoped, function(b) {
						return b.tipologia_fase == 'Fase_' + a.area && b.nome == a.kpi;
					}.bind(this));

					if(is)
						return true;
					return false;

				}.bind(this));

			}

			if(this.expanded.indexOf(persona.persona_id) !== -1)
				return persona.kpi;
			else return _.chunk(persona.kpi, 5)[0];

		},
		svuotaPersone: function() {
			this.hidePreset = [];
			this.savePersone(this.hidePreset);
		},
		savePersone: function(persone) {
			this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/recovery-set-persone', { persone }).then(function(data) {
				this.auth.refreshUser(this.$root.$children[0]);
				this.ux.hideDisabled = false;
			});
		},
		downloadDati: function() {

			var url = [this.$store.state.apiEndPoint + '/atlas/dashboard/recovery/csv/' + this.user.user.id + '/' + this.user.user.api_token];

			url.push('?from=' + this.ux.from);
			url.push('&to=' + this.ux.to);
			url.push('&team_id=' + this.rosaTeamId);

			window.open(url.join(''));

		},
		test: function(e) {
			$(e.target).fadeIn();
		},
		applyPreset: function(days) {

			this.ux.from = moment().add(days, 'days').format('YYYY-MM-DD');
			this.ux.to = moment().format('YYYY-MM-DD');

			this.getDashboard();

		},
		setOrder: function(order) {

			if(this.ux.sort == order) {
				this.ux.order *= -1;
			} else
				this.ux.order = 1;

			this.ux.sort = order;
			$('.face').fadeOut();
		},
		getDashboard: function() {

			this.dati = null;
			this.canFilter = null;
			this.expanded = [];

			this.$http.get(this.$store.state.apiEndPoint + '/atlas/reports/prevenzione', {
				team_id: this.rosaTeamId,
				from: this.ux.from,
				to: this.ux.to
			}).then(function(data) {
				this.dati = data.data;
			});

		},
	}
}

</script>