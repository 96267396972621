<template>
  <div class="wrapper home videotag">
    <nav v-if="!sidebarHidden" id="sidebar" data-simplebar>
      <video-tags />
    </nav>

    <b-container fluid>

      <b-row>
        <!-- Video container -->
        <video-player v-if="data && data.video" :video="data.video">
          <template v-if="data && !fullScreen">
            <b-row>
              <b-col class="mt-2" v-if="false">
                <settings />
              </b-col>

              <b-col class="mt-2">
                <b-button variant="primary" class="mr-2 w-100" @click="toggleSidebar()" :title="hideSidebarText">
                  {{ hideSidebarText }}
                </b-button>
              </b-col>

              <b-col class="mt-2">
                <b-button variant="primary" class="mr-2 w-100" @click="enableFullScreen()" title="Fullscreen">
                  Fullscreen
                </b-button>
              </b-col>

              <b-col class="mt-2" v-if="false">
                <export-tags />
              </b-col>

              <b-col class="mt-2">
                <b-button
                :variant="tagListEnabled ? 'success' : 'primary'"
                :class="['mr-2 w-100', tagListEnabled ? 'active' : '']"
                @click="toggleTagList()">
                Elenco tag
              </b-button>
            </b-col>

            <b-col class="col-goal mt-2" v-if="goalVisible">
              <goal />
              <goal negative />
            </b-col>

            <b-col class="mt-2">
              <b-button class="w-100" variant="primary" @click="switchMode">
                {{ goalkeeperMode ? 'Modalità giocatori' : 'Modalità portiere' }}
              </b-button>
            </b-col>

            <b-col class="mt-2">
              <bookmarks v-if="data.bookmarks" />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-2" v-for="bookmark in videoBookmarks" :key="bookmark.id">
              <bookmark :bookmark="bookmark" />
            </b-col>
          </b-row>
          
          <b-row v-if="newTag" class="my-0">
            <b-col>
              <tag-summary
              :tag="newTag"
              @cancel="cancelNewTag()"
              @update="updateTag()" />
            </b-col>
          </b-row>   
        
        </template>
        <new-tag-full-screen v-else />
      </video-player>
      <!-- New tag -->
      <template v-if="!fullScreen">
        <new-tag
        v-if="data && data.teams"
        v-show="!tagListEnabled"
        :teams="data.teams" />
        <tag-filters v-if="data" v-show="tagListEnabled" />
      </template>
    </b-row>

    <!-- Timeline -->
    <timeline-tabs
    v-if="data && data.teams && data.tags && data.skills && !fullScreen"
    :teams="data.teams"
    :tags="data.tags" />
  </b-container>

  <template v-for="tag in tags">
    <share-tag :key="`share-${tag.id}`" :tag="tag" />
    <info-tag :key="`info-${tag.id}`" :tag="tag" />
  </template>

  <goal-modal />
  <goal-modal negative />
</div>
</template>

<script>
import 'simplebar';
import { mapState, mapGetters } from 'vuex';
import VideoPlayer from './video/VideoPlayer.vue';
import TimelineTabs from './timeline/TimelineTabs.vue';
import VideoTags from './videotags/VideoTags.vue';
import NewTag from './newtag/NewTag.vue';
import Bookmarks from './bookmarks/Bookmarks.vue';
import TagFilters from './tagfilters/TagFilters.vue';
import ExportTags from './export/ExportTags.vue';
import ShareTag from './modals/ShareTag.vue';
import InfoTag from './modals/InfoTag.vue';
import Settings from './Settings.vue';
import NewTagFullScreen from './newtag/NewTagFullscreen.vue';
import Goal from './goal/Goal.vue';
import GoalModal from './goal/GoalModal.vue';
import Bookmark from './bookmarks/Bookmark.vue';

import TagSummary from './newtag/TagSummary.vue';

export default {
  name: 'Home',
  components: {
    VideoPlayer,
    TimelineTabs,
    VideoTags,
    NewTag,
    Bookmarks,
    TagFilters,
    ExportTags,
    ShareTag,
    InfoTag,
    Settings,
    NewTagFullScreen,
    Goal,
    Bookmark,
    GoalModal,
    TagSummary
  },

  props: {
    goalVisible: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    ...mapState({
      data: 'data',
      tagListEnabled: 'tagListEnabled',
      sidebarHidden: 'sidebarHidden',
      fullScreen: 'fullScreen',
      currentTime: 'currentTime',
      videoBookmarks: state => state.data.video.bookmarks,
      goalkeeperMode: 'goalkeeperMode',
      newTag: 'newTag'
    }),
    ...mapGetters(['tags']),

    hideSidebarText() {
      return this.sidebarHidden ? 'Mostra sidebar' : 'Nascondi sidebar';
    },
  },

  methods: {
    toggleTagList() {
      if (this.tagListEnabled) {
        this.$store.commit('disableTagList');
      } else {
        this.$store.commit('enableTagList');
      }
    },

    toggleSidebar() {
      this.$store.commit('toggleSidebar');
    },

    enableFullScreen() {
      this.$store.commit('enableFullScreen');
    },

    switchMode() {
      this.$store.commit('toggleGoalkeeperMode');
    },

    cancelNewTag() {
      this.$store.commit('cancelNewTag');
    },

    updateTag() {
      this.$store.dispatch('saveTag');
    },
  },
};
</script>

<style lang="scss" scoped>

.wrapper {
  display: flex;
}

#sidebar {
  min-width: 300px;
  max-width: 300px;
  height: 100vh;
}

.container-fluid, #sidebar {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.col-goal {
  display: inline-flex;

  div:last-child {
    margin: 0 !important;
  }
}
</style>