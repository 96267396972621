<template>
	<div>
		
		<div id="blocklyDiv" ref="blockly" style="height: 480px; width: 100%; z-index: 9999"></div>

		<b-form-checkbox switch v-model="ux.ruleById">Mostra le regole per codice</b-form-checkbox>
		<!-- <b-button @click.prevent="startBlockly">!</b-button> -->
		<div class="mt-5" v-if="false && blockly">
			<b-button @click.prevent="exportBlocks">Export Blocks</b-button>
			<b-button @click.prevent="importBlocks">Import Blocks</b-button>
			<pre style="font-size: 150%">{{ code }}</pre>
			<pre>{{rules}}</pre>
		</div>
		<!--
		<pre>{{toolbox}}</pre>-->

	</div>
</template>

<style type="text/css">

.blocklyDropDownDiv {
	z-index: 9999;
}
	
</style>

<script type="text/javascript">

export default {

	props: [ 'rules', 'workspace' ],
	data: function() {
		return {
			blockly: null,
			ux: {
				show: false,
				ruleById: false
			}
		}
	},
	watch: {
		'ux.ruleById': {
			handler: function() {
				this.reBlockly();
				/*var info = Blockly.serialization.workspaces.save(this.blockly);

				this.startBlockly();
				_.delay(function() {
					Blockly.serialization.workspaces.load(info, this.blockly);
				}.bind(this), 300);*/


			}
		}
	},
	mounted: function() {
		_.delay(function() {

			this.startBlockly(this.workspace);
			/*
			_.delay(function() {
				console.log('Now!@!!');
				var b = this.blockly.newBlock('block_maturazione_simple');
				b.initSvg();
				b.render();
			}.bind(this), 2000);*/

		}.bind(this), 1000);
	},
	methods: {
		reBlockly: function() {

			var info = Blockly.serialization.workspaces.save(this.blockly);

			this.startBlockly();

			_.delay(function() {
				Blockly.serialization.workspaces.load(info, this.blockly);
			}.bind(this), 300);

		},
		startBlockly: function(workspace = false) {

			if(this.blockly) {
				this.blockly.dispose();
			}

			this.bakeBlocks();

			this.blockly = Blockly.inject(this.$refs.blockly, {
				toolbox: this.toolbox,
				scrollbars: true,
				sounds: false,
				trashcan: true
			});

			this.blockly.addChangeListener(function() {
				this.exportBlocks();
			}.bind(this));

			if(workspace == false || !workspace || workspace.length == 0) {
				var b = this.blockly.newBlock('block_maturazione_simple');
				b.initSvg();
				b.render();
			} else {
				Blockly.serialization.workspaces.load(JSON.parse(workspace), this.blockly);
			}

		},
		importBlocks: function() {
			var w = window.prompt('Inserisci Payload');
			Blockly.serialization.workspaces.load(JSON.parse(w), this.blockly);
		},
		exportBlocks: function() {

			var info = Blockly.serialization.workspaces.save(this.blockly);
			this.$emit('workspaceupdate', JSON.stringify(info))
			// console.log(JSON.stringify(info));

		},

		bakeBlocks: function() {

			var blocks = [];
			var rules = [];

			// blocks.push(
			// 	{
			// 	  "type": "multiplier",
			// 	  "message0": "Moltiplicatore %1 Valore %2",
			// 	  "args0": [
			// 	    {
			// 	      "type": "input_dummy"
			// 	    },
			// 	    {
			// 	      "type": "input_value",
			// 	      "name": "value"
			// 	    }
			// 	  ],
			// 	  "colour": 270,
			// 	  "tooltip": "",
			// 	  "helpUrl": ""
			// 	});

			_.each(this.rules, function(a) {

				var ruleType = 'rule' + a.posizione;
				var name = this.ux.ruleById ? ruleType : a.name;
				blocks.push({
					type: ruleType,
					message0: name + ' %1',
					args0: [{
						type: 'field_dropdown',
						name: "KEY",
						options: [
							[ "Risultato", "result" ],
				        	[ "Valore", "amount" ]
				        ]
					}],
					output: [
						"Boolean",
						"Number"
					],
					nextStatement: null,
					previousStatement: null,
					colour: 230,
					tooltip: "",
					helpUrl: ""
				});

				rules.push({ id: ruleType, handler: function(block) {
					var dropdown_key = block.getFieldValue('KEY');
					var code = 'rule1.' + dropdown_key;
					return [code, Blockly.JavaScript.ORDER_NONE];
				} });

			}.bind(this));


			blocks.push({
				type: "block_maturazione_simple",
				message0: "Il bonus è maturato se %1 %2 Confronta in %3",
				args0: [
				    {
				      "type": "input_dummy"
				    },
				    {
				      "type": "input_statement",
				      "name": "NAME"
				    },
				    {
				      "type": "field_dropdown",
				      "name": "NAME",
				      "options": [
				        [
				          "AND",
				          "and"
				        ],
				        [
				          "OR",
				          "or"
				        ]
				      ]
				    }
				  ],
				colour: 300,
				tooltip: "",
				helpUrl: ""
			});

			blocks.push({
				type: "block_multiplier",
				message0: "Il bonus è moltiplicato per: %1 %2",
				args0: [
				    {
				      "type": "input_dummy"
				    },
				    {
				      "type": "input_value",
				      "name": "lk"
				    }
				  ],
				colour: 390,
				tooltip: "",
				helpUrl: ""
			});

			blocks.push({
				type: "block_multiplier_repeater",
				message0: "Il bonus si ripete quando le regole: %1 %2 raggiungono un multiplo.",
				args0: [
				    {
				      "type": "input_dummy"
				    },
				    {
				    	"type": "input_statement",
				    	"name": "NAME"
				    }
				  ],
				colour: 390,
				tooltip: "",
				helpUrl: ""
			});

			blocks.push({
				type: "block_maturazione_logic",
				message0: "Il bonus è maturato se la condizione è verificata: %1 %2",
				args0: [
				    {
				      "type": "input_dummy"
				    },
				    {
				      "type": "input_value",
				      "name": "lk"
				    }
				  ],
				colour: 300,
				tooltip: "",
				helpUrl: ""
			});

			blocks.push({
				type: "isEmpty",
				message0: "è zero %1 %2",
				args0: [
					{ type: "input_dummy" },
					{ type: "input_value", name: "NAME" }
				],
				inputsInline: true,
				output: "Boolean",
				nextStatement: null,
				previousStatement: null,
				colour: 230,
				tooltip: "",
				helpUrl: ""
			});

			// console.log(Blockly.common);

			Blockly.common.defineBlocksWithJsonArray(blocks);

			_.each(rules, function(a) {
				Blockly.JavaScript[a.id] = a.handler;
			});

			/*
			Blockly.JavaScript['rule1'] = function(block) {
				var dropdown_key = block.getFieldValue('KEY');
				var code = 'rule1.' + dropdown_key;
				return [code, Blockly.JavaScript.ORDER_NONE];
			};

			Blockly.JavaScript['rule2'] = function(block) {
				var dropdown_key = block.getFieldValue('KEY');
				var code = 'rule2.' + dropdown_key;
				return [code, Blockly.JavaScript.ORDER_NONE];
			};

			Blockly.JavaScript['rule3'] = function(block) {
				var dropdown_key = block.getFieldValue('KEY');
				var code = 'rule3.' + dropdown_key;
				return [code, Blockly.JavaScript.ORDER_NONE];
			};
			*/

		}
	},
	computed: {
		code: function() {

			if(this.blockly) {
				return Blockly.JavaScript.workspaceToCode(this.blockly);
				return javascriptGenerator.workspaceToCode(this.blockly);
			}

		},
		toolbox: function() {

			var contents = [];

			var LogicBlocks = [];
			LogicBlocks.push({
				"kind": "block",
				"type": "logic_compare"
			});

			LogicBlocks.push({
				"kind": "block",
				"type": "logic_operation"
			});

			LogicBlocks.push({
				"kind": "block",
				"type": "math_arithmetic"
			});

			LogicBlocks.push({
				"kind": "block",
				"type": "math_modulo"
			});

			LogicBlocks.push({
				"kind": "block",
				"type": "isEmpty"
			});

			LogicBlocks.push({
				"kind": "block",
				"type": "math_number"
			});

			// contents.push({
			// 	"kind": "block",
			// 	"type": "multiplier"
			// });

			var RuleBlocks = [];
			_.each(this.rules, function(a,k) {
				RuleBlocks.push({
					kind: 'block',
					type: 'rule' + (k+1)
				})
			});

			var SystemBlocks = [];

			SystemBlocks.push({
				kind: 'block',
				type: "block_maturazione_simple",
			});

			SystemBlocks.push({
				kind: 'block',
				type: "block_maturazione_logic",
			});
			
			SystemBlocks.push({
				kind: 'block',
				type: "block_multiplier",
			});
			
			SystemBlocks.push({
				kind: 'block',
				type: "block_multiplier_repeater",
			});

			contents.push({
				kind: 'category',
				name: 'Regole',
				contents: RuleBlocks
			});

			contents.push({
				kind: 'category',
				name: 'Logica e Matematica',
				contents: LogicBlocks
			});

			contents.push({
				kind: 'category',
				name: 'Validazione',
				contents: SystemBlocks
			});

			return {
				'kind': 'categoryToolbox',
				'contents': contents
					/*{
						'kind': 'block',
						'type': 'controls_repeat_ext',
						'inputs': {
							'TIMES': {
								'shadow': {
									'type': 'math_number',
									'fields': {
										'NUM': 5
									}
								}
							}
						}
					}*/
			};

		}

	}

}

</script>