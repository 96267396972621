<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<b-card title="Servizi" title-tag="span">
				<b-card-body class="px-0">
					<div v-for="route in routes">
						<b-form-group :label="route.name" :description="root + route.description">
							<b-row>
								<b-col v-for="param in route.params">
									<b-form-input type="text" v-model="param.value" :placeholder="param.label" :title="param.label"></b-form-input>
								</b-col>
								<b-col v-for="param in route.params2">
									<b-form-input :type="param.type ? param.type : 'text'" v-model="param.value" :placeholder="param.title" :disabled="!param.editable" :title="param.title"></b-form-input>
								</b-col>
								<b-col>
									<b-button variant="primary" @click="goToLink(route.path)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
								</b-col>
							</b-row>	
						</b-form-group>
					</div>
					<b-form-group label="Socket Client" description="https://socket.asromasystem.com/home">
						<b-row>
							<b-col>
								<b-button variant="primary" @click="openLink('https://socket.asromasystem.com/home')"><i class="fa fa-search" aria-hidden="true"></i></b-button>
							</b-col>
						</b-row>	
					</b-form-group>
					<b-form-group label="Serie A: classifica e risultati" description="http://meta.asromasystem.com/serie-a/#/">
						<b-row>
							<b-col>
								<b-button variant="primary" @click="openLink('http://meta.asromasystem.com/serie-a/#/')"><i class="fa fa-search" aria-hidden="true"></i></b-button>
							</b-col>
						</b-row>	
					</b-form-group>
					<b-form-group label="Primavera: classifica e risultati" description="http://meta.asromasystem.com/primavera-a#/">
						<b-row>
							<b-col>
								<b-button variant="primary" @click="openLink('http://meta.asromasystem.com/primavera-a#/')"><i class="fa fa-search" aria-hidden="true"></i></b-button>
							</b-col>
						</b-row>	
					</b-form-group>

				</b-card-body>
			</b-card>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import moment from 'moment';
export default {

	data: function() {
		return {
			root: 'https://app.asromasystem.com/plain/monitor/#',
			routes: [
			{
				path: '/report-pre-partita',
				name: 'Distinta Gara',
				description: '/report-pre-partita/:match_id?edit=1',
				params: [{label: ':match_id', value: null }],
				params2: [{label: 'edit', value: 1}],
			},
			{
				path: '/screen',
				name: 'Whiteboard',
				description: '/screen'
			},
			{
				path: '/screen/edit',
				name: 'Whiteboard Edit',
				description: '/screen/edit'
			},
			{
				path: '/pdf',
				name: 'Whiteboard Config',
				params: [{label: ':whiteboard_id', value: null }],
				description: '/pdf/:whiteboard_id'
			},
			{
				path: '/report-partita',
				name: 'Cartellino Partita',
				params: [{label: ':match_id', value: null }],
				description: '/report-partita/:match_id'
			},
			{
				path: '/report-scouting',
				name: 'Report Scouting',
				params: [{label: ':giocatore_id', value: null }],
				params2: [{label: 'user', value: Auth.user.id}],
				description: '/report-scouting/:giocatore_id?user=:utente_id'
			},
			{
				path: '/report-scouting-graph',
				name: 'Report Scouting Graph',
				params: [{label: ':giocatore_id', value: null }],
				params2: [{label: 'user', value: Auth.user.id}],
				description: '/report-scouting-graph/:giocatore_id?user=:utente_id'
			},
			{
				path: '/report-scouting-mobile',
				name: 'Report Scouting Mobile',
				params: [{label: ':giocatore_id', value: null }],
				params2: [{label: 'user', value: Auth.user.id}],
				description: '/report-scouting-mobile/:giocatore_id?user=:utente_id'
			},
			{
				path: '/reports/prestiti',
				name: 'Report Prestiti',
				params2: [{label: 'utente', value: 0, editable:true, title: ':utente_id'}, {label: 'dal', value: null, editable:true, type: 'date', title: ':dal'}, {label: 'al', value: null, editable:true, type: 'date', title: ':al'}, {label: 'utenti', value: true, title: 'utenti'}],
				description: '/reports/prestiti?utente=:utente_id&dal=:dal&al=:al&utenti=true'
			},
			{
				path: '/reports/on-loan',
				name: 'Report Players On Loan',
				params2: [{label: 'from', value: null, editable:true, type: 'date', title: ':from'}, {label: 'to', value: null, editable:true, type: 'date', title: ':to'}, {label: 'active', value: 1, editable: true, title: ':active'}],
				description: '/reports/on-loan?active=:active&from=:from&to=:to'
			},
			{
				path: '/reports/yellow',
				name: 'Report Players Yellows',
				params: [{label: ':comp (serie-a|coppa-italia|uefa)', value: null}],
				description: '/reports/yellow/:comp'
			},
			{
				path: '/reports/yellow-h',
				name: 'Report Players Yellows Horizontal All',
				description: '/reports/yellow-h'
			},
			{
				path: '/reports/players-matches',
				name: 'Report Players Matches',
				params: [{label: ':comp (serie-a|coppa-italia|uefa)', value: null}],
				description: '/reports/players-matches/:comp'
			},
			{
				path: '/reports/players-matches-h',
				name: 'Report Players Matches Horizontal',
				params: [{label: ':comp (serie-a|coppa-italia|uefa)', value: null}],
				description: '/reports/players-matches-h/:comp'
			},
			{
				path: '/reports/players-matches-h-all',
				name: 'Report Players Matches Horizontal All',
				description: '/reports/players-matches-h-all'
			},
			{
				path: '/reports/prestiti-single',
				name: 'Report Prestiti Single',
				params2: [{label: 'osservato', value: null, editable:true, title: ':giocatore_id'}, {label: 'utente', value: 0, editable:true, title: ':utente_id'}, {label: 'dal', value: null, editable:true, type: 'date', title: ':dal'}, {label: 'al', value: null, editable:true, type: 'date', title: ':al'}, {label: 'utenti', value: true, title: 'utenti'}],
				description: '/reports/prestiti-single?osservato=:giocatore_id&utente=:utente_id&dal=:dal&al=:al&utenti=true'
			},  
			{
				path: '/formazione-in-campo',
				name: 'Formazione In Campo',
				params: [{label: ':formazione_id', value: null }, {label: ':label', value: false }],
				description: ' /formazione-in-campo/:formazione_id/:label'
			}
			]
		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},

	created: function () {   
	},

	watch: {
	},
	methods: {
		goToLink(path) {
			var route = this.routes.find((item) => item.path == path);

			var link = this.root + path;

			for(var i in route.params) {
				var param = route.params[i];
				if(param.value !== null) {
					link += '/' + param.value;
				}
			}
			if(route.params2) {
				for(var i in route.params2) {
					var param = route.params2[i];
					console.log(param.value);
					if(param.value !== '') {
						console.log(param.value);
						if(i == 0) {
							link += '?';
						}
						if(i > 0) {
							link += '&';
						}
						link += param.label + '=' + param.value;
					}
				}

			}
			console.log(link);

			window.open(link, "_blank");		
		},

		openLink(link) {
			console.log(link);
			window.open(link, "_blank");		
		}
	}
}
</script>
