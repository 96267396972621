<template>
	<div class="d-flex">
		<div style="max-height: 500px; overflow-y: auto">
			<b-table style="min-width: 250px" small striped hover :items="giocatori" :fields="[{ key: 'giocatore', sortable: true }, { key: 'year', sortable: true }, { key: 'osservato_ruolo', sortable: true }]" selectable select-mode="single" @row-selected="onRowSelect" selected-variant="info">	
				<template #head(osservato_ruolo)="data">
        			Ruolo
      			</template>
      			<template #head(year)="data">
        			Anno
      			</template>
			</b-table>
		</div>
		<div :key="random">
			<SingoloGiocatore class="ml-2" v-if="args.dettaglio" :id="args.dettaglio" :ds="ds"></SingoloGiocatore>
		</div>
	</div>
</template>

<script>

import SingoloGiocatore from "@/components/layout/report-scouting/DettaglioSingoloGiocatore.vue";

export default {
	props: [ 'ds', 'args' ],
	data: function() {
		return {
			selected: null,
			sort: false,
			key: 'year',
			random: Math.random(),
		}
	},
	methods: {
		carriera: function(id) {
			return _.find(this.ds, { raw_osservato_id: id });
		},
		onRowSelect: function(a) {
			this.args.dettaglio = a[0].id;
			this.selected = a;
			this.random = Math.random();
		},
	},
	components: {
		SingoloGiocatore
	},
	computed: {
		dataset: function() {
			return _.filter(this.ds, function(a) {
				if(a.nazione_osservato_code == this.args.nazione) return true;
			}.bind(this));
		},
		giocatori: function() {
			return _.orderBy(_.map(_.uniq(_.map(this.dataset, 'osservato')), function(a) {
				return {
					giocatore: a,
					osservato_ruolo: _.find(this.dataset, { osservato: a }).osservato_ruolo, 
					year: _.find(this.dataset, { osservato: a }).osservato_dob,
					id: _.find(this.dataset, { osservato: a }).raw_osservato_id,
					partite: _.map(_.filter(this.dataset, { osservato: a }), function(a) {
						return {
							partita: a.partita,
							id_partita: a.id_partita
						}
					})
				}
			}.bind(this)), [ this.key ], [ this.sort ? 'asc' : 'desc' ]);
		},
		anniCounter: function() {
			return _.mapValues(_.groupBy(this.giocatori, 'year'), function(a) {
				return a.length;
			});
		}
	}
}

</script>