<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<b-card title="Scouting Report">
			<b-row v-if="apiData" align-h="end" align-v="center">
				<b-col cols="2">
					<b-datepicker v-model="filtering.from" placeholder="From" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
				</b-col>
				<b-col cols="2">
					<b-datepicker v-model="filtering.to" placeholder="To" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
				</b-col>
				<b-col cols="2">
					<multiselect size="sm" v-model="osservatori_selected" :options="osservatori" :multiple="true" placeholder="Seleziona" label="cognome" track-by="id"></multiselect>
				</b-col>
				<b-col cols="1">
					<b-button size="sm" v-if="changed" @click.prevent="doFilter"class="ml-1" :disabled="loading"><i class="fa fa-search" aria-hidden="true"></i></b-button>
				</b-col>
				<b-col cols="1">
					<b-form-select size="sm" v-model="key" :options="key_options" value-field="value" text-field="label"></b-form-select>
				</b-col>
			</b-row>

			<div style="position: relative">
				<div class="hello" ref="chartdiv"></div>
				<div style="position: absolute; right: 5px; top: 5px;">
					<b-button size="sm" v-if="continent && focus" @click.prevent="resetZoomTo(continent)">Back to {{continent}}</b-button>
					<b-button size="sm" @click.prevent="resetZoom">Reset Zoom</b-button>
				</div>
				<div v-if="focus" class="d-flex ai-start" style="max-height: 540px; overflow-y: hidden; position: absolute; left: 10px; top: 10px; background-color: rgba(255,255,255,0.95); padding: 1em; border-radius: 10px; box-shadow: 0px 0px 5px rgba(0,0,0,.1)">

					<div style="min-width: 200px;">
						<h2 style="margin: 0; padding: 0" class="mb-2">{{ mapFocus.name }}</h2>

						<div class="d-flex ai-center ms" @click.prevent="navigate('partite', { nazione: focus })" :style="currentNavigate && currentNavigate.context == 'partite' ? 'background-color: rgba(0,0,0,.05)' : ''">
							<div class="col-9 p-1">Partite</div>
							<div class="col-3 p-1" style="font-size: 110%; font-weight: bold">{{mapFocus.partite.length}}</div>
						</div>
						<div class="d-flex ai-center ms" @click.prevent="navigate('giocatori', { nazione: focus })" :style="currentNavigate && currentNavigate.context == 'giocatori' ? 'background-color: rgba(0,0,0,.05)' : ''">
							<div class="col-9 p-1">Giocatori</div>
							<div class="col-3 p-1" style="font-size: 110%; font-weight: bold">{{mapFocus.osservati.length}}</div>
						</div>
						<div class="d-flex ai-center ms" @click.prevent="navigate('osservatori', { nazione: focus })" :style="currentNavigate && currentNavigate.context == 'osservatori' ? 'background-color: rgba(0,0,0,.05)' : ''">
							<div class="col-9 p-1">Osservatori</div>
							<div class="col-3 p-1" style="font-size: 110%; font-weight: bold">{{mapFocus.osservatori.length}}</div>
						</div>

						<div class="d-flex ai-center ms" :style="currentNavigate && currentNavigate.context == 'gradimenti' ? 'background-color: rgba(0,0,0,.05)' : ''">
							<div class="col-9">Gradimenti</div>
						</div>
						<donut-gradimento @picked="navigate('gradimenti', { nazione: focus, gradimento: $event })" :key="focus" :data="groupedGradimenti(mapFocus.gradimenti)" id="ag" style="max-width:200px;position:relative;"></donut-gradimento>
					</div>
					<div v-if="currentNavigate" class="ml-2 custom">
						<component :is="navigateComponent" :ds="apiData.results" :args="currentNavigate.args"></component>
					</div>
				</div>

				<div v-if="!apiData" class="d-flex align-content-center flex-wrap justify-content-center" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,.1)">
					<div style="min-width: 200px;">
						<div>
							<b-form-group label="Dal">
								<b-datepicker v-model="filtering.from" placeholder="From" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm"></b-datepicker>
							</b-form-group>
						</div>
						<div>
							<b-form-group label="Al">
								<b-datepicker v-model="filtering.to" placeholder="To" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm"></b-datepicker>
							</b-form-group>
						</div>
						<div>
							<b-form-group label="Osservatori">
								<multiselect v-model="osservatori_selected" :options="osservatori" :multiple="true" placeholder="Seleziona" label="cognome" track-by="id"></multiselect>
							</b-form-group>
						</div>
						<div>
							<b-form-group label="Filtro">
								<b-form-select v-model="key" :options="key_options" value-field="value" text-field="label"></b-form-select>
							</b-form-group>
						</div>
						<b-button @click.prevent="doFilter" :disabled="loading" style="width:100%">Filtra Risultati</b-button>
					</div>
				</div>
			</div>
		</b-card>
		<footerapp></footerapp>
	</section>
</template>

<script>
import pageBase from '@/mixins/pageBase.js';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am5geodata_continentsLow from "@amcharts/amcharts4-geodata/continentsLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as $mapUtils from "@amcharts/amcharts5/.internal/charts/map/MapUtils";
import DonutGradimento from "@/components/layout/charts/DonutGradimento.vue";
import NavigaPartite from "@/components/layout/report-scouting/NavigaPartite.vue";
import NavigaGiocatori from "@/components/layout/report-scouting/NavigaGiocatori.vue";
import NavigaOsservatori from "@/components/layout/report-scouting/NavigaOsservatori.vue";
import NavigaGradimenti from "@/components/layout/report-scouting/NavigaGradimenti.vue";

var europe_ = [ "AL", "AD", "AT", "BY", "BE", "BA", "BG", "HR", "CZ", "DK", "EE", "FO", "FI", "FR", "DE", "GI", "GR", "GG", "VA", "HU", "IS", "IE", "IM", "IT", "JE", "LV", "LI", "LT", "LU", "MT", "MD", "MC", "ME", "NL", "MK", "NO", "PL", "PT", "RO", "RU", "SM", "RS", "SK", "SI", "ES", "SJ", "SE", "CH", "UA", "GB" ];
var africa_ = ["BJ", "BW", "IO", "BF", "BI", "CV", "CM", "CF", "TD", "KM", "CG", "CD", "CI", "DJ", "EG", "GQ", "ER", "SZ", "ET", "TF", "GA", "GM", "GH", "GN", "GW", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RE", "RW", "SH", "ST", "SN", "SC", "SL", "SO", "ZA", "SS", "SD", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW"];
var na_ = [ "BM", "CA", "GL", "PM", "US" ];
var sa_ = [ "AI", "AG", "AR", "AW", "BS", "BB", "BZ", "BO", "BQ", "BV", "BR", "KY", "CL", "CO", "CR", "CU", "CW", "DM", "DO", "EC", "SV", "FK", "GF", "GD", "GP", "GT", "GY", "HT", "HN", "JM", "MQ", "MX", "MS", "NI", "PA", "PY", "PE", "PR", "BL", "KN", "LC", "MF", "VC", "SX", "GS", "SR", "TT", "TC", "UY", "VE", "VG", "VI" ];
var asia_ = [ "AF", "AM", "AZ", "BH", "BD", "BT", "BN", "KH", "CN", "CY", "GE", "HK", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KP", "KR", "KW", "KG", "LA", "LB", "MO", "MY", "MV", "MN", "MM", "NP", "OM", "PK", "PS", "PH", "QA", "SA", "SG", "LK", "SY", "TW", "TJ", "TH", "TL", "TR", "TM", "AE", "UZ", "VN", "YE" ];
var oceania_ = [ "AS", "AU", "CX", "CC", "CK", "FJ", "PF", "GU", "HM", "KI", "MH", "FM", "NR", "NC", "NZ", "NU", "NF", "MP", "PW", "PG", "PN", "WS", "SB", "TK", "TO", "TV", "UM", "VU", "WF" ];

var paesi = {
	europe: europe_,
	northAmerica: na_,
	southAmerica: sa_,
	africa: africa_,
	asia: asia_,
	oceania: oceania_,
}

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			osservatori: [],
			changed: false,
			loading: false,
			apiData: false,
			chart: null,
			focus: null,
			_continentSeries: null,
			polygonSeries: null,
			_bubbleSeries: null,
			heatLegend: null,
			circleTemplate: null,
			superMappa: null,
			continent: null,
			zoom: 0,
			key: 'partite',
			CircleTemplate: null,
			ContinentSeries: null,
			GeoSeries: null,
			BubbleSeries: null,
			HeatLegend: null,
			rooot: null,
			key_options: [
				{ value: 'partite', label: 'Partite osservate'},
				{ value: 'giocatori', label: 'Giocatori Osservati'}
				],
			grad_colors: {
				A: '#00B386',
				'B+': '#93D050',
				B: '#FFBF02',
				C: '#FE0200'
			},
			filtering: {
				from: null,
				to: null,
				osservatori_id: []
			},
			osservatori_selected: [],
			currentNavigate: null
		}
	},
	components: {
		NavigaPartite,
		NavigaGiocatori,
		NavigaOsservatori,
		NavigaGradimenti,
		DonutGradimento
	},

	computed: {
		navigateComponent: function() {
			return 'Naviga' + _.startCase(this.currentNavigate.context);
		},
		chartInfo: function() {
			if(!this.superMappa) return false;
			var data = _.filter(_.map(_.filter(this.superMappa, function(a) {
				if(this.zoom == 0)
					return a.scope == 'continenti';
				else {
					if(paesi[this.continent].indexOf(a.id) === -1) return false;
					return a.scope != 'continenti';
				}
			}.bind(this)), function(a) {
				if(!a.id) return false;
				var value = 0;
				if(this.key == 'partite')
					value = a.partite.length;
				else if(this.key == 'giocatori')
					value = a.osservati.length;
				return {
					id: a.id,
					name: a.name,
					value
				};
			}.bind(this)));
			return {
				key: this.key,
				data: data,
				min: _.minBy(data, 'value').value,
				max: _.maxBy(data, 'value').value,
			}
		},
		mapFocus: function() {
			return _.find(this.superMappa, { id: this.focus });
		}
	},
	watch: {
		filtering: {
			deep: true,
			handler: function() {
				if(this.apiData == null) return false;
				this.changed = true;
			}
		},
		key: function() {
			if(this.apiData)
				this.applyData();
		},
		zoom: function() {
			if(this.apiData)
				this.applyData();
		},
		osservatori_selected: function() {
			this.filtering.osservatori_id = _.map(this.osservatori_selected, 'id');
		}
	},
	methods: {
		navigate: function(context, args) {
			this.currentNavigate = {
				context,
				args
			};
		},
		groupedGradimenti: function(gradimenti) {
			var out = {};
			_.each(gradimenti, function(single) {
				if(out[single] == undefined)
					out[single] = {
						value: 1,
						label: single,
						color: this.grad_colors[single]
					}
					else
						out[single].value++;
				}.bind(this));
			return _.values(out);
		},
		maxVisibility: function() {
			_.delay(function() {
				_.each(this.chart.series.values[this.zoom].mapPolygons.values, function(p) {		
					if(this.zoom == 0) {
						p.set('opacity', 1);
						p.set('interactive', true);
						return true;
					}
					if(this.continent && paesi[this.continent].indexOf(p.dataItem.dataContext.id) == -1) {
						p.set('opacity', 0.1);
						p.set('interactive', false);
					} else {
						p.set('opacity', 1);
						p.set('interactive', true);
					}

				}.bind(this));

			}.bind(this), 500);		
		},
		specVisibility: function(id, opacityFind, opacityNotFind) {

			_.each(this.chart.series.values[0].mapPolygons.values, function(p) {
				if(id == p.dataItem.dataContext.id) {
					p.set('opacity', opacityFind);
				} else {
					p.set('opacity', opacityNotFind);
				}
			});

			_.each(this.chart.series.values[1].mapPolygons.values, function(p) {
				if(id == p.dataItem.dataContext.id) {
					p.set('opacity', opacityFind);
				} else {
					p.set('opacity', opacityNotFind);
				}
			});

		},
		applyData: function() {
			if(this.zoom == 0) {
				this.ContinentSeries.data.setAll(this.chartInfo.data);
				this.ContinentSeries.show();
				this.GeoSeries.hide();
			} else if(this.zoom == 1) {
				this.GeoSeries.data.setAll(this.chartInfo.data);
				this.ContinentSeries.hide();
				this.GeoSeries.show();
			}

			this.BubbleSeries.data.setAll(this.chartInfo.data);

			this.BubbleSeries.set("heatRules", [{
				target: this.CircleTemplate,
				dataField: "value",
				min: 10,
				max: 20,
				minValue: _.minBy(this.chartInfo.data, 'value').value,
				maxValue: _.maxBy(this.chartInfo.data, 'value').value,
				key: "radius",
			}]);

			this.GeoSeries.set("heatRules", [{
				target: this.GeoSeries.mapPolygons.template,
				dataField: "value",
				min: am5.color(0xfbba00),
				max: am5.color(0x990a2c),
				key: "fill"
			}]);

			if(this.focus) {
				this.specVisibility(this.focus, 1, 0.1);
			} else
			this.maxVisibility();

		},
		amChartCreate: function() {
			var circleTemplate = am5.Template.new({});
			circleTemplate.events.on("click", function(ev) {
				this.select(ev.target.dataItem.dataContext.id);
			}.bind(this));

			var root = am5.Root.new(this.$refs.chartdiv);
			root.setThemes([
				am5themes_Animated.new(root)
				]);

			var chart = root.container.children.push(am5map.MapChart.new(root, {
				layout: root.horizontalLayout
			}));

			var ContinentSeries = chart.series.push(
				am5map.MapPolygonSeries.new(root, {
					geoJSON: am5geodata_continentsLow,
					exclude: ["antarctica"],
					fill: 'rgb(222,222,222)',
					stroke: 'rgb(50,50,50)',
					valueField: "value",
					calculateAggregates: true
				})
				);

			var GeoSeries = chart.series.push(
				am5map.MapPolygonSeries.new(root, {
					geoJSON: am5geodata_worldLow,
					exclude: ["AQ"],
					fill: 'rgb(222,222,222)',
					stroke: 'rgb(50,50,50)',
					valueField: "value",
					calculateAggregates: true,
				})
				);

			var BubbleSeries = chart.series.push(
				am5map.MapPointSeries.new(root, {
					valueField: "value",
					calculateAggregates: true,
					polygonIdField: "id"
				})
				);

			ContinentSeries.mapPolygons.template.events.on("click", function(ev) {
				this.select(ev.target.dataItem.dataContext.id);
			}.bind(this));

			GeoSeries.mapPolygons.template.events.on("click", function(ev) {
				this.select(ev.target.dataItem.dataContext.id);
			}.bind(this));

			GeoSeries.mapPolygons.template.setAll({
				tooltipText: "{name}: [bold]{value}[/]",
				toggleKey: "active",
				interactive: true
			})

			GeoSeries.hide();

			BubbleSeries.bullets.push(function(root, series, dataItem) {

				let container = am5.Container.new(root, {});
				let circle = container.children.push(am5.Circle.new(root, {
					radius: 20,
					fillOpacity: 0.7,
					fill: 'black',
					cursorOverStyle: "pointer",
					tooltipText: `{name}: [bold]{value}[/]`,
				}, circleTemplate));

				container.children.push(am5.Label.new(root, {
					fontSize: 11,
					text: "{value.formatNumber('#.')}",
					fill: am5.color(0xffffff),
					populateText: true,
					centerX: am5.p50,
					centerY: am5.p50,
					textAlign: "center"
				}));

				return am5.Bullet.new(root, {
					sprite: container,
					dynamic: true
				});
			});

			ContinentSeries.mapPolygons.template.states.create("hover", {
				fill: 'green'
			});
			this.chart = chart;
			this.ContinentSeries = ContinentSeries;
			this.GeoSeries = GeoSeries;
			this.BubbleSeries = BubbleSeries;
			this.CircleTemplate = circleTemplate;
			return true;
		},

		buildMappa: function() {

			if(this.apiData == false) return [];

			var nazioni = {};

			_.each(this.apiData.results, function(i) {
				if(nazioni[i.nazione_home_code] == undefined) {
					nazioni[i.nazione_home_code] = {
						scope: 'nazioni',
						id: i.nazione_home_code,
						name: i.nazione_home,
						partite: [],
						osservati: [],
						osservatori: [],
						gradimenti: []
					}
				}
				if(nazioni[i.nazione_away_code] == undefined) {
					nazioni[i.nazione_away_code] = {
						scope: 'nazioni',
						id: i.nazione_away_code,
						name: i.nazione_away,
						partite: [],
						osservati: [],
						osservatori: [],
						gradimenti: []
					}
				}
				if(i.id_partita != null) {
					if(nazioni[i.nazione_home_code].partite.indexOf(i.partita) == -1) 
						nazioni[i.nazione_home_code].partite.push(i.partita);

					if(nazioni[i.nazione_away_code].partite.indexOf(i.partita) == -1)
						nazioni[i.nazione_away_code].partite.push(i.partita);
				}
				if(i.nazione_osservato != null) {
					if(nazioni[i.nazione_osservato_code].osservati.indexOf(i.osservato) == -1)
						nazioni[i.nazione_osservato_code].osservati.push(i.osservato);

					nazioni[i.nazione_osservato_code].gradimenti.push(i.gradimento);

					if(nazioni[i.nazione_osservato_code].osservatori.indexOf(i.osservatore) == -1)
						nazioni[i.nazione_osservato_code].osservatori.push(i.osservatore);
				}
			});
			nazioni['europe'] = {
				scope: 'continenti',
				id: 'europe',
				name: 'Europa',
				partite: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return europe_.indexOf(a.id) !== -1;
				}), 'partite')),
				osservati: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return europe_.indexOf(a.id) !== -1;
				}), 'osservati')),
				osservatori: [],
				gradimenti: []
			}
			nazioni['africa'] = {
				scope: 'continenti',
				id: 'africa',
				name: 'Africa',
				partite: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return africa_.indexOf(a.id) !== -1;
				}), 'partite')),
				osservati: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return africa_.indexOf(a.id) !== -1;
				}), 'osservati')),
				osservatori: [],
				gradimenti: []
			}
			nazioni['northAmerica'] = {
				scope: 'continenti',
				id: 'northAmerica',
				name: 'Nord America',
				partite: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return na_.indexOf(a.id) !== -1;
				}), 'partite')),
				osservati: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return na_.indexOf(a.id) !== -1;
				}), 'osservati')),
				osservatori: [],
				gradimenti: []
			}
			nazioni['southAmerica'] = {
				scope: 'continenti',
				id: 'southAmerica',
				name: 'Sud America',
				partite: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return sa_.indexOf(a.id) !== -1;
				}), 'partite')),
				osservati: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return sa_.indexOf(a.id) !== -1;
				}), 'osservati')),
				osservatori: [],
				gradimenti: []
			}
			nazioni['asia'] = {
				scope: 'continenti',
				id: 'asia',
				name: 'Asia',
				partite: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return asia_.indexOf(a.id) !== -1;
				}), 'partite')),
				osservati: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return asia_.indexOf(a.id) !== -1;
				}), 'osservati')),
				osservatori: [],
				gradimenti: []
			}
			nazioni['oceania'] = {
				scope: 'continenti',
				id: 'oceania',
				name: 'Oceania',
				partite: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return oceania_.indexOf(a.id) !== -1;
				}), 'partite')),
				osservati: _.flatten(_.map(_.filter(_.values(nazioni), function(a) {
					return oceania_.indexOf(a.id) !== -1;
				}), 'osservati')),
				osservatori: [],
				gradimenti: []
			}
			this.superMappa = _.values(nazioni);
		},
		select: function(id) {
			this.currentNavigate = null;
			if(this.zoom == 0) {
				this.continent = id;
				var polygon = null;
				_.each(this.chart.series.values[0].mapPolygons.values, function(p) {
					if(id == p.dataItem.dataContext.id)
						polygon = p;
				});
				var boundingBox = $mapUtils.getGeoBounds(polygon.dataItem.dataContext.geometry);	
				if(id == 'asia') {
					boundingBox.left = boundingBox.left + (boundingBox.right - boundingBox.left)/2;
					boundingBox.top = boundingBox.top + (boundingBox.bottom - boundingBox.top)/2;
				}
				this.chart.zoomToGeoBounds(boundingBox).waitForStop().then(function() {
					this.zoom = 1;
					this.maxVisibility();
				}.bind(this))

			} else {
				var polygon = null;
				_.each(this.chart.series.values[1].mapPolygons.values, function(p) {
					if(id == p.dataItem.dataContext.id)
						polygon = p;
				});
				var boundingBox = $mapUtils.getGeoBounds(polygon.dataItem.dataContext.geometry);
				boundingBox.left = boundingBox.left - (boundingBox.right - boundingBox.left)
				this.focus = id;
				this.chart.zoomToGeoBounds(boundingBox).waitForStop().then(function(){
					this.specVisibility(this.focus, 1, 0.1);
				}.bind(this));
			}

		},
		resetZoomTo: function(continent) {
			this.currentNavigate = null;
			_.each(this.chart.series.values[0].mapPolygons.values, function(p) {
				if(continent == p.dataItem.dataContext.id) {
					var boundingBox = $mapUtils.getGeoBounds(p.dataItem.dataContext.geometry);
					
					if(continent == 'asia') {
						boundingBox.left = boundingBox.left + (boundingBox.right - boundingBox.left)/2;
						boundingBox.top = boundingBox.top + (boundingBox.bottom - boundingBox.top)/2;
					}

					this.chart.zoomToGeoBounds(boundingBox).waitForStop().then(function() {
						this.focus = null;
						this.maxVisibility();
					}.bind(this));

				}
			}.bind(this));
		},
		resetZoom: function() {
			this.currentNavigate = null;
			this.chart.zoomToPoint({x: 0, y: 0}, 1).waitForStop().then(function() {
				_.delay(function() {
					this.resetSelection();
				}.bind(this), 300);
			}.bind(this));
		},
		resetSelection: function() {
			this.continent = null;
			this.focus = null;
			this.zoom = 0;
		},
		doFilter: function() {
			this.loading = true;
			this.$http.post(this.$store.state.apiEndPoint + '/atlas/scouting/report-nazioni', this.filtering).then(function(data) {
				this.loading = false;
				this.changed = false;
				this.apiData = data.data;
				this.buildMappa();
				this.applyData();
				this.chart.appear();
			});
		}
	},
	created: function() {
		this.$http.get(this.$store.state.apiEndPoint + '/osservatori/newscout').then(function(data) {
			this.osservatori = data.data;
		});
		this.filtering.from = this.moment().add(-90, 'days').format('YYYY-MM-DD');
		this.filtering.to = this.moment().format('YYYY-MM-DD');
	},
	mounted: function() {
		this.amChartCreate();
	}
	
};
</script> 
<style lang="scss">

.custom .scrollpanel-wrapper {
	border-right: 9px solid #fbba00;
}

.custom .scrollpanel-bar {
	background-color: #990a2c;
	opacity: 1;
	transition: background-color .3s;
}

.custom .scrollpanel-bar:hover {
	background-color: #990a2c;
}


.ms {
	cursor: pointer
}

.ms:hover {
	background-color: rgba(0,0,0,.05)
}

.hello {
	width: 100%;
	height: 80vh;
}
</style>  
